/* eslint-disable import/order */
import { PseudoBigNumber } from 'lib/BigInt'
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig
} from 'graphql'
import * as Operations from './documents'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: PseudoBigNumber
  Date: Date
  JSON: { [key: string]: any }
  Upload: any
}

export type Answer = {
  __typename: 'Answer'
  choices: Array<AnswerChoice>
}

export type AnswerChoice = {
  __typename: 'AnswerChoice'
  dateChoice?: Maybe<Scalars['Date']>
  stringChoice?: Maybe<Scalars['String']>
}

export type AnswerChoiceInput = {
  dateChoice?: InputMaybe<Scalars['Date']>
  stringChoice?: InputMaybe<Scalars['String']>
}

export type AnswerInput = {
  choices?: InputMaybe<Array<AnswerChoiceInput>>
}

export type AssetQueryInput = {
  contractTypes?: InputMaybe<Array<ContractTypes>>
}

export enum Blockchain {
  ETH = 'ETH',
  FLOW = 'FLOW'
}

export enum BroadcastRole {
  PUBLISHER = 'PUBLISHER',
  SUBSCRIBER = 'SUBSCRIBER'
}

export type BroadcastSignalInput = {
  command: SignalCommand
  data: Scalars['String']
  eventId: Scalars['ID']
}

export enum BroadcastVideoLayout {
  GRID = 'GRID',
  HOST = 'HOST',
  PIP = 'PIP',
  PRESENTER = 'PRESENTER',
  SPLIT_HORIZONTAL = 'SPLIT_HORIZONTAL',
  SPLIT_VERTICAL = 'SPLIT_VERTICAL'
}

export type BundledCollectiveLog = {
  __typename: 'BundledCollectiveLog'
  collectiveUsers: Array<CollectiveUser>
  date: Scalars['Date']
  eventType: CollectiveLogEventType
  logs: Array<CollectiveLog>
}

export type CastVoteInput = {
  delegationsPayload?: InputMaybe<Array<DelegationsPayload>>
  payload: Scalars['JSON']
  proposalId: Scalars['ID']
  signature?: InputMaybe<Scalars['String']>
  type: VoteType
  useDelegation?: InputMaybe<Scalars['Boolean']>
}

export type CastVoteMutationResponse = {
  __typename: 'CastVoteMutationResponse'
  delegationFailures: Array<DelegationResult>
  votes: Array<CollectiveProposalVote>
}

export type Category = {
  __typename: 'Category'
  archived: Scalars['Boolean']
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  discussionBoard: DiscussionBoard
  governance: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  notificationSettings: DiscussionBoardCategoryNotificationSetting
  order: Scalars['Int']
  publicUrl: Scalars['String']
  removable: Scalars['Boolean']
  threads: ThreadConnection
  totalThreads: Scalars['Int']
  updatedAt: Scalars['Date']
}

export type CategorythreadsArgs = {
  threadConnection: ConnectionInput
}

export type CategoryInput = {
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  order?: InputMaybe<Scalars['Int']>
}

export type ChangePasswordMutationResponse = MutationResponse & {
  __typename: 'ChangePasswordMutationResponse'
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ChoiceBasedResults = {
  __typename: 'ChoiceBasedResults'
  dataset: Array<Scalars['Int']>
  datasetPercent: Array<Scalars['Float']>
  labels: Array<Scalars['String']>
  totalAnswers: Scalars['Int']
}

export type CollaboratorConnection = {
  __typename: 'CollaboratorConnection'
  edges: Array<CollaboratorEdge>
  pageInfo: PageInfo
}

export type CollaboratorEdge = {
  __typename: 'CollaboratorEdge'
  cursor: Scalars['String']
  node: User
}

export type Collective = {
  __typename: 'Collective'
  activeProposals: Scalars['Int']
  address: Scalars['String']
  announcement?: Maybe<CollectiveAnnouncement>
  archived: Scalars['Boolean']
  assets: CollectiveAssetsConnection
  createdAt: Scalars['Date']
  delegatesGrantedByViewer: Array<CommandDelegation>
  delegatesGrantedToViewer: Array<CommandDelegation>
  delegationAddress?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  discussionBoard: DiscussionBoard
  engagements: EngagementConnection
  erc20Assets: Array<ERC20Asset>
  externalAssets: Array<ExternalAsset>
  governanceType: CollectiveTokenType
  id: Scalars['ID']
  logs: CollectiveLogsConnection
  memberFileExport?: Maybe<FileExport>
  members: CollectiveMemberConnection
  membership?: Maybe<CollectiveMember>
  membershipRequest?: Maybe<CollectiveMembershipRequest>
  membershipRequests: Array<CollectiveMembershipRequest>
  name: Scalars['String']
  network: EthNetwork
  nftAssets: Array<NFTAsset>
  ogImageUrl?: Maybe<Scalars['String']>
  parameters: CollectiveParameters
  proposals: CollectiveProposalConnection
  publicUrl: Scalars['String']
  safeAddress: Scalars['String']
  safeThreshold: Scalars['Int']
  signators: Array<CollectiveMember>
  statsDatapoints: Array<CollectiveStatsDatapoint>
  subdomain?: Maybe<Scalars['String']>
  syncStatus: SyncStatus
  thumb?: Maybe<Scalars['String']>
  tokens: Array<CollectiveToken>
  totalMembers: Scalars['Int']
  totalMembershipRequests: Scalars['Int']
  totalProposals: Scalars['Int']
  totalVotingMembers: Scalars['Int']
  treasuryBalance: Scalars['BigInt']
  wallets: Array<CollectiveWallet>
}

export type CollectiveassetsArgs = {
  assetsConnection: ConnectionInput
  where: CollectiveAssetsQueryInput
}

export type CollectiveengagementsArgs = {
  engagementConnection: ConnectionInput
  filters: Array<FilterableEngagementField>
  sort: EngagementSortingInput
}

export type CollectivelogsArgs = {
  requestConnection: ConnectionInput
  where: CollectiveLogsQueryInput
}

export type CollectivememberFileExportArgs = {
  fileExportId: Scalars['ID']
}

export type CollectivemembersArgs = {
  memberConnection: ConnectionInput
}

export type CollectiveproposalsArgs = {
  proposalConnection: ConnectionInput
  searchString?: InputMaybe<Scalars['String']>
}

export type CollectivestatsDatapointsArgs = {
  endDate?: InputMaybe<Scalars['Date']>
  startDate?: InputMaybe<Scalars['Date']>
}

export type CollectiveAnnouncement = {
  __typename: 'CollectiveAnnouncement'
  bodyHtml?: Maybe<Scalars['String']>
  collective: Collective
  createdAt: Scalars['Date']
  id: Scalars['ID']
  postedBy: CollectiveMember
  sendPush: Scalars['Boolean']
}

export type CollectiveApplication = {
  __typename: 'CollectiveApplication'
  createdAt: Scalars['Date']
  id: Scalars['ID']
  payload: Scalars['JSON']
  status: CollectiveApplicationStatus
  thumb?: Maybe<Scalars['String']>
}

export enum CollectiveApplicationStatus {
  APPROVED = 'APPROVED',
  IN_REVIEW = 'IN_REVIEW',
  REJECTED = 'REJECTED'
}

export type CollectiveAsset = ERCAsset | ExternalAsset

export type CollectiveAssetEdge = {
  __typename: 'CollectiveAssetEdge'
  cursor: Scalars['String']
  node: CollectiveAsset
}

export type CollectiveAssetPreview = {
  __typename: 'CollectiveAssetPreview'
  name: Scalars['String']
  symbol: Scalars['String']
  thumb?: Maybe<Scalars['String']>
}

export enum CollectiveAssetType {
  ERC20 = 'ERC20',
  NFT = 'NFT'
}

export type CollectiveAssetsConnection = {
  __typename: 'CollectiveAssetsConnection'
  edges: Array<CollectiveAssetEdge>
  pageInfo: PageInfo
}

export type CollectiveAssetsQueryInput = {
  assetType?: InputMaybe<CollectiveAssetType>
  collectiveWalletType?: InputMaybe<CollectiveWalletType>
  searchString?: InputMaybe<Scalars['String']>
}

export type CollectiveConnection = {
  __typename: 'CollectiveConnection'
  edges: Array<CollectiveEdge>
  pageInfo: PageInfo
}

export type CollectiveDemo = {
  __typename: 'CollectiveDemo'
  collective: Scalars['JSON']
  id: Scalars['ID']
}

export type CollectiveDraft = {
  __typename: 'CollectiveDraft'
  avatar?: Maybe<Scalars['String']>
  createdAt: Scalars['Date']
  customerSupport?: Maybe<Array<Scalars['String']>>
  depositFeeInPercentage?: Maybe<Scalars['Float']>
  description: Scalars['String']
  disableTokenTransfers?: Maybe<Scalars['Boolean']>
  email?: Maybe<Scalars['String']>
  enableDeposits?: Maybe<Scalars['Boolean']>
  enableNewMembershipRequests?: Maybe<Scalars['Boolean']>
  enableWithdraws?: Maybe<Scalars['Boolean']>
  exchangeRate?: Maybe<Scalars['Float']>
  externalTokenAddress?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  gateDeposits?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  intent: CollectiveIntent
  jumpableSteps: Array<InputStep>
  lastName?: Maybe<Scalars['String']>
  lastStep: InputStep
  members?: Maybe<Array<Scalars['String']>>
  minEthContribution?: Maybe<Scalars['BigInt']>
  name: Scalars['String']
  proposalThreshold?: Maybe<Scalars['BigInt']>
  quorumNeededInPercentage?: Maybe<Scalars['Float']>
  requireVotingSignature?: Maybe<Scalars['Boolean']>
  subdomain?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  votingAnonymity?: Maybe<VotingAnonymity>
  withdrawFeeInPercentage?: Maybe<Scalars['Float']>
}

export type CollectiveDraftConnection = {
  __typename: 'CollectiveDraftConnection'
  edges: Array<CollectiveDraftEdge>
  pageInfo: PageInfo
}

export type CollectiveDraftEdge = {
  __typename: 'CollectiveDraftEdge'
  cursor: Scalars['String']
  node: CollectiveDraft
}

export type CollectiveDraftInput = {
  avatarUrl?: InputMaybe<Scalars['String']>
  customerSupport?: InputMaybe<Array<Scalars['String']>>
  depositFeeInPercentage?: InputMaybe<Scalars['Float']>
  description: Scalars['String']
  disableTokenTransfers?: InputMaybe<Scalars['Boolean']>
  email: Scalars['String']
  enableDeposits?: InputMaybe<Scalars['Boolean']>
  enableNewMembershipRequests?: InputMaybe<Scalars['Boolean']>
  enableWithdraws?: InputMaybe<Scalars['Boolean']>
  exchangeRate?: InputMaybe<Scalars['Float']>
  externalTokenAddress?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gateDeposits?: InputMaybe<Scalars['Boolean']>
  governanceTokenType?: InputMaybe<CollectiveTokenType>
  intent: CollectiveIntent
  jumpableSteps: Array<InputStep>
  lastName?: InputMaybe<Scalars['String']>
  lastStep: InputStep
  members?: InputMaybe<Array<Scalars['String']>>
  minEthContribution?: InputMaybe<Scalars['BigInt']>
  name: Scalars['String']
  proposalThreshold?: InputMaybe<Scalars['BigInt']>
  quorumNeededInPercentage?: InputMaybe<Scalars['Float']>
  requireVotingSignature?: InputMaybe<Scalars['Boolean']>
  subdomain?: InputMaybe<Scalars['String']>
  symbol?: InputMaybe<Scalars['String']>
  votingAnonymity?: InputMaybe<VotingAnonymity>
  withdrawFeeInPercentage?: InputMaybe<Scalars['Float']>
}

export type CollectiveEdge = {
  __typename: 'CollectiveEdge'
  cursor: Scalars['String']
  node: Collective
}

export type CollectiveFaq = {
  __typename: 'CollectiveFaq'
  link: Scalars['String']
  question: Scalars['String']
  snippet?: Maybe<Scalars['String']>
}

export enum CollectiveIntent {
  COMMUNITY = 'COMMUNITY',
  INVEST = 'INVEST',
  NFT = 'NFT',
  SOCIAL = 'SOCIAL',
  VAULT = 'VAULT'
}

export type CollectiveLog = {
  __typename: 'CollectiveLog'
  blockNumber?: Maybe<Scalars['Int']>
  collectiveUser?: Maybe<CollectiveUser>
  data: CollectiveLogDataType
  date: Scalars['Date']
  eventType: CollectiveLogEventType
  id: Scalars['ID']
  txHash?: Maybe<Scalars['String']>
}

export type CollectiveLogDataOnAddedOwner = {
  __typename: 'CollectiveLogDataOnAddedOwner'
  eventType: CollectiveLogEventType
  owner: Scalars['String']
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnAnnouncementChanged = {
  __typename: 'CollectiveLogDataOnAnnouncementChanged'
  announcement: CollectiveAnnouncement
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnAnnouncementCreated = {
  __typename: 'CollectiveLogDataOnAnnouncementCreated'
  announcement: CollectiveAnnouncement
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnAnnouncementDeleted = {
  __typename: 'CollectiveLogDataOnAnnouncementDeleted'
  announcement: CollectiveAnnouncement
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnChangedThreshold = {
  __typename: 'CollectiveLogDataOnChangedThreshold'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  threshold: Scalars['BigInt']
}

export type CollectiveLogDataOnCollectiveMembershipProposed = {
  __typename: 'CollectiveLogDataOnCollectiveMembershipProposed'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  request?: Maybe<CollectiveMembershipRequest>
}

export type CollectiveLogDataOnCollectiveMembershipRequested = {
  __typename: 'CollectiveLogDataOnCollectiveMembershipRequested'
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnCollectiveSyncRequested = {
  __typename: 'CollectiveLogDataOnCollectiveSyncRequested'
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnCollectiveSynced = {
  __typename: 'CollectiveLogDataOnCollectiveSynced'
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnDelegationsGranted = {
  __typename: 'CollectiveLogDataOnDelegationsGranted'
  delegations: Array<CommandDelegationStruct>
  delegator: CollectiveUser
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnDelegationsRevoked = {
  __typename: 'CollectiveLogDataOnDelegationsRevoked'
  delegations: Array<CommandDelegationStruct>
  delegator: CollectiveUser
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnDisableTokenTransfersChanged = {
  __typename: 'CollectiveLogDataOnDisableTokenTransfersChanged'
  disableTokenTransfers: Scalars['Boolean']
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnEnableDepositsChanged = {
  __typename: 'CollectiveLogDataOnEnableDepositsChanged'
  enableDeposits: Scalars['Boolean']
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnEnableNewMembershipRequestsChanged = {
  __typename: 'CollectiveLogDataOnEnableNewMembershipRequestsChanged'
  enableNewMembershipRequests: Scalars['Boolean']
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnEnableWithdrawsChanged = {
  __typename: 'CollectiveLogDataOnEnableWithdrawsChanged'
  enableWithdraws: Scalars['Boolean']
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnExchangeRateChanged = {
  __typename: 'CollectiveLogDataOnExchangeRateChanged'
  eventType: CollectiveLogEventType
  newRate: Scalars['BigInt']
  previousRate: Scalars['BigInt']
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnExternalCollectiveJoined = {
  __typename: 'CollectiveLogDataOnExternalCollectiveJoined'
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnFeesChanged = {
  __typename: 'CollectiveLogDataOnFeesChanged'
  depositFee: Scalars['BigInt']
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnFundsDeposited = {
  __typename: 'CollectiveLogDataOnFundsDeposited'
  etherAmount: Scalars['BigInt']
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnGateDepositsChanged = {
  __typename: 'CollectiveLogDataOnGateDepositsChanged'
  eventType: CollectiveLogEventType
  gateDeposits: Scalars['Boolean']
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnMemberAdded = {
  __typename: 'CollectiveLogDataOnMemberAdded'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  targetMember: Scalars['String']
}

export type CollectiveLogDataOnMemberDepositedFunds = {
  __typename: 'CollectiveLogDataOnMemberDepositedFunds'
  etherAmount: Scalars['BigInt']
  eventType: CollectiveLogEventType
  tokenAmount: Scalars['BigInt']
}

export type CollectiveLogDataOnMemberRemoved = {
  __typename: 'CollectiveLogDataOnMemberRemoved'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  targetMember: Scalars['String']
}

export type CollectiveLogDataOnMemberWithdrewFunds = {
  __typename: 'CollectiveLogDataOnMemberWithdrewFunds'
  etherAmount: Scalars['BigInt']
  eventType: CollectiveLogEventType
}

export type CollectiveLogDataOnMinEthContributionChanged = {
  __typename: 'CollectiveLogDataOnMinEthContributionChanged'
  contributionAmount: Scalars['BigInt']
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnNonDepositEtherReceived = {
  __typename: 'CollectiveLogDataOnNonDepositEtherReceived'
  etherAmount: Scalars['BigInt']
  eventType: CollectiveLogEventType
  sender: Scalars['String']
}

export type CollectiveLogDataOnProposalCanceled = {
  __typename: 'CollectiveLogDataOnProposalCanceled'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  reason: Scalars['String']
}

export type CollectiveLogDataOnProposalExecuted = {
  __typename: 'CollectiveLogDataOnProposalExecuted'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnProposalExecutionReset = {
  __typename: 'CollectiveLogDataOnProposalExecutionReset'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnProposalRequireVoteSignatureChanged = {
  __typename: 'CollectiveLogDataOnProposalRequireVoteSignatureChanged'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  requireVotingSignature: Scalars['Boolean']
}

export type CollectiveLogDataOnProposalSigned = {
  __typename: 'CollectiveLogDataOnProposalSigned'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnProposalsBatched = {
  __typename: 'CollectiveLogDataOnProposalsBatched'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnQuorumNeededInPercentageChanged = {
  __typename: 'CollectiveLogDataOnQuorumNeededInPercentageChanged'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  quorumNeededInPercentage: Scalars['Float']
}

export type CollectiveLogDataOnRemovedOwner = {
  __typename: 'CollectiveLogDataOnRemovedOwner'
  eventType: CollectiveLogEventType
  owner: Scalars['String']
  proposal?: Maybe<CollectiveProposal>
}

export type CollectiveLogDataOnTransfer = {
  __typename: 'CollectiveLogDataOnTransfer'
  eventType: CollectiveLogEventType
  from: Scalars['String']
  to: Scalars['String']
  token?: Maybe<CollectiveToken>
  tokenId?: Maybe<Scalars['BigInt']>
  value?: Maybe<Scalars['BigInt']>
}

export type CollectiveLogDataOnTransferBatch = {
  __typename: 'CollectiveLogDataOnTransferBatch'
  eventType: CollectiveLogEventType
  from: Scalars['String']
  to: Scalars['String']
  token?: Maybe<CollectiveToken>
  tokenIds: Array<Scalars['BigInt']>
  values: Array<Scalars['BigInt']>
}

export type CollectiveLogDataOnTransferSingle = {
  __typename: 'CollectiveLogDataOnTransferSingle'
  eventType: CollectiveLogEventType
  from: Scalars['String']
  to: Scalars['String']
  token?: Maybe<CollectiveToken>
  tokenId?: Maybe<Scalars['BigInt']>
  value?: Maybe<Scalars['BigInt']>
}

export type CollectiveLogDataOnVoteCast = {
  __typename: 'CollectiveLogDataOnVoteCast'
  eventType: CollectiveLogEventType
  masterVote?: Maybe<CollectiveProposalVote>
  proposal?: Maybe<CollectiveProposal>
  user: CollectiveMember
  vote: CollectiveProposalVote
}

export type CollectiveLogDataOnVoteChanged = {
  __typename: 'CollectiveLogDataOnVoteChanged'
  eventType: CollectiveLogEventType
  masterVote?: Maybe<CollectiveProposalVote>
  newVote: CollectiveProposalVote
  previousVote: CollectiveProposalVote
  proposal?: Maybe<CollectiveProposal>
  user: CollectiveMember
}

export type CollectiveLogDataOnVoteInvalidated = {
  __typename: 'CollectiveLogDataOnVoteInvalidated'
  eventType: CollectiveLogEventType
  invalidatedVote: CollectiveProposalVote
  oldWeight: Scalars['BigInt']
  proposal?: Maybe<CollectiveProposal>
  user: CollectiveMember
}

export type CollectiveLogDataOnVoteWeightUpdated = {
  __typename: 'CollectiveLogDataOnVoteWeightUpdated'
  eventType: CollectiveLogEventType
  newWeight: Scalars['BigInt']
  oldWeight: Scalars['BigInt']
  proposal?: Maybe<CollectiveProposal>
  updatedVote: CollectiveProposalVote
  user: CollectiveMember
}

export type CollectiveLogDataOnVotingAnonymityChanged = {
  __typename: 'CollectiveLogDataOnVotingAnonymityChanged'
  eventType: CollectiveLogEventType
  proposal?: Maybe<CollectiveProposal>
  votingAnonymity: VotingAnonymity
}

export type CollectiveLogDataOnWalletLinked = {
  __typename: 'CollectiveLogDataOnWalletLinked'
  address: Scalars['String']
  blockchain: Scalars['String']
  eventType: CollectiveLogEventType
  nickname: Scalars['String']
}

export type CollectiveLogDataOnWalletUnlinked = {
  __typename: 'CollectiveLogDataOnWalletUnlinked'
  eventType: CollectiveLogEventType
  walletId: Scalars['String']
}

export type CollectiveLogDataType =
  | CollectiveLogDataOnAddedOwner
  | CollectiveLogDataOnAnnouncementChanged
  | CollectiveLogDataOnAnnouncementCreated
  | CollectiveLogDataOnAnnouncementDeleted
  | CollectiveLogDataOnChangedThreshold
  | CollectiveLogDataOnCollectiveMembershipProposed
  | CollectiveLogDataOnCollectiveMembershipRequested
  | CollectiveLogDataOnCollectiveSyncRequested
  | CollectiveLogDataOnCollectiveSynced
  | CollectiveLogDataOnDelegationsGranted
  | CollectiveLogDataOnDelegationsRevoked
  | CollectiveLogDataOnDisableTokenTransfersChanged
  | CollectiveLogDataOnEnableDepositsChanged
  | CollectiveLogDataOnEnableNewMembershipRequestsChanged
  | CollectiveLogDataOnEnableWithdrawsChanged
  | CollectiveLogDataOnExchangeRateChanged
  | CollectiveLogDataOnExternalCollectiveJoined
  | CollectiveLogDataOnFeesChanged
  | CollectiveLogDataOnFundsDeposited
  | CollectiveLogDataOnGateDepositsChanged
  | CollectiveLogDataOnMemberAdded
  | CollectiveLogDataOnMemberDepositedFunds
  | CollectiveLogDataOnMemberRemoved
  | CollectiveLogDataOnMemberWithdrewFunds
  | CollectiveLogDataOnMinEthContributionChanged
  | CollectiveLogDataOnNonDepositEtherReceived
  | CollectiveLogDataOnProposalCanceled
  | CollectiveLogDataOnProposalExecuted
  | CollectiveLogDataOnProposalExecutionReset
  | CollectiveLogDataOnProposalRequireVoteSignatureChanged
  | CollectiveLogDataOnProposalSigned
  | CollectiveLogDataOnProposalsBatched
  | CollectiveLogDataOnQuorumNeededInPercentageChanged
  | CollectiveLogDataOnRemovedOwner
  | CollectiveLogDataOnTransfer
  | CollectiveLogDataOnTransferBatch
  | CollectiveLogDataOnTransferSingle
  | CollectiveLogDataOnVoteCast
  | CollectiveLogDataOnVoteChanged
  | CollectiveLogDataOnVoteInvalidated
  | CollectiveLogDataOnVoteWeightUpdated
  | CollectiveLogDataOnVotingAnonymityChanged
  | CollectiveLogDataOnWalletLinked
  | CollectiveLogDataOnWalletUnlinked

export enum CollectiveLogEventType {
  ON_ADDED_OWNER = 'ON_ADDED_OWNER',
  ON_ANNOUNCEMENT_CHANGED = 'ON_ANNOUNCEMENT_CHANGED',
  ON_ANNOUNCEMENT_CREATED = 'ON_ANNOUNCEMENT_CREATED',
  ON_ANNOUNCEMENT_DELETED = 'ON_ANNOUNCEMENT_DELETED',
  ON_CHANGED_THRESHOLD = 'ON_CHANGED_THRESHOLD',
  ON_COLLECTIVE_MEMBERSHIP_PROPOSED = 'ON_COLLECTIVE_MEMBERSHIP_PROPOSED',
  ON_COLLECTIVE_MEMBERSHIP_REQUESTED = 'ON_COLLECTIVE_MEMBERSHIP_REQUESTED',
  ON_COLLECTIVE_SYNCED = 'ON_COLLECTIVE_SYNCED',
  ON_COLLECTIVE_SYNC_REQUESTED = 'ON_COLLECTIVE_SYNC_REQUESTED',
  ON_DELEGATION_GRANTED = 'ON_DELEGATION_GRANTED',
  ON_DELEGATION_REVOKED = 'ON_DELEGATION_REVOKED',
  ON_DISABLE_TOKEN_TRANSFERS_CHANGED = 'ON_DISABLE_TOKEN_TRANSFERS_CHANGED',
  ON_ENABLED_NEW_MEMBER_REQUESTS_CHANGED = 'ON_ENABLED_NEW_MEMBER_REQUESTS_CHANGED',
  ON_ENABLE_DESPOSITS_CHANGED = 'ON_ENABLE_DESPOSITS_CHANGED',
  ON_ENABLE_WITHDRAWS_CHANGED = 'ON_ENABLE_WITHDRAWS_CHANGED',
  ON_EXCHANGERATE_CHANGED = 'ON_EXCHANGERATE_CHANGED',
  ON_EXTERNAL_COLLECTIVE_JOINED = 'ON_EXTERNAL_COLLECTIVE_JOINED',
  ON_FEES_CHANGED = 'ON_FEES_CHANGED',
  ON_FUNDS_DEPOSITED = 'ON_FUNDS_DEPOSITED',
  ON_GATE_DEPOSITS_CHANGED = 'ON_GATE_DEPOSITS_CHANGED',
  ON_MEMBER_ADDED = 'ON_MEMBER_ADDED',
  ON_MEMBER_DESPOSITED_FUNDS = 'ON_MEMBER_DESPOSITED_FUNDS',
  ON_MEMBER_REMOVED = 'ON_MEMBER_REMOVED',
  ON_MEMBER_WITHDREW_FUNDS = 'ON_MEMBER_WITHDREW_FUNDS',
  ON_MIN_ETH_CONTRIBUTION_CHANGED = 'ON_MIN_ETH_CONTRIBUTION_CHANGED',
  ON_NON_DEPOSITED_ETHER = 'ON_NON_DEPOSITED_ETHER',
  ON_PROPOSALS_BATCHED = 'ON_PROPOSALS_BATCHED',
  ON_PROPOSAL_CANCELED = 'ON_PROPOSAL_CANCELED',
  ON_PROPOSAL_EXECUTED = 'ON_PROPOSAL_EXECUTED',
  ON_PROPOSAL_EXECUTED_RESET = 'ON_PROPOSAL_EXECUTED_RESET',
  ON_PROPOSAL_REQUIRE_VOTE_SIGNATURE_CHANGED = 'ON_PROPOSAL_REQUIRE_VOTE_SIGNATURE_CHANGED',
  ON_PROPOSAL_SIGNED = 'ON_PROPOSAL_SIGNED',
  ON_QUORUM_NEEDED_IN_PERCETANGE = 'ON_QUORUM_NEEDED_IN_PERCETANGE',
  ON_REMOVED_OWNER = 'ON_REMOVED_OWNER',
  ON_TRANSFER_ = 'ON_TRANSFER_',
  ON_TRANSFER_BATCH = 'ON_TRANSFER_BATCH',
  ON_TRANSFER_SINGLE = 'ON_TRANSFER_SINGLE',
  ON_VOTE_CAST = 'ON_VOTE_CAST',
  ON_VOTE_CHANGED = 'ON_VOTE_CHANGED',
  ON_VOTE_INVALIDATED = 'ON_VOTE_INVALIDATED',
  ON_VOTE_WEIGHT_UPDATED = 'ON_VOTE_WEIGHT_UPDATED',
  ON_VOTING_ANONYMITY_CHANGED = 'ON_VOTING_ANONYMITY_CHANGED',
  ON_WALLET_LINKED = 'ON_WALLET_LINKED',
  ON_WALLET_UNLINKED = 'ON_WALLET_UNLINKED'
}

export type CollectiveLogsConnection = {
  __typename: 'CollectiveLogsConnection'
  edges: Array<CollectiveLogsEdge>
  pageInfo: PageInfo
}

export type CollectiveLogsEdge = {
  __typename: 'CollectiveLogsEdge'
  cursor: Scalars['String']
  node: BundledCollectiveLog
}

export type CollectiveLogsQueryInput = {
  eventTypes?: InputMaybe<Array<CollectiveLogEventType>>
  searchString?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

export type CollectiveMember = {
  __typename: 'CollectiveMember'
  approved: Scalars['Boolean']
  bannedFromEvents: Scalars['Boolean']
  collective: Collective
  createdAt: Scalars['Date']
  id: Scalars['ID']
  percentOfTotalTokenSupply: Scalars['Float']
  privileged: Scalars['Boolean']
  proposalsProposed: CollectiveProposalConnection
  proposalsVoted: Array<CollectiveProposalVote>
  totalTokens: Scalars['BigInt']
  user: CollectiveUser
}

export type CollectiveMemberproposalsProposedArgs = {
  proposalConnection: ConnectionInput
}

export type CollectiveMemberConnection = {
  __typename: 'CollectiveMemberConnection'
  edges: Array<CollectiveMemberEdge>
  pageInfo: PageInfo
}

export type CollectiveMemberEdge = {
  __typename: 'CollectiveMemberEdge'
  cursor: Scalars['String']
  node: CollectiveMember
}

export type CollectiveMembershipRequest = {
  __typename: 'CollectiveMembershipRequest'
  collective: Collective
  createdAt: Scalars['Date']
  id: Scalars['ID']
  membershipProposal?: Maybe<CollectiveProposal>
  notes?: Maybe<Scalars['String']>
  status: CollectiveMembershipRequestStatus
  user: CollectiveUser
}

export enum CollectiveMembershipRequestStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  PROPOSED = 'PROPOSED',
  REJECTED = 'REJECTED'
}

export type CollectiveParameters = {
  __typename: 'CollectiveParameters'
  depositFeeInPercentage: Scalars['Float']
  disableTokenTransfers: Scalars['Boolean']
  enableDeposits: Scalars['Boolean']
  enableNewMembershipRequests: Scalars['Boolean']
  enableWithdraws: Scalars['Boolean']
  exchangeRate: Scalars['Float']
  gateDeposits: Scalars['Boolean']
  minEthContribution: Scalars['BigInt']
  proposalThreshold: Scalars['BigInt']
  quorumNeededInPercentage: Scalars['Float']
  requireVotingSignature: Scalars['Boolean']
  votingAnonymity: VotingAnonymity
  votingDelayInMinutes: Scalars['Int']
  votingPeriodInMinutes: Scalars['Int']
  withdrawFeeInPercentage: Scalars['Float']
}

export type CollectiveProposal = {
  __typename: 'CollectiveProposal'
  batchProposal?: Maybe<CollectiveProposal>
  batchedProposals: Array<CollectiveProposal>
  canVote: Scalars['Boolean']
  collective: Collective
  commands: Array<CollectiveProposalCommand>
  createdAt: Scalars['Date']
  description: Scalars['String']
  descriptionHtml: Scalars['String']
  eligibleDelegations: Array<CollectiveMember>
  endTime: Scalars['Date']
  estimatedExecutionGas?: Maybe<Scalars['BigInt']>
  execution?: Maybe<CollectiveProposalExecution>
  hasOnChainCommands: Scalars['Boolean']
  id: Scalars['ID']
  isAuthor: Scalars['Boolean']
  isBatch: Scalars['Boolean']
  isPartOfBatch: Scalars['Boolean']
  isQuorumReached: Scalars['Boolean']
  isShortForm: Scalars['Boolean']
  isUsingMatchingWallet: Scalars['Boolean']
  isVotable: Scalars['Boolean']
  myVote?: Maybe<VoteType>
  ogImageUrl?: Maybe<Scalars['String']>
  origin?: Maybe<CollectiveProposalOrigin>
  proposedBy: CollectiveMember
  publicUrl: Scalars['String']
  quorumNeeded: Scalars['Int']
  requireVotingSignature: Scalars['Boolean']
  signatures: Array<CollectiveProposalSignature>
  startTime: Scalars['Date']
  status: CollectiveProposalStatus
  statusHistory: Array<CollectiveProposalStatusHistory>
  survey?: Maybe<Survey>
  thread?: Maybe<Thread>
  title: Scalars['String']
  totalVoters: Scalars['BigInt']
  totalVotersAbstain: Scalars['BigInt']
  totalVotersAgainst: Scalars['BigInt']
  totalVotersFor: Scalars['BigInt']
  totalVotes: Scalars['BigInt']
  type: CollectiveProposalType
  votersAbstain: Array<CollectiveProposalVote>
  votersAgainst: Array<CollectiveProposalVote>
  votersFor: Array<CollectiveProposalVote>
  votes: CollectiveProposalVoteConnection
  votesAbstain: Scalars['BigInt']
  votesAgainst: Scalars['BigInt']
  votesFor: Scalars['BigInt']
  votingAnonymity: VotingAnonymity
}

export type CollectiveProposalvotesArgs = {
  voteConnection: ConnectionInput
}

export type CollectiveProposalAddMemberCommand = {
  __typename: 'CollectiveProposalAddMemberCommand'
  memberAddress: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalAddSignatorCommand = {
  __typename: 'CollectiveProposalAddSignatorCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  signatorAddress: Scalars['String']
}

export type CollectiveProposalCallRemoteCommand = {
  __typename: 'CollectiveProposalCallRemoteCommand'
  data: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  value: Scalars['BigInt']
}

export type CollectiveProposalChangeDisableTokenTransfersCommand = {
  __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
  disableTokenTransfers: Scalars['Boolean']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeEnableDepositsCommand = {
  __typename: 'CollectiveProposalChangeEnableDepositsCommand'
  enableDeposits: Scalars['Boolean']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeEnableNewMembershipRequests = {
  __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
  enableNewMembershipRequests: Scalars['Boolean']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeEnableWithdrawsCommand = {
  __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
  enableWithdraws: Scalars['Boolean']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeExchangeRateCommand = {
  __typename: 'CollectiveProposalChangeExchangeRateCommand'
  exchangeRate: Scalars['Float']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeGateDepositsCommand = {
  __typename: 'CollectiveProposalChangeGateDepositsCommand'
  gateDeposits: Scalars['Boolean']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeMinEthContributionCommand = {
  __typename: 'CollectiveProposalChangeMinEthContributionCommand'
  minEthContribution: Scalars['BigInt']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeRequireVotingSignatureCommand = {
  __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
  requireVotingSignature: Scalars['Boolean']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalChangeSafeThresholdCommand = {
  __typename: 'CollectiveProposalChangeSafeThresholdCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  safeThreshold: Scalars['Int']
}

export type CollectiveProposalChangeVotingAnonymityCommand = {
  __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  votingAnonymity: VotingAnonymity
}

export type CollectiveProposalCommand =
  | CollectiveProposalAddMemberCommand
  | CollectiveProposalAddSignatorCommand
  | CollectiveProposalCallRemoteCommand
  | CollectiveProposalChangeDisableTokenTransfersCommand
  | CollectiveProposalChangeEnableDepositsCommand
  | CollectiveProposalChangeEnableNewMembershipRequests
  | CollectiveProposalChangeEnableWithdrawsCommand
  | CollectiveProposalChangeExchangeRateCommand
  | CollectiveProposalChangeGateDepositsCommand
  | CollectiveProposalChangeMinEthContributionCommand
  | CollectiveProposalChangeRequireVotingSignatureCommand
  | CollectiveProposalChangeSafeThresholdCommand
  | CollectiveProposalChangeVotingAnonymityCommand
  | CollectiveProposalLinkWalletCommand
  | CollectiveProposalRemoveMemberCommand
  | CollectiveProposalRemoveSignatorCommand
  | CollectiveProposalSendEtherCommand
  | CollectiveProposalSendEtherDisbursementCommand
  | CollectiveProposalSendEtherDisbursementCommands
  | CollectiveProposalSendTokenCommand
  | CollectiveProposalTransferERC721Command
  | CollectiveProposalTransferERC1155Command
  | CollectiveProposalTransferNFTCommand
  | CollectiveProposalTransferTokensCommand
  | CollectiveProposalUnlinkWalletCommand
  | CollectiveProposalUpdateQuorumCommand

export type CollectiveProposalConnection = {
  __typename: 'CollectiveProposalConnection'
  edges: Array<CollectiveProposalEdge>
  pageInfo: PageInfo
}

export type CollectiveProposalEdge = {
  __typename: 'CollectiveProposalEdge'
  cursor: Scalars['String']
  node: CollectiveProposal
}

export type CollectiveProposalExecution = {
  __typename: 'CollectiveProposalExecution'
  blockNumber: Scalars['Int']
  errorMessage?: Maybe<Scalars['String']>
  status: CollectiveProposalExecutionStatus
  txHash: Scalars['String']
}

export enum CollectiveProposalExecutionStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

export type CollectiveProposalLinkWalletCommand = {
  __typename: 'CollectiveProposalLinkWalletCommand'
  blockchain: Blockchain
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  walletNickname: Scalars['String']
}

export type CollectiveProposalOrigin = CollectiveMembershipRequest

export type CollectiveProposalRemoveMemberCommand = {
  __typename: 'CollectiveProposalRemoveMemberCommand'
  memberAddress: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalRemoveSignatorCommand = {
  __typename: 'CollectiveProposalRemoveSignatorCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  signatorAddress: Scalars['String']
}

export type CollectiveProposalSendEtherCommand = {
  __typename: 'CollectiveProposalSendEtherCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  value: Scalars['BigInt']
}

export type CollectiveProposalSendEtherDisbursementCommand = {
  __typename: 'CollectiveProposalSendEtherDisbursementCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  value: Scalars['BigInt']
}

export type CollectiveProposalSendEtherDisbursementCommands = {
  __typename: 'CollectiveProposalSendEtherDisbursementCommands'
  commands: Array<CollectiveProposalSendEtherDisbursementCommand>
  value: Scalars['BigInt']
}

export type CollectiveProposalSendTokenCommand = {
  __typename: 'CollectiveProposalSendTokenCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  value: Scalars['BigInt']
}

export type CollectiveProposalSignature = {
  __typename: 'CollectiveProposalSignature'
  createdAt: Scalars['Date']
  id: Scalars['ID']
  signature: Scalars['String']
  user: CollectiveUser
  valid: Scalars['Boolean']
}

export enum CollectiveProposalStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DEFEATED = 'DEFEATED',
  EXECUTED = 'EXECUTED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

export type CollectiveProposalStatusHistory = {
  __typename: 'CollectiveProposalStatusHistory'
  createdAt: Scalars['Date']
  status: CollectiveProposalStatus
}

export type CollectiveProposalTransferERC721Command = {
  __typename: 'CollectiveProposalTransferERC721Command'
  asset?: Maybe<CollectiveAssetPreview>
  contractAddress: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  tokenId: Scalars['String']
}

export type CollectiveProposalTransferERC1155Command = {
  __typename: 'CollectiveProposalTransferERC1155Command'
  amount: Scalars['BigInt']
  asset?: Maybe<CollectiveAssetPreview>
  contractAddress: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  tokenId: Scalars['String']
}

export type CollectiveProposalTransferNFTCommand = {
  __typename: 'CollectiveProposalTransferNFTCommand'
  asset?: Maybe<CollectiveAssetPreview>
  contractAddress: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  tokenId: Scalars['String']
}

export type CollectiveProposalTransferTokensCommand = {
  __typename: 'CollectiveProposalTransferTokensCommand'
  asset?: Maybe<CollectiveAssetPreview>
  contractAddress: Scalars['String']
  safeInstructions?: Maybe<Scalars['JSON']>
  target: Scalars['String']
  value: Scalars['BigInt']
}

export enum CollectiveProposalType {
  COMMANDS = 'COMMANDS',
  SURVEY = 'SURVEY'
}

export type CollectiveProposalUnlinkWalletCommand = {
  __typename: 'CollectiveProposalUnlinkWalletCommand'
  safeInstructions?: Maybe<Scalars['JSON']>
  walletId: Scalars['ID']
}

export type CollectiveProposalUpdateQuorumCommand = {
  __typename: 'CollectiveProposalUpdateQuorumCommand'
  quorumNeededInPercentage: Scalars['Float']
  safeInstructions?: Maybe<Scalars['JSON']>
}

export type CollectiveProposalVote = {
  __typename: 'CollectiveProposalVote'
  id: Scalars['ID']
  masterVote?: Maybe<CollectiveProposalVote>
  member: CollectiveMember
  proposal: CollectiveProposal
  signature: Scalars['String']
  signatureUrl?: Maybe<Scalars['String']>
  surveyAnswers?: Maybe<Array<Answer>>
  type: VoteType
  weight: Scalars['BigInt']
}

export type CollectiveProposalVoteConnection = {
  __typename: 'CollectiveProposalVoteConnection'
  edges: Array<CollectiveProposalVoteEdge>
  pageInfo: PageInfo
}

export type CollectiveProposalVoteEdge = {
  __typename: 'CollectiveProposalVoteEdge'
  cursor: Scalars['String']
  node: CollectiveProposalVote
}

export enum CollectiveScreen {
  COLLECTIVES_LIST = 'COLLECTIVES_LIST',
  COLLECTIVE_DETAILS = 'COLLECTIVE_DETAILS',
  MEMBERS_LIST = 'MEMBERS_LIST',
  PROPOSALS_LIST = 'PROPOSALS_LIST',
  PROPOSAL_DETAILS = 'PROPOSAL_DETAILS'
}

export type CollectiveStatsDatapoint = {
  __typename: 'CollectiveStatsDatapoint'
  collective: Collective
  estimatedTotalValueEth: Scalars['BigInt']
  estimatedTotalValueUSD: Scalars['Float']
  time: Scalars['Date']
  totalMembers: Scalars['Int']
  totalProposals: Scalars['Int']
}

export type CollectiveSubmissionLogInput = {
  collectiveId?: InputMaybe<Scalars['ID']>
  debugLogs: Array<Scalars['String']>
  errorMessage?: InputMaybe<Scalars['String']>
  formData?: InputMaybe<Scalars['JSON']>
  network?: InputMaybe<EthNetwork>
  persistenceId?: InputMaybe<Scalars['String']>
  signerAddress?: InputMaybe<Scalars['String']>
  submissionData?: InputMaybe<Scalars['JSON']>
  submissionFailed?: InputMaybe<Scalars['Boolean']>
  submissionStarted?: InputMaybe<Scalars['Boolean']>
  submissionSucceeded?: InputMaybe<Scalars['Boolean']>
  userId?: InputMaybe<Scalars['ID']>
}

export enum CollectiveSyncStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

export type CollectiveTOS = {
  __typename: 'CollectiveTOS'
  collectiveId?: Maybe<Scalars['ID']>
  createdAt: Scalars['Date']
  id: Scalars['ID']
  isSigned: Scalars['Boolean']
  revision: Scalars['Int']
  tosHtml: Scalars['String']
}

export type CollectiveToken = {
  __typename: 'CollectiveToken'
  address: Scalars['String']
  contractType: Scalars['String']
  decimals: Scalars['Int']
  id: Scalars['ID']
  isMainToken: Scalars['Boolean']
  name: Scalars['String']
  symbol: Scalars['String']
  thumb?: Maybe<Scalars['String']>
  totalSupply: Scalars['BigInt']
  totalVotingSupply: Scalars['BigInt']
  type: CollectiveTokenType
  voteWeight: Scalars['BigInt']
}

export enum CollectiveTokenType {
  EXTERNAL = 'EXTERNAL',
  OWNED = 'OWNED'
}

export type CollectiveUser = {
  __typename: 'CollectiveUser'
  address: Scalars['String']
  collectives: Array<Collective>
  createdAt: Scalars['Date']
  id: Scalars['ID']
  platformUser?: Maybe<User>
  proposalsProposed: CollectiveProposalConnection
  proposalsVoted: Array<CollectiveProposalVote>
}

export type CollectiveUserproposalsProposedArgs = {
  proposalConnection: ConnectionInput
}

export type CollectiveWallet = {
  __typename: 'CollectiveWallet'
  address: Scalars['String']
  balance: Scalars['BigInt']
  id: Scalars['ID']
  isMainWallet: Scalars['Boolean']
  nickname?: Maybe<Scalars['String']>
  statsDatapoints: Array<CollectiveWalletStatsDatapoint>
  type: WalletType
}

export type CollectiveWalletstatsDatapointsArgs = {
  endDate?: InputMaybe<Scalars['Date']>
  startDate?: InputMaybe<Scalars['Date']>
}

export type CollectiveWalletStatsDatapoint = {
  __typename: 'CollectiveWalletStatsDatapoint'
  conversionRateUSD: Scalars['Float']
  estimatedNFTValueEth: Scalars['BigInt']
  estimatedNFTValueUSD: Scalars['Float']
  estimatedTokensValueEth: Scalars['BigInt']
  estimatedTokensValueUSD: Scalars['Float']
  estimatedTotalValueEth: Scalars['BigInt']
  estimatedTotalValueUSD: Scalars['Float']
  estimatedValueEth: Scalars['BigInt']
  estimatedValueUSD: Scalars['Float']
  time: Scalars['Date']
  wallet: CollectiveWallet
}

export enum CollectiveWalletType {
  COLLECTIVE = 'COLLECTIVE',
  LINKED = 'LINKED'
}

export type CommandDelegation = {
  __typename: 'CommandDelegation'
  collectiveMember: CollectiveMember
  command?: Maybe<ProposalCommandType>
  maxValue?: Maybe<Scalars['BigInt']>
  surveys?: Maybe<Scalars['Boolean']>
  wildcard?: Maybe<Scalars['Boolean']>
}

export type CommandDelegationStruct = {
  __typename: 'CommandDelegationStruct'
  command?: Maybe<ProposalCommandType>
  delegatee: CollectiveUser
  maxValue?: Maybe<Scalars['BigInt']>
  surveys?: Maybe<Scalars['Boolean']>
  wildcard?: Maybe<Scalars['Boolean']>
}

export type Comment = {
  __typename: 'Comment'
  attachments: Array<ThreadAttachment>
  body: Scalars['String']
  comments: CommentConnection
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  parentComment?: Maybe<Comment>
  parentThread?: Maybe<Thread>
  replyCount: Scalars['Int']
  type: ThreadType
  updatedAt: Scalars['Date']
  user: User
}

export type CommentcommentsArgs = {
  commentConnection: ConnectionInput
}

export type CommentConnection = {
  __typename: 'CommentConnection'
  edges: Array<CommentEdge>
  pageInfo: PageInfo
}

export type CommentCreateInput = {
  attachments: Array<ThreadAttachmentInput>
  body: Scalars['String']
}

export type CommentEdge = {
  __typename: 'CommentEdge'
  cursor: Scalars['String']
  node: Comment
}

export type CommentUpdateInput = {
  body: Scalars['String']
  existingAttachments: Array<Scalars['ID']>
  newAttachments: Array<ThreadAttachmentInput>
}

export type ConnectionInput = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type ContractAbi = {
  __typename: 'ContractAbi'
  abi: Scalars['JSON']
  network: EthNetwork
}

export enum ContractTypes {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155'
}

export type CreateCollectiveInput = {
  address: Scalars['String']
  avatarUrl?: InputMaybe<Scalars['String']>
  collectiveName: Scalars['String']
  delegationAddress: Scalars['String']
  draftId?: InputMaybe<Scalars['ID']>
  governanceTokenType: CollectiveTokenType
  intent: CollectiveIntent
  network: EthNetwork
  safeAddress: Scalars['String']
  subdomain?: InputMaybe<Scalars['String']>
  tokenAddress?: InputMaybe<Scalars['String']>
}

export type CryptoWalletAuth = {
  address: Scalars['String']
  ephemeralCryptoAuthId: Scalars['ID']
  signature: Scalars['String']
}

export enum DJPlayState {
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  STOPPED = 'STOPPED'
}

export type DJSong = {
  __typename: 'DJSong'
  artist: Scalars['String']
  id: Scalars['ID']
  title: Scalars['String']
  url: Scalars['String']
}

export type DJState = {
  __typename: 'DJState'
  nowPlayingSongId?: Maybe<Scalars['ID']>
  playState: DJPlayState
  volume: Scalars['Float']
}

export type DateOptions = {
  __typename: 'DateOptions'
  includeTime: Scalars['Boolean']
  max?: Maybe<Scalars['Date']>
  min?: Maybe<Scalars['Date']>
}

export type DateOptionsInput = {
  includeTime: Scalars['Boolean']
  max?: InputMaybe<Scalars['Date']>
  min?: InputMaybe<Scalars['Date']>
}

export type DelegationResult = {
  __typename: 'DelegationResult'
  collectiveMember: CollectiveMember
  message: Scalars['String']
}

export type DelegationsPayload = {
  delegatorCollectiveUserId: Scalars['ID']
}

export type DeviceInfoInput = {
  appVersion?: InputMaybe<Scalars['String']>
  buildNumber?: InputMaybe<Scalars['String']>
  deviceId?: InputMaybe<Scalars['String']>
  deviceLocale?: InputMaybe<Scalars['String']>
  deviceName?: InputMaybe<Scalars['String']>
  systemVersion?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<Scalars['String']>
  uniqueId?: InputMaybe<Scalars['String']>
}

export type DiscussionBoard = {
  __typename: 'DiscussionBoard'
  categories: Array<Category>
  id: Scalars['ID']
  notificationSettings: DiscussionBoardNotificationSetting
}

export type DiscussionBoardCategoryNotificationSetting = {
  __typename: 'DiscussionBoardCategoryNotificationSetting'
  allowNotifications: Scalars['Boolean']
  id: Scalars['ID']
}

export type DiscussionBoardNotificationSetting = {
  __typename: 'DiscussionBoardNotificationSetting'
  allowGlobalNotifications: Scalars['Boolean']
  id: Scalars['ID']
}

export type DiscussionBoardThreadNotificationSetting = {
  __typename: 'DiscussionBoardThreadNotificationSetting'
  allowNotifications: Scalars['Boolean']
  id: Scalars['ID']
}

export type DynamicFileExportFieldsInput = {
  memberFileExportFields?: InputMaybe<MemberFileExportFieldsInput>
}

export type ERC20Asset = {
  __typename: 'ERC20Asset'
  address: Scalars['String']
  balance: Scalars['BigInt']
  decimals: Scalars['Int']
  estimatedValueEth?: Maybe<Scalars['BigInt']>
  estimatedValueUSD?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  lastSynced: Scalars['Date']
  name: Scalars['String']
  symbol: Scalars['String']
  thumb?: Maybe<Scalars['String']>
  wallet: CollectiveWallet
}

export type ERCAsset = {
  __typename: 'ERCAsset'
  address: Scalars['String']
  balance: Scalars['BigInt']
  contractName: Scalars['String']
  contractType: ContractTypes
  decimals?: Maybe<Scalars['Int']>
  estimatedValueEth?: Maybe<Scalars['BigInt']>
  estimatedValueUSD?: Maybe<Scalars['Float']>
  externalUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastSynced: Scalars['Date']
  logo?: Maybe<Scalars['String']>
  mediaUrls: Array<Scalars['String']>
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['String']>
  tokenId?: Maybe<Scalars['String']>
  user?: Maybe<CollectiveUser>
  wallet?: Maybe<CollectiveWallet>
}

export type EmailPreferences = {
  __typename: 'EmailPreferences'
  allowedTypes: Array<EmailType>
  truncatedEmail: Scalars['String']
  unsubscribedFromAll: Scalars['Boolean']
}

export enum EmailType {
  DM_REMINDER = 'DM_REMINDER',
  NEWSLETTER = 'NEWSLETTER'
}

export type Engagement = Event

export type EngagementConnection = {
  __typename: 'EngagementConnection'
  edges: Array<EngagementEdge>
  pageInfo: PageInfo
}

export type EngagementEdge = {
  __typename: 'EngagementEdge'
  cursor: Scalars['String']
  node: Engagement
}

export type EngagementSortingInput = {
  direction?: InputMaybe<SortDirection>
  field?: InputMaybe<SortableEngagementField>
}

export type EnrichmentEmployment = {
  __typename: 'EnrichmentEmployment'
  company?: Maybe<Scalars['String']>
  isPrimary?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type EnrichmentNFT = {
  __typename: 'EnrichmentNFT'
  address: Scalars['String']
  collectionName: Scalars['String']
  name: Scalars['String']
  tokenId: Scalars['String']
  url: Scalars['String']
}

export type EnrichmentSocialProfile = {
  __typename: 'EnrichmentSocialProfile'
  network?: Maybe<SocialNetwork>
  username?: Maybe<Scalars['String']>
}

export type EnrichmentSuggestions = {
  __typename: 'EnrichmentSuggestions'
  nfts?: Maybe<Array<EnrichmentNFT>>
}

export type EphemeralCryptoAuth = {
  __typename: 'EphemeralCryptoAuth'
  authMessage: Scalars['String']
  id: Scalars['ID']
}

export enum EthNetwork {
  GOERLI = 'GOERLI',
  MAINNET = 'MAINNET'
}

export type Event = {
  __typename: 'Event'
  actualStartTime?: Maybe<Scalars['Date']>
  assets: Array<EventAsset>
  canEdit: Scalars['Boolean']
  canJoinNotice?: Maybe<Scalars['String']>
  canJoinVideo: Scalars['Boolean']
  capacity: Scalars['Int']
  collective?: Maybe<Collective>
  counts: EventCounts
  createdAt: Scalars['Date']
  deleted: Scalars['Boolean']
  description: Scalars['String']
  discoverable: Scalars['Boolean']
  format: EventFormat
  formatDescription: Scalars['String']
  formatLabel: Scalars['String']
  geolocation?: Maybe<GeolocationObject>
  id: Scalars['ID']
  introductionTime: Scalars['Float']
  isCurrentlySpeaking: Scalars['Boolean']
  isOwner: Scalars['Boolean']
  isPrivileged: Scalars['Boolean']
  joinLobbyInMute: Scalars['Boolean']
  location?: Maybe<Scalars['String']>
  media: EventMedia
  messages?: Maybe<EventMessageConnection>
  ogImageUrl?: Maybe<Scalars['String']>
  onlineGuestCount: Scalars['Int']
  onlineGuestIds: Array<Scalars['ID']>
  passive: Scalars['Boolean']
  plan?: Maybe<EventPlan>
  publicUrl: Scalars['String']
  recording: Scalars['Boolean']
  registrations: EventRegistrationConnection
  rsvp?: Maybe<EventRegistrationRSVP>
  series?: Maybe<EventSeries>
  shareable: Scalars['Boolean']
  speakers: Array<User>
  startTime: Scalars['Date']
  state: EventState
  thumb?: Maybe<Scalars['String']>
  title: Scalars['String']
  user: User
  userPlan?: Maybe<EventUserPlan>
  waitlisted: Scalars['Boolean']
}

export type EventmessagesArgs = {
  messageConnection?: InputMaybe<ConnectionInput>
}

export type EventregistrationsArgs = {
  registrationConnection: ConnectionInput
}

export type EventAsset = {
  __typename: 'EventAsset'
  caption: Scalars['String']
  createdAt: Scalars['Date']
  deleted: Scalars['Boolean']
  event: Event
  id: Scalars['ID']
  public: Scalars['Boolean']
  thumbnail?: Maybe<Scalars['String']>
  type: EventAssetType
  url: Scalars['String']
}

export enum EventAssetType {
  AUDIO_FILE = 'AUDIO_FILE',
  IMAGE_FILE = 'IMAGE_FILE',
  VIDEO_FILE = 'VIDEO_FILE'
}

export type EventBorder = {
  __typename: 'EventBorder'
  id: Scalars['ID']
  lobbyHeightRatio?: Maybe<Scalars['Float']>
  lobbyTopUrl?: Maybe<Scalars['String']>
  meetingBottomUrl?: Maybe<Scalars['String']>
  meetingHeightRatio?: Maybe<Scalars['Float']>
  meetingTopUrl?: Maybe<Scalars['String']>
}

export type EventConnection = {
  __typename: 'EventConnection'
  edges: Array<EventEdge>
  pageInfo: PageInfo
}

export type EventCounts = {
  __typename: 'EventCounts'
  attended?: Maybe<Scalars['Int']>
  attending: Scalars['Int']
  matched?: Maybe<Scalars['Int']>
  maybeAttending: Scalars['Int']
  notAttending: Scalars['Int']
  passive?: Maybe<Scalars['Int']>
}

export type EventEdge = {
  __typename: 'EventEdge'
  cursor: Scalars['String']
  node: Event
}

export enum EventFormat {
  FREE_FOR_ALL = 'FREE_FOR_ALL',
  HOLIDAY_MIXER = 'HOLIDAY_MIXER',
  IN_PERSON = 'IN_PERSON',
  MIXER = 'MIXER'
}

export type EventInput = {
  capacity: Scalars['Int']
  collectiveId?: InputMaybe<Scalars['ID']>
  description: Scalars['String']
  discoverable: Scalars['Boolean']
  format: EventFormat
  geolocation?: InputMaybe<GeolocationObjectInput>
  introductionTime?: InputMaybe<Scalars['Float']>
  location?: InputMaybe<Scalars['String']>
  quickEmojis?: InputMaybe<Array<Scalars['String']>>
  recording: Scalars['Boolean']
  seriesId?: InputMaybe<Scalars['ID']>
  shareable: Scalars['Boolean']
  startTime: Scalars['Date']
  title: Scalars['String']
}

export type EventMedia = {
  __typename: 'EventMedia'
  borders: Array<EventBorder>
  djPlaylist: Array<DJSong>
  djUser: User
  enableBorders: Scalars['Boolean']
  enableDJ: Scalars['Boolean']
}

export type EventMessage = {
  __typename: 'EventMessage'
  createdAt?: Maybe<Scalars['Date']>
  deleted: Scalars['Boolean']
  event: Event
  id: Scalars['ID']
  text: Scalars['String']
  type: EventMessageType
  user: User
}

export type EventMessageConnection = {
  __typename: 'EventMessageConnection'
  edges: Array<EventMessageEdge>
  pageInfo: PageInfo
}

export type EventMessageEdge = {
  __typename: 'EventMessageEdge'
  cursor: Scalars['String']
  node: EventMessage
}

export enum EventMessageType {
  TEXT = 'TEXT',
  USER_JOINED = 'USER_JOINED',
  USER_LEFT = 'USER_LEFT',
  USER_STALE = 'USER_STALE'
}

export type EventPlan = {
  __typename: 'EventPlan'
  event: Event
  layout: BroadcastVideoLayout
  mainChannelSessionId: Scalars['String']
  speakers?: Maybe<Array<User>>
}

export type EventRegistration = {
  __typename: 'EventRegistration'
  attended?: Maybe<Scalars['Boolean']>
  banned: Scalars['Boolean']
  createdAt: Scalars['Date']
  deleted: Scalars['Boolean']
  event: Event
  id: Scalars['ID']
  online: Scalars['Boolean']
  passive: Scalars['Boolean']
  role: EventRegistrationRole
  rsvp: EventRegistrationRSVP
  user: User
  waitlisted: Scalars['Boolean']
}

export type EventRegistrationConnection = {
  __typename: 'EventRegistrationConnection'
  edges: Array<EventRegistrationEdge>
  pageInfo: PageInfo
}

export type EventRegistrationEdge = {
  __typename: 'EventRegistrationEdge'
  cursor: Scalars['String']
  node: EventRegistration
}

export enum EventRegistrationRSVP {
  MAYBE = 'MAYBE',
  NO = 'NO',
  PENDING = 'PENDING',
  YES = 'YES'
}

export enum EventRegistrationRole {
  GUEST = 'GUEST',
  HOST = 'HOST',
  SPEAKER = 'SPEAKER'
}

export enum EventSegment {
  PAST = 'PAST',
  UPCOMING = 'UPCOMING'
}

export type EventSeries = {
  __typename: 'EventSeries'
  id: Scalars['String']
  label: Scalars['String']
  slug: Scalars['String']
}

export type EventSignal = {
  __typename: 'EventSignal'
  command: SignalCommand
  createdAt: Scalars['Date']
  data: Scalars['String']
  event: Event
  sender: User
}

export enum EventState {
  CANCELED = 'CANCELED',
  ENDED = 'ENDED',
  HOST_INTRO = 'HOST_INTRO',
  SCHEDULED = 'SCHEDULED'
}

export type EventUserPlan = {
  __typename: 'EventUserPlan'
  currentChannel: EventVideoChannel
  currentLayout: BroadcastVideoLayout
  currentSpeakers?: Maybe<Array<User>>
  fetchedAt: Scalars['Date']
  lobbyChannel: EventVideoChannel
  mainChannel: EventVideoChannel
  nextRefecthAt?: Maybe<Scalars['Date']>
}

export type EventVideoChannel = {
  __typename: 'EventVideoChannel'
  provider: VideoProvider
  sessionId: Scalars['String']
  token: Scalars['String']
}

export type EventsInSeriesInput = {
  id?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
}

export type ExternalAsset = {
  __typename: 'ExternalAsset'
  address: Scalars['String']
  estimatedValueUSD?: Maybe<Scalars['Float']>
  externalUrl?: Maybe<Scalars['String']>
  formattedAmount?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastSynced: Scalars['Date']
  symbol?: Maybe<Scalars['String']>
  thumb?: Maybe<Scalars['String']>
  type: ExternalAssetType
  wallet?: Maybe<CollectiveWallet>
}

export enum ExternalAssetType {
  FLOW = 'FLOW'
}

export type FeaturedSpeaker = {
  __typename: 'FeaturedSpeaker'
  event: Event
  speaker: User
}

export type FileExport = {
  __typename: 'FileExport'
  errorMessage?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['String']>
  id: Scalars['ID']
  status: FileExportStatus
  type: FileExportType
}

export type FileExportInput = {
  fields: DynamicFileExportFieldsInput
  type: FileExportType
}

export enum FileExportStatus {
  ERROR = 'ERROR',
  FINISHED = 'FINISHED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING'
}

export enum FileExportType {
  MEMBER = 'MEMBER'
}

export type FileGet = {
  __typename: 'FileGet'
  url: Scalars['String']
}

export type FileUpload = {
  __typename: 'FileUpload'
  key: Scalars['String']
  url: Scalars['String']
}

export enum FilterableEngagementField {
  HOSTED = 'HOSTED',
  SPOKE_AT = 'SPOKE_AT',
  STATUS_ENDED = 'STATUS_ENDED',
  STATUS_NOT_ENDED = 'STATUS_NOT_ENDED',
  TYPE_SOCIAL = 'TYPE_SOCIAL'
}

export type GenericMutationResponse = MutationResponse & {
  __typename: 'GenericMutationResponse'
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GeolocationObject = {
  __typename: 'GeolocationObject'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type GeolocationObjectInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export enum IncidentLocation {
  AUDIO_LOBBY = 'AUDIO_LOBBY',
  EVENT_BREAKOUT = 'EVENT_BREAKOUT',
  OTHER = 'OTHER'
}

export enum InputStep {
  CONTRACT_SETTINGS = 'CONTRACT_SETTINGS',
  DAO_ADDITIONAL_DETAILS = 'DAO_ADDITIONAL_DETAILS',
  DAO_DETAILS = 'DAO_DETAILS',
  MEMBERS = 'MEMBERS',
  PERSONAL_INFO = 'PERSONAL_INFO',
  SUBMIT = 'SUBMIT',
  TOKEN_INFO = 'TOKEN_INFO'
}

export type JobFairParticipantCompany = {
  __typename: 'JobFairParticipantCompany'
  lookingToHire: Scalars['String']
  user: User
}

export type Languages = {
  __typename: 'Languages'
  en?: Maybe<Scalars['JSON']>
}

export type LocalFile = {
  __typename: 'LocalFile'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

export type LocalFileInput = {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  uri?: InputMaybe<Scalars['String']>
}

export type LoginInput = {
  deviceInfo?: InputMaybe<DeviceInfoInput>
  email: Scalars['String']
  password: Scalars['String']
}

export type LoginMutationResponse = MutationResponse & {
  __typename: 'LoginMutationResponse'
  code?: Maybe<Scalars['String']>
  jwtToken: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type LoginWithAppleIdInput = {
  appleUserAccountId: Scalars['String']
  code?: InputMaybe<Scalars['String']>
  deviceInfo: DeviceInfoInput
  identityToken: Scalars['String']
}

export type MediaUpload = {
  __typename: 'MediaUpload'
  createdAt?: Maybe<Scalars['Date']>
  deleted: Scalars['Boolean']
  id: Scalars['ID']
  mediaUrl: Scalars['String']
  owner: User
  thumbUrl: Scalars['String']
  type: MediaUploadType
}

export enum MediaUploadType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

export enum MeetingHint {
  AVOID = 'AVOID',
  SUGGEST = 'SUGGEST'
}

export type MemberFileExportFieldsInput = {
  address: Scalars['Boolean']
  events: Scalars['Boolean']
  name: Scalars['Boolean']
  totalTokens: Scalars['Boolean']
}

export type MetaTag = {
  __typename: 'MetaTag'
  content: Scalars['String']
  name: Scalars['String']
}

export type Mutation = {
  __typename: 'Mutation'
  agreeToCollectiveTos?: Maybe<CollectiveTOS>
  answerCollectiveProposalSurvey: CollectiveProposalVote
  archiveCategory: Category
  authCrypto?: Maybe<Viewer>
  banAttendee?: Maybe<EventRegistration>
  banMemberFromEvents?: Maybe<CollectiveMember>
  batchProposals?: Maybe<CollectiveProposal>
  cancelProposal?: Maybe<CollectiveProposal>
  castVote?: Maybe<CollectiveProposalVote>
  castVoteWithDelegations?: Maybe<CastVoteMutationResponse>
  changePassword?: Maybe<ChangePasswordMutationResponse>
  createCategory: Category
  createCollective?: Maybe<Collective>
  createCollectiveDraft?: Maybe<CollectiveDraft>
  createComment: Comment
  createEvent?: Maybe<Event>
  createMemberFileExport: FileExport
  createProposal?: Maybe<CollectiveProposal>
  createThread: Thread
  deleteAccount?: Maybe<GenericMutationResponse>
  deleteAnnouncement?: Maybe<Collective>
  deleteCategory?: Maybe<GenericMutationResponse>
  deleteCollectiveDraft?: Maybe<CollectiveDraft>
  deleteEvent?: Maybe<Event>
  deleteThread?: Maybe<GenericMutationResponse>
  editAnnouncement?: Maybe<CollectiveAnnouncement>
  editEventAsset: EventAsset
  endSpeaker?: Maybe<GenericMutationResponse>
  execProposal?: Maybe<CollectiveProposal>
  joinExternalCollective?: Maybe<Collective>
  leaveEvent?: Maybe<Event>
  login?: Maybe<LoginMutationResponse>
  loginCrypto?: Maybe<LoginMutationResponse>
  loginWithAppleId?: Maybe<LoginMutationResponse>
  pinThread: Thread
  postAnnouncement?: Maybe<Collective>
  postCollectiveSubmissionLog?: Maybe<GenericMutationResponse>
  prioritizeUserForAssetSync?: Maybe<User>
  proposeCollectiveMembership?: Maybe<CollectiveProposal>
  recoverPassword?: Maybe<GenericMutationResponse>
  reportContent?: Maybe<ReportContentMutationResponse>
  requestCollectiveMembership?: Maybe<CollectiveMembershipRequest>
  resendVerificationEmail?: Maybe<GenericMutationResponse>
  resetExecution?: Maybe<CollectiveProposal>
  resetPassword?: Maybe<ResetPasswordMutationResponse>
  rsvpToEvent?: Maybe<Event>
  saveEmailPreferences: EmailPreferences
  setAttendeeRole?: Maybe<EventRegistration>
  setEventState?: Maybe<Event>
  setPassiveMode?: Maybe<Event>
  setPreference?: Maybe<UserPreference>
  setRecordingState?: Maybe<Event>
  setSpeaker?: Maybe<GenericMutationResponse>
  signProposal?: Maybe<CollectiveProposal>
  signalAttendee?: Maybe<GenericMutationResponse>
  signalAttendees?: Maybe<GenericMutationResponse>
  signup?: Maybe<SignupMutationResponse>
  signupWithAppleId?: Maybe<SignupMutationResponse>
  startEvent?: Maybe<Event>
  submitCollectiveApplication?: Maybe<CollectiveApplication>
  syncCollective?: Maybe<Collective>
  unlinkWallet?: Maybe<Viewer>
  unsubscribeFromAllEmails: EmailPreferences
  updateCategory: Category
  updateCollectiveApplication?: Maybe<CollectiveApplication>
  updateCollectiveDraft?: Maybe<CollectiveDraft>
  updateComment: Comment
  updateDiscussionBoardCategoryNotificationSetting: DiscussionBoard
  updateDiscussionBoardNotificationSetting: DiscussionBoard
  updateDiscussionBoardThreadNotificationSetting: Thread
  updateEvent?: Maybe<Event>
  updateThread: Thread
  updateUser?: Maybe<Viewer>
  upgradeWaitlistedAttendee?: Maybe<EventRegistration>
  uploadAvatar?: Maybe<Viewer>
  uploadAvatarURL?: Maybe<Viewer>
  uploadCollectiveApplicationAvatar?: Maybe<CollectiveApplication>
  uploadCollectiveAvatar?: Maybe<Collective>
  uploadCollectiveDraftAvatar: Scalars['String']
  uploadEventThumb?: Maybe<Event>
  verifyEmail?: Maybe<Viewer>
}

export type MutationagreeToCollectiveTosArgs = {
  id?: InputMaybe<Scalars['ID']>
  type: TosAgreementType
}

export type MutationanswerCollectiveProposalSurveyArgs = {
  survey: SurveyInput
}

export type MutationarchiveCategoryArgs = {
  archived: Scalars['Boolean']
  categoryId: Scalars['ID']
}

export type MutationauthCryptoArgs = {
  address: Scalars['String']
  signature: Scalars['String']
}

export type MutationbanAttendeeArgs = {
  eventId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationbanMemberFromEventsArgs = {
  collectiveId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationbatchProposalsArgs = {
  proposalIds: Array<Scalars['ID']>
}

export type MutationcancelProposalArgs = {
  proposalId: Scalars['ID']
  reason?: InputMaybe<Scalars['String']>
}

export type MutationcastVoteArgs = {
  vote: CastVoteInput
}

export type MutationcastVoteWithDelegationsArgs = {
  vote: CastVoteInput
}

export type MutationchangePasswordArgs = {
  password: Scalars['String']
}

export type MutationcreateCategoryArgs = {
  categoryDetails: CategoryInput
  discussionBoardId: Scalars['ID']
}

export type MutationcreateCollectiveArgs = {
  input: CreateCollectiveInput
}

export type MutationcreateCollectiveDraftArgs = {
  input: CollectiveDraftInput
}

export type MutationcreateCommentArgs = {
  commentDetails: CommentCreateInput
  threadId: Scalars['ID']
}

export type MutationcreateEventArgs = {
  event: EventInput
}

export type MutationcreateMemberFileExportArgs = {
  collectiveId: Scalars['ID']
  where: FileExportInput
}

export type MutationcreateProposalArgs = {
  collectiveId: Scalars['ID']
  proposal: ProposalInput
}

export type MutationcreateThreadArgs = {
  categoryId: Scalars['ID']
  threadDetails: ThreadCreateInput
}

export type MutationdeleteAnnouncementArgs = {
  id: Scalars['ID']
}

export type MutationdeleteCategoryArgs = {
  categoryId: Scalars['ID']
}

export type MutationdeleteCollectiveDraftArgs = {
  collectiveDraftId: Scalars['ID']
}

export type MutationdeleteEventArgs = {
  eventId: Scalars['ID']
}

export type MutationdeleteThreadArgs = {
  threadId: Scalars['ID']
}

export type MutationeditAnnouncementArgs = {
  announcement: PostAnnouncementInput
  announcementId: Scalars['ID']
}

export type MutationeditEventAssetArgs = {
  assetId: Scalars['ID']
  caption: Scalars['String']
  public: Scalars['Boolean']
}

export type MutationendSpeakerArgs = {
  eventId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationexecProposalArgs = {
  blockNumber: Scalars['Int']
  proposalId: Scalars['ID']
  txHash: Scalars['String']
}

export type MutationjoinExternalCollectiveArgs = {
  collectiveId: Scalars['ID']
}

export type MutationleaveEventArgs = {
  eventId: Scalars['ID']
}

export type MutationloginArgs = {
  login: LoginInput
}

export type MutationloginCryptoArgs = {
  login: CryptoWalletAuth
}

export type MutationloginWithAppleIdArgs = {
  login: LoginWithAppleIdInput
}

export type MutationpinThreadArgs = {
  pinned: Scalars['Boolean']
  threadId: Scalars['ID']
}

export type MutationpostAnnouncementArgs = {
  announcement: PostAnnouncementInput
  collectiveId: Scalars['ID']
}

export type MutationpostCollectiveSubmissionLogArgs = {
  log: CollectiveSubmissionLogInput
}

export type MutationprioritizeUserForAssetSyncArgs = {
  id: Scalars['ID']
}

export type MutationproposeCollectiveMembershipArgs = {
  notes?: InputMaybe<Scalars['String']>
  requestId: Scalars['ID']
}

export type MutationrecoverPasswordArgs = {
  email: Scalars['String']
}

export type MutationreportContentArgs = {
  report: ReportContentInput
}

export type MutationrequestCollectiveMembershipArgs = {
  collectiveId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
}

export type MutationresetExecutionArgs = {
  proposalId: Scalars['ID']
}

export type MutationresetPasswordArgs = {
  reset: ResetPasswordInput
}

export type MutationrsvpToEventArgs = {
  eventId: Scalars['ID']
  rsvp: EventRegistrationRSVP
}

export type MutationsaveEmailPreferencesArgs = {
  allowedTypes: Array<EmailType>
  emailId: Scalars['ID']
  unsubscribedFromAll: Scalars['Boolean']
}

export type MutationsetAttendeeRoleArgs = {
  eventId: Scalars['ID']
  role: EventRegistrationRole
  userId: Scalars['ID']
}

export type MutationsetEventStateArgs = {
  eventId: Scalars['ID']
  eventState: EventState
}

export type MutationsetPassiveModeArgs = {
  eventId: Scalars['ID']
  passive: Scalars['Boolean']
}

export type MutationsetPreferenceArgs = {
  preference: SetPreferenceInput
}

export type MutationsetRecordingStateArgs = {
  eventId: Scalars['ID']
  newRecordingState: Scalars['Boolean']
}

export type MutationsetSpeakerArgs = {
  eventId: Scalars['ID']
  layout?: InputMaybe<BroadcastVideoLayout>
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationsignProposalArgs = {
  nonce: Scalars['Int']
  proposalId: Scalars['ID']
  signature: Scalars['String']
  txHash?: InputMaybe<Scalars['String']>
}

export type MutationsignalAttendeeArgs = {
  signal: SignalInput
}

export type MutationsignalAttendeesArgs = {
  signal: BroadcastSignalInput
}

export type MutationsignupArgs = {
  signup: SignupInput
}

export type MutationsignupWithAppleIdArgs = {
  signup: SignupWithAppleIdInput
}

export type MutationstartEventArgs = {
  eventId: Scalars['ID']
}

export type MutationsubmitCollectiveApplicationArgs = {
  payload: Scalars['JSON']
}

export type MutationsyncCollectiveArgs = {
  collectiveId: Scalars['ID']
}

export type MutationunlinkWalletArgs = {
  address: Scalars['String']
}

export type MutationunsubscribeFromAllEmailsArgs = {
  emailId: Scalars['ID']
}

export type MutationupdateCategoryArgs = {
  categoryDetails: CategoryInput
  categoryId: Scalars['ID']
}

export type MutationupdateCollectiveApplicationArgs = {
  applicationId: Scalars['ID']
  payload: Scalars['JSON']
}

export type MutationupdateCollectiveDraftArgs = {
  collectiveDraftId: Scalars['ID']
  input: CollectiveDraftInput
}

export type MutationupdateCommentArgs = {
  commentDetails: CommentUpdateInput
  threadId: Scalars['ID']
}

export type MutationupdateDiscussionBoardCategoryNotificationSettingArgs = {
  allowNotifications: Scalars['Boolean']
  categoryId: Scalars['ID']
}

export type MutationupdateDiscussionBoardNotificationSettingArgs = {
  allowGlobalNotifications: Scalars['Boolean']
  discussionBoardId: Scalars['ID']
}

export type MutationupdateDiscussionBoardThreadNotificationSettingArgs = {
  allowNotifications: Scalars['Boolean']
  threadId: Scalars['ID']
}

export type MutationupdateEventArgs = {
  event: EventInput
  eventId: Scalars['ID']
}

export type MutationupdateThreadArgs = {
  threadDetails: ThreadUpdateInput
  threadId: Scalars['ID']
}

export type MutationupdateUserArgs = {
  update: UpdateUserInput
}

export type MutationupgradeWaitlistedAttendeeArgs = {
  eventId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationuploadAvatarArgs = {
  file: Scalars['Upload']
}

export type MutationuploadAvatarURLArgs = {
  avatarURL: Scalars['String']
}

export type MutationuploadCollectiveApplicationAvatarArgs = {
  applicationId: Scalars['ID']
  file: Scalars['Upload']
}

export type MutationuploadCollectiveAvatarArgs = {
  collectiveId: Scalars['ID']
  file: Scalars['Upload']
}

export type MutationuploadCollectiveDraftAvatarArgs = {
  collectiveDraftId?: InputMaybe<Scalars['ID']>
  file: Scalars['Upload']
}

export type MutationuploadEventThumbArgs = {
  eventId: Scalars['ID']
  file: Scalars['Upload']
}

export type MutationverifyEmailArgs = {
  emailVerificationKey: Scalars['String']
}

export type MutationResponse = {
  code?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type NFTAsset = {
  __typename: 'NFTAsset'
  address: Scalars['String']
  amount: Scalars['BigInt']
  contractType: Scalars['String']
  description?: Maybe<Scalars['String']>
  estimatedValueEth?: Maybe<Scalars['BigInt']>
  estimatedValueUSD?: Maybe<Scalars['Float']>
  externalUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastSynced: Scalars['Date']
  mediaUrls: Array<Scalars['String']>
  name: Scalars['String']
  symbol: Scalars['String']
  tokenId: Scalars['String']
  uri?: Maybe<Scalars['String']>
  wallet: CollectiveWallet
}

export type PageInfo = {
  __typename: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PostAnnouncementInput = {
  bodyHtml: Scalars['String']
  sendPush?: InputMaybe<Scalars['Boolean']>
}

export type PostEventMessageInput = {
  eventId: Scalars['ID']
  text: Scalars['String']
}

export type PreviouslyRecordedEvent = {
  __typename: 'PreviouslyRecordedEvent'
  caption: Scalars['String']
  createdAt: Scalars['Date']
  id: Scalars['ID']
  thumb: Scalars['String']
  url: Scalars['String']
}

export type ProposalCommandInput = {
  amount?: InputMaybe<Scalars['BigInt']>
  blockchain?: InputMaybe<Blockchain>
  contractAddress?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['String']>
  disableTokenTransfers?: InputMaybe<Scalars['Boolean']>
  enableDeposits?: InputMaybe<Scalars['Boolean']>
  enableNewMembershipRequests?: InputMaybe<Scalars['Boolean']>
  enableWithdraws?: InputMaybe<Scalars['Boolean']>
  exchangeRate?: InputMaybe<Scalars['Float']>
  gateDeposits?: InputMaybe<Scalars['Boolean']>
  memberAddress?: InputMaybe<Scalars['String']>
  minEthContribution?: InputMaybe<Scalars['BigInt']>
  quorumNeededInPercentage?: InputMaybe<Scalars['Float']>
  requireVotingSignature?: InputMaybe<Scalars['Boolean']>
  safeThreshold?: InputMaybe<Scalars['Int']>
  signatorAddress?: InputMaybe<Scalars['String']>
  target?: InputMaybe<Scalars['String']>
  tokenId?: InputMaybe<Scalars['String']>
  type: ProposalCommandType
  value?: InputMaybe<Scalars['BigInt']>
  votingAnonymity?: InputMaybe<VotingAnonymity>
  walletId?: InputMaybe<Scalars['ID']>
  walletNickname?: InputMaybe<Scalars['String']>
}

export enum ProposalCommandType {
  ADD_MEMBER = 'ADD_MEMBER',
  ADD_SIGNATOR = 'ADD_SIGNATOR',
  CALL_REMOTE = 'CALL_REMOTE',
  CHANGE_DISABLE_TOKEN_TRANSFERS = 'CHANGE_DISABLE_TOKEN_TRANSFERS',
  CHANGE_ENABLE_DEPOSITS = 'CHANGE_ENABLE_DEPOSITS',
  CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS = 'CHANGE_ENABLE_NEW_MEMBERSHIP_REQUESTS',
  CHANGE_ENABLE_WITHDRAWS = 'CHANGE_ENABLE_WITHDRAWS',
  CHANGE_EXCHANGE_RATE = 'CHANGE_EXCHANGE_RATE',
  CHANGE_GATE_DEPOSITS = 'CHANGE_GATE_DEPOSITS',
  CHANGE_MIN_ETH_CONTRIBUTION = 'CHANGE_MIN_ETH_CONTRIBUTION',
  CHANGE_QUORUM = 'CHANGE_QUORUM',
  CHANGE_REQUIRE_VOTE_SIGNATURE = 'CHANGE_REQUIRE_VOTE_SIGNATURE',
  CHANGE_SAFE_THRESHOLD = 'CHANGE_SAFE_THRESHOLD',
  CHANGE_VOTING_ANONYMITY = 'CHANGE_VOTING_ANONYMITY',
  LINK_WALLET = 'LINK_WALLET',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  REMOVE_SIGNATOR = 'REMOVE_SIGNATOR',
  REMOVE_ZERO_BALANCE_MEMBERS = 'REMOVE_ZERO_BALANCE_MEMBERS',
  SEND_DISBURSEMENT = 'SEND_DISBURSEMENT',
  SEND_ETHER = 'SEND_ETHER',
  SEND_TOKEN = 'SEND_TOKEN',
  TRANSFER_ERC721 = 'TRANSFER_ERC721',
  TRANSFER_ERC1155 = 'TRANSFER_ERC1155',
  TRANSFER_NFT = 'TRANSFER_NFT',
  TRANSFER_TOKENS = 'TRANSFER_TOKENS',
  UNLINK_WALLET = 'UNLINK_WALLET'
}

export type ProposalInput = {
  commands: Array<ProposalCommandInput>
  description?: InputMaybe<Scalars['String']>
  durationInHours?: InputMaybe<Scalars['Int']>
  survey?: InputMaybe<SurveyCreationInput>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<CollectiveProposalType>
}

export type Query = {
  __typename: 'Query'
  authMessage: Scalars['String']
  category: Category
  collective?: Maybe<Collective>
  collectiveApplication?: Maybe<CollectiveApplication>
  collectiveApplications?: Maybe<Array<CollectiveApplication>>
  collectiveDemo?: Maybe<CollectiveDemo>
  collectiveDraft?: Maybe<CollectiveDraft>
  collectiveFaqs: Array<CollectiveFaq>
  collectiveProposal?: Maybe<CollectiveProposal>
  collectiveTos: CollectiveTOS
  comment: Comment
  contractAbi: ContractAbi
  currentCollectiveId?: Maybe<Scalars['ID']>
  currentEvent?: Maybe<Event>
  discoverEngagements?: Maybe<EngagementConnection>
  discussionBoard: DiscussionBoard
  enrichments?: Maybe<UserEnrichments>
  ephemeralCryptoAuth: EphemeralCryptoAuth
  event: Event
  eventHeartbeat?: Maybe<GenericMutationResponse>
  eventNoShows?: Maybe<Array<User>>
  eventSeriesBySlug?: Maybe<EventSeries>
  eventSeriesSuggestion: Array<EventSeries>
  featuredEvents: Array<Event>
  featuredSpeakers: Array<FeaturedSpeaker>
  flags: Array<Maybe<SiteFlag>>
  getEmailPreferences: EmailPreferences
  hasChrome: Scalars['Boolean']
  hasGmail: Scalars['Boolean']
  hasTestFlight: Scalars['Boolean']
  isConnected: Scalars['Boolean']
  language: Scalars['JSON']
  languages: Languages
  me: Viewer
  previousEvents: Array<PreviouslyRecordedEvent>
  resolveSubdomain?: Maybe<SubdomainEntity>
  search: SearchResults
  seo?: Maybe<SEO>
  tags?: Maybe<Array<Maybe<Tag>>>
  thread: Thread
  threadFileGetUrl: FileGet
  threadFileUploadUrl: FileUpload
  token?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type QuerycategoryArgs = {
  categoryId: Scalars['ID']
}

export type QuerycollectiveArgs = {
  collectiveId: Scalars['ID']
}

export type QuerycollectiveApplicationArgs = {
  applicationId: Scalars['ID']
}

export type QuerycollectiveDemoArgs = {
  collectiveDemoId: Scalars['ID']
}

export type QuerycollectiveDraftArgs = {
  collectiveDraftId: Scalars['ID']
}

export type QuerycollectiveFaqsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  screen?: InputMaybe<CollectiveScreen>
}

export type QuerycollectiveProposalArgs = {
  proposalId: Scalars['ID']
}

export type QuerycollectiveTosArgs = {
  collectiveId: Scalars['ID']
}

export type QuerycommentArgs = {
  commentId: Scalars['ID']
}

export type QuerycontractAbiArgs = {
  address: Scalars['String']
  network: EthNetwork
}

export type QuerydiscoverEngagementsArgs = {
  engagementConnection: ConnectionInput
  filters: Array<FilterableEngagementField>
  ignoreEventId?: InputMaybe<Scalars['ID']>
  seriesId?: InputMaybe<Scalars['ID']>
  seriesSlug?: InputMaybe<Scalars['String']>
  sort: EngagementSortingInput
}

export type QuerydiscussionBoardArgs = {
  discussionBoardId: Scalars['ID']
}

export type QueryeventArgs = {
  id: Scalars['ID']
}

export type QueryeventHeartbeatArgs = {
  id: Scalars['ID']
}

export type QueryeventNoShowsArgs = {
  id: Scalars['ID']
}

export type QueryeventSeriesBySlugArgs = {
  slug: Scalars['String']
}

export type QueryeventSeriesSuggestionArgs = {
  query?: InputMaybe<Scalars['String']>
}

export type QueryfeaturedEventsArgs = {
  first?: InputMaybe<Scalars['Int']>
}

export type QueryfeaturedSpeakersArgs = {
  first?: InputMaybe<Scalars['Int']>
}

export type QuerygetEmailPreferencesArgs = {
  emailId: Scalars['ID']
}

export type QuerylanguageArgs = {
  language: Scalars['String']
}

export type QuerymeArgs = {
  deviceInfo?: InputMaybe<DeviceInfoInput>
}

export type QuerypreviousEventsArgs = {
  first?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}

export type QueryresolveSubdomainArgs = {
  slug: Scalars['String']
}

export type QuerysearchArgs = {
  query: Scalars['String']
}

export type QueryseoArgs = {
  path: Scalars['ID']
}

export type QuerythreadArgs = {
  threadId: Scalars['ID']
}

export type QuerythreadFileGetUrlArgs = {
  discussionBoardId: Scalars['ID']
  key: Scalars['String']
}

export type QuerythreadFileUploadUrlArgs = {
  discussionBoardId: Scalars['ID']
  fileName?: InputMaybe<Scalars['String']>
  fileType?: InputMaybe<Scalars['String']>
}

export type QueryuserArgs = {
  id: Scalars['ID']
}

export type Question = {
  __typename: 'Question'
  choices?: Maybe<Array<QuestionChoice>>
  options: QuestionOptions
  question: Scalars['String']
  questionType: QuestionType
}

export type QuestionChoice = {
  __typename: 'QuestionChoice'
  stringChoice?: Maybe<Scalars['String']>
}

export type QuestionChoiceInput = {
  stringChoice?: InputMaybe<Scalars['String']>
}

export type QuestionCreationInput = {
  choices?: InputMaybe<Array<QuestionChoiceInput>>
  options: QuestionOptionsInput
  question: Scalars['String']
  questionType: QuestionType
}

export type QuestionOptions = {
  __typename: 'QuestionOptions'
  dateOptions?: Maybe<DateOptions>
  required: Scalars['Boolean']
}

export type QuestionOptionsInput = {
  dateOptions?: InputMaybe<DateOptionsInput>
  required: Scalars['Boolean']
}

export type QuestionResults = ChoiceBasedResults

export enum QuestionType {
  CHECKBOX = 'CHECKBOX',
  DATETIME = 'DATETIME',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
}

export type ReportContentInput = {
  blockUser?: InputMaybe<Scalars['Boolean']>
  eventId?: InputMaybe<Scalars['ID']>
  profileId?: InputMaybe<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type ReportContentMutationResponse = MutationResponse & {
  __typename: 'ReportContentMutationResponse'
  code?: Maybe<Scalars['String']>
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ResetPasswordInput = {
  password: Scalars['String']
  passwordResetKey: Scalars['String']
}

export type ResetPasswordMutationResponse = MutationResponse & {
  __typename: 'ResetPasswordMutationResponse'
  code?: Maybe<Scalars['String']>
  jwtToken: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type SEO = {
  __typename: 'SEO'
  additionalMetaTags?: Maybe<Array<MetaTag>>
  metaDescription?: Maybe<Scalars['String']>
  metaKeywords?: Maybe<Array<Scalars['String']>>
  path: Scalars['ID']
  title?: Maybe<Scalars['String']>
}

export type SearchResultHit = Collective | CollectiveProposal | Event | User

export type SearchResults = {
  __typename: 'SearchResults'
  hits: Array<SearchResultHit>
  totalHits: Scalars['Int']
}

export type SetPreferenceInput = {
  key: Scalars['String']
  type: UserPreferenceType
  value?: InputMaybe<Scalars['String']>
}

export enum ShowResultsAfter {
  AFTER_PROPOSAL_ENDS = 'AFTER_PROPOSAL_ENDS',
  AFTER_VOTING = 'AFTER_VOTING',
  ALWAYS = 'ALWAYS'
}

export enum SignalCommand {
  ATTENDEE_PASSIVE_CHANGED = 'ATTENDEE_PASSIVE_CHANGED',
  CUSTOM = 'CUSTOM',
  INVITE_DJ = 'INVITE_DJ',
  LEAVE = 'LEAVE',
  MUTE = 'MUTE',
  PAUSE_MUSIC = 'PAUSE_MUSIC',
  PLAY_MUSIC = 'PLAY_MUSIC',
  REFETCH_EVENT = 'REFETCH_EVENT',
  SELECT_SONG = 'SELECT_SONG',
  SET_VOLUME = 'SET_VOLUME',
  STOP_MUSIC = 'STOP_MUSIC',
  STOP_VIDEO = 'STOP_VIDEO',
  SYNC_MUSIC_STATE = 'SYNC_MUSIC_STATE'
}

export type SignalInput = {
  command: SignalCommand
  data: Scalars['String']
  eventId: Scalars['ID']
  userId: Scalars['ID']
}

export type SignupInput = {
  collectiveId?: InputMaybe<Scalars['ID']>
  deviceInfo: DeviceInfoInput
  email: Scalars['String']
  eventId?: InputMaybe<Scalars['ID']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  username?: InputMaybe<Scalars['String']>
  wallet?: InputMaybe<CryptoWalletAuth>
}

export type SignupMutationResponse = MutationResponse & {
  __typename: 'SignupMutationResponse'
  code?: Maybe<Scalars['String']>
  jwtToken: Scalars['String']
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type SignupWithAppleIdInput = {
  appleUserAccountId: Scalars['String']
  code?: InputMaybe<Scalars['String']>
  collectiveId?: InputMaybe<Scalars['ID']>
  deviceInfo: DeviceInfoInput
  email: Scalars['String']
  eventId?: InputMaybe<Scalars['ID']>
  firstName?: InputMaybe<Scalars['String']>
  identityToken: Scalars['String']
  lastName?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  wallet?: InputMaybe<CryptoWalletAuth>
}

export type SiteFlag = SiteFlagBoolean | SiteFlagNumber | SiteFlagString

export type SiteFlagBoolean = {
  __typename: 'SiteFlagBoolean'
  id: Scalars['ID']
  key: Scalars['String']
  type: SiteFlagType
  value?: Maybe<Scalars['Boolean']>
}

export type SiteFlagNumber = {
  __typename: 'SiteFlagNumber'
  id: Scalars['ID']
  key: Scalars['String']
  type: SiteFlagType
  value?: Maybe<Scalars['Float']>
}

export type SiteFlagString = {
  __typename: 'SiteFlagString'
  id: Scalars['ID']
  key: Scalars['String']
  type: SiteFlagType
  value?: Maybe<Scalars['String']>
}

export enum SiteFlagType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export enum SocialNetwork {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER'
}

export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export enum SortableEngagementField {
  START_TIME = 'START_TIME'
}

export type SubdomainEntity = Collective

export type Subscription = {
  __typename: 'Subscription'
  categoryAdded: Category
  categoryChanged: Category
  collectiveChanged: Collective
  commentAdded: Comment
  commentChanged: Comment
  discussionBoardChanged: DiscussionBoard
  emailVerifiedChanged?: Maybe<Viewer>
  eventMessageAdded: EventMessage
  eventOnlineGuestCountChanged: Event
  eventSignalReceived: EventSignal
  eventStateChanged: Event
  eventUserPlanUpdated: Event
  proposalChanged: CollectiveProposal
  replyAdded: Comment
  threadAdded: Thread
  threadChanged: Thread
  threadPinned: Thread
}

export type SubscriptioncategoryAddedArgs = {
  discussionBoardId: Scalars['ID']
}

export type SubscriptioncategoryChangedArgs = {
  categoryId: Scalars['ID']
}

export type SubscriptioncollectiveChangedArgs = {
  collectiveId: Scalars['ID']
}

export type SubscriptioncommentAddedArgs = {
  threadId: Scalars['ID']
}

export type SubscriptioncommentChangedArgs = {
  commentId: Scalars['ID']
}

export type SubscriptiondiscussionBoardChangedArgs = {
  discussionBoardId: Scalars['ID']
}

export type SubscriptioneventMessageAddedArgs = {
  eventId: Scalars['ID']
}

export type SubscriptioneventOnlineGuestCountChangedArgs = {
  eventId: Scalars['ID']
}

export type SubscriptioneventSignalReceivedArgs = {
  eventId: Scalars['ID']
}

export type SubscriptioneventStateChangedArgs = {
  eventId: Scalars['ID']
}

export type SubscriptioneventUserPlanUpdatedArgs = {
  eventId: Scalars['ID']
}

export type SubscriptionproposalChangedArgs = {
  proposalId: Scalars['ID']
}

export type SubscriptionreplyAddedArgs = {
  commentId: Scalars['ID']
}

export type SubscriptionthreadAddedArgs = {
  categoryId: Scalars['ID']
}

export type SubscriptionthreadChangedArgs = {
  threadId: Scalars['ID']
}

export type SubscriptionthreadPinnedArgs = {
  categoryId: Scalars['ID']
}

export type Survey = {
  __typename: 'Survey'
  answeredOn?: Maybe<Scalars['Date']>
  isAnswered: Scalars['Boolean']
  myAnswers?: Maybe<Array<Answer>>
  options: SurveyOptions
  proposal?: Maybe<CollectiveProposal>
  questions: Array<Question>
  surveyResults: Array<QuestionResults>
  totalAnswers: Scalars['BigInt']
  totalQuestions: Scalars['Int']
}

export type SurveyCreationInput = {
  options: SurveyOptionsInput
  questions: Array<QuestionCreationInput>
}

export type SurveyInput = {
  answers: Array<AnswerInput>
  collectiveProposalId: Scalars['ID']
  signature: Scalars['String']
}

export type SurveyOptions = {
  __typename: 'SurveyOptions'
  showResultsAfter?: Maybe<ShowResultsAfter>
}

export type SurveyOptionsInput = {
  showResultsAfter?: InputMaybe<ShowResultsAfter>
}

export type SyncStatus = {
  __typename: 'SyncStatus'
  lastSyncedAt?: Maybe<Scalars['Date']>
  status: CollectiveSyncStatus
  syncStartedAt?: Maybe<Scalars['Date']>
}

export type Tag = {
  __typename: 'Tag'
  label: Scalars['String']
}

export type Thread = {
  __typename: 'Thread'
  attachments: Array<ThreadAttachment>
  body: Scalars['String']
  category: Category
  collaborators: CollaboratorConnection
  collaboratorsCount: Scalars['Int']
  comments: CommentConnection
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  isPinned: Scalars['Boolean']
  notificationSettings: DiscussionBoardThreadNotificationSetting
  pinnedBy?: Maybe<User>
  proposal?: Maybe<CollectiveProposal>
  publicUrl: Scalars['String']
  replyCount: Scalars['Int']
  title: Scalars['String']
  type: ThreadType
  updatedAt: Scalars['Date']
  user: User
  viewsCount: Scalars['Int']
}

export type ThreadcollaboratorsArgs = {
  collaboratorConnection: ConnectionInput
}

export type ThreadcommentsArgs = {
  commentConnection: ConnectionInput
}

export type ThreadAttachment = {
  __typename: 'ThreadAttachment'
  id: Scalars['ID']
  name: Scalars['String']
  type: Scalars['String']
  url: Scalars['String']
}

export type ThreadAttachmentInput = {
  key: Scalars['String']
  name: Scalars['String']
  type: Scalars['String']
}

export type ThreadConnection = {
  __typename: 'ThreadConnection'
  edges: Array<ThreadEdge>
  pageInfo: PageInfo
}

export type ThreadCreateInput = {
  attachments: Array<ThreadAttachmentInput>
  body: Scalars['String']
  title: Scalars['String']
}

export type ThreadEdge = {
  __typename: 'ThreadEdge'
  cursor: Scalars['String']
  node: Thread
}

export enum ThreadType {
  COMMENT = 'COMMENT',
  POST = 'POST',
  PROPOSAL = 'PROPOSAL'
}

export type ThreadUpdateInput = {
  body: Scalars['String']
  existingAttachments: Array<Scalars['ID']>
  newAttachments: Array<ThreadAttachmentInput>
  title: Scalars['String']
}

export enum TosAgreementType {
  BRIEF = 'BRIEF',
  DETAILED = 'DETAILED'
}

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>
  instagram?: InputMaybe<Scalars['String']>
  linkedin?: InputMaybe<Scalars['String']>
  twitter?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type User = {
  __typename: 'User'
  assets: UserAssetsConnection
  blocked: Scalars['Boolean']
  canSendDM: Scalars['Boolean']
  collectiveUser: Array<CollectiveUser>
  collectives: CollectiveConnection
  created: Scalars['Boolean']
  createdAt?: Maybe<Scalars['Date']>
  deleted: Scalars['Boolean']
  displayName?: Maybe<Scalars['String']>
  engagementDates: Array<Scalars['Date']>
  engagements: EngagementConnection
  id: Scalars['ID']
  instagram?: Maybe<Scalars['String']>
  isBot: Scalars['Boolean']
  linkedin?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  possiblySpam: Scalars['Boolean']
  publicUrl: Scalars['String']
  shortDisplayName?: Maybe<Scalars['String']>
  syncStatus: UserSyncStatus
  syncStatusDate?: Maybe<Scalars['Date']>
  thumb?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userType: UserType
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

export type UserassetsArgs = {
  assetConnectionInput: ConnectionInput
  assetFilters: AssetQueryInput
}

export type UsercollectivesArgs = {
  collectiveConnection: ConnectionInput
}

export type UserengagementsArgs = {
  dateFilter?: InputMaybe<Scalars['Date']>
  engagementConnection: ConnectionInput
  filters: Array<FilterableEngagementField>
  sort: EngagementSortingInput
}

export type UserAssetDetailsType = UserERC20Asset | UserNFTAsset

export type UserAssetType = {
  __typename: 'UserAssetType'
  address: Scalars['String']
  contractName: Scalars['String']
  contractType: ContractTypes
  details: UserAssetDetailsType
  estimatedValueEth?: Maybe<Scalars['BigInt']>
  id: Scalars['ID']
  symbol: Scalars['String']
}

export type UserAssetsConnection = {
  __typename: 'UserAssetsConnection'
  awaitingSync: Scalars['Boolean']
  edges: Array<UserAssetsEdge>
  pageInfo: PageInfo
}

export type UserAssetsEdge = {
  __typename: 'UserAssetsEdge'
  cursor: Scalars['String']
  node: UserAssetType
}

export type UserConnection = {
  __typename: 'UserConnection'
  edges: Array<UserEdge>
  pageInfo: PageInfo
}

export type UserERC20Asset = {
  __typename: 'UserERC20Asset'
  balance: Scalars['BigInt']
  decimals: Scalars['Int']
  id: Scalars['ID']
  thumbnail?: Maybe<Scalars['String']>
}

export type UserEdge = {
  __typename: 'UserEdge'
  cursor: Scalars['String']
  node: User
}

export type UserEnrichments = {
  __typename: 'UserEnrichments'
  awaitingEnrichments: Scalars['Boolean']
  suggestions: EnrichmentSuggestions
}

export type UserNFTAsset = {
  __typename: 'UserNFTAsset'
  amount: Scalars['BigInt']
  id: Scalars['ID']
  mediaUrls: Array<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nftImageUrl?: Maybe<Scalars['String']>
  tokenId?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum UserNotificationCategory {
  COLLECTIVE_APPLICATION_SUBMITTED = 'COLLECTIVE_APPLICATION_SUBMITTED',
  COLLECTIVE_MEMBERSHIP_REQUEST_APPROVED = 'COLLECTIVE_MEMBERSHIP_REQUEST_APPROVED',
  COLLECTIVE_MEMBERSHIP_REQUEST_CREATED = 'COLLECTIVE_MEMBERSHIP_REQUEST_CREATED',
  COLLECTIVE_PROPOSAL_CANCELED = 'COLLECTIVE_PROPOSAL_CANCELED',
  COLLECTIVE_PROPOSAL_CREATED = 'COLLECTIVE_PROPOSAL_CREATED',
  COLLECTIVE_PROPOSAL_EXECUTED = 'COLLECTIVE_PROPOSAL_EXECUTED',
  COLLECTIVE_PROPOSAL_SUCCEEDED = 'COLLECTIVE_PROPOSAL_SUCCEEDED',
  DISCUSSION_BOARD_COMMENT_CREATED = 'DISCUSSION_BOARD_COMMENT_CREATED',
  DISCUSSION_BOARD_THREAD_CREATED = 'DISCUSSION_BOARD_THREAD_CREATED',
  EVENT_REMINDER_NON_RSVP = 'EVENT_REMINDER_NON_RSVP',
  EVENT_REMINDER_RSVP = 'EVENT_REMINDER_RSVP',
  EVENT_STARTED = 'EVENT_STARTED',
  EXTERNAL_COLLECTIVE_JOINED = 'EXTERNAL_COLLECTIVE_JOINED',
  USER_FIRST_WEEK_REVIEW = 'USER_FIRST_WEEK_REVIEW',
  USER_MORE_ABOUT_UPSTREAM = 'USER_MORE_ABOUT_UPSTREAM',
  USER_POST_EMAIL_VERIFICATION = 'USER_POST_EMAIL_VERIFICATION'
}

export type UserPreference = {
  __typename: 'UserPreference'
  id: Scalars['ID']
  key: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export enum UserPreferenceType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export enum UserSyncStatus {
  FAILED = 'FAILED',
  NEVER = 'NEVER',
  PRIORITIZED = 'PRIORITIZED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

export enum UserType {
  BOT = 'BOT',
  NORMAL = 'NORMAL'
}

export enum VideoProvider {
  ACH = 'ACH',
  AVD = 'AVD'
}

export type Viewer = {
  __typename: 'Viewer'
  appleIdConnected?: Maybe<Scalars['Boolean']>
  assets: UserAssetsConnection
  blockedUsers: Array<User>
  canCreateDiscoverableEvents: Scalars['Boolean']
  collectiveDrafts: CollectiveDraftConnection
  collectiveUser: Array<CollectiveUser>
  collectives: CollectiveConnection
  collectivesActionablesCount: Scalars['Int']
  createdAt?: Maybe<Scalars['Date']>
  deleted: Scalars['Boolean']
  displayName?: Maybe<Scalars['String']>
  email: Scalars['String']
  emailVerified: Scalars['Boolean']
  engagementDates: Array<Scalars['Date']>
  engagements: EngagementConnection
  hasCollectives: Scalars['Boolean']
  id: Scalars['ID']
  instagram?: Maybe<Scalars['String']>
  isBot: Scalars['Boolean']
  jwtToken?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  nonce: Scalars['String']
  possiblySpam: Scalars['Boolean']
  preferences?: Maybe<Array<Maybe<UserPreference>>>
  proposalsToExecute: Array<CollectiveProposal>
  proposalsToVote: Array<CollectiveProposal>
  publicUrl: Scalars['String']
  shortDisplayName?: Maybe<Scalars['String']>
  streamChatToken?: Maybe<Scalars['String']>
  syncStatus: UserSyncStatus
  syncStatusDate?: Maybe<Scalars['Date']>
  thumb?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userType: UserType
  username?: Maybe<Scalars['String']>
}

export type ViewerassetsArgs = {
  assetConnectionInput: ConnectionInput
  assetFilters: AssetQueryInput
}

export type ViewercollectiveDraftsArgs = {
  collectiveConnection: ConnectionInput
}

export type ViewercollectivesArgs = {
  collectiveConnection: ConnectionInput
}

export type ViewerengagementsArgs = {
  dateFilter?: InputMaybe<Scalars['Date']>
  engagementConnection: ConnectionInput
  filters: Array<FilterableEngagementField>
  sort: EngagementSortingInput
}

export enum VoteType {
  ABSTAIN = 'ABSTAIN',
  AGAINST = 'AGAINST',
  FOR = 'FOR'
}

export enum VotingAnonymity {
  ANONYMOUS = 'ANONYMOUS',
  PARTICIPATION = 'PARTICIPATION',
  TRANSPARENT = 'TRANSPARENT'
}

export enum WalletType {
  ETHEREUM = 'ETHEREUM',
  FLOW = 'FLOW'
}

export type EngagementFieldsFragment = {
  __typename: 'Event'
  id: string
  thumb?: string | null
  title: string
  description: string
  format: EventFormat
  formatLabel: string
  formatDescription: string
  capacity: number
  rsvp?: EventRegistrationRSVP | null
  waitlisted: boolean
  passive: boolean
  actualStartTime?: Date | null
  startTime: Date
  state: EventState
  createdAt: Date
  deleted: boolean
  publicUrl: string
  canJoinVideo: boolean
  canJoinNotice?: string | null
  isOwner: boolean
  isPrivileged: boolean
  isCurrentlySpeaking: boolean
  canEdit: boolean
  introductionTime: number
  location?: string | null
  discoverable: boolean
  shareable: boolean
  recording: boolean
  counts: {
    __typename: 'EventCounts'
    attending: number
    notAttending: number
    maybeAttending: number
    attended?: number | null
    matched?: number | null
    passive?: number | null
  }
  speakers: Array<{
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }>
  user: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }
  geolocation?: {
    __typename: 'GeolocationObject'
    latitude: number
    longitude: number
  } | null
  collective?: {
    __typename: 'Collective'
    id: string
    name: string
    thumb?: string | null
    publicUrl: string
    membership?: {
      __typename: 'CollectiveMember'
      id: string
      approved: boolean
      privileged: boolean
    } | null
  } | null
  series?: {
    __typename: 'EventSeries'
    id: string
    slug: string
    label: string
  } | null
}

export type ChannelFieldsFragment = {
  __typename: 'EventVideoChannel'
  provider: VideoProvider
  sessionId: string
  token: string
}

export type EventFieldsFragment = {
  __typename: 'Event'
  id: string
  thumb?: string | null
  title: string
  description: string
  format: EventFormat
  formatLabel: string
  formatDescription: string
  capacity: number
  rsvp?: EventRegistrationRSVP | null
  waitlisted: boolean
  passive: boolean
  actualStartTime?: Date | null
  startTime: Date
  state: EventState
  createdAt: Date
  deleted: boolean
  publicUrl: string
  canJoinVideo: boolean
  canJoinNotice?: string | null
  isOwner: boolean
  isPrivileged: boolean
  isCurrentlySpeaking: boolean
  canEdit: boolean
  introductionTime: number
  location?: string | null
  discoverable: boolean
  shareable: boolean
  recording: boolean
  counts: {
    __typename: 'EventCounts'
    attending: number
    notAttending: number
    maybeAttending: number
    attended?: number | null
    matched?: number | null
    passive?: number | null
  }
  speakers: Array<{
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }>
  user: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }
  geolocation?: {
    __typename: 'GeolocationObject'
    latitude: number
    longitude: number
  } | null
  collective?: {
    __typename: 'Collective'
    id: string
    name: string
    thumb?: string | null
    publicUrl: string
    membership?: {
      __typename: 'CollectiveMember'
      id: string
      approved: boolean
      privileged: boolean
    } | null
  } | null
  series?: {
    __typename: 'EventSeries'
    id: string
    slug: string
    label: string
  } | null
}

export type EventSeriesFieldsFragment = {
  __typename: 'EventSeries'
  id: string
  slug: string
  label: string
}

export type EventUserPlanFieldsFragment = {
  __typename: 'EventUserPlan'
  currentLayout: BroadcastVideoLayout
  fetchedAt: Date
  nextRefecthAt?: Date | null
  currentSpeakers?: Array<{
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }> | null
  mainChannel: {
    __typename: 'EventVideoChannel'
    provider: VideoProvider
    sessionId: string
    token: string
  }
  lobbyChannel: {
    __typename: 'EventVideoChannel'
    provider: VideoProvider
    sessionId: string
    token: string
  }
  currentChannel: {
    __typename: 'EventVideoChannel'
    provider: VideoProvider
    sessionId: string
    token: string
  }
}

export type PageInfoFieldsFragment = {
  __typename: 'PageInfo'
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor?: string | null
  endCursor?: string | null
}

export type ProfileFieldsFragment = {
  __typename: 'User'
  id: string
  publicUrl: string
  thumb?: string | null
  shortDisplayName?: string | null
  displayName?: string | null
  url?: string | null
  twitter?: string | null
  linkedin?: string | null
  instagram?: string | null
  createdAt?: Date | null
  blocked: boolean
  deleted: boolean
  userType: UserType
  isBot: boolean
}

export type BaseProfileFieldsFragment = {
  __typename: 'User'
  id: string
  publicUrl: string
  thumb?: string | null
  shortDisplayName?: string | null
  displayName?: string | null
  blocked: boolean
  userType: UserType
  isBot: boolean
}

export type SurveyFieldsFragment = {
  __typename: 'Survey'
  answeredOn?: Date | null
  totalQuestions: number
  totalAnswers: PseudoBigNumber
  isAnswered: boolean
  questions: Array<{
    __typename: 'Question'
    question: string
    questionType: QuestionType
    options: {
      __typename: 'QuestionOptions'
      required: boolean
      dateOptions?: {
        __typename: 'DateOptions'
        min?: Date | null
        max?: Date | null
        includeTime: boolean
      } | null
    }
    choices?: Array<{
      __typename: 'QuestionChoice'
      stringChoice?: string | null
    }> | null
  }>
  myAnswers?: Array<{
    __typename: 'Answer'
    choices: Array<{
      __typename: 'AnswerChoice'
      dateChoice?: Date | null
      stringChoice?: string | null
    }>
  }> | null
  surveyResults: Array<{
    __typename: 'ChoiceBasedResults'
    labels: Array<string>
    dataset: Array<number>
    datasetPercent: Array<number>
    totalAnswers: number
  }>
  options: {
    __typename: 'SurveyOptions'
    showResultsAfter?: ShowResultsAfter | null
  }
  proposal?: { __typename: 'CollectiveProposal'; id: string } | null
}

export type TagFieldsFragment = { __typename: 'Tag'; label: string }

export type UserAssetFieldsFragment = {
  __typename: 'UserAssetType'
  id: string
  contractName: string
  contractType: ContractTypes
  estimatedValueEth?: PseudoBigNumber | null
  symbol: string
  address: string
  details:
    | {
        __typename: 'UserERC20Asset'
        decimals: number
        balance: PseudoBigNumber
        thumbnail?: string | null
      }
    | {
        __typename: 'UserNFTAsset'
        tokenId?: string | null
        url?: string | null
        amount: PseudoBigNumber
        mediaUrls: Array<string>
        name?: string | null
        nftImageUrl?: string | null
      }
}

export type ViewerFieldsFragment = {
  __typename: 'Viewer'
  id: string
  thumb?: string | null
  username?: string | null
  appleIdConnected?: boolean | null
  shortDisplayName?: string | null
  displayName?: string | null
  url?: string | null
  twitter?: string | null
  linkedin?: string | null
  instagram?: string | null
  createdAt?: Date | null
  deleted: boolean
  emailVerified: boolean
  email: string
  collectivesActionablesCount: number
  jwtToken?: string | null
  possiblySpam: boolean
  streamChatToken?: string | null
  userType: UserType
  hasCollectives: boolean
  canCreateDiscoverableEvents: boolean
  syncStatus: UserSyncStatus
  syncStatusDate?: Date | null
  preferences?: Array<{
    __typename: 'UserPreference'
    key: string
    value?: string | null
  } | null> | null
  collectiveUser: Array<{
    __typename: 'CollectiveUser'
    id: string
    address: string
    collectives: Array<{ __typename: 'Collective'; id: string }>
  }>
}

export type CastVoteMutationResponseFieldsFragment = {
  __typename: 'CastVoteMutationResponse'
  votes: Array<{
    __typename: 'CollectiveProposalVote'
    id: string
    type: VoteType
    weight: PseudoBigNumber
    signatureUrl?: string | null
    signature: string
    masterVote?: {
      __typename: 'CollectiveProposalVote'
      id: string
      type: VoteType
      weight: PseudoBigNumber
      signatureUrl?: string | null
      signature: string
      surveyAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      member: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    } | null
    surveyAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    member: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
  }>
  delegationFailures: Array<{
    __typename: 'DelegationResult'
    message: string
    collectiveMember: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
  }>
}

export type CollectiveAnnouncementFieldsFragment = {
  __typename: 'CollectiveAnnouncement'
  id: string
  bodyHtml?: string | null
  createdAt: Date
  postedBy: {
    __typename: 'CollectiveMember'
    id: string
    totalTokens: PseudoBigNumber
    percentOfTotalTokenSupply: number
    privileged: boolean
    approved: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }
}

export type CollectiveAssetFieldsFragment_ERCAsset_ = {
  __typename: 'ERCAsset'
  id: string
  tokenId?: string | null
  name?: string | null
  symbol?: string | null
  address: string
  balance: PseudoBigNumber
  decimals?: number | null
  thumb?: string | null
  contractType: ContractTypes
  contractName: string
  mediaUrls: Array<string>
  externalUrl?: string | null
  lastSynced: Date
  estimatedValueEth?: PseudoBigNumber | null
  estimatedValueUSD?: number | null
  wallet?: {
    __typename: 'CollectiveWallet'
    id: string
    isMainWallet: boolean
  } | null
}

export type CollectiveAssetFieldsFragment_ExternalAsset_ = {
  __typename: 'ExternalAsset'
  id: string
  type: ExternalAssetType
  address: string
  thumb?: string | null
  externalUrl?: string | null
  symbol?: string | null
  formattedAmount?: string | null
  lastSynced: Date
  estimatedValueUSD?: number | null
  wallet?: {
    __typename: 'CollectiveWallet'
    id: string
    isMainWallet: boolean
  } | null
}

export type CollectiveAssetFieldsFragment =
  | CollectiveAssetFieldsFragment_ERCAsset_
  | CollectiveAssetFieldsFragment_ExternalAsset_

export type CollectiveDraftFieldsFragment = {
  __typename: 'CollectiveDraft'
  id: string
  subdomain?: string | null
  avatar?: string | null
  externalTokenAddress?: string | null
  name: string
  intent: CollectiveIntent
  description: string
  quorumNeededInPercentage?: number | null
  proposalThreshold?: PseudoBigNumber | null
  depositFeeInPercentage?: number | null
  withdrawFeeInPercentage?: number | null
  enableNewMembershipRequests?: boolean | null
  enableDeposits?: boolean | null
  enableWithdraws?: boolean | null
  gateDeposits?: boolean | null
  exchangeRate?: number | null
  minEthContribution?: PseudoBigNumber | null
  disableTokenTransfers?: boolean | null
  requireVotingSignature?: boolean | null
  votingAnonymity?: VotingAnonymity | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  symbol?: string | null
  customerSupport?: Array<string> | null
  members?: Array<string> | null
  createdAt: Date
  lastStep: InputStep
  jumpableSteps: Array<InputStep>
}

export type MinimalUserFragment = {
  __typename: 'User'
  id: string
  thumb?: string | null
  shortDisplayName?: string | null
  displayName?: string | null
  userType: UserType
  isBot: boolean
}

export type MinimalCollectiveUserFragment = {
  __typename: 'CollectiveUser'
  id: string
  address: string
  platformUser?: {
    __typename: 'User'
    id: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    userType: UserType
    isBot: boolean
  } | null
}

export type MinimalCollectiveMemberFragment = {
  __typename: 'CollectiveMember'
  id: string
  user: {
    __typename: 'CollectiveUser'
    id: string
    address: string
    platformUser?: {
      __typename: 'User'
      id: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      userType: UserType
      isBot: boolean
    } | null
  }
}

export type MinimalCollectiveProposalFragment = {
  __typename: 'CollectiveProposal'
  id: string
  status: CollectiveProposalStatus
  title: string
  description: string
  type: CollectiveProposalType
  isShortForm: boolean
  publicUrl: string
  eligibleDelegations: Array<{
    __typename: 'CollectiveMember'
    id: string
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        userType: UserType
        isBot: boolean
      } | null
    }
  }>
  batchedProposals: Array<{ __typename: 'CollectiveProposal'; id: string }>
  survey?: {
    __typename: 'Survey'
    totalQuestions: number
    questions: Array<{ __typename: 'Question'; question: string }>
  } | null
  proposedBy: {
    __typename: 'CollectiveMember'
    id: string
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        userType: UserType
        isBot: boolean
      } | null
    }
  }
  collective: { __typename: 'Collective'; id: string; name: string }
}

export type MinimalCollectiveProposalBaseFragment = {
  __typename: 'CollectiveProposal'
  id: string
  status: CollectiveProposalStatus
  title: string
  description: string
  type: CollectiveProposalType
  isShortForm: boolean
  publicUrl: string
  batchedProposals: Array<{ __typename: 'CollectiveProposal'; id: string }>
  survey?: {
    __typename: 'Survey'
    totalQuestions: number
    questions: Array<{ __typename: 'Question'; question: string }>
  } | null
  proposedBy: {
    __typename: 'CollectiveMember'
    id: string
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        userType: UserType
        isBot: boolean
      } | null
    }
  }
  collective: { __typename: 'Collective'; id: string; name: string }
}

export type MinimalCollectiveProposalVoteBaseFragment = {
  __typename: 'CollectiveProposalVote'
  id: string
  type: VoteType
  weight: PseudoBigNumber
  surveyAnswers?: Array<{
    __typename: 'Answer'
    choices: Array<{
      __typename: 'AnswerChoice'
      dateChoice?: Date | null
      stringChoice?: string | null
    }>
  }> | null
  member: {
    __typename: 'CollectiveMember'
    id: string
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        userType: UserType
        isBot: boolean
      } | null
    }
  }
}

export type MinimalCollectiveProposalVoteFragment = {
  __typename: 'CollectiveProposalVote'
  id: string
  type: VoteType
  weight: PseudoBigNumber
  masterVote?: {
    __typename: 'CollectiveProposalVote'
    id: string
    type: VoteType
    weight: PseudoBigNumber
    surveyAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    member: {
      __typename: 'CollectiveMember'
      id: string
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          userType: UserType
          isBot: boolean
        } | null
      }
    }
  } | null
  surveyAnswers?: Array<{
    __typename: 'Answer'
    choices: Array<{
      __typename: 'AnswerChoice'
      dateChoice?: Date | null
      stringChoice?: string | null
    }>
  }> | null
  member: {
    __typename: 'CollectiveMember'
    id: string
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        userType: UserType
        isBot: boolean
      } | null
    }
  }
}

export type CollectiveLogFieldsFragment = {
  __typename: 'CollectiveLog'
  id: string
  eventType: CollectiveLogEventType
  txHash?: string | null
  blockNumber?: number | null
  date: Date
  collectiveUser?: {
    __typename: 'CollectiveUser'
    id: string
    address: string
    platformUser?: {
      __typename: 'User'
      id: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      userType: UserType
      isBot: boolean
    } | null
  } | null
  data:
    | {
        __typename: 'CollectiveLogDataOnAddedOwner'
        owner: string
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnAnnouncementChanged'
        announcement: {
          __typename: 'CollectiveAnnouncement'
          bodyHtml?: string | null
        }
      }
    | {
        __typename: 'CollectiveLogDataOnAnnouncementCreated'
        announcement: {
          __typename: 'CollectiveAnnouncement'
          bodyHtml?: string | null
        }
      }
    | {
        __typename: 'CollectiveLogDataOnAnnouncementDeleted'
        announcement: {
          __typename: 'CollectiveAnnouncement'
          bodyHtml?: string | null
        }
      }
    | {
        __typename: 'CollectiveLogDataOnChangedThreshold'
        threshold: PseudoBigNumber
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnCollectiveMembershipProposed'
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
        request?: {
          __typename: 'CollectiveMembershipRequest'
          id: string
          status: CollectiveMembershipRequestStatus
          createdAt: Date
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        } | null
      }
    | { __typename: 'CollectiveLogDataOnCollectiveMembershipRequested' }
    | { __typename: 'CollectiveLogDataOnCollectiveSyncRequested' }
    | { __typename: 'CollectiveLogDataOnCollectiveSynced' }
    | {
        __typename: 'CollectiveLogDataOnDelegationsGranted'
        delegator: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            userType: UserType
            isBot: boolean
          } | null
        }
        delegations: Array<{
          __typename: 'CommandDelegationStruct'
          command?: ProposalCommandType | null
          maxValue?: PseudoBigNumber | null
          wildcard?: boolean | null
          surveys?: boolean | null
          delegatee: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        }>
      }
    | {
        __typename: 'CollectiveLogDataOnDelegationsRevoked'
        delegator: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            userType: UserType
            isBot: boolean
          } | null
        }
        delegations: Array<{
          __typename: 'CommandDelegationStruct'
          command?: ProposalCommandType | null
          maxValue?: PseudoBigNumber | null
          wildcard?: boolean | null
          surveys?: boolean | null
          delegatee: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        }>
      }
    | {
        __typename: 'CollectiveLogDataOnDisableTokenTransfersChanged'
        disableTokenTransfers: boolean
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnEnableDepositsChanged'
        enableDeposits: boolean
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnEnableNewMembershipRequestsChanged'
        enableNewMembershipRequests: boolean
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnEnableWithdrawsChanged'
        enableWithdraws: boolean
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnExchangeRateChanged'
        previousRate: PseudoBigNumber
        newRate: PseudoBigNumber
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | { __typename: 'CollectiveLogDataOnExternalCollectiveJoined' }
    | {
        __typename: 'CollectiveLogDataOnFeesChanged'
        depositFee: PseudoBigNumber
      }
    | {
        __typename: 'CollectiveLogDataOnFundsDeposited'
        etherAmount: PseudoBigNumber
      }
    | {
        __typename: 'CollectiveLogDataOnGateDepositsChanged'
        gateDeposits: boolean
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnMemberAdded'
        targetMember: string
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnMemberDepositedFunds'
        etherAmount: PseudoBigNumber
        tokenAmount: PseudoBigNumber
      }
    | {
        __typename: 'CollectiveLogDataOnMemberRemoved'
        targetMember: string
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnMemberWithdrewFunds'
        etherAmount: PseudoBigNumber
      }
    | {
        __typename: 'CollectiveLogDataOnMinEthContributionChanged'
        contributionAmount: PseudoBigNumber
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnNonDepositEtherReceived'
        etherAmount: PseudoBigNumber
        sender: string
      }
    | {
        __typename: 'CollectiveLogDataOnProposalCanceled'
        reason: string
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnProposalExecuted'
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnProposalExecutionReset'
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnProposalRequireVoteSignatureChanged'
        requireVotingSignature: boolean
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnProposalSigned'
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnProposalsBatched'
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnQuorumNeededInPercentageChanged'
        quorumNeededInPercentage: number
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnRemovedOwner'
        owner: string
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnTransfer'
        value?: PseudoBigNumber | null
        tokenId?: PseudoBigNumber | null
        from: string
        to: string
        token?: {
          __typename: 'CollectiveToken'
          id: string
          address: string
          symbol: string
          decimals: number
          thumb?: string | null
          name: string
          contractType: string
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnTransferBatch'
        values: Array<PseudoBigNumber>
        tokenIds: Array<PseudoBigNumber>
        from: string
        to: string
        token?: {
          __typename: 'CollectiveToken'
          id: string
          address: string
          symbol: string
          decimals: number
          thumb?: string | null
          name: string
          contractType: string
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnTransferSingle'
        value?: PseudoBigNumber | null
        tokenId?: PseudoBigNumber | null
        from: string
        to: string
        token?: {
          __typename: 'CollectiveToken'
          id: string
          address: string
          symbol: string
          decimals: number
          thumb?: string | null
          name: string
          contractType: string
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnVoteCast'
        vote: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
        user: {
          __typename: 'CollectiveMember'
          id: string
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        }
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnVoteChanged'
        previousVote: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
        newVote: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
        user: {
          __typename: 'CollectiveMember'
          id: string
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        }
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
        masterVote?: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnVoteInvalidated'
        oldWeight: PseudoBigNumber
        invalidatedVote: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
        user: {
          __typename: 'CollectiveMember'
          id: string
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        }
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnVoteWeightUpdated'
        oldWeight: PseudoBigNumber
        newWeight: PseudoBigNumber
        updatedVote: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
        user: {
          __typename: 'CollectiveMember'
          id: string
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
        }
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnVotingAnonymityChanged'
        votingAnonymity: VotingAnonymity
        proposal?: {
          __typename: 'CollectiveProposal'
          id: string
          status: CollectiveProposalStatus
          title: string
          description: string
          type: CollectiveProposalType
          isShortForm: boolean
          publicUrl: string
          eligibleDelegations: Array<{
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
          batchedProposals: Array<{
            __typename: 'CollectiveProposal'
            id: string
          }>
          survey?: {
            __typename: 'Survey'
            totalQuestions: number
            questions: Array<{ __typename: 'Question'; question: string }>
          } | null
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          collective: { __typename: 'Collective'; id: string; name: string }
        } | null
      }
    | {
        __typename: 'CollectiveLogDataOnWalletLinked'
        address: string
        blockchain: string
        nickname: string
      }
    | { __typename: 'CollectiveLogDataOnWalletUnlinked'; walletId: string }
}

export type BundledCollectiveLogFieldsFragment = {
  __typename: 'BundledCollectiveLog'
  date: Date
  eventType: CollectiveLogEventType
  logs: Array<{
    __typename: 'CollectiveLog'
    id: string
    eventType: CollectiveLogEventType
    txHash?: string | null
    blockNumber?: number | null
    date: Date
    collectiveUser?: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        userType: UserType
        isBot: boolean
      } | null
    } | null
    data:
      | {
          __typename: 'CollectiveLogDataOnAddedOwner'
          owner: string
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnAnnouncementChanged'
          announcement: {
            __typename: 'CollectiveAnnouncement'
            bodyHtml?: string | null
          }
        }
      | {
          __typename: 'CollectiveLogDataOnAnnouncementCreated'
          announcement: {
            __typename: 'CollectiveAnnouncement'
            bodyHtml?: string | null
          }
        }
      | {
          __typename: 'CollectiveLogDataOnAnnouncementDeleted'
          announcement: {
            __typename: 'CollectiveAnnouncement'
            bodyHtml?: string | null
          }
        }
      | {
          __typename: 'CollectiveLogDataOnChangedThreshold'
          threshold: PseudoBigNumber
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnCollectiveMembershipProposed'
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
          request?: {
            __typename: 'CollectiveMembershipRequest'
            id: string
            status: CollectiveMembershipRequestStatus
            createdAt: Date
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          } | null
        }
      | { __typename: 'CollectiveLogDataOnCollectiveMembershipRequested' }
      | { __typename: 'CollectiveLogDataOnCollectiveSyncRequested' }
      | { __typename: 'CollectiveLogDataOnCollectiveSynced' }
      | {
          __typename: 'CollectiveLogDataOnDelegationsGranted'
          delegator: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
          delegations: Array<{
            __typename: 'CommandDelegationStruct'
            command?: ProposalCommandType | null
            maxValue?: PseudoBigNumber | null
            wildcard?: boolean | null
            surveys?: boolean | null
            delegatee: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
        }
      | {
          __typename: 'CollectiveLogDataOnDelegationsRevoked'
          delegator: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }
          delegations: Array<{
            __typename: 'CommandDelegationStruct'
            command?: ProposalCommandType | null
            maxValue?: PseudoBigNumber | null
            wildcard?: boolean | null
            surveys?: boolean | null
            delegatee: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }>
        }
      | {
          __typename: 'CollectiveLogDataOnDisableTokenTransfersChanged'
          disableTokenTransfers: boolean
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnEnableDepositsChanged'
          enableDeposits: boolean
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnEnableNewMembershipRequestsChanged'
          enableNewMembershipRequests: boolean
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnEnableWithdrawsChanged'
          enableWithdraws: boolean
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnExchangeRateChanged'
          previousRate: PseudoBigNumber
          newRate: PseudoBigNumber
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | { __typename: 'CollectiveLogDataOnExternalCollectiveJoined' }
      | {
          __typename: 'CollectiveLogDataOnFeesChanged'
          depositFee: PseudoBigNumber
        }
      | {
          __typename: 'CollectiveLogDataOnFundsDeposited'
          etherAmount: PseudoBigNumber
        }
      | {
          __typename: 'CollectiveLogDataOnGateDepositsChanged'
          gateDeposits: boolean
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnMemberAdded'
          targetMember: string
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnMemberDepositedFunds'
          etherAmount: PseudoBigNumber
          tokenAmount: PseudoBigNumber
        }
      | {
          __typename: 'CollectiveLogDataOnMemberRemoved'
          targetMember: string
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnMemberWithdrewFunds'
          etherAmount: PseudoBigNumber
        }
      | {
          __typename: 'CollectiveLogDataOnMinEthContributionChanged'
          contributionAmount: PseudoBigNumber
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnNonDepositEtherReceived'
          etherAmount: PseudoBigNumber
          sender: string
        }
      | {
          __typename: 'CollectiveLogDataOnProposalCanceled'
          reason: string
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnProposalExecuted'
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnProposalExecutionReset'
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnProposalRequireVoteSignatureChanged'
          requireVotingSignature: boolean
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnProposalSigned'
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnProposalsBatched'
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnQuorumNeededInPercentageChanged'
          quorumNeededInPercentage: number
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnRemovedOwner'
          owner: string
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnTransfer'
          value?: PseudoBigNumber | null
          tokenId?: PseudoBigNumber | null
          from: string
          to: string
          token?: {
            __typename: 'CollectiveToken'
            id: string
            address: string
            symbol: string
            decimals: number
            thumb?: string | null
            name: string
            contractType: string
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnTransferBatch'
          values: Array<PseudoBigNumber>
          tokenIds: Array<PseudoBigNumber>
          from: string
          to: string
          token?: {
            __typename: 'CollectiveToken'
            id: string
            address: string
            symbol: string
            decimals: number
            thumb?: string | null
            name: string
            contractType: string
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnTransferSingle'
          value?: PseudoBigNumber | null
          tokenId?: PseudoBigNumber | null
          from: string
          to: string
          token?: {
            __typename: 'CollectiveToken'
            id: string
            address: string
            symbol: string
            decimals: number
            thumb?: string | null
            name: string
            contractType: string
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnVoteCast'
          vote: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            masterVote?: {
              __typename: 'CollectiveProposalVote'
              id: string
              type: VoteType
              weight: PseudoBigNumber
              surveyAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              member: {
                __typename: 'CollectiveMember'
                id: string
                user: {
                  __typename: 'CollectiveUser'
                  id: string
                  address: string
                  platformUser?: {
                    __typename: 'User'
                    id: string
                    thumb?: string | null
                    shortDisplayName?: string | null
                    displayName?: string | null
                    userType: UserType
                    isBot: boolean
                  } | null
                }
              }
            } | null
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          }
          user: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnVoteChanged'
          previousVote: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            masterVote?: {
              __typename: 'CollectiveProposalVote'
              id: string
              type: VoteType
              weight: PseudoBigNumber
              surveyAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              member: {
                __typename: 'CollectiveMember'
                id: string
                user: {
                  __typename: 'CollectiveUser'
                  id: string
                  address: string
                  platformUser?: {
                    __typename: 'User'
                    id: string
                    thumb?: string | null
                    shortDisplayName?: string | null
                    displayName?: string | null
                    userType: UserType
                    isBot: boolean
                  } | null
                }
              }
            } | null
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          }
          newVote: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            masterVote?: {
              __typename: 'CollectiveProposalVote'
              id: string
              type: VoteType
              weight: PseudoBigNumber
              surveyAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              member: {
                __typename: 'CollectiveMember'
                id: string
                user: {
                  __typename: 'CollectiveUser'
                  id: string
                  address: string
                  platformUser?: {
                    __typename: 'User'
                    id: string
                    thumb?: string | null
                    shortDisplayName?: string | null
                    displayName?: string | null
                    userType: UserType
                    isBot: boolean
                  } | null
                }
              }
            } | null
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          }
          user: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            masterVote?: {
              __typename: 'CollectiveProposalVote'
              id: string
              type: VoteType
              weight: PseudoBigNumber
              surveyAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              member: {
                __typename: 'CollectiveMember'
                id: string
                user: {
                  __typename: 'CollectiveUser'
                  id: string
                  address: string
                  platformUser?: {
                    __typename: 'User'
                    id: string
                    thumb?: string | null
                    shortDisplayName?: string | null
                    displayName?: string | null
                    userType: UserType
                    isBot: boolean
                  } | null
                }
              }
            } | null
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnVoteInvalidated'
          oldWeight: PseudoBigNumber
          invalidatedVote: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            masterVote?: {
              __typename: 'CollectiveProposalVote'
              id: string
              type: VoteType
              weight: PseudoBigNumber
              surveyAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              member: {
                __typename: 'CollectiveMember'
                id: string
                user: {
                  __typename: 'CollectiveUser'
                  id: string
                  address: string
                  platformUser?: {
                    __typename: 'User'
                    id: string
                    thumb?: string | null
                    shortDisplayName?: string | null
                    displayName?: string | null
                    userType: UserType
                    isBot: boolean
                  } | null
                }
              }
            } | null
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          }
          user: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnVoteWeightUpdated'
          oldWeight: PseudoBigNumber
          newWeight: PseudoBigNumber
          updatedVote: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            masterVote?: {
              __typename: 'CollectiveProposalVote'
              id: string
              type: VoteType
              weight: PseudoBigNumber
              surveyAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              member: {
                __typename: 'CollectiveMember'
                id: string
                user: {
                  __typename: 'CollectiveUser'
                  id: string
                  address: string
                  platformUser?: {
                    __typename: 'User'
                    id: string
                    thumb?: string | null
                    shortDisplayName?: string | null
                    displayName?: string | null
                    userType: UserType
                    isBot: boolean
                  } | null
                }
              }
            } | null
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          }
          user: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnVotingAnonymityChanged'
          votingAnonymity: VotingAnonymity
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            status: CollectiveProposalStatus
            title: string
            description: string
            type: CollectiveProposalType
            isShortForm: boolean
            publicUrl: string
            eligibleDelegations: Array<{
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }>
            batchedProposals: Array<{
              __typename: 'CollectiveProposal'
              id: string
            }>
            survey?: {
              __typename: 'Survey'
              totalQuestions: number
              questions: Array<{ __typename: 'Question'; question: string }>
            } | null
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            collective: { __typename: 'Collective'; id: string; name: string }
          } | null
        }
      | {
          __typename: 'CollectiveLogDataOnWalletLinked'
          address: string
          blockchain: string
          nickname: string
        }
      | { __typename: 'CollectiveLogDataOnWalletUnlinked'; walletId: string }
  }>
  collectiveUsers: Array<{
    __typename: 'CollectiveUser'
    id: string
    address: string
    platformUser?: {
      __typename: 'User'
      id: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      userType: UserType
      isBot: boolean
    } | null
  }>
}

export type CollectiveMemberFieldsFragment = {
  __typename: 'CollectiveMember'
  id: string
  totalTokens: PseudoBigNumber
  percentOfTotalTokenSupply: number
  privileged: boolean
  approved: boolean
  createdAt: Date
  user: {
    __typename: 'CollectiveUser'
    id: string
    address: string
    platformUser?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
  }
}

export type CollectiveProposalFieldsFragment = {
  __typename: 'CollectiveProposal'
  id: string
  status: CollectiveProposalStatus
  title: string
  descriptionHtml: string
  type: CollectiveProposalType
  startTime: Date
  endTime: Date
  requireVotingSignature: boolean
  votingAnonymity: VotingAnonymity
  quorumNeeded: number
  isQuorumReached: boolean
  myVote?: VoteType | null
  isAuthor: boolean
  isUsingMatchingWallet: boolean
  isVotable: boolean
  isShortForm: boolean
  canVote: boolean
  totalVoters: PseudoBigNumber
  totalVotes: PseudoBigNumber
  totalVotersFor: PseudoBigNumber
  votesFor: PseudoBigNumber
  totalVotersAgainst: PseudoBigNumber
  votesAgainst: PseudoBigNumber
  totalVotersAbstain: PseudoBigNumber
  votesAbstain: PseudoBigNumber
  ogImageUrl?: string | null
  isBatch: boolean
  isPartOfBatch: boolean
  publicUrl: string
  createdAt: Date
  hasOnChainCommands: boolean
  eligibleDelegations: Array<{
    __typename: 'CollectiveMember'
    id: string
    totalTokens: PseudoBigNumber
    percentOfTotalTokenSupply: number
    privileged: boolean
    approved: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }>
  statusHistory: Array<{
    __typename: 'CollectiveProposalStatusHistory'
    status: CollectiveProposalStatus
    createdAt: Date
  }>
  execution?: {
    __typename: 'CollectiveProposalExecution'
    txHash: string
    blockNumber: number
    status: CollectiveProposalExecutionStatus
    errorMessage?: string | null
  } | null
  commands: Array<
    | {
        __typename: 'CollectiveProposalAddMemberCommand'
        memberAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalAddSignatorCommand'
        signatorAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalCallRemoteCommand'
        target: string
        data: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
        disableTokenTransfers: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeEnableDepositsCommand'
        enableDeposits: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
        enableNewMembershipRequests: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
        enableWithdraws: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeExchangeRateCommand'
        exchangeRate: number
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeGateDepositsCommand'
        gateDeposits: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeMinEthContributionCommand'
        minEthContribution: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
        requireVotingSignature: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeSafeThresholdCommand'
        safeThreshold: number
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
        votingAnonymity: VotingAnonymity
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalLinkWalletCommand'
        target: string
        blockchain: Blockchain
        walletNickname: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalRemoveMemberCommand'
        memberAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalRemoveSignatorCommand'
        signatorAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalSendEtherCommand'
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalSendEtherDisbursementCommand'
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalSendEtherDisbursementCommands'
        value: PseudoBigNumber
        commands: Array<{
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }>
      }
    | {
        __typename: 'CollectiveProposalSendTokenCommand'
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalTransferERC721Command'
        contractAddress: string
        target: string
        tokenId: string
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalTransferERC1155Command'
        contractAddress: string
        target: string
        tokenId: string
        amount: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalTransferNFTCommand'
        contractAddress: string
        target: string
        tokenId: string
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalTransferTokensCommand'
        contractAddress: string
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalUnlinkWalletCommand'
        walletId: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalUpdateQuorumCommand'
        quorumNeededInPercentage: number
        safeInstructions?: { [key: string]: any } | null
      }
  >
  survey?: {
    __typename: 'Survey'
    answeredOn?: Date | null
    totalQuestions: number
    totalAnswers: PseudoBigNumber
    isAnswered: boolean
    questions: Array<{
      __typename: 'Question'
      question: string
      questionType: QuestionType
      options: {
        __typename: 'QuestionOptions'
        required: boolean
        dateOptions?: {
          __typename: 'DateOptions'
          min?: Date | null
          max?: Date | null
          includeTime: boolean
        } | null
      }
      choices?: Array<{
        __typename: 'QuestionChoice'
        stringChoice?: string | null
      }> | null
    }>
    myAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    surveyResults: Array<{
      __typename: 'ChoiceBasedResults'
      labels: Array<string>
      dataset: Array<number>
      datasetPercent: Array<number>
      totalAnswers: number
    }>
    options: {
      __typename: 'SurveyOptions'
      showResultsAfter?: ShowResultsAfter | null
    }
    proposal?: { __typename: 'CollectiveProposal'; id: string } | null
  } | null
  origin?: {
    __typename: 'CollectiveMembershipRequest'
    id: string
    notes?: string | null
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  } | null
  signatures: Array<{
    __typename: 'CollectiveProposalSignature'
    id: string
    signature: string
    valid: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }>
  proposedBy: {
    __typename: 'CollectiveMember'
    id: string
    totalTokens: PseudoBigNumber
    percentOfTotalTokenSupply: number
    privileged: boolean
    approved: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }
  batchProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    title: string
    publicUrl: string
  } | null
  batchedProposals: Array<{
    __typename: 'CollectiveProposal'
    id: string
    title: string
    publicUrl: string
  }>
  collective: { __typename: 'Collective'; id: string; name: string }
  thread?: {
    __typename: 'Thread'
    id: string
    replyCount: number
    title: string
  } | null
}

export type CollectiveProposalFieldsBaseFragment = {
  __typename: 'CollectiveProposal'
  id: string
  status: CollectiveProposalStatus
  title: string
  descriptionHtml: string
  type: CollectiveProposalType
  startTime: Date
  endTime: Date
  requireVotingSignature: boolean
  votingAnonymity: VotingAnonymity
  quorumNeeded: number
  isQuorumReached: boolean
  myVote?: VoteType | null
  isAuthor: boolean
  isUsingMatchingWallet: boolean
  isVotable: boolean
  isShortForm: boolean
  canVote: boolean
  totalVoters: PseudoBigNumber
  totalVotes: PseudoBigNumber
  totalVotersFor: PseudoBigNumber
  votesFor: PseudoBigNumber
  totalVotersAgainst: PseudoBigNumber
  votesAgainst: PseudoBigNumber
  totalVotersAbstain: PseudoBigNumber
  votesAbstain: PseudoBigNumber
  ogImageUrl?: string | null
  isBatch: boolean
  isPartOfBatch: boolean
  publicUrl: string
  createdAt: Date
  hasOnChainCommands: boolean
  statusHistory: Array<{
    __typename: 'CollectiveProposalStatusHistory'
    status: CollectiveProposalStatus
    createdAt: Date
  }>
  execution?: {
    __typename: 'CollectiveProposalExecution'
    txHash: string
    blockNumber: number
    status: CollectiveProposalExecutionStatus
    errorMessage?: string | null
  } | null
  commands: Array<
    | {
        __typename: 'CollectiveProposalAddMemberCommand'
        memberAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalAddSignatorCommand'
        signatorAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalCallRemoteCommand'
        target: string
        data: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
        disableTokenTransfers: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeEnableDepositsCommand'
        enableDeposits: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
        enableNewMembershipRequests: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
        enableWithdraws: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeExchangeRateCommand'
        exchangeRate: number
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeGateDepositsCommand'
        gateDeposits: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeMinEthContributionCommand'
        minEthContribution: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
        requireVotingSignature: boolean
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeSafeThresholdCommand'
        safeThreshold: number
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
        votingAnonymity: VotingAnonymity
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalLinkWalletCommand'
        target: string
        blockchain: Blockchain
        walletNickname: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalRemoveMemberCommand'
        memberAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalRemoveSignatorCommand'
        signatorAddress: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalSendEtherCommand'
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalSendEtherDisbursementCommand'
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalSendEtherDisbursementCommands'
        value: PseudoBigNumber
        commands: Array<{
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }>
      }
    | {
        __typename: 'CollectiveProposalSendTokenCommand'
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalTransferERC721Command'
        contractAddress: string
        target: string
        tokenId: string
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalTransferERC1155Command'
        contractAddress: string
        target: string
        tokenId: string
        amount: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalTransferNFTCommand'
        contractAddress: string
        target: string
        tokenId: string
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalTransferTokensCommand'
        contractAddress: string
        target: string
        value: PseudoBigNumber
        safeInstructions?: { [key: string]: any } | null
        asset?: {
          __typename: 'CollectiveAssetPreview'
          name: string
          symbol: string
          thumb?: string | null
        } | null
      }
    | {
        __typename: 'CollectiveProposalUnlinkWalletCommand'
        walletId: string
        safeInstructions?: { [key: string]: any } | null
      }
    | {
        __typename: 'CollectiveProposalUpdateQuorumCommand'
        quorumNeededInPercentage: number
        safeInstructions?: { [key: string]: any } | null
      }
  >
  survey?: {
    __typename: 'Survey'
    answeredOn?: Date | null
    totalQuestions: number
    totalAnswers: PseudoBigNumber
    isAnswered: boolean
    questions: Array<{
      __typename: 'Question'
      question: string
      questionType: QuestionType
      options: {
        __typename: 'QuestionOptions'
        required: boolean
        dateOptions?: {
          __typename: 'DateOptions'
          min?: Date | null
          max?: Date | null
          includeTime: boolean
        } | null
      }
      choices?: Array<{
        __typename: 'QuestionChoice'
        stringChoice?: string | null
      }> | null
    }>
    myAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    surveyResults: Array<{
      __typename: 'ChoiceBasedResults'
      labels: Array<string>
      dataset: Array<number>
      datasetPercent: Array<number>
      totalAnswers: number
    }>
    options: {
      __typename: 'SurveyOptions'
      showResultsAfter?: ShowResultsAfter | null
    }
    proposal?: { __typename: 'CollectiveProposal'; id: string } | null
  } | null
  origin?: {
    __typename: 'CollectiveMembershipRequest'
    id: string
    notes?: string | null
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  } | null
  signatures: Array<{
    __typename: 'CollectiveProposalSignature'
    id: string
    signature: string
    valid: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }>
  proposedBy: {
    __typename: 'CollectiveMember'
    id: string
    totalTokens: PseudoBigNumber
    percentOfTotalTokenSupply: number
    privileged: boolean
    approved: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }
  batchProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    title: string
    publicUrl: string
  } | null
  batchedProposals: Array<{
    __typename: 'CollectiveProposal'
    id: string
    title: string
    publicUrl: string
  }>
  collective: { __typename: 'Collective'; id: string; name: string }
  thread?: {
    __typename: 'Thread'
    id: string
    replyCount: number
    title: string
  } | null
}

export type CollectiveProposalSummaryFieldsFragment = {
  __typename: 'CollectiveProposal'
  id: string
  type: CollectiveProposalType
  title: string
  status: CollectiveProposalStatus
  totalVoters: PseudoBigNumber
  totalVotes: PseudoBigNumber
  votesFor: PseudoBigNumber
  votesAgainst: PseudoBigNumber
  requireVotingSignature: boolean
  votingAnonymity: VotingAnonymity
  votesAbstain: PseudoBigNumber
  isBatch: boolean
  isPartOfBatch: boolean
  publicUrl: string
  myVote?: VoteType | null
  quorumNeeded: number
  isQuorumReached: boolean
  isAuthor: boolean
  isUsingMatchingWallet: boolean
  isVotable: boolean
  isShortForm: boolean
  canVote: boolean
  startTime: Date
  endTime: Date
  createdAt: Date
  execution?: {
    __typename: 'CollectiveProposalExecution'
    txHash: string
    blockNumber: number
    status: CollectiveProposalExecutionStatus
    errorMessage?: string | null
  } | null
  survey?: {
    __typename: 'Survey'
    answeredOn?: Date | null
    totalQuestions: number
    totalAnswers: PseudoBigNumber
    isAnswered: boolean
    questions: Array<{
      __typename: 'Question'
      question: string
      questionType: QuestionType
      options: {
        __typename: 'QuestionOptions'
        required: boolean
        dateOptions?: {
          __typename: 'DateOptions'
          min?: Date | null
          max?: Date | null
          includeTime: boolean
        } | null
      }
      choices?: Array<{
        __typename: 'QuestionChoice'
        stringChoice?: string | null
      }> | null
    }>
    myAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    surveyResults: Array<{
      __typename: 'ChoiceBasedResults'
      labels: Array<string>
      dataset: Array<number>
      datasetPercent: Array<number>
      totalAnswers: number
    }>
    options: {
      __typename: 'SurveyOptions'
      showResultsAfter?: ShowResultsAfter | null
    }
    proposal?: { __typename: 'CollectiveProposal'; id: string } | null
  } | null
  collective: { __typename: 'Collective'; id: string }
  proposedBy: {
    __typename: 'CollectiveMember'
    id: string
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }
  thread?: {
    __typename: 'Thread'
    id: string
    replyCount: number
    title: string
  } | null
}

export type CollectiveProposalVoteBaseFieldsFragment = {
  __typename: 'CollectiveProposalVote'
  id: string
  type: VoteType
  weight: PseudoBigNumber
  signatureUrl?: string | null
  signature: string
  surveyAnswers?: Array<{
    __typename: 'Answer'
    choices: Array<{
      __typename: 'AnswerChoice'
      dateChoice?: Date | null
      stringChoice?: string | null
    }>
  }> | null
  member: {
    __typename: 'CollectiveMember'
    id: string
    totalTokens: PseudoBigNumber
    percentOfTotalTokenSupply: number
    privileged: boolean
    approved: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }
}

export type CollectiveProposalVoteFieldsFragment = {
  __typename: 'CollectiveProposalVote'
  id: string
  type: VoteType
  weight: PseudoBigNumber
  signatureUrl?: string | null
  signature: string
  masterVote?: {
    __typename: 'CollectiveProposalVote'
    id: string
    type: VoteType
    weight: PseudoBigNumber
    signatureUrl?: string | null
    signature: string
    surveyAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    member: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
  } | null
  surveyAnswers?: Array<{
    __typename: 'Answer'
    choices: Array<{
      __typename: 'AnswerChoice'
      dateChoice?: Date | null
      stringChoice?: string | null
    }>
  }> | null
  member: {
    __typename: 'CollectiveMember'
    id: string
    totalTokens: PseudoBigNumber
    percentOfTotalTokenSupply: number
    privileged: boolean
    approved: boolean
    createdAt: Date
    user: {
      __typename: 'CollectiveUser'
      id: string
      address: string
      platformUser?: {
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      } | null
    }
  }
}

export type CollectiveUserFieldsFragment = {
  __typename: 'CollectiveUser'
  id: string
  address: string
  platformUser?: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  } | null
}

export type CategoryFieldsFragment = {
  __typename: 'Category'
  id: string
  name: string
  description?: string | null
  order: number
  removable: boolean
  governance: boolean
  archived: boolean
  totalThreads: number
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date | null
  publicUrl: string
  notificationSettings: {
    __typename: 'DiscussionBoardCategoryNotificationSetting'
    id: string
    allowNotifications: boolean
  }
  discussionBoard: { __typename: 'DiscussionBoard'; id: string }
}

export type CommentFieldsFragment = {
  __typename: 'Comment'
  id: string
  body: string
  replyCount: number
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date | null
  user: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }
  attachments: Array<{
    __typename: 'ThreadAttachment'
    id: string
    url: string
    type: string
    name: string
  }>
}

export type DiscussionBoardFieldsFragment = {
  __typename: 'DiscussionBoard'
  id: string
  categories: Array<{
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }>
  notificationSettings: {
    __typename: 'DiscussionBoardNotificationSetting'
    allowGlobalNotifications: boolean
  }
}

export type ThreadFieldsFragment = {
  __typename: 'Thread'
  id: string
  title: string
  body: string
  type: ThreadType
  replyCount: number
  viewsCount: number
  publicUrl: string
  isPinned: boolean
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date | null
  collaboratorsCount: number
  pinnedBy?: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  } | null
  user: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }
  proposal?: {
    __typename: 'CollectiveProposal'
    id: string
    type: CollectiveProposalType
    title: string
    status: CollectiveProposalStatus
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    votesFor: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    votesAbstain: PseudoBigNumber
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    myVote?: VoteType | null
    quorumNeeded: number
    isQuorumReached: boolean
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    startTime: Date
    endTime: Date
    createdAt: Date
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    collective: { __typename: 'Collective'; id: string }
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
  notificationSettings: {
    __typename: 'DiscussionBoardThreadNotificationSetting'
    id: string
    allowNotifications: boolean
  }
  attachments: Array<{
    __typename: 'ThreadAttachment'
    id: string
    url: string
    type: string
    name: string
  }>
  category: { __typename: 'Category'; id: string }
}

export type GenericMutationResponseFieldsFragment = {
  __typename: 'GenericMutationResponse'
  success: boolean
  code?: string | null
  message?: string | null
}

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']
}>

export type ChangePasswordMutationResult = {
  __typename: 'Mutation'
  changePassword?: {
    __typename: 'ChangePasswordMutationResponse'
    success: boolean
  } | null
}

export type LoginCryptoMutationVariables = Exact<{
  login: CryptoWalletAuth
}>

export type LoginCryptoMutationResult = {
  __typename: 'Mutation'
  loginCrypto?: {
    __typename: 'LoginMutationResponse'
    success: boolean
    jwtToken: string
  } | null
}

export type LoginMutationVariables = Exact<{
  login: LoginInput
}>

export type LoginMutationResult = {
  __typename: 'Mutation'
  login?: {
    __typename: 'LoginMutationResponse'
    success: boolean
    jwtToken: string
  } | null
}

export type LoginWithAppleIdMutationVariables = Exact<{
  login: LoginWithAppleIdInput
}>

export type LoginWithAppleIdMutationResult = {
  __typename: 'Mutation'
  loginWithAppleId?: {
    __typename: 'LoginMutationResponse'
    success: boolean
    jwtToken: string
  } | null
}

export type PrioritizeUserForAssetSyncMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type PrioritizeUserForAssetSyncMutationResult = {
  __typename: 'Mutation'
  prioritizeUserForAssetSync?: { __typename: 'User'; id: string } | null
}

export type RecoverPasswordMutationVariables = Exact<{
  email: Scalars['String']
}>

export type RecoverPasswordMutationResult = {
  __typename: 'Mutation'
  recoverPassword?: {
    __typename: 'GenericMutationResponse'
    success: boolean
    message?: string | null
  } | null
}

export type ReportContentMutationVariables = Exact<{
  report: ReportContentInput
}>

export type ReportContentMutationResult = {
  __typename: 'Mutation'
  reportContent?: {
    __typename: 'ReportContentMutationResponse'
    id: string
    success: boolean
    message?: string | null
  } | null
}

export type ResendVerificationEmailMutationVariables = Exact<{
  [key: string]: never
}>

export type ResendVerificationEmailMutationResult = {
  __typename: 'Mutation'
  resendVerificationEmail?: {
    __typename: 'GenericMutationResponse'
    success: boolean
    message?: string | null
  } | null
}

export type ResetPasswordMutationVariables = Exact<{
  reset: ResetPasswordInput
}>

export type ResetPasswordMutationResult = {
  __typename: 'Mutation'
  resetPassword?: {
    __typename: 'ResetPasswordMutationResponse'
    success: boolean
    jwtToken: string
  } | null
}

export type SignupWithAppleIdMutationVariables = Exact<{
  signup: SignupWithAppleIdInput
}>

export type SignupWithAppleIdMutationResult = {
  __typename: 'Mutation'
  signupWithAppleId?: {
    __typename: 'SignupMutationResponse'
    success: boolean
    jwtToken: string
  } | null
}

export type SetPreferenceMutationVariables = Exact<{
  preference: SetPreferenceInput
}>

export type SetPreferenceMutationResult = {
  __typename: 'Mutation'
  setPreference?: {
    __typename: 'UserPreference'
    key: string
    value?: string | null
  } | null
}

export type SignupMutationVariables = Exact<{
  signup: SignupInput
}>

export type SignupMutationResult = {
  __typename: 'Mutation'
  signup?: {
    __typename: 'SignupMutationResponse'
    success: boolean
    jwtToken: string
  } | null
}

export type StartEventMutationVariables = Exact<{
  eventId: Scalars['ID']
}>

export type StartEventMutationResult = {
  __typename: 'Mutation'
  startEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  update: UpdateUserInput
}>

export type UpdateUserMutationResult = {
  __typename: 'Mutation'
  updateUser?: {
    __typename: 'Viewer'
    id: string
    email: string
    emailVerified: boolean
    username?: string | null
    name?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    url?: string | null
    twitter?: string | null
    linkedin?: string | null
    instagram?: string | null
  } | null
}

export type UploadAvatarMutationVariables = Exact<{
  file: Scalars['Upload']
}>

export type UploadAvatarMutationResult = {
  __typename: 'Mutation'
  uploadAvatar?: {
    __typename: 'Viewer'
    id: string
    thumb?: string | null
  } | null
}

export type UploadAvatarURLMutationVariables = Exact<{
  avatarURL: Scalars['String']
}>

export type UploadAvatarURLMutationResult = {
  __typename: 'Mutation'
  uploadAvatarURL?: {
    __typename: 'Viewer'
    id: string
    thumb?: string | null
  } | null
}

export type VerifyEmailMutationVariables = Exact<{
  emailVerificationKey: Scalars['String']
}>

export type VerifyEmailMutationResult = {
  __typename: 'Mutation'
  verifyEmail?: {
    __typename: 'Viewer'
    id: string
    emailVerified: boolean
  } | null
}

export type SubmitCollectiveApplicationMutationVariables = Exact<{
  payload: Scalars['JSON']
}>

export type SubmitCollectiveApplicationMutationResult = {
  __typename: 'Mutation'
  submitCollectiveApplication?: {
    __typename: 'CollectiveApplication'
    id: string
    thumb?: string | null
    payload: { [key: string]: any }
    status: CollectiveApplicationStatus
    createdAt: Date
  } | null
}

export type UpdateCollectiveApplicationMutationVariables = Exact<{
  applicationId: Scalars['ID']
  payload: Scalars['JSON']
}>

export type UpdateCollectiveApplicationMutationResult = {
  __typename: 'Mutation'
  updateCollectiveApplication?: {
    __typename: 'CollectiveApplication'
    id: string
    thumb?: string | null
    payload: { [key: string]: any }
    status: CollectiveApplicationStatus
    createdAt: Date
  } | null
}

export type UploadCollectiveApplicationAvatarMutationVariables = Exact<{
  applicationId: Scalars['ID']
  file: Scalars['Upload']
}>

export type UploadCollectiveApplicationAvatarMutationResult = {
  __typename: 'Mutation'
  uploadCollectiveApplicationAvatar?: {
    __typename: 'CollectiveApplication'
    id: string
    thumb?: string | null
  } | null
}

export type CreateCollectiveDraftMutationVariables = Exact<{
  input: CollectiveDraftInput
}>

export type CreateCollectiveDraftMutationResult = {
  __typename: 'Mutation'
  createCollectiveDraft?: {
    __typename: 'CollectiveDraft'
    id: string
    subdomain?: string | null
    avatar?: string | null
    externalTokenAddress?: string | null
    name: string
    intent: CollectiveIntent
    description: string
    quorumNeededInPercentage?: number | null
    proposalThreshold?: PseudoBigNumber | null
    depositFeeInPercentage?: number | null
    withdrawFeeInPercentage?: number | null
    enableNewMembershipRequests?: boolean | null
    enableDeposits?: boolean | null
    enableWithdraws?: boolean | null
    gateDeposits?: boolean | null
    exchangeRate?: number | null
    minEthContribution?: PseudoBigNumber | null
    disableTokenTransfers?: boolean | null
    requireVotingSignature?: boolean | null
    votingAnonymity?: VotingAnonymity | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    symbol?: string | null
    customerSupport?: Array<string> | null
    members?: Array<string> | null
    createdAt: Date
    lastStep: InputStep
    jumpableSteps: Array<InputStep>
  } | null
}

export type deleteCollectiveDraftMutationVariables = Exact<{
  collectiveDraftId: Scalars['ID']
}>

export type deleteCollectiveDraftMutationResult = {
  __typename: 'Mutation'
  deleteCollectiveDraft?: {
    __typename: 'CollectiveDraft'
    id: string
    subdomain?: string | null
    avatar?: string | null
    externalTokenAddress?: string | null
    name: string
    intent: CollectiveIntent
    description: string
    quorumNeededInPercentage?: number | null
    proposalThreshold?: PseudoBigNumber | null
    depositFeeInPercentage?: number | null
    withdrawFeeInPercentage?: number | null
    enableNewMembershipRequests?: boolean | null
    enableDeposits?: boolean | null
    enableWithdraws?: boolean | null
    gateDeposits?: boolean | null
    exchangeRate?: number | null
    minEthContribution?: PseudoBigNumber | null
    disableTokenTransfers?: boolean | null
    requireVotingSignature?: boolean | null
    votingAnonymity?: VotingAnonymity | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    symbol?: string | null
    customerSupport?: Array<string> | null
    members?: Array<string> | null
    createdAt: Date
    lastStep: InputStep
    jumpableSteps: Array<InputStep>
  } | null
}

export type UpdateCollectiveDraftMutationVariables = Exact<{
  collectiveDraftId: Scalars['ID']
  input: CollectiveDraftInput
}>

export type UpdateCollectiveDraftMutationResult = {
  __typename: 'Mutation'
  updateCollectiveDraft?: {
    __typename: 'CollectiveDraft'
    id: string
    subdomain?: string | null
    avatar?: string | null
    externalTokenAddress?: string | null
    name: string
    intent: CollectiveIntent
    description: string
    quorumNeededInPercentage?: number | null
    proposalThreshold?: PseudoBigNumber | null
    depositFeeInPercentage?: number | null
    withdrawFeeInPercentage?: number | null
    enableNewMembershipRequests?: boolean | null
    enableDeposits?: boolean | null
    enableWithdraws?: boolean | null
    gateDeposits?: boolean | null
    exchangeRate?: number | null
    minEthContribution?: PseudoBigNumber | null
    disableTokenTransfers?: boolean | null
    requireVotingSignature?: boolean | null
    votingAnonymity?: VotingAnonymity | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    symbol?: string | null
    customerSupport?: Array<string> | null
    members?: Array<string> | null
    createdAt: Date
    lastStep: InputStep
    jumpableSteps: Array<InputStep>
  } | null
}

export type PostCollectiveSubmissionLogMutationVariables = Exact<{
  log: CollectiveSubmissionLogInput
}>

export type PostCollectiveSubmissionLogMutationResult = {
  __typename: 'Mutation'
  postCollectiveSubmissionLog?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type AgreeToCollectiveTosMutationVariables = Exact<{
  type: TosAgreementType
  id?: InputMaybe<Scalars['ID']>
}>

export type AgreeToCollectiveTosMutationResult = {
  __typename: 'Mutation'
  agreeToCollectiveTos?: {
    __typename: 'CollectiveTOS'
    id: string
    isSigned: boolean
  } | null
}

export type AnswerCollectiveProposalSurveyMutationVariables = Exact<{
  survey: SurveyInput
}>

export type AnswerCollectiveProposalSurveyMutationResult = {
  __typename: 'Mutation'
  answerCollectiveProposalSurvey: {
    __typename: 'CollectiveProposalVote'
    id: string
    type: VoteType
    weight: PseudoBigNumber
    signatureUrl?: string | null
    signature: string
    proposal: {
      __typename: 'CollectiveProposal'
      id: string
      myVote?: VoteType | null
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      totalVotersFor: PseudoBigNumber
      votesFor: PseudoBigNumber
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
    }
    surveyAnswers?: Array<{
      __typename: 'Answer'
      choices: Array<{
        __typename: 'AnswerChoice'
        dateChoice?: Date | null
        stringChoice?: string | null
      }>
    }> | null
    member: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
  }
}

export type AuthCryptoMutationVariables = Exact<{
  address: Scalars['String']
  signature: Scalars['String']
}>

export type AuthCryptoMutationResult = {
  __typename: 'Mutation'
  authCrypto?: {
    __typename: 'Viewer'
    id: string
    collectiveUser: Array<{
      __typename: 'CollectiveUser'
      id: string
      address: string
    }>
  } | null
}

export type BanMemberFromEventsMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  userId: Scalars['ID']
}>

export type BanMemberFromEventsMutationResult = {
  __typename: 'Mutation'
  banMemberFromEvents?: { __typename: 'CollectiveMember'; id: string } | null
}

export type BatchProposalsMutationVariables = Exact<{
  proposalIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type BatchProposalsMutationResult = {
  __typename: 'Mutation'
  batchProposals?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type CancelProposalMutationVariables = Exact<{
  proposalId: Scalars['ID']
  reason?: InputMaybe<Scalars['String']>
}>

export type CancelProposalMutationResult = {
  __typename: 'Mutation'
  cancelProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type CastVoteMutationVariables = Exact<{
  vote: CastVoteInput
}>

export type CastVoteMutationResult = {
  __typename: 'Mutation'
  castVote?: {
    __typename: 'CollectiveProposalVote'
    id: string
    type: VoteType
    weight: PseudoBigNumber
    signatureUrl?: string | null
    proposal: {
      __typename: 'CollectiveProposal'
      id: string
      myVote?: VoteType | null
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      totalVotersFor: PseudoBigNumber
      votesFor: PseudoBigNumber
      totalVotersAgainst: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      totalVotersAbstain: PseudoBigNumber
      votesAbstain: PseudoBigNumber
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
    }
  } | null
}

export type CastVoteWithDelegationsMutationVariables = Exact<{
  vote: CastVoteInput
}>

export type CastVoteWithDelegationsMutationResult = {
  __typename: 'Mutation'
  castVoteWithDelegations?: {
    __typename: 'CastVoteMutationResponse'
    votes: Array<{
      __typename: 'CollectiveProposalVote'
      id: string
      type: VoteType
      weight: PseudoBigNumber
      signatureUrl?: string | null
      signature: string
      masterVote?: {
        __typename: 'CollectiveProposalVote'
        id: string
        type: VoteType
        weight: PseudoBigNumber
        signatureUrl?: string | null
        signature: string
        surveyAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        member: {
          __typename: 'CollectiveMember'
          id: string
          totalTokens: PseudoBigNumber
          percentOfTotalTokenSupply: number
          privileged: boolean
          approved: boolean
          createdAt: Date
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              publicUrl: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              blocked: boolean
              userType: UserType
              isBot: boolean
            } | null
          }
        }
      } | null
      surveyAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      member: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    }>
    delegationFailures: Array<{
      __typename: 'DelegationResult'
      message: string
      collectiveMember: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    }>
  } | null
}

export type CreateCollectiveMutationVariables = Exact<{
  input: CreateCollectiveInput
}>

export type CreateCollectiveMutationResult = {
  __typename: 'Mutation'
  createCollective?: {
    __typename: 'Collective'
    id: string
    name: string
    publicUrl: string
  } | null
}

export type CreateMemberFileExportMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  where: FileExportInput
}>

export type CreateMemberFileExportMutationResult = {
  __typename: 'Mutation'
  createMemberFileExport: {
    __typename: 'FileExport'
    id: string
    status: FileExportStatus
    errorMessage?: string | null
    type: FileExportType
  }
}

export type CreateProposalMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  proposal: ProposalInput
}>

export type CreateProposalMutationResult = {
  __typename: 'Mutation'
  createProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type DeleteAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteAnnouncementMutationResult = {
  __typename: 'Mutation'
  deleteAnnouncement?: {
    __typename: 'Collective'
    id: string
    announcement?: {
      __typename: 'CollectiveAnnouncement'
      id: string
      bodyHtml?: string | null
      createdAt: Date
      postedBy: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    } | null
  } | null
}

export type EditAnnouncementMutationVariables = Exact<{
  announcementId: Scalars['ID']
  announcement: PostAnnouncementInput
}>

export type EditAnnouncementMutationResult = {
  __typename: 'Mutation'
  editAnnouncement?: {
    __typename: 'CollectiveAnnouncement'
    id: string
    bodyHtml?: string | null
    createdAt: Date
    postedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
  } | null
}

export type ExecProposalMutationVariables = Exact<{
  proposalId: Scalars['ID']
  txHash: Scalars['String']
  blockNumber: Scalars['Int']
}>

export type ExecProposalMutationResult = {
  __typename: 'Mutation'
  execProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type JoinExternalCollectiveMutationVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type JoinExternalCollectiveMutationResult = {
  __typename: 'Mutation'
  joinExternalCollective?: {
    __typename: 'Collective'
    id: string
    membership?: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
    } | null
  } | null
}

export type PostAnnouncementMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  announcement: PostAnnouncementInput
}>

export type PostAnnouncementMutationResult = {
  __typename: 'Mutation'
  postAnnouncement?: {
    __typename: 'Collective'
    id: string
    announcement?: {
      __typename: 'CollectiveAnnouncement'
      id: string
      bodyHtml?: string | null
      createdAt: Date
      postedBy: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    } | null
  } | null
}

export type ProposeCollectiveMembershipMutationVariables = Exact<{
  requestId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
}>

export type ProposeCollectiveMembershipMutationResult = {
  __typename: 'Mutation'
  proposeCollectiveMembership?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    publicUrl: string
  } | null
}

export type RequestCollectiveMembershipMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  notes?: InputMaybe<Scalars['String']>
}>

export type RequestCollectiveMembershipMutationResult = {
  __typename: 'Mutation'
  requestCollectiveMembership?: {
    __typename: 'CollectiveMembershipRequest'
    id: string
    status: CollectiveMembershipRequestStatus
  } | null
}

export type ResetExecutionMutationVariables = Exact<{
  proposalId: Scalars['ID']
}>

export type ResetExecutionMutationResult = {
  __typename: 'Mutation'
  resetExecution?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type SignProposalMutationVariables = Exact<{
  proposalId: Scalars['ID']
  signature: Scalars['String']
  nonce: Scalars['Int']
  txHash?: InputMaybe<Scalars['String']>
}>

export type SignProposalMutationResult = {
  __typename: 'Mutation'
  signProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type SyncCollectiveMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  proposalConnection: ConnectionInput
}>

export type SyncCollectiveMutationResult = {
  __typename: 'Mutation'
  syncCollective?: {
    __typename: 'Collective'
    id: string
    address: string
    safeAddress: string
    safeThreshold: number
    network: EthNetwork
    name: string
    description?: string | null
    archived: boolean
    totalProposals: number
    activeProposals: number
    totalMembers: number
    totalVotingMembers: number
    treasuryBalance: PseudoBigNumber
    governanceType: CollectiveTokenType
    createdAt: Date
    parameters: {
      __typename: 'CollectiveParameters'
      votingDelayInMinutes: number
      votingPeriodInMinutes: number
      quorumNeededInPercentage: number
      proposalThreshold: PseudoBigNumber
      depositFeeInPercentage: number
      withdrawFeeInPercentage: number
      enableNewMembershipRequests: boolean
      enableDeposits: boolean
      enableWithdraws: boolean
      gateDeposits: boolean
      exchangeRate: number
      minEthContribution: PseudoBigNumber
      disableTokenTransfers: boolean
    }
    syncStatus: {
      __typename: 'SyncStatus'
      syncStartedAt?: Date | null
      lastSyncedAt?: Date | null
      status: CollectiveSyncStatus
    }
    proposals: {
      __typename: 'CollectiveProposalConnection'
      edges: Array<{
        __typename: 'CollectiveProposalEdge'
        cursor: string
        node: {
          __typename: 'CollectiveProposal'
          id: string
          title: string
          status: CollectiveProposalStatus
          totalVoters: PseudoBigNumber
          totalVotes: PseudoBigNumber
          votesFor: PseudoBigNumber
          votesAgainst: PseudoBigNumber
          votesAbstain: PseudoBigNumber
          myVote?: VoteType | null
          isAuthor: boolean
          isUsingMatchingWallet: boolean
          isVotable: boolean
          isShortForm: boolean
          canVote: boolean
          createdAt: Date
          collective: { __typename: 'Collective'; id: string }
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                publicUrl: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                blocked: boolean
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
    tokens: Array<{
      __typename: 'CollectiveToken'
      id: string
      isMainToken: boolean
      type: CollectiveTokenType
      address: string
      voteWeight: PseudoBigNumber
      symbol: string
      decimals: number
      totalSupply: PseudoBigNumber
      totalVotingSupply: PseudoBigNumber
      thumb?: string | null
      name: string
    }>
    membership?: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
    } | null
  } | null
}

export type UnlinkWalletMutationVariables = Exact<{
  address: Scalars['String']
}>

export type UnlinkWalletMutationResult = {
  __typename: 'Mutation'
  unlinkWallet?: {
    __typename: 'Viewer'
    id: string
    collectiveUser: Array<{
      __typename: 'CollectiveUser'
      id: string
      address: string
    }>
  } | null
}

export type UploadCollectiveAvatarMutationVariables = Exact<{
  collectiveId: Scalars['ID']
  file: Scalars['Upload']
}>

export type UploadCollectiveAvatarMutationResult = {
  __typename: 'Mutation'
  uploadCollectiveAvatar?: {
    __typename: 'Collective'
    id: string
    thumb?: string | null
  } | null
}

export type UploadCollectiveDraftAvatarMutationVariables = Exact<{
  file: Scalars['Upload']
  collectiveDraftId?: InputMaybe<Scalars['ID']>
}>

export type UploadCollectiveDraftAvatarMutationResult = {
  __typename: 'Mutation'
  uploadCollectiveDraftAvatar: string
}

export type CreateCategoryMutationVariables = Exact<{
  discussionBoardId: Scalars['ID']
  categoryDetails: CategoryInput
}>

export type CreateCategoryMutationResult = {
  __typename: 'Mutation'
  createCategory: {
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }
}

export type CreateCommentMutationVariables = Exact<{
  threadId: Scalars['ID']
  commentDetails: CommentCreateInput
}>

export type CreateCommentMutationResult = {
  __typename: 'Mutation'
  createComment: {
    __typename: 'Comment'
    id: string
    body: string
    replyCount: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type CreateThreadMutationVariables = Exact<{
  categoryId: Scalars['ID']
  threadDetails: ThreadCreateInput
}>

export type CreateThreadMutationResult = {
  __typename: 'Mutation'
  createThread: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type DeleteCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID']
}>

export type DeleteCategoryMutationResult = {
  __typename: 'Mutation'
  deleteCategory?: {
    __typename: 'GenericMutationResponse'
    success: boolean
    code?: string | null
    message?: string | null
  } | null
}

export type DeleteThreadMutationVariables = Exact<{
  threadId: Scalars['ID']
}>

export type DeleteThreadMutationResult = {
  __typename: 'Mutation'
  deleteThread?: {
    __typename: 'GenericMutationResponse'
    success: boolean
    code?: string | null
    message?: string | null
  } | null
}

export type PinThreadMutationVariables = Exact<{
  threadId: Scalars['ID']
  pinned: Scalars['Boolean']
}>

export type PinThreadMutationResult = {
  __typename: 'Mutation'
  pinThread: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type UpdateCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID']
  categoryDetails: CategoryInput
}>

export type UpdateCategoryMutationResult = {
  __typename: 'Mutation'
  updateCategory: {
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }
}

export type UpdateCommentMutationVariables = Exact<{
  threadId: Scalars['ID']
  commentDetails: CommentUpdateInput
}>

export type UpdateCommentMutationResult = {
  __typename: 'Mutation'
  updateComment: {
    __typename: 'Comment'
    id: string
    body: string
    replyCount: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type UpdateDiscussionBoardCategoryNotificationSettingMutationVariables =
  Exact<{
    categoryId: Scalars['ID']
    allowNotifications: Scalars['Boolean']
  }>

export type UpdateDiscussionBoardCategoryNotificationSettingMutationResult = {
  __typename: 'Mutation'
  updateDiscussionBoardCategoryNotificationSetting: {
    __typename: 'DiscussionBoard'
    id: string
    categories: Array<{
      __typename: 'Category'
      id: string
      name: string
      description?: string | null
      order: number
      removable: boolean
      governance: boolean
      archived: boolean
      totalThreads: number
      createdAt: Date
      updatedAt: Date
      deletedAt?: Date | null
      publicUrl: string
      notificationSettings: {
        __typename: 'DiscussionBoardCategoryNotificationSetting'
        id: string
        allowNotifications: boolean
      }
      discussionBoard: { __typename: 'DiscussionBoard'; id: string }
    }>
    notificationSettings: {
      __typename: 'DiscussionBoardNotificationSetting'
      allowGlobalNotifications: boolean
    }
  }
}

export type UpdateDiscussionBoardNotificationSettingMutationVariables = Exact<{
  discussionBoardId: Scalars['ID']
  allowGlobalNotifications: Scalars['Boolean']
}>

export type UpdateDiscussionBoardNotificationSettingMutationResult = {
  __typename: 'Mutation'
  updateDiscussionBoardNotificationSetting: {
    __typename: 'DiscussionBoard'
    id: string
    categories: Array<{
      __typename: 'Category'
      id: string
      name: string
      description?: string | null
      order: number
      removable: boolean
      governance: boolean
      archived: boolean
      totalThreads: number
      createdAt: Date
      updatedAt: Date
      deletedAt?: Date | null
      publicUrl: string
      notificationSettings: {
        __typename: 'DiscussionBoardCategoryNotificationSetting'
        id: string
        allowNotifications: boolean
      }
      discussionBoard: { __typename: 'DiscussionBoard'; id: string }
    }>
    notificationSettings: {
      __typename: 'DiscussionBoardNotificationSetting'
      allowGlobalNotifications: boolean
    }
  }
}

export type UpdateDiscussionBoardThreadNotificationSettingMutationVariables =
  Exact<{
    threadId: Scalars['ID']
    allowNotifications: Scalars['Boolean']
  }>

export type UpdateDiscussionBoardThreadNotificationSettingMutationResult = {
  __typename: 'Mutation'
  updateDiscussionBoardThreadNotificationSetting: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type UpdateThreadMutationVariables = Exact<{
  threadId: Scalars['ID']
  threadDetails: ThreadUpdateInput
}>

export type UpdateThreadMutationResult = {
  __typename: 'Mutation'
  updateThread: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type ArchiveCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID']
  archived: Scalars['Boolean']
}>

export type ArchiveCategoryMutationResult = {
  __typename: 'Mutation'
  archiveCategory: {
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }
}

export type BanAttendeeMutationVariables = Exact<{
  eventId: Scalars['ID']
  userId: Scalars['ID']
}>

export type BanAttendeeMutationResult = {
  __typename: 'Mutation'
  banAttendee?: {
    __typename: 'EventRegistration'
    id: string
    banned: boolean
  } | null
}

export type CreateEventMutationVariables = Exact<{
  event: EventInput
}>

export type CreateEventMutationResult = {
  __typename: 'Mutation'
  createEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['ID']
}>

export type DeleteEventMutationResult = {
  __typename: 'Mutation'
  deleteEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type EditEventAssetMutationVariables = Exact<{
  assetId: Scalars['ID']
  caption: Scalars['String']
  public: Scalars['Boolean']
}>

export type EditEventAssetMutationResult = {
  __typename: 'Mutation'
  editEventAsset: {
    __typename: 'EventAsset'
    id: string
    public: boolean
    caption: string
  }
}

export type EndSpeakerMutationVariables = Exact<{
  eventId: Scalars['ID']
  userId: Scalars['ID']
}>

export type EndSpeakerMutationResult = {
  __typename: 'Mutation'
  endSpeaker?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type LeaveEventMutationVariables = Exact<{
  eventId: Scalars['ID']
}>

export type LeaveEventMutationResult = {
  __typename: 'Mutation'
  leaveEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type RSVPToEventMutationVariables = Exact<{
  eventId: Scalars['ID']
  rsvp: EventRegistrationRSVP
}>

export type RSVPToEventMutationResult = {
  __typename: 'Mutation'
  rsvpToEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type SetAttendeeRoleMutationVariables = Exact<{
  eventId: Scalars['ID']
  userId: Scalars['ID']
  role: EventRegistrationRole
}>

export type SetAttendeeRoleMutationResult = {
  __typename: 'Mutation'
  setAttendeeRole?: {
    __typename: 'EventRegistration'
    id: string
    role: EventRegistrationRole
  } | null
}

export type SetEventStateMutationVariables = Exact<{
  eventId: Scalars['ID']
  eventState: EventState
}>

export type SetEventStateMutationResult = {
  __typename: 'Mutation'
  setEventState?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type SetPassiveModeMutationVariables = Exact<{
  eventId: Scalars['ID']
  passive: Scalars['Boolean']
}>

export type SetPassiveModeMutationResult = {
  __typename: 'Mutation'
  setPassiveMode?: { __typename: 'Event'; id: string; passive: boolean } | null
}

export type SetRecordingStateMutationVariables = Exact<{
  eventId: Scalars['ID']
  newRecordingState: Scalars['Boolean']
}>

export type SetRecordingStateMutationResult = {
  __typename: 'Mutation'
  setRecordingState?: {
    __typename: 'Event'
    id: string
    recording: boolean
  } | null
}

export type SetSpeakerMutationVariables = Exact<{
  eventId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
  layout?: InputMaybe<BroadcastVideoLayout>
}>

export type SetSpeakerMutationResult = {
  __typename: 'Mutation'
  setSpeaker?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type SignalAttendeeMutationVariables = Exact<{
  signal: SignalInput
}>

export type SignalAttendeeMutationResult = {
  __typename: 'Mutation'
  signalAttendee?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type SignalAttendeesMutationVariables = Exact<{
  signal: BroadcastSignalInput
}>

export type SignalAttendeesMutationResult = {
  __typename: 'Mutation'
  signalAttendees?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type UpdateEventMutationVariables = Exact<{
  eventId: Scalars['ID']
  event: EventInput
}>

export type UpdateEventMutationResult = {
  __typename: 'Mutation'
  updateEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type UpgradeWaitlistedAttendeeMutationVariables = Exact<{
  eventId: Scalars['ID']
  userId: Scalars['ID']
}>

export type UpgradeWaitlistedAttendeeMutationResult = {
  __typename: 'Mutation'
  upgradeWaitlistedAttendee?: {
    __typename: 'EventRegistration'
    id: string
    rsvp: EventRegistrationRSVP
    waitlisted: boolean
  } | null
}

export type UploadEventThumbMutationVariables = Exact<{
  eventId: Scalars['ID']
  file: Scalars['Upload']
}>

export type UploadEventThumbMutationResult = {
  __typename: 'Mutation'
  uploadEventThumb?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
  } | null
}

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>

export type DeleteAccountMutationResult = {
  __typename: 'Mutation'
  deleteAccount?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type BaseUserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type BaseUserQueryResult = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  } | null
}

export type BlockedUsersQueryVariables = Exact<{ [key: string]: never }>

export type BlockedUsersQueryResult = {
  __typename: 'Query'
  me: {
    __typename: 'Viewer'
    id: string
    blockedUsers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
  }
}

export type CurrentCollectiveIdQueryVariables = Exact<{ [key: string]: never }>

export type CurrentCollectiveIdQueryResult = {
  __typename: 'Query'
  currentCollectiveId?: string | null
}

export type DiscoverEngagementsQueryVariables = Exact<{
  engagementConnection?: ConnectionInput
  sort: EngagementSortingInput
  filters: Array<FilterableEngagementField> | FilterableEngagementField
  seriesId?: InputMaybe<Scalars['ID']>
  seriesSlug?: InputMaybe<Scalars['String']>
  ignoreEventId?: InputMaybe<Scalars['ID']>
}>

export type DiscoverEngagementsQueryResult = {
  __typename: 'Query'
  discoverEngagements?: {
    __typename: 'EngagementConnection'
    edges: Array<{
      __typename: 'EngagementEdge'
      cursor: string
      node: {
        __typename: 'Event'
        id: string
        thumb?: string | null
        title: string
        description: string
        format: EventFormat
        formatLabel: string
        formatDescription: string
        capacity: number
        rsvp?: EventRegistrationRSVP | null
        waitlisted: boolean
        passive: boolean
        actualStartTime?: Date | null
        startTime: Date
        state: EventState
        createdAt: Date
        deleted: boolean
        publicUrl: string
        canJoinVideo: boolean
        canJoinNotice?: string | null
        isOwner: boolean
        isPrivileged: boolean
        isCurrentlySpeaking: boolean
        canEdit: boolean
        introductionTime: number
        location?: string | null
        discoverable: boolean
        shareable: boolean
        recording: boolean
        counts: {
          __typename: 'EventCounts'
          attending: number
          notAttending: number
          maybeAttending: number
          attended?: number | null
          matched?: number | null
          passive?: number | null
        }
        speakers: Array<{
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        }>
        user: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        }
        geolocation?: {
          __typename: 'GeolocationObject'
          latitude: number
          longitude: number
        } | null
        collective?: {
          __typename: 'Collective'
          id: string
          name: string
          thumb?: string | null
          publicUrl: string
          membership?: {
            __typename: 'CollectiveMember'
            id: string
            approved: boolean
            privileged: boolean
          } | null
        } | null
        series?: {
          __typename: 'EventSeries'
          id: string
          slug: string
          label: string
        } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor?: string | null
      endCursor?: string | null
    }
  } | null
}

export type EnrichmentsQueryVariables = Exact<{ [key: string]: never }>

export type EnrichmentsQueryResult = {
  __typename: 'Query'
  enrichments?: {
    __typename: 'UserEnrichments'
    awaitingEnrichments: boolean
    suggestions: {
      __typename: 'EnrichmentSuggestions'
      nfts?: Array<{
        __typename: 'EnrichmentNFT'
        name: string
        tokenId: string
        url: string
        address: string
        collectionName: string
      }> | null
    }
  } | null
}

export type EventUserPlanQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EventUserPlanQueryResult = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    state: EventState
    actualStartTime?: Date | null
    userPlan?: {
      __typename: 'EventUserPlan'
      currentLayout: BroadcastVideoLayout
      fetchedAt: Date
      nextRefecthAt?: Date | null
      currentSpeakers?: Array<{
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      }> | null
      mainChannel: {
        __typename: 'EventVideoChannel'
        provider: VideoProvider
        sessionId: string
        token: string
      }
      lobbyChannel: {
        __typename: 'EventVideoChannel'
        provider: VideoProvider
        sessionId: string
        token: string
      }
      currentChannel: {
        __typename: 'EventVideoChannel'
        provider: VideoProvider
        sessionId: string
        token: string
      }
    } | null
  }
}

export type GetEmailPreferencesQueryVariables = Exact<{
  emailId: Scalars['ID']
}>

export type GetEmailPreferencesQueryResult = {
  __typename: 'Query'
  getEmailPreferences: {
    __typename: 'EmailPreferences'
    truncatedEmail: string
    allowedTypes: Array<EmailType>
    unsubscribedFromAll: boolean
  }
}

export type HasChromeQueryVariables = Exact<{ [key: string]: never }>

export type HasChromeQueryResult = { __typename: 'Query'; hasChrome: boolean }

export type HasGmailQueryVariables = Exact<{ [key: string]: never }>

export type HasGmailQueryResult = { __typename: 'Query'; hasGmail: boolean }

export type IsConnectedQueryVariables = Exact<{ [key: string]: never }>

export type IsConnectedQueryResult = {
  __typename: 'Query'
  isConnected: boolean
}

export type LanguageQueryVariables = Exact<{
  language: Scalars['String']
}>

export type LanguageQueryResult = {
  __typename: 'Query'
  language: { [key: string]: any }
}

export type NonceQueryVariables = Exact<{ [key: string]: never }>

export type NonceQueryResult = {
  __typename: 'Query'
  me: { __typename: 'Viewer'; id: string; nonce: string }
}

export type ResolveSubdomainQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ResolveSubdomainQueryResult = {
  __typename: 'Query'
  resolveSubdomain?: {
    __typename: 'Collective'
    id: string
    publicUrl: string
  } | null
}

export type SEOQueryVariables = Exact<{
  path: Scalars['ID']
}>

export type SEOQueryResult = {
  __typename: 'Query'
  seo?: {
    __typename: 'SEO'
    path: string
    title?: string | null
    metaDescription?: string | null
    metaKeywords?: Array<string> | null
    additionalMetaTags?: Array<{
      __typename: 'MetaTag'
      name: string
      content: string
    }> | null
  } | null
}

export type SearchQueryVariables = Exact<{
  query: Scalars['String']
}>

export type SearchQueryResult = {
  __typename: 'Query'
  search: {
    __typename: 'SearchResults'
    totalHits: number
    hits: Array<
      | {
          __typename: 'Collective'
          id: string
          publicUrl: string
          thumb?: string | null
          name: string
          description?: string | null
          totalMembers: number
          tokens: Array<{
            __typename: 'CollectiveToken'
            id: string
            symbol: string
          }>
          membership?: {
            __typename: 'CollectiveMember'
            id: string
            privileged: boolean
            approved: boolean
          } | null
          membershipRequest?: {
            __typename: 'CollectiveMembershipRequest'
            id: string
            status: CollectiveMembershipRequestStatus
          } | null
        }
      | {
          __typename: 'CollectiveProposal'
          id: string
          publicUrl: string
          status: CollectiveProposalStatus
          title: string
          type: CollectiveProposalType
          startTime: Date
          endTime: Date
          myVote?: VoteType | null
          isBatch: boolean
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
      | {
          __typename: 'Event'
          id: string
          publicUrl: string
          thumb?: string | null
          location?: string | null
          eventTitle: string
          counts: { __typename: 'EventCounts'; attending: number }
          user: {
            __typename: 'User'
            id: string
            thumb?: string | null
            shortDisplayName?: string | null
          }
          series?: {
            __typename: 'EventSeries'
            id: string
            label: string
          } | null
        }
      | {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          userType: UserType
          isBot: boolean
        }
    >
  }
}

export type SiteFlagsQueryVariables = Exact<{ [key: string]: never }>

export type SiteFlagsQueryResult = {
  __typename: 'Query'
  flags: Array<
    | {
        __typename: 'SiteFlagBoolean'
        id: string
        key: string
        type: SiteFlagType
        booleanValue?: boolean | null
      }
    | {
        __typename: 'SiteFlagNumber'
        id: string
        key: string
        type: SiteFlagType
        numberValue?: number | null
      }
    | {
        __typename: 'SiteFlagString'
        id: string
        key: string
        type: SiteFlagType
        stringValue?: string | null
      }
    | null
  >
}

export type TagsQueryVariables = Exact<{ [key: string]: never }>

export type TagsQueryResult = {
  __typename: 'Query'
  tags?: Array<{ __typename: 'Tag'; label: string } | null> | null
}

export type TokenQueryVariables = Exact<{ [key: string]: never }>

export type TokenQueryResult = { __typename: 'Query'; token?: string | null }

export type UserEngagementDatesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserEngagementDatesQueryResult = {
  __typename: 'Query'
  user?: { __typename: 'User'; id: string; engagementDates: Array<Date> } | null
}

export type UserEngagementsQueryVariables = Exact<{
  id: Scalars['ID']
  engagementConnection: ConnectionInput
  sort: EngagementSortingInput
  filters: Array<FilterableEngagementField> | FilterableEngagementField
  dateFilter?: InputMaybe<Scalars['Date']>
}>

export type UserEngagementsQueryResult = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    engagements: {
      __typename: 'EngagementConnection'
      edges: Array<{
        __typename: 'EngagementEdge'
        cursor: string
        node: {
          __typename: 'Event'
          id: string
          thumb?: string | null
          title: string
          description: string
          format: EventFormat
          formatLabel: string
          formatDescription: string
          capacity: number
          rsvp?: EventRegistrationRSVP | null
          waitlisted: boolean
          passive: boolean
          actualStartTime?: Date | null
          startTime: Date
          state: EventState
          createdAt: Date
          deleted: boolean
          publicUrl: string
          canJoinVideo: boolean
          canJoinNotice?: string | null
          isOwner: boolean
          isPrivileged: boolean
          isCurrentlySpeaking: boolean
          canEdit: boolean
          introductionTime: number
          location?: string | null
          discoverable: boolean
          shareable: boolean
          recording: boolean
          counts: {
            __typename: 'EventCounts'
            attending: number
            notAttending: number
            maybeAttending: number
            attended?: number | null
            matched?: number | null
            passive?: number | null
          }
          speakers: Array<{
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }>
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }
          geolocation?: {
            __typename: 'GeolocationObject'
            latitude: number
            longitude: number
          } | null
          collective?: {
            __typename: 'Collective'
            id: string
            name: string
            thumb?: string | null
            publicUrl: string
            membership?: {
              __typename: 'CollectiveMember'
              id: string
              approved: boolean
              privileged: boolean
            } | null
          } | null
          series?: {
            __typename: 'EventSeries'
            id: string
            slug: string
            label: string
          } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type UserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserQueryResult = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    canSendDM: boolean
    syncStatus: UserSyncStatus
    syncStatusDate?: Date | null
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    url?: string | null
    twitter?: string | null
    linkedin?: string | null
    instagram?: string | null
    createdAt?: Date | null
    blocked: boolean
    deleted: boolean
    userType: UserType
    isBot: boolean
  } | null
}

export type ViewerQueryVariables = Exact<{
  deviceInfo?: InputMaybe<DeviceInfoInput>
}>

export type ViewerQueryResult = {
  __typename: 'Query'
  me: {
    __typename: 'Viewer'
    id: string
    thumb?: string | null
    username?: string | null
    appleIdConnected?: boolean | null
    shortDisplayName?: string | null
    displayName?: string | null
    url?: string | null
    twitter?: string | null
    linkedin?: string | null
    instagram?: string | null
    createdAt?: Date | null
    deleted: boolean
    emailVerified: boolean
    email: string
    collectivesActionablesCount: number
    jwtToken?: string | null
    possiblySpam: boolean
    streamChatToken?: string | null
    userType: UserType
    hasCollectives: boolean
    canCreateDiscoverableEvents: boolean
    syncStatus: UserSyncStatus
    syncStatusDate?: Date | null
    preferences?: Array<{
      __typename: 'UserPreference'
      key: string
      value?: string | null
    } | null> | null
    collectiveUser: Array<{
      __typename: 'CollectiveUser'
      id: string
      address: string
      collectives: Array<{ __typename: 'Collective'; id: string }>
    }>
  }
}

export type EphemeralCryptoAuthQueryVariables = Exact<{ [key: string]: never }>

export type EphemeralCryptoAuthQueryResult = {
  __typename: 'Query'
  ephemeralCryptoAuth: {
    __typename: 'EphemeralCryptoAuth'
    id: string
    authMessage: string
  }
}

export type CollectiveApplicationQueryVariables = Exact<{
  applicationId: Scalars['ID']
}>

export type CollectiveApplicationQueryResult = {
  __typename: 'Query'
  collectiveApplication?: {
    __typename: 'CollectiveApplication'
    id: string
    thumb?: string | null
    payload: { [key: string]: any }
    status: CollectiveApplicationStatus
    createdAt: Date
  } | null
}

export type CollectiveApplicationsQueryVariables = Exact<{
  [key: string]: never
}>

export type CollectiveApplicationsQueryResult = {
  __typename: 'Query'
  collectiveApplications?: Array<{
    __typename: 'CollectiveApplication'
    id: string
    thumb?: string | null
    payload: { [key: string]: any }
    status: CollectiveApplicationStatus
    createdAt: Date
  }> | null
}

export type CollectiveDraftQueryVariables = Exact<{
  collectiveDraftId: Scalars['ID']
}>

export type CollectiveDraftQueryResult = {
  __typename: 'Query'
  collectiveDraft?: {
    __typename: 'CollectiveDraft'
    id: string
    subdomain?: string | null
    avatar?: string | null
    externalTokenAddress?: string | null
    name: string
    intent: CollectiveIntent
    description: string
    quorumNeededInPercentage?: number | null
    proposalThreshold?: PseudoBigNumber | null
    depositFeeInPercentage?: number | null
    withdrawFeeInPercentage?: number | null
    enableNewMembershipRequests?: boolean | null
    enableDeposits?: boolean | null
    enableWithdraws?: boolean | null
    gateDeposits?: boolean | null
    exchangeRate?: number | null
    minEthContribution?: PseudoBigNumber | null
    disableTokenTransfers?: boolean | null
    requireVotingSignature?: boolean | null
    votingAnonymity?: VotingAnonymity | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    symbol?: string | null
    customerSupport?: Array<string> | null
    members?: Array<string> | null
    createdAt: Date
    lastStep: InputStep
    jumpableSteps: Array<InputStep>
  } | null
}

export type ViewerCollectiveDraftsQueryVariables = Exact<{
  collectiveConnection: ConnectionInput
}>

export type ViewerCollectiveDraftsQueryResult = {
  __typename: 'Query'
  me: {
    __typename: 'Viewer'
    id: string
    collectiveDrafts: {
      __typename: 'CollectiveDraftConnection'
      edges: Array<{
        __typename: 'CollectiveDraftEdge'
        cursor: string
        node: {
          __typename: 'CollectiveDraft'
          id: string
          subdomain?: string | null
          avatar?: string | null
          externalTokenAddress?: string | null
          name: string
          intent: CollectiveIntent
          description: string
          quorumNeededInPercentage?: number | null
          proposalThreshold?: PseudoBigNumber | null
          depositFeeInPercentage?: number | null
          withdrawFeeInPercentage?: number | null
          enableNewMembershipRequests?: boolean | null
          enableDeposits?: boolean | null
          enableWithdraws?: boolean | null
          gateDeposits?: boolean | null
          exchangeRate?: number | null
          minEthContribution?: PseudoBigNumber | null
          disableTokenTransfers?: boolean | null
          requireVotingSignature?: boolean | null
          votingAnonymity?: VotingAnonymity | null
          firstName?: string | null
          lastName?: string | null
          email?: string | null
          symbol?: string | null
          customerSupport?: Array<string> | null
          members?: Array<string> | null
          createdAt: Date
          lastStep: InputStep
          jumpableSteps: Array<InputStep>
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type AuthMessageQueryVariables = Exact<{ [key: string]: never }>

export type AuthMessageQueryResult = {
  __typename: 'Query'
  authMessage: string
}

export type BaseCollectiveQueryVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type BaseCollectiveQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    thumb?: string | null
    network: EthNetwork
    name: string
    description?: string | null
    totalMembers: number
    totalVotingMembers: number
    governanceType: CollectiveTokenType
    archived: boolean
    ogImageUrl?: string | null
    publicUrl: string
    createdAt: Date
    signators: Array<{
      __typename: 'CollectiveMember'
      id: string
      user: {
        __typename: 'CollectiveUser'
        id: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    tokens: Array<{ __typename: 'CollectiveToken'; symbol: string }>
    parameters: {
      __typename: 'CollectiveParameters'
      enableNewMembershipRequests: boolean
      minEthContribution: PseudoBigNumber
    }
    syncStatus: {
      __typename: 'SyncStatus'
      syncStartedAt?: Date | null
      lastSyncedAt?: Date | null
      status: CollectiveSyncStatus
    }
  } | null
}

export type CollectiveAssetsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  assetsConnection: ConnectionInput
  where: CollectiveAssetsQueryInput
}>

export type CollectiveAssetsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    assets: {
      __typename: 'CollectiveAssetsConnection'
      edges: Array<{
        __typename: 'CollectiveAssetEdge'
        cursor: string
        node:
          | {
              __typename: 'ERCAsset'
              id: string
              tokenId?: string | null
              name?: string | null
              symbol?: string | null
              address: string
              balance: PseudoBigNumber
              decimals?: number | null
              thumb?: string | null
              contractType: ContractTypes
              contractName: string
              mediaUrls: Array<string>
              externalUrl?: string | null
              lastSynced: Date
              estimatedValueEth?: PseudoBigNumber | null
              estimatedValueUSD?: number | null
              wallet?: {
                __typename: 'CollectiveWallet'
                id: string
                isMainWallet: boolean
              } | null
            }
          | {
              __typename: 'ExternalAsset'
              id: string
              type: ExternalAssetType
              address: string
              thumb?: string | null
              externalUrl?: string | null
              symbol?: string | null
              formattedAmount?: string | null
              lastSynced: Date
              estimatedValueUSD?: number | null
              wallet?: {
                __typename: 'CollectiveWallet'
                id: string
                isMainWallet: boolean
              } | null
            }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type CollectiveDemoQueryVariables = Exact<{
  collectiveDemoId: Scalars['ID']
}>

export type CollectiveDemoQueryResult = {
  __typename: 'Query'
  collectiveDemo?: {
    __typename: 'CollectiveDemo'
    id: string
    collective: { [key: string]: any }
  } | null
}

export type CollectiveEngagementsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  engagementConnection: ConnectionInput
  sort: EngagementSortingInput
  filters: Array<FilterableEngagementField> | FilterableEngagementField
}>

export type CollectiveEngagementsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    engagements: {
      __typename: 'EngagementConnection'
      edges: Array<{
        __typename: 'EngagementEdge'
        cursor: string
        node: {
          __typename: 'Event'
          id: string
          thumb?: string | null
          title: string
          description: string
          format: EventFormat
          formatLabel: string
          formatDescription: string
          capacity: number
          rsvp?: EventRegistrationRSVP | null
          waitlisted: boolean
          passive: boolean
          actualStartTime?: Date | null
          startTime: Date
          state: EventState
          createdAt: Date
          deleted: boolean
          publicUrl: string
          canJoinVideo: boolean
          canJoinNotice?: string | null
          isOwner: boolean
          isPrivileged: boolean
          isCurrentlySpeaking: boolean
          canEdit: boolean
          introductionTime: number
          location?: string | null
          discoverable: boolean
          shareable: boolean
          recording: boolean
          counts: {
            __typename: 'EventCounts'
            attending: number
            notAttending: number
            maybeAttending: number
            attended?: number | null
            matched?: number | null
            passive?: number | null
          }
          speakers: Array<{
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }>
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }
          geolocation?: {
            __typename: 'GeolocationObject'
            latitude: number
            longitude: number
          } | null
          collective?: {
            __typename: 'Collective'
            id: string
            name: string
            thumb?: string | null
            publicUrl: string
            membership?: {
              __typename: 'CollectiveMember'
              id: string
              approved: boolean
              privileged: boolean
            } | null
          } | null
          series?: {
            __typename: 'EventSeries'
            id: string
            slug: string
            label: string
          } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type CollectiveFaqsQueryVariables = Exact<{
  screen?: InputMaybe<CollectiveScreen>
  limit?: InputMaybe<Scalars['Int']>
}>

export type CollectiveFaqsQueryResult = {
  __typename: 'Query'
  collectiveFaqs: Array<{
    __typename: 'CollectiveFaq'
    question: string
    snippet?: string | null
    link: string
  }>
}

export type ListCollectiveLogsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  requestConnection: ConnectionInput
  where: CollectiveLogsQueryInput
}>

export type ListCollectiveLogsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    logs: {
      __typename: 'CollectiveLogsConnection'
      edges: Array<{
        __typename: 'CollectiveLogsEdge'
        cursor: string
        node: {
          __typename: 'BundledCollectiveLog'
          date: Date
          eventType: CollectiveLogEventType
          logs: Array<{
            __typename: 'CollectiveLog'
            id: string
            eventType: CollectiveLogEventType
            txHash?: string | null
            blockNumber?: number | null
            date: Date
            collectiveUser?: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                userType: UserType
                isBot: boolean
              } | null
            } | null
            data:
              | {
                  __typename: 'CollectiveLogDataOnAddedOwner'
                  owner: string
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnAnnouncementChanged'
                  announcement: {
                    __typename: 'CollectiveAnnouncement'
                    bodyHtml?: string | null
                  }
                }
              | {
                  __typename: 'CollectiveLogDataOnAnnouncementCreated'
                  announcement: {
                    __typename: 'CollectiveAnnouncement'
                    bodyHtml?: string | null
                  }
                }
              | {
                  __typename: 'CollectiveLogDataOnAnnouncementDeleted'
                  announcement: {
                    __typename: 'CollectiveAnnouncement'
                    bodyHtml?: string | null
                  }
                }
              | {
                  __typename: 'CollectiveLogDataOnChangedThreshold'
                  threshold: PseudoBigNumber
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnCollectiveMembershipProposed'
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                  request?: {
                    __typename: 'CollectiveMembershipRequest'
                    id: string
                    status: CollectiveMembershipRequestStatus
                    createdAt: Date
                    user: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnCollectiveMembershipRequested'
                }
              | { __typename: 'CollectiveLogDataOnCollectiveSyncRequested' }
              | { __typename: 'CollectiveLogDataOnCollectiveSynced' }
              | {
                  __typename: 'CollectiveLogDataOnDelegationsGranted'
                  delegator: {
                    __typename: 'CollectiveUser'
                    id: string
                    address: string
                    platformUser?: {
                      __typename: 'User'
                      id: string
                      thumb?: string | null
                      shortDisplayName?: string | null
                      displayName?: string | null
                      userType: UserType
                      isBot: boolean
                    } | null
                  }
                  delegations: Array<{
                    __typename: 'CommandDelegationStruct'
                    command?: ProposalCommandType | null
                    maxValue?: PseudoBigNumber | null
                    wildcard?: boolean | null
                    surveys?: boolean | null
                    delegatee: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  }>
                }
              | {
                  __typename: 'CollectiveLogDataOnDelegationsRevoked'
                  delegator: {
                    __typename: 'CollectiveUser'
                    id: string
                    address: string
                    platformUser?: {
                      __typename: 'User'
                      id: string
                      thumb?: string | null
                      shortDisplayName?: string | null
                      displayName?: string | null
                      userType: UserType
                      isBot: boolean
                    } | null
                  }
                  delegations: Array<{
                    __typename: 'CommandDelegationStruct'
                    command?: ProposalCommandType | null
                    maxValue?: PseudoBigNumber | null
                    wildcard?: boolean | null
                    surveys?: boolean | null
                    delegatee: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  }>
                }
              | {
                  __typename: 'CollectiveLogDataOnDisableTokenTransfersChanged'
                  disableTokenTransfers: boolean
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnEnableDepositsChanged'
                  enableDeposits: boolean
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnEnableNewMembershipRequestsChanged'
                  enableNewMembershipRequests: boolean
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnEnableWithdrawsChanged'
                  enableWithdraws: boolean
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnExchangeRateChanged'
                  previousRate: PseudoBigNumber
                  newRate: PseudoBigNumber
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | { __typename: 'CollectiveLogDataOnExternalCollectiveJoined' }
              | {
                  __typename: 'CollectiveLogDataOnFeesChanged'
                  depositFee: PseudoBigNumber
                }
              | {
                  __typename: 'CollectiveLogDataOnFundsDeposited'
                  etherAmount: PseudoBigNumber
                }
              | {
                  __typename: 'CollectiveLogDataOnGateDepositsChanged'
                  gateDeposits: boolean
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnMemberAdded'
                  targetMember: string
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnMemberDepositedFunds'
                  etherAmount: PseudoBigNumber
                  tokenAmount: PseudoBigNumber
                }
              | {
                  __typename: 'CollectiveLogDataOnMemberRemoved'
                  targetMember: string
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnMemberWithdrewFunds'
                  etherAmount: PseudoBigNumber
                }
              | {
                  __typename: 'CollectiveLogDataOnMinEthContributionChanged'
                  contributionAmount: PseudoBigNumber
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnNonDepositEtherReceived'
                  etherAmount: PseudoBigNumber
                  sender: string
                }
              | {
                  __typename: 'CollectiveLogDataOnProposalCanceled'
                  reason: string
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnProposalExecuted'
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnProposalExecutionReset'
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnProposalRequireVoteSignatureChanged'
                  requireVotingSignature: boolean
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnProposalSigned'
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnProposalsBatched'
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnQuorumNeededInPercentageChanged'
                  quorumNeededInPercentage: number
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnRemovedOwner'
                  owner: string
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnTransfer'
                  value?: PseudoBigNumber | null
                  tokenId?: PseudoBigNumber | null
                  from: string
                  to: string
                  token?: {
                    __typename: 'CollectiveToken'
                    id: string
                    address: string
                    symbol: string
                    decimals: number
                    thumb?: string | null
                    name: string
                    contractType: string
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnTransferBatch'
                  values: Array<PseudoBigNumber>
                  tokenIds: Array<PseudoBigNumber>
                  from: string
                  to: string
                  token?: {
                    __typename: 'CollectiveToken'
                    id: string
                    address: string
                    symbol: string
                    decimals: number
                    thumb?: string | null
                    name: string
                    contractType: string
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnTransferSingle'
                  value?: PseudoBigNumber | null
                  tokenId?: PseudoBigNumber | null
                  from: string
                  to: string
                  token?: {
                    __typename: 'CollectiveToken'
                    id: string
                    address: string
                    symbol: string
                    decimals: number
                    thumb?: string | null
                    name: string
                    contractType: string
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnVoteCast'
                  vote: {
                    __typename: 'CollectiveProposalVote'
                    id: string
                    type: VoteType
                    weight: PseudoBigNumber
                    masterVote?: {
                      __typename: 'CollectiveProposalVote'
                      id: string
                      type: VoteType
                      weight: PseudoBigNumber
                      surveyAnswers?: Array<{
                        __typename: 'Answer'
                        choices: Array<{
                          __typename: 'AnswerChoice'
                          dateChoice?: Date | null
                          stringChoice?: string | null
                        }>
                      }> | null
                      member: {
                        __typename: 'CollectiveMember'
                        id: string
                        user: {
                          __typename: 'CollectiveUser'
                          id: string
                          address: string
                          platformUser?: {
                            __typename: 'User'
                            id: string
                            thumb?: string | null
                            shortDisplayName?: string | null
                            displayName?: string | null
                            userType: UserType
                            isBot: boolean
                          } | null
                        }
                      }
                    } | null
                    surveyAnswers?: Array<{
                      __typename: 'Answer'
                      choices: Array<{
                        __typename: 'AnswerChoice'
                        dateChoice?: Date | null
                        stringChoice?: string | null
                      }>
                    }> | null
                    member: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                  }
                  user: {
                    __typename: 'CollectiveMember'
                    id: string
                    user: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  }
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnVoteChanged'
                  previousVote: {
                    __typename: 'CollectiveProposalVote'
                    id: string
                    type: VoteType
                    weight: PseudoBigNumber
                    masterVote?: {
                      __typename: 'CollectiveProposalVote'
                      id: string
                      type: VoteType
                      weight: PseudoBigNumber
                      surveyAnswers?: Array<{
                        __typename: 'Answer'
                        choices: Array<{
                          __typename: 'AnswerChoice'
                          dateChoice?: Date | null
                          stringChoice?: string | null
                        }>
                      }> | null
                      member: {
                        __typename: 'CollectiveMember'
                        id: string
                        user: {
                          __typename: 'CollectiveUser'
                          id: string
                          address: string
                          platformUser?: {
                            __typename: 'User'
                            id: string
                            thumb?: string | null
                            shortDisplayName?: string | null
                            displayName?: string | null
                            userType: UserType
                            isBot: boolean
                          } | null
                        }
                      }
                    } | null
                    surveyAnswers?: Array<{
                      __typename: 'Answer'
                      choices: Array<{
                        __typename: 'AnswerChoice'
                        dateChoice?: Date | null
                        stringChoice?: string | null
                      }>
                    }> | null
                    member: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                  }
                  newVote: {
                    __typename: 'CollectiveProposalVote'
                    id: string
                    type: VoteType
                    weight: PseudoBigNumber
                    masterVote?: {
                      __typename: 'CollectiveProposalVote'
                      id: string
                      type: VoteType
                      weight: PseudoBigNumber
                      surveyAnswers?: Array<{
                        __typename: 'Answer'
                        choices: Array<{
                          __typename: 'AnswerChoice'
                          dateChoice?: Date | null
                          stringChoice?: string | null
                        }>
                      }> | null
                      member: {
                        __typename: 'CollectiveMember'
                        id: string
                        user: {
                          __typename: 'CollectiveUser'
                          id: string
                          address: string
                          platformUser?: {
                            __typename: 'User'
                            id: string
                            thumb?: string | null
                            shortDisplayName?: string | null
                            displayName?: string | null
                            userType: UserType
                            isBot: boolean
                          } | null
                        }
                      }
                    } | null
                    surveyAnswers?: Array<{
                      __typename: 'Answer'
                      choices: Array<{
                        __typename: 'AnswerChoice'
                        dateChoice?: Date | null
                        stringChoice?: string | null
                      }>
                    }> | null
                    member: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                  }
                  user: {
                    __typename: 'CollectiveMember'
                    id: string
                    user: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  }
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                  masterVote?: {
                    __typename: 'CollectiveProposalVote'
                    id: string
                    type: VoteType
                    weight: PseudoBigNumber
                    masterVote?: {
                      __typename: 'CollectiveProposalVote'
                      id: string
                      type: VoteType
                      weight: PseudoBigNumber
                      surveyAnswers?: Array<{
                        __typename: 'Answer'
                        choices: Array<{
                          __typename: 'AnswerChoice'
                          dateChoice?: Date | null
                          stringChoice?: string | null
                        }>
                      }> | null
                      member: {
                        __typename: 'CollectiveMember'
                        id: string
                        user: {
                          __typename: 'CollectiveUser'
                          id: string
                          address: string
                          platformUser?: {
                            __typename: 'User'
                            id: string
                            thumb?: string | null
                            shortDisplayName?: string | null
                            displayName?: string | null
                            userType: UserType
                            isBot: boolean
                          } | null
                        }
                      }
                    } | null
                    surveyAnswers?: Array<{
                      __typename: 'Answer'
                      choices: Array<{
                        __typename: 'AnswerChoice'
                        dateChoice?: Date | null
                        stringChoice?: string | null
                      }>
                    }> | null
                    member: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnVoteInvalidated'
                  oldWeight: PseudoBigNumber
                  invalidatedVote: {
                    __typename: 'CollectiveProposalVote'
                    id: string
                    type: VoteType
                    weight: PseudoBigNumber
                    masterVote?: {
                      __typename: 'CollectiveProposalVote'
                      id: string
                      type: VoteType
                      weight: PseudoBigNumber
                      surveyAnswers?: Array<{
                        __typename: 'Answer'
                        choices: Array<{
                          __typename: 'AnswerChoice'
                          dateChoice?: Date | null
                          stringChoice?: string | null
                        }>
                      }> | null
                      member: {
                        __typename: 'CollectiveMember'
                        id: string
                        user: {
                          __typename: 'CollectiveUser'
                          id: string
                          address: string
                          platformUser?: {
                            __typename: 'User'
                            id: string
                            thumb?: string | null
                            shortDisplayName?: string | null
                            displayName?: string | null
                            userType: UserType
                            isBot: boolean
                          } | null
                        }
                      }
                    } | null
                    surveyAnswers?: Array<{
                      __typename: 'Answer'
                      choices: Array<{
                        __typename: 'AnswerChoice'
                        dateChoice?: Date | null
                        stringChoice?: string | null
                      }>
                    }> | null
                    member: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                  }
                  user: {
                    __typename: 'CollectiveMember'
                    id: string
                    user: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  }
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnVoteWeightUpdated'
                  oldWeight: PseudoBigNumber
                  newWeight: PseudoBigNumber
                  updatedVote: {
                    __typename: 'CollectiveProposalVote'
                    id: string
                    type: VoteType
                    weight: PseudoBigNumber
                    masterVote?: {
                      __typename: 'CollectiveProposalVote'
                      id: string
                      type: VoteType
                      weight: PseudoBigNumber
                      surveyAnswers?: Array<{
                        __typename: 'Answer'
                        choices: Array<{
                          __typename: 'AnswerChoice'
                          dateChoice?: Date | null
                          stringChoice?: string | null
                        }>
                      }> | null
                      member: {
                        __typename: 'CollectiveMember'
                        id: string
                        user: {
                          __typename: 'CollectiveUser'
                          id: string
                          address: string
                          platformUser?: {
                            __typename: 'User'
                            id: string
                            thumb?: string | null
                            shortDisplayName?: string | null
                            displayName?: string | null
                            userType: UserType
                            isBot: boolean
                          } | null
                        }
                      }
                    } | null
                    surveyAnswers?: Array<{
                      __typename: 'Answer'
                      choices: Array<{
                        __typename: 'AnswerChoice'
                        dateChoice?: Date | null
                        stringChoice?: string | null
                      }>
                    }> | null
                    member: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                  }
                  user: {
                    __typename: 'CollectiveMember'
                    id: string
                    user: {
                      __typename: 'CollectiveUser'
                      id: string
                      address: string
                      platformUser?: {
                        __typename: 'User'
                        id: string
                        thumb?: string | null
                        shortDisplayName?: string | null
                        displayName?: string | null
                        userType: UserType
                        isBot: boolean
                      } | null
                    }
                  }
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnVotingAnonymityChanged'
                  votingAnonymity: VotingAnonymity
                  proposal?: {
                    __typename: 'CollectiveProposal'
                    id: string
                    status: CollectiveProposalStatus
                    title: string
                    description: string
                    type: CollectiveProposalType
                    isShortForm: boolean
                    publicUrl: string
                    eligibleDelegations: Array<{
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }>
                    batchedProposals: Array<{
                      __typename: 'CollectiveProposal'
                      id: string
                    }>
                    survey?: {
                      __typename: 'Survey'
                      totalQuestions: number
                      questions: Array<{
                        __typename: 'Question'
                        question: string
                      }>
                    } | null
                    proposedBy: {
                      __typename: 'CollectiveMember'
                      id: string
                      user: {
                        __typename: 'CollectiveUser'
                        id: string
                        address: string
                        platformUser?: {
                          __typename: 'User'
                          id: string
                          thumb?: string | null
                          shortDisplayName?: string | null
                          displayName?: string | null
                          userType: UserType
                          isBot: boolean
                        } | null
                      }
                    }
                    collective: {
                      __typename: 'Collective'
                      id: string
                      name: string
                    }
                  } | null
                }
              | {
                  __typename: 'CollectiveLogDataOnWalletLinked'
                  address: string
                  blockchain: string
                  nickname: string
                }
              | {
                  __typename: 'CollectiveLogDataOnWalletUnlinked'
                  walletId: string
                }
          }>
          collectiveUsers: Array<{
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              userType: UserType
              isBot: boolean
            } | null
          }>
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type CollectiveMemberFileExportQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  fileExportId: Scalars['ID']
}>

export type CollectiveMemberFileExportQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    memberFileExport?: {
      __typename: 'FileExport'
      id: string
      status: FileExportStatus
      errorMessage?: string | null
      type: FileExportType
      file?: string | null
    } | null
  } | null
}

export type CollectiveMembersQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  memberConnection: ConnectionInput
}>

export type CollectiveMembersQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    members: {
      __typename: 'CollectiveMemberConnection'
      edges: Array<{
        __typename: 'CollectiveMemberEdge'
        cursor: string
        node: {
          __typename: 'CollectiveMember'
          id: string
          totalTokens: PseudoBigNumber
          percentOfTotalTokenSupply: number
          privileged: boolean
          approved: boolean
          createdAt: Date
          user: {
            __typename: 'CollectiveUser'
            id: string
            address: string
            platformUser?: {
              __typename: 'User'
              id: string
              publicUrl: string
              thumb?: string | null
              shortDisplayName?: string | null
              displayName?: string | null
              blocked: boolean
              userType: UserType
              isBot: boolean
            } | null
          }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type CollectiveMembershipRequestsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type CollectiveMembershipRequestsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    membershipRequests: Array<{
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      status: CollectiveMembershipRequestStatus
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
  } | null
}

export type CollectiveProposalDelegationsQueryVariables = Exact<{
  proposalId: Scalars['ID']
}>

export type CollectiveProposalDelegationsQueryResult = {
  __typename: 'Query'
  collectiveProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
  } | null
}

export type CollectiveProposalOgImageQueryVariables = Exact<{
  proposalId: Scalars['ID']
}>

export type CollectiveProposalOgImageQueryResult = {
  __typename: 'Query'
  collectiveProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    description: string
    startTime: Date
    endTime: Date
    ogImageUrl?: string | null
    publicUrl: string
    createdAt: Date
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    collective: {
      __typename: 'Collective'
      id: string
      thumb?: string | null
      network: EthNetwork
      name: string
      description?: string | null
    }
  } | null
}

export type CollectiveProposalQueryVariables = Exact<{
  proposalId: Scalars['ID']
}>

export type CollectiveProposalQueryResult = {
  __typename: 'Query'
  collectiveProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    status: CollectiveProposalStatus
    title: string
    descriptionHtml: string
    type: CollectiveProposalType
    startTime: Date
    endTime: Date
    requireVotingSignature: boolean
    votingAnonymity: VotingAnonymity
    quorumNeeded: number
    isQuorumReached: boolean
    myVote?: VoteType | null
    isAuthor: boolean
    isUsingMatchingWallet: boolean
    isVotable: boolean
    isShortForm: boolean
    canVote: boolean
    totalVoters: PseudoBigNumber
    totalVotes: PseudoBigNumber
    totalVotersFor: PseudoBigNumber
    votesFor: PseudoBigNumber
    totalVotersAgainst: PseudoBigNumber
    votesAgainst: PseudoBigNumber
    totalVotersAbstain: PseudoBigNumber
    votesAbstain: PseudoBigNumber
    ogImageUrl?: string | null
    isBatch: boolean
    isPartOfBatch: boolean
    publicUrl: string
    createdAt: Date
    hasOnChainCommands: boolean
    eligibleDelegations: Array<{
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    statusHistory: Array<{
      __typename: 'CollectiveProposalStatusHistory'
      status: CollectiveProposalStatus
      createdAt: Date
    }>
    execution?: {
      __typename: 'CollectiveProposalExecution'
      txHash: string
      blockNumber: number
      status: CollectiveProposalExecutionStatus
      errorMessage?: string | null
    } | null
    commands: Array<
      | {
          __typename: 'CollectiveProposalAddMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalAddSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalCallRemoteCommand'
          target: string
          data: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
          disableTokenTransfers: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableDepositsCommand'
          enableDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
          enableNewMembershipRequests: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
          enableWithdraws: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeExchangeRateCommand'
          exchangeRate: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeGateDepositsCommand'
          gateDeposits: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeMinEthContributionCommand'
          minEthContribution: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
          requireVotingSignature: boolean
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeSafeThresholdCommand'
          safeThreshold: number
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
          votingAnonymity: VotingAnonymity
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalLinkWalletCommand'
          target: string
          blockchain: Blockchain
          walletNickname: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveMemberCommand'
          memberAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalRemoveSignatorCommand'
          signatorAddress: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalSendEtherDisbursementCommands'
          value: PseudoBigNumber
          commands: Array<{
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }>
        }
      | {
          __typename: 'CollectiveProposalSendTokenCommand'
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC721Command'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferERC1155Command'
          contractAddress: string
          target: string
          tokenId: string
          amount: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferNFTCommand'
          contractAddress: string
          target: string
          tokenId: string
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalTransferTokensCommand'
          contractAddress: string
          target: string
          value: PseudoBigNumber
          safeInstructions?: { [key: string]: any } | null
          asset?: {
            __typename: 'CollectiveAssetPreview'
            name: string
            symbol: string
            thumb?: string | null
          } | null
        }
      | {
          __typename: 'CollectiveProposalUnlinkWalletCommand'
          walletId: string
          safeInstructions?: { [key: string]: any } | null
        }
      | {
          __typename: 'CollectiveProposalUpdateQuorumCommand'
          quorumNeededInPercentage: number
          safeInstructions?: { [key: string]: any } | null
        }
    >
    survey?: {
      __typename: 'Survey'
      answeredOn?: Date | null
      totalQuestions: number
      totalAnswers: PseudoBigNumber
      isAnswered: boolean
      questions: Array<{
        __typename: 'Question'
        question: string
        questionType: QuestionType
        options: {
          __typename: 'QuestionOptions'
          required: boolean
          dateOptions?: {
            __typename: 'DateOptions'
            min?: Date | null
            max?: Date | null
            includeTime: boolean
          } | null
        }
        choices?: Array<{
          __typename: 'QuestionChoice'
          stringChoice?: string | null
        }> | null
      }>
      myAnswers?: Array<{
        __typename: 'Answer'
        choices: Array<{
          __typename: 'AnswerChoice'
          dateChoice?: Date | null
          stringChoice?: string | null
        }>
      }> | null
      surveyResults: Array<{
        __typename: 'ChoiceBasedResults'
        labels: Array<string>
        dataset: Array<number>
        datasetPercent: Array<number>
        totalAnswers: number
      }>
      options: {
        __typename: 'SurveyOptions'
        showResultsAfter?: ShowResultsAfter | null
      }
      proposal?: { __typename: 'CollectiveProposal'; id: string } | null
    } | null
    origin?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      notes?: string | null
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    } | null
    signatures: Array<{
      __typename: 'CollectiveProposalSignature'
      id: string
      signature: string
      valid: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
    proposedBy: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      createdAt: Date
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }
    batchProposal?: {
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    } | null
    batchedProposals: Array<{
      __typename: 'CollectiveProposal'
      id: string
      title: string
      publicUrl: string
    }>
    collective: { __typename: 'Collective'; id: string; name: string }
    thread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
      title: string
    } | null
  } | null
}

export type CollectiveVotesQueryVariables = Exact<{
  proposalId: Scalars['ID']
  voteConnection: ConnectionInput
}>

export type CollectiveVotesQueryResult = {
  __typename: 'Query'
  collectiveProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    votes: {
      __typename: 'CollectiveProposalVoteConnection'
      edges: Array<{
        __typename: 'CollectiveProposalVoteEdge'
        cursor: string
        node: {
          __typename: 'CollectiveProposalVote'
          id: string
          type: VoteType
          weight: PseudoBigNumber
          signatureUrl?: string | null
          signature: string
          masterVote?: {
            __typename: 'CollectiveProposalVote'
            id: string
            type: VoteType
            weight: PseudoBigNumber
            signatureUrl?: string | null
            signature: string
            surveyAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            member: {
              __typename: 'CollectiveMember'
              id: string
              totalTokens: PseudoBigNumber
              percentOfTotalTokenSupply: number
              privileged: boolean
              approved: boolean
              createdAt: Date
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  publicUrl: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  blocked: boolean
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
          surveyAnswers?: Array<{
            __typename: 'Answer'
            choices: Array<{
              __typename: 'AnswerChoice'
              dateChoice?: Date | null
              stringChoice?: string | null
            }>
          }> | null
          member: {
            __typename: 'CollectiveMember'
            id: string
            totalTokens: PseudoBigNumber
            percentOfTotalTokenSupply: number
            privileged: boolean
            approved: boolean
            createdAt: Date
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                publicUrl: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                blocked: boolean
                userType: UserType
                isBot: boolean
              } | null
            }
          }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type CollectiveProposalsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  proposalConnection: ConnectionInput
  searchString?: InputMaybe<Scalars['String']>
}>

export type CollectiveProposalsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    proposals: {
      __typename: 'CollectiveProposalConnection'
      edges: Array<{
        __typename: 'CollectiveProposalEdge'
        cursor: string
        node: {
          __typename: 'CollectiveProposal'
          id: string
          type: CollectiveProposalType
          title: string
          status: CollectiveProposalStatus
          totalVoters: PseudoBigNumber
          totalVotes: PseudoBigNumber
          votesFor: PseudoBigNumber
          votesAgainst: PseudoBigNumber
          requireVotingSignature: boolean
          votingAnonymity: VotingAnonymity
          votesAbstain: PseudoBigNumber
          isBatch: boolean
          isPartOfBatch: boolean
          publicUrl: string
          myVote?: VoteType | null
          quorumNeeded: number
          isQuorumReached: boolean
          isAuthor: boolean
          isUsingMatchingWallet: boolean
          isVotable: boolean
          isShortForm: boolean
          canVote: boolean
          startTime: Date
          endTime: Date
          createdAt: Date
          execution?: {
            __typename: 'CollectiveProposalExecution'
            txHash: string
            blockNumber: number
            status: CollectiveProposalExecutionStatus
            errorMessage?: string | null
          } | null
          survey?: {
            __typename: 'Survey'
            answeredOn?: Date | null
            totalQuestions: number
            totalAnswers: PseudoBigNumber
            isAnswered: boolean
            questions: Array<{
              __typename: 'Question'
              question: string
              questionType: QuestionType
              options: {
                __typename: 'QuestionOptions'
                required: boolean
                dateOptions?: {
                  __typename: 'DateOptions'
                  min?: Date | null
                  max?: Date | null
                  includeTime: boolean
                } | null
              }
              choices?: Array<{
                __typename: 'QuestionChoice'
                stringChoice?: string | null
              }> | null
            }>
            myAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            surveyResults: Array<{
              __typename: 'ChoiceBasedResults'
              labels: Array<string>
              dataset: Array<number>
              datasetPercent: Array<number>
              totalAnswers: number
            }>
            options: {
              __typename: 'SurveyOptions'
              showResultsAfter?: ShowResultsAfter | null
            }
            proposal?: { __typename: 'CollectiveProposal'; id: string } | null
          } | null
          collective: { __typename: 'Collective'; id: string }
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                publicUrl: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                blocked: boolean
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          thread?: {
            __typename: 'Thread'
            id: string
            replyCount: number
            title: string
          } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type CollectiveQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  proposalConnection: ConnectionInput
}>

export type CollectiveQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    thumb?: string | null
    address: string
    delegationAddress?: string | null
    safeAddress: string
    safeThreshold: number
    network: EthNetwork
    name: string
    description?: string | null
    archived: boolean
    totalProposals: number
    activeProposals: number
    totalMembers: number
    totalVotingMembers: number
    totalMembershipRequests: number
    treasuryBalance: PseudoBigNumber
    governanceType: CollectiveTokenType
    ogImageUrl?: string | null
    publicUrl: string
    createdAt: Date
    parameters: {
      __typename: 'CollectiveParameters'
      votingDelayInMinutes: number
      votingPeriodInMinutes: number
      quorumNeededInPercentage: number
      proposalThreshold: PseudoBigNumber
      depositFeeInPercentage: number
      withdrawFeeInPercentage: number
      enableNewMembershipRequests: boolean
      enableDeposits: boolean
      enableWithdraws: boolean
      gateDeposits: boolean
      exchangeRate: number
      minEthContribution: PseudoBigNumber
      disableTokenTransfers: boolean
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
    }
    syncStatus: {
      __typename: 'SyncStatus'
      syncStartedAt?: Date | null
      lastSyncedAt?: Date | null
      status: CollectiveSyncStatus
    }
    proposals: {
      __typename: 'CollectiveProposalConnection'
      edges: Array<{
        __typename: 'CollectiveProposalEdge'
        cursor: string
        node: {
          __typename: 'CollectiveProposal'
          id: string
          type: CollectiveProposalType
          title: string
          status: CollectiveProposalStatus
          totalVoters: PseudoBigNumber
          totalVotes: PseudoBigNumber
          votesFor: PseudoBigNumber
          votesAgainst: PseudoBigNumber
          requireVotingSignature: boolean
          votingAnonymity: VotingAnonymity
          votesAbstain: PseudoBigNumber
          isBatch: boolean
          isPartOfBatch: boolean
          publicUrl: string
          myVote?: VoteType | null
          quorumNeeded: number
          isQuorumReached: boolean
          isAuthor: boolean
          isUsingMatchingWallet: boolean
          isVotable: boolean
          isShortForm: boolean
          canVote: boolean
          startTime: Date
          endTime: Date
          createdAt: Date
          execution?: {
            __typename: 'CollectiveProposalExecution'
            txHash: string
            blockNumber: number
            status: CollectiveProposalExecutionStatus
            errorMessage?: string | null
          } | null
          survey?: {
            __typename: 'Survey'
            answeredOn?: Date | null
            totalQuestions: number
            totalAnswers: PseudoBigNumber
            isAnswered: boolean
            questions: Array<{
              __typename: 'Question'
              question: string
              questionType: QuestionType
              options: {
                __typename: 'QuestionOptions'
                required: boolean
                dateOptions?: {
                  __typename: 'DateOptions'
                  min?: Date | null
                  max?: Date | null
                  includeTime: boolean
                } | null
              }
              choices?: Array<{
                __typename: 'QuestionChoice'
                stringChoice?: string | null
              }> | null
            }>
            myAnswers?: Array<{
              __typename: 'Answer'
              choices: Array<{
                __typename: 'AnswerChoice'
                dateChoice?: Date | null
                stringChoice?: string | null
              }>
            }> | null
            surveyResults: Array<{
              __typename: 'ChoiceBasedResults'
              labels: Array<string>
              dataset: Array<number>
              datasetPercent: Array<number>
              totalAnswers: number
            }>
            options: {
              __typename: 'SurveyOptions'
              showResultsAfter?: ShowResultsAfter | null
            }
            proposal?: { __typename: 'CollectiveProposal'; id: string } | null
          } | null
          collective: { __typename: 'Collective'; id: string }
          proposedBy: {
            __typename: 'CollectiveMember'
            id: string
            user: {
              __typename: 'CollectiveUser'
              id: string
              address: string
              platformUser?: {
                __typename: 'User'
                id: string
                publicUrl: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                blocked: boolean
                userType: UserType
                isBot: boolean
              } | null
            }
          }
          thread?: {
            __typename: 'Thread'
            id: string
            replyCount: number
            title: string
          } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
    tokens: Array<{
      __typename: 'CollectiveToken'
      id: string
      isMainToken: boolean
      type: CollectiveTokenType
      address: string
      voteWeight: PseudoBigNumber
      symbol: string
      decimals: number
      totalSupply: PseudoBigNumber
      totalVotingSupply: PseudoBigNumber
      thumb?: string | null
      name: string
    }>
    erc20Assets: Array<{
      __typename: 'ERC20Asset'
      id: string
      name: string
      symbol: string
      address: string
      balance: PseudoBigNumber
      decimals: number
      thumb?: string | null
      lastSynced: Date
      estimatedValueEth?: PseudoBigNumber | null
      estimatedValueUSD?: number | null
      wallet: {
        __typename: 'CollectiveWallet'
        id: string
        isMainWallet: boolean
      }
    }>
    nftAssets: Array<{
      __typename: 'NFTAsset'
      id: string
      tokenId: string
      name: string
      symbol: string
      address: string
      amount: PseudoBigNumber
      contractType: string
      uri?: string | null
      mediaUrls: Array<string>
      description?: string | null
      externalUrl?: string | null
      lastSynced: Date
      estimatedValueEth?: PseudoBigNumber | null
      estimatedValueUSD?: number | null
      wallet: {
        __typename: 'CollectiveWallet'
        id: string
        isMainWallet: boolean
      }
    }>
    externalAssets: Array<{
      __typename: 'ExternalAsset'
      id: string
      type: ExternalAssetType
      address: string
      thumb?: string | null
      externalUrl?: string | null
      symbol?: string | null
      formattedAmount?: string | null
      lastSynced: Date
      estimatedValueUSD?: number | null
      wallet?: {
        __typename: 'CollectiveWallet'
        id: string
        isMainWallet: boolean
      } | null
    }>
    wallets: Array<{
      __typename: 'CollectiveWallet'
      id: string
      balance: PseudoBigNumber
      address: string
      isMainWallet: boolean
      nickname?: string | null
      type: WalletType
    }>
    announcement?: {
      __typename: 'CollectiveAnnouncement'
      id: string
      bodyHtml?: string | null
      createdAt: Date
      postedBy: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    } | null
    membership?: {
      __typename: 'CollectiveMember'
      id: string
      totalTokens: PseudoBigNumber
      percentOfTotalTokenSupply: number
      privileged: boolean
      approved: boolean
      user: { __typename: 'CollectiveUser'; id: string; address: string }
    } | null
    membershipRequest?: {
      __typename: 'CollectiveMembershipRequest'
      id: string
      status: CollectiveMembershipRequestStatus
      notes?: string | null
      createdAt: Date
    } | null
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  } | null
}

export type CollectiveSignatorsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type CollectiveSignatorsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    signators: Array<{
      __typename: 'CollectiveMember'
      id: string
      user: {
        __typename: 'CollectiveUser'
        id: string
        address: string
        platformUser?: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          blocked: boolean
          userType: UserType
          isBot: boolean
        } | null
      }
    }>
  } | null
}

export type CollectiveStatsQueryVariables = Exact<{
  collectiveId: Scalars['ID']
  startDate?: InputMaybe<Scalars['Date']>
  endDate?: InputMaybe<Scalars['Date']>
}>

export type CollectiveStatsQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    statsDatapoints: Array<{
      __typename: 'CollectiveStatsDatapoint'
      time: Date
      estimatedTotalValueEth: PseudoBigNumber
      estimatedTotalValueUSD: number
      totalMembers: number
      totalProposals: number
    }>
    wallets: Array<{
      __typename: 'CollectiveWallet'
      id: string
      nickname?: string | null
      statsDatapoints: Array<{
        __typename: 'CollectiveWalletStatsDatapoint'
        time: Date
        estimatedValueEth: PseudoBigNumber
        estimatedValueUSD: number
        estimatedNFTValueEth: PseudoBigNumber
        estimatedNFTValueUSD: number
        estimatedTokensValueEth: PseudoBigNumber
        estimatedTokensValueUSD: number
        estimatedTotalValueEth: PseudoBigNumber
        estimatedTotalValueUSD: number
        conversionRateUSD: number
      }>
    }>
  } | null
}

export type CollectiveTosQueryVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type CollectiveTosQueryResult = {
  __typename: 'Query'
  collectiveTos: {
    __typename: 'CollectiveTOS'
    id: string
    revision: number
    tosHtml: string
    createdAt: Date
    isSigned: boolean
    collectiveId?: string | null
  }
}

export type CollectivesDashboardQueryVariables = Exact<{
  collectiveConnection: ConnectionInput
}>

export type CollectivesDashboardQueryResult = {
  __typename: 'Query'
  me: {
    __typename: 'Viewer'
    id: string
    collectiveDrafts: {
      __typename: 'CollectiveDraftConnection'
      edges: Array<{
        __typename: 'CollectiveDraftEdge'
        cursor: string
        node: {
          __typename: 'CollectiveDraft'
          id: string
          subdomain?: string | null
          avatar?: string | null
          externalTokenAddress?: string | null
          name: string
          intent: CollectiveIntent
          description: string
          quorumNeededInPercentage?: number | null
          proposalThreshold?: PseudoBigNumber | null
          depositFeeInPercentage?: number | null
          withdrawFeeInPercentage?: number | null
          enableNewMembershipRequests?: boolean | null
          enableDeposits?: boolean | null
          enableWithdraws?: boolean | null
          gateDeposits?: boolean | null
          exchangeRate?: number | null
          minEthContribution?: PseudoBigNumber | null
          disableTokenTransfers?: boolean | null
          requireVotingSignature?: boolean | null
          votingAnonymity?: VotingAnonymity | null
          firstName?: string | null
          lastName?: string | null
          email?: string | null
          symbol?: string | null
          customerSupport?: Array<string> | null
          members?: Array<string> | null
          createdAt: Date
          lastStep: InputStep
          jumpableSteps: Array<InputStep>
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
    collectives: {
      __typename: 'CollectiveConnection'
      edges: Array<{
        __typename: 'CollectiveEdge'
        cursor: string
        node: {
          __typename: 'Collective'
          id: string
          thumb?: string | null
          address: string
          network: EthNetwork
          name: string
          description?: string | null
          totalProposals: number
          activeProposals: number
          totalMembers: number
          totalVotingMembers: number
          totalMembershipRequests: number
          governanceType: CollectiveTokenType
          publicUrl: string
          createdAt: Date
          tokens: Array<{
            __typename: 'CollectiveToken'
            id: string
            isMainToken: boolean
            type: CollectiveTokenType
            address: string
            voteWeight: PseudoBigNumber
            symbol: string
            decimals: number
            totalSupply: PseudoBigNumber
            totalVotingSupply: PseudoBigNumber
            thumb?: string | null
            name: string
          }>
          membership?: {
            __typename: 'CollectiveMember'
            id: string
            percentOfTotalTokenSupply: number
            privileged: boolean
            approved: boolean
          } | null
          membershipRequest?: {
            __typename: 'CollectiveMembershipRequest'
            id: string
            status: CollectiveMembershipRequestStatus
          } | null
          announcement?: {
            __typename: 'CollectiveAnnouncement'
            id: string
            bodyHtml?: string | null
            createdAt: Date
            postedBy: {
              __typename: 'CollectiveMember'
              id: string
              totalTokens: PseudoBigNumber
              percentOfTotalTokenSupply: number
              privileged: boolean
              approved: boolean
              createdAt: Date
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  publicUrl: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  blocked: boolean
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
          } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
    proposalsToVote: Array<{
      __typename: 'CollectiveProposal'
      id: string
      status: CollectiveProposalStatus
      title: string
      descriptionHtml: string
      type: CollectiveProposalType
      startTime: Date
      endTime: Date
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      quorumNeeded: number
      isQuorumReached: boolean
      myVote?: VoteType | null
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      totalVotersFor: PseudoBigNumber
      votesFor: PseudoBigNumber
      totalVotersAgainst: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      totalVotersAbstain: PseudoBigNumber
      votesAbstain: PseudoBigNumber
      ogImageUrl?: string | null
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      createdAt: Date
      hasOnChainCommands: boolean
      collective: {
        __typename: 'Collective'
        totalVotingMembers: number
        archived: boolean
        id: string
        name: string
        membership?: {
          __typename: 'CollectiveMember'
          id: string
          percentOfTotalTokenSupply: number
          privileged: boolean
          approved: boolean
        } | null
        tokens: Array<{
          __typename: 'CollectiveToken'
          totalVotingSupply: PseudoBigNumber
          decimals: number
        }>
        parameters: {
          __typename: 'CollectiveParameters'
          quorumNeededInPercentage: number
        }
      }
      eligibleDelegations: Array<{
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }>
      statusHistory: Array<{
        __typename: 'CollectiveProposalStatusHistory'
        status: CollectiveProposalStatus
        createdAt: Date
      }>
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      commands: Array<
        | {
            __typename: 'CollectiveProposalAddMemberCommand'
            memberAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalAddSignatorCommand'
            signatorAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalCallRemoteCommand'
            target: string
            data: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
            disableTokenTransfers: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeEnableDepositsCommand'
            enableDeposits: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
            enableNewMembershipRequests: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
            enableWithdraws: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeExchangeRateCommand'
            exchangeRate: number
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeGateDepositsCommand'
            gateDeposits: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeMinEthContributionCommand'
            minEthContribution: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
            requireVotingSignature: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeSafeThresholdCommand'
            safeThreshold: number
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
            votingAnonymity: VotingAnonymity
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalLinkWalletCommand'
            target: string
            blockchain: Blockchain
            walletNickname: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalRemoveMemberCommand'
            memberAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalRemoveSignatorCommand'
            signatorAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalSendEtherCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalSendEtherDisbursementCommands'
            value: PseudoBigNumber
            commands: Array<{
              __typename: 'CollectiveProposalSendEtherDisbursementCommand'
              target: string
              value: PseudoBigNumber
              safeInstructions?: { [key: string]: any } | null
            }>
          }
        | {
            __typename: 'CollectiveProposalSendTokenCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalTransferERC721Command'
            contractAddress: string
            target: string
            tokenId: string
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalTransferERC1155Command'
            contractAddress: string
            target: string
            tokenId: string
            amount: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalTransferNFTCommand'
            contractAddress: string
            target: string
            tokenId: string
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalTransferTokensCommand'
            contractAddress: string
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalUnlinkWalletCommand'
            walletId: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalUpdateQuorumCommand'
            quorumNeededInPercentage: number
            safeInstructions?: { [key: string]: any } | null
          }
      >
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      origin?: {
        __typename: 'CollectiveMembershipRequest'
        id: string
        notes?: string | null
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      } | null
      signatures: Array<{
        __typename: 'CollectiveProposalSignature'
        id: string
        signature: string
        valid: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }>
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      batchProposal?: {
        __typename: 'CollectiveProposal'
        id: string
        title: string
        publicUrl: string
      } | null
      batchedProposals: Array<{
        __typename: 'CollectiveProposal'
        id: string
        title: string
        publicUrl: string
      }>
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    }>
    proposalsToExecute: Array<{
      __typename: 'CollectiveProposal'
      id: string
      status: CollectiveProposalStatus
      title: string
      descriptionHtml: string
      type: CollectiveProposalType
      startTime: Date
      endTime: Date
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      quorumNeeded: number
      isQuorumReached: boolean
      myVote?: VoteType | null
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      totalVotersFor: PseudoBigNumber
      votesFor: PseudoBigNumber
      totalVotersAgainst: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      totalVotersAbstain: PseudoBigNumber
      votesAbstain: PseudoBigNumber
      ogImageUrl?: string | null
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      createdAt: Date
      hasOnChainCommands: boolean
      collective: {
        __typename: 'Collective'
        totalVotingMembers: number
        archived: boolean
        id: string
        name: string
        membership?: {
          __typename: 'CollectiveMember'
          id: string
          percentOfTotalTokenSupply: number
          privileged: boolean
          approved: boolean
        } | null
        tokens: Array<{
          __typename: 'CollectiveToken'
          totalVotingSupply: PseudoBigNumber
          decimals: number
        }>
        parameters: {
          __typename: 'CollectiveParameters'
          quorumNeededInPercentage: number
        }
      }
      eligibleDelegations: Array<{
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }>
      statusHistory: Array<{
        __typename: 'CollectiveProposalStatusHistory'
        status: CollectiveProposalStatus
        createdAt: Date
      }>
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      commands: Array<
        | {
            __typename: 'CollectiveProposalAddMemberCommand'
            memberAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalAddSignatorCommand'
            signatorAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalCallRemoteCommand'
            target: string
            data: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeDisableTokenTransfersCommand'
            disableTokenTransfers: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeEnableDepositsCommand'
            enableDeposits: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeEnableNewMembershipRequests'
            enableNewMembershipRequests: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeEnableWithdrawsCommand'
            enableWithdraws: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeExchangeRateCommand'
            exchangeRate: number
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeGateDepositsCommand'
            gateDeposits: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeMinEthContributionCommand'
            minEthContribution: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeRequireVotingSignatureCommand'
            requireVotingSignature: boolean
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeSafeThresholdCommand'
            safeThreshold: number
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalChangeVotingAnonymityCommand'
            votingAnonymity: VotingAnonymity
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalLinkWalletCommand'
            target: string
            blockchain: Blockchain
            walletNickname: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalRemoveMemberCommand'
            memberAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalRemoveSignatorCommand'
            signatorAddress: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalSendEtherCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalSendEtherDisbursementCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalSendEtherDisbursementCommands'
            value: PseudoBigNumber
            commands: Array<{
              __typename: 'CollectiveProposalSendEtherDisbursementCommand'
              target: string
              value: PseudoBigNumber
              safeInstructions?: { [key: string]: any } | null
            }>
          }
        | {
            __typename: 'CollectiveProposalSendTokenCommand'
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalTransferERC721Command'
            contractAddress: string
            target: string
            tokenId: string
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalTransferERC1155Command'
            contractAddress: string
            target: string
            tokenId: string
            amount: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalTransferNFTCommand'
            contractAddress: string
            target: string
            tokenId: string
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalTransferTokensCommand'
            contractAddress: string
            target: string
            value: PseudoBigNumber
            safeInstructions?: { [key: string]: any } | null
            asset?: {
              __typename: 'CollectiveAssetPreview'
              name: string
              symbol: string
              thumb?: string | null
            } | null
          }
        | {
            __typename: 'CollectiveProposalUnlinkWalletCommand'
            walletId: string
            safeInstructions?: { [key: string]: any } | null
          }
        | {
            __typename: 'CollectiveProposalUpdateQuorumCommand'
            quorumNeededInPercentage: number
            safeInstructions?: { [key: string]: any } | null
          }
      >
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      origin?: {
        __typename: 'CollectiveMembershipRequest'
        id: string
        notes?: string | null
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      } | null
      signatures: Array<{
        __typename: 'CollectiveProposalSignature'
        id: string
        signature: string
        valid: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }>
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      batchProposal?: {
        __typename: 'CollectiveProposal'
        id: string
        title: string
        publicUrl: string
      } | null
      batchedProposals: Array<{
        __typename: 'CollectiveProposal'
        id: string
        title: string
        publicUrl: string
      }>
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    }>
  }
}

export type CollectivesQueryVariables = Exact<{
  collectiveConnection?: InputMaybe<ConnectionInput>
}>

export type CollectivesQueryResult = {
  __typename: 'Query'
  me: {
    __typename: 'Viewer'
    id: string
    collectives: {
      __typename: 'CollectiveConnection'
      edges: Array<{
        __typename: 'CollectiveEdge'
        cursor: string
        node: {
          __typename: 'Collective'
          id: string
          thumb?: string | null
          network: EthNetwork
          name: string
          description?: string | null
          activeProposals: number
          totalMembers: number
          publicUrl: string
          archived: boolean
          membership?: {
            __typename: 'CollectiveMember'
            id: string
            privileged: boolean
            approved: boolean
          } | null
          membershipRequest?: {
            __typename: 'CollectiveMembershipRequest'
            id: string
            status: CollectiveMembershipRequestStatus
          } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type ContractAbiQueryVariables = Exact<{
  address: Scalars['String']
  network: EthNetwork
}>

export type ContractAbiQueryResult = {
  __typename: 'Query'
  contractAbi: {
    __typename: 'ContractAbi'
    abi: { [key: string]: any }
    network: EthNetwork
  }
}

export type DelegatesGrantedByViewerQueryVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type DelegatesGrantedByViewerQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    delegatesGrantedByViewer: Array<{
      __typename: 'CommandDelegation'
      command?: ProposalCommandType | null
      wildcard?: boolean | null
      surveys?: boolean | null
      maxValue?: PseudoBigNumber | null
      collectiveMember: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    }>
  } | null
}

export type DelegatesGrantedToViewerQueryVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type DelegatesGrantedToViewerQueryResult = {
  __typename: 'Query'
  collective?: {
    __typename: 'Collective'
    id: string
    delegatesGrantedToViewer: Array<{
      __typename: 'CommandDelegation'
      command?: ProposalCommandType | null
      maxValue?: PseudoBigNumber | null
      wildcard?: boolean | null
      surveys?: boolean | null
      collectiveMember: {
        __typename: 'CollectiveMember'
        id: string
        totalTokens: PseudoBigNumber
        percentOfTotalTokenSupply: number
        privileged: boolean
        approved: boolean
        createdAt: Date
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
    }>
  } | null
}

export type EstimatedExecutionGasQueryVariables = Exact<{
  proposalId: Scalars['ID']
}>

export type EstimatedExecutionGasQueryResult = {
  __typename: 'Query'
  collectiveProposal?: {
    __typename: 'CollectiveProposal'
    id: string
    estimatedExecutionGas?: PseudoBigNumber | null
  } | null
}

export type CategoryThreadsQueryVariables = Exact<{
  categoryId: Scalars['ID']
  threadConnection: ConnectionInput
  collaboratorConnection: ConnectionInput
}>

export type CategoryThreadsQueryResult = {
  __typename: 'Query'
  category: {
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    threads: {
      __typename: 'ThreadConnection'
      edges: Array<{
        __typename: 'ThreadEdge'
        cursor: string
        node: {
          __typename: 'Thread'
          id: string
          title: string
          body: string
          type: ThreadType
          replyCount: number
          viewsCount: number
          publicUrl: string
          isPinned: boolean
          createdAt: Date
          updatedAt: Date
          deletedAt?: Date | null
          collaboratorsCount: number
          collaborators: {
            __typename: 'CollaboratorConnection'
            edges: Array<{
              __typename: 'CollaboratorEdge'
              cursor: string
              node: {
                __typename: 'User'
                id: string
                publicUrl: string
                thumb?: string | null
                shortDisplayName?: string | null
                displayName?: string | null
                url?: string | null
                twitter?: string | null
                linkedin?: string | null
                instagram?: string | null
                createdAt?: Date | null
                blocked: boolean
                deleted: boolean
                userType: UserType
                isBot: boolean
              }
            }>
            pageInfo: {
              __typename: 'PageInfo'
              hasPreviousPage: boolean
              hasNextPage: boolean
              startCursor?: string | null
              endCursor?: string | null
            }
          }
          pinnedBy?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }
          proposal?: {
            __typename: 'CollectiveProposal'
            id: string
            type: CollectiveProposalType
            title: string
            status: CollectiveProposalStatus
            totalVoters: PseudoBigNumber
            totalVotes: PseudoBigNumber
            votesFor: PseudoBigNumber
            votesAgainst: PseudoBigNumber
            requireVotingSignature: boolean
            votingAnonymity: VotingAnonymity
            votesAbstain: PseudoBigNumber
            isBatch: boolean
            isPartOfBatch: boolean
            publicUrl: string
            myVote?: VoteType | null
            quorumNeeded: number
            isQuorumReached: boolean
            isAuthor: boolean
            isUsingMatchingWallet: boolean
            isVotable: boolean
            isShortForm: boolean
            canVote: boolean
            startTime: Date
            endTime: Date
            createdAt: Date
            execution?: {
              __typename: 'CollectiveProposalExecution'
              txHash: string
              blockNumber: number
              status: CollectiveProposalExecutionStatus
              errorMessage?: string | null
            } | null
            survey?: {
              __typename: 'Survey'
              answeredOn?: Date | null
              totalQuestions: number
              totalAnswers: PseudoBigNumber
              isAnswered: boolean
              questions: Array<{
                __typename: 'Question'
                question: string
                questionType: QuestionType
                options: {
                  __typename: 'QuestionOptions'
                  required: boolean
                  dateOptions?: {
                    __typename: 'DateOptions'
                    min?: Date | null
                    max?: Date | null
                    includeTime: boolean
                  } | null
                }
                choices?: Array<{
                  __typename: 'QuestionChoice'
                  stringChoice?: string | null
                }> | null
              }>
              myAnswers?: Array<{
                __typename: 'Answer'
                choices: Array<{
                  __typename: 'AnswerChoice'
                  dateChoice?: Date | null
                  stringChoice?: string | null
                }>
              }> | null
              surveyResults: Array<{
                __typename: 'ChoiceBasedResults'
                labels: Array<string>
                dataset: Array<number>
                datasetPercent: Array<number>
                totalAnswers: number
              }>
              options: {
                __typename: 'SurveyOptions'
                showResultsAfter?: ShowResultsAfter | null
              }
              proposal?: { __typename: 'CollectiveProposal'; id: string } | null
            } | null
            collective: { __typename: 'Collective'; id: string }
            proposedBy: {
              __typename: 'CollectiveMember'
              id: string
              user: {
                __typename: 'CollectiveUser'
                id: string
                address: string
                platformUser?: {
                  __typename: 'User'
                  id: string
                  publicUrl: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  blocked: boolean
                  userType: UserType
                  isBot: boolean
                } | null
              }
            }
            thread?: {
              __typename: 'Thread'
              id: string
              replyCount: number
              title: string
            } | null
          } | null
          notificationSettings: {
            __typename: 'DiscussionBoardThreadNotificationSetting'
            id: string
            allowNotifications: boolean
          }
          attachments: Array<{
            __typename: 'ThreadAttachment'
            id: string
            url: string
            type: string
            name: string
          }>
          category: { __typename: 'Category'; id: string }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }
}

export type CommentQueryVariables = Exact<{
  commentId: Scalars['ID']
}>

export type CommentQueryResult = {
  __typename: 'Query'
  comment: {
    __typename: 'Comment'
    id: string
    body: string
    replyCount: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type CommentRepliesQueryVariables = Exact<{
  commentId: Scalars['ID']
  commentConnection: ConnectionInput
}>

export type CommentRepliesQueryResult = {
  __typename: 'Query'
  comment: {
    __typename: 'Comment'
    id: string
    comments: {
      __typename: 'CommentConnection'
      edges: Array<{
        __typename: 'CommentEdge'
        cursor: string
        node: {
          __typename: 'Comment'
          id: string
          body: string
          replyCount: number
          createdAt: Date
          updatedAt: Date
          deletedAt?: Date | null
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }
          attachments: Array<{
            __typename: 'ThreadAttachment'
            id: string
            url: string
            type: string
            name: string
          }>
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type DiscussionBoardQueryVariables = Exact<{
  discussionBoardId: Scalars['ID']
}>

export type DiscussionBoardQueryResult = {
  __typename: 'Query'
  discussionBoard: {
    __typename: 'DiscussionBoard'
    id: string
    categories: Array<{
      __typename: 'Category'
      id: string
      name: string
      description?: string | null
      order: number
      removable: boolean
      governance: boolean
      archived: boolean
      totalThreads: number
      createdAt: Date
      updatedAt: Date
      deletedAt?: Date | null
      publicUrl: string
      notificationSettings: {
        __typename: 'DiscussionBoardCategoryNotificationSetting'
        id: string
        allowNotifications: boolean
      }
      discussionBoard: { __typename: 'DiscussionBoard'; id: string }
    }>
    notificationSettings: {
      __typename: 'DiscussionBoardNotificationSetting'
      allowGlobalNotifications: boolean
    }
  }
}

export type ThreadCollaboratorsQueryVariables = Exact<{
  threadId: Scalars['ID']
  collaboratorConnection: ConnectionInput
}>

export type ThreadCollaboratorsQueryResult = {
  __typename: 'Query'
  thread: {
    __typename: 'Thread'
    id: string
    collaborators: {
      __typename: 'CollaboratorConnection'
      edges: Array<{
        __typename: 'CollaboratorEdge'
        cursor: string
        node: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          url?: string | null
          twitter?: string | null
          linkedin?: string | null
          instagram?: string | null
          createdAt?: Date | null
          blocked: boolean
          deleted: boolean
          userType: UserType
          isBot: boolean
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type ThreadCommentsQueryVariables = Exact<{
  threadId: Scalars['ID']
  commentConnection: ConnectionInput
  repliesConnection: ConnectionInput
  collaboratorConnection: ConnectionInput
}>

export type ThreadCommentsQueryResult = {
  __typename: 'Query'
  thread: {
    __typename: 'Thread'
    id: string
    comments: {
      __typename: 'CommentConnection'
      edges: Array<{
        __typename: 'CommentEdge'
        cursor: string
        node: {
          __typename: 'Comment'
          id: string
          body: string
          replyCount: number
          createdAt: Date
          updatedAt: Date
          deletedAt?: Date | null
          comments: {
            __typename: 'CommentConnection'
            edges: Array<{
              __typename: 'CommentEdge'
              cursor: string
              node: {
                __typename: 'Comment'
                id: string
                body: string
                replyCount: number
                createdAt: Date
                updatedAt: Date
                deletedAt?: Date | null
                user: {
                  __typename: 'User'
                  id: string
                  publicUrl: string
                  thumb?: string | null
                  shortDisplayName?: string | null
                  displayName?: string | null
                  blocked: boolean
                  userType: UserType
                  isBot: boolean
                }
                attachments: Array<{
                  __typename: 'ThreadAttachment'
                  id: string
                  url: string
                  type: string
                  name: string
                }>
              }
            }>
            pageInfo: {
              __typename: 'PageInfo'
              hasPreviousPage: boolean
              hasNextPage: boolean
              startCursor?: string | null
              endCursor?: string | null
            }
          }
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }
          attachments: Array<{
            __typename: 'ThreadAttachment'
            id: string
            url: string
            type: string
            name: string
          }>
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type ThreadFileGetURLQueryVariables = Exact<{
  discussionBoardId: Scalars['ID']
  key: Scalars['String']
}>

export type ThreadFileGetURLQueryResult = {
  __typename: 'Query'
  threadFileGetUrl: { __typename: 'FileGet'; url: string }
}

export type ThreadFileUploadURLQueryVariables = Exact<{
  discussionBoardId: Scalars['ID']
  fileName?: InputMaybe<Scalars['String']>
  fileExtension?: InputMaybe<Scalars['String']>
  fileType?: InputMaybe<Scalars['String']>
}>

export type ThreadFileUploadURLQueryResult = {
  __typename: 'Query'
  threadFileUploadUrl: { __typename: 'FileUpload'; url: string; key: string }
}

export type ThreadQueryVariables = Exact<{
  threadId: Scalars['ID']
  collaboratorConnection: ConnectionInput
}>

export type ThreadQueryResult = {
  __typename: 'Query'
  thread: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    collaborators: {
      __typename: 'CollaboratorConnection'
      edges: Array<{
        __typename: 'CollaboratorEdge'
        cursor: string
        node: {
          __typename: 'User'
          id: string
          publicUrl: string
          thumb?: string | null
          shortDisplayName?: string | null
          displayName?: string | null
          url?: string | null
          twitter?: string | null
          linkedin?: string | null
          instagram?: string | null
          createdAt?: Date | null
          blocked: boolean
          deleted: boolean
          userType: UserType
          isBot: boolean
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type CurrentEventQueryVariables = Exact<{ [key: string]: never }>

export type CurrentEventQueryResult = {
  __typename: 'Query'
  currentEvent?: {
    __typename: 'Event'
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  } | null
}

export type EventHeartbeatQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EventHeartbeatQueryResult = {
  __typename: 'Query'
  eventHeartbeat?: {
    __typename: 'GenericMutationResponse'
    success: boolean
  } | null
}

export type EventHostQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EventHostQueryResult = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    onlineGuestCount: number
    onlineGuestIds: Array<string>
    joinLobbyInMute: boolean
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    recording: boolean
    plan?: { __typename: 'EventPlan'; mainChannelSessionId: string } | null
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  }
}

export type EventMessagesQueryVariables = Exact<{
  id: Scalars['ID']
  messageConnection: ConnectionInput
}>

export type EventMessagesQueryResult = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    messages?: {
      __typename: 'EventMessageConnection'
      edges: Array<{
        __typename: 'EventMessageEdge'
        cursor: string
        node: {
          __typename: 'EventMessage'
          id: string
          type: EventMessageType
          text: string
          createdAt?: Date | null
          deleted: boolean
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    } | null
  }
}

export type EventNoShowsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EventNoShowsQueryResult = {
  __typename: 'Query'
  eventNoShows?: Array<{
    __typename: 'User'
    id: string
    publicUrl: string
    thumb?: string | null
    shortDisplayName?: string | null
    displayName?: string | null
    blocked: boolean
    userType: UserType
    isBot: boolean
  }> | null
}

export type EventQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type EventQueryResult = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    ogImageUrl?: string | null
    onlineGuestCount: number
    onlineGuestIds: Array<string>
    joinLobbyInMute: boolean
    recording: boolean
    id: string
    thumb?: string | null
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    formatDescription: string
    capacity: number
    rsvp?: EventRegistrationRSVP | null
    waitlisted: boolean
    passive: boolean
    actualStartTime?: Date | null
    startTime: Date
    state: EventState
    createdAt: Date
    deleted: boolean
    publicUrl: string
    canJoinVideo: boolean
    canJoinNotice?: string | null
    isOwner: boolean
    isPrivileged: boolean
    isCurrentlySpeaking: boolean
    canEdit: boolean
    introductionTime: number
    location?: string | null
    discoverable: boolean
    shareable: boolean
    media: {
      __typename: 'EventMedia'
      enableBorders: boolean
      enableDJ: boolean
      borders: Array<{
        __typename: 'EventBorder'
        id: string
        meetingTopUrl?: string | null
        meetingBottomUrl?: string | null
        meetingHeightRatio?: number | null
        lobbyTopUrl?: string | null
        lobbyHeightRatio?: number | null
      }>
      djUser: { __typename: 'User'; id: string }
      djPlaylist: Array<{
        __typename: 'DJSong'
        id: string
        title: string
        artist: string
        url: string
      }>
    }
    assets: Array<{
      __typename: 'EventAsset'
      id: string
      public: boolean
      url: string
      caption: string
      thumbnail?: string | null
      type: EventAssetType
      createdAt: Date
      deleted: boolean
    }>
    counts: {
      __typename: 'EventCounts'
      attending: number
      notAttending: number
      maybeAttending: number
      attended?: number | null
      matched?: number | null
      passive?: number | null
    }
    speakers: Array<{
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }>
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    geolocation?: {
      __typename: 'GeolocationObject'
      latitude: number
      longitude: number
    } | null
    collective?: {
      __typename: 'Collective'
      id: string
      name: string
      thumb?: string | null
      publicUrl: string
      membership?: {
        __typename: 'CollectiveMember'
        id: string
        approved: boolean
        privileged: boolean
      } | null
    } | null
    series?: {
      __typename: 'EventSeries'
      id: string
      slug: string
      label: string
    } | null
  }
}

export type EventRegistrationsQueryVariables = Exact<{
  id: Scalars['ID']
  registrationConnection: ConnectionInput
}>

export type EventRegistrationsQueryResult = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    registrations: {
      __typename: 'EventRegistrationConnection'
      edges: Array<{
        __typename: 'EventRegistrationEdge'
        cursor: string
        node: {
          __typename: 'EventRegistration'
          id: string
          role: EventRegistrationRole
          rsvp: EventRegistrationRSVP
          waitlisted: boolean
          passive: boolean
          online: boolean
          attended?: boolean | null
          createdAt: Date
          deleted: boolean
          user: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            url?: string | null
            twitter?: string | null
            linkedin?: string | null
            instagram?: string | null
            createdAt?: Date | null
            blocked: boolean
            deleted: boolean
            userType: UserType
            isBot: boolean
          }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type EventSeriesBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type EventSeriesBySlugQueryResult = {
  __typename: 'Query'
  eventSeriesBySlug?: {
    __typename: 'EventSeries'
    id: string
    slug: string
    label: string
  } | null
}

export type EventSeriesSuggestionQueryVariables = Exact<{
  query: Scalars['String']
}>

export type EventSeriesSuggestionQueryResult = {
  __typename: 'Query'
  eventSeriesSuggestion: Array<{
    __typename: 'EventSeries'
    id: string
    slug: string
    label: string
  }>
}

export type FeaturedEventsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
}>

export type FeaturedEventsQueryResult = {
  __typename: 'Query'
  featuredEvents: Array<{
    __typename: 'Event'
    id: string
    title: string
    description: string
    format: EventFormat
    formatLabel: string
    startTime: Date
  }>
}

export type FeaturedSpeakersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
}>

export type FeaturedSpeakersQueryResult = {
  __typename: 'Query'
  featuredSpeakers: Array<{
    __typename: 'FeaturedSpeaker'
    speaker: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    event: {
      __typename: 'Event'
      id: string
      title: string
      description: string
      format: EventFormat
      formatLabel: string
      startTime: Date
    }
  }>
}

export type PreviousEventsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}>

export type PreviousEventsQueryResult = {
  __typename: 'Query'
  previousEvents: Array<{
    __typename: 'PreviouslyRecordedEvent'
    id: string
    url: string
    thumb: string
    caption: string
    createdAt: Date
  }>
}

export type UserAssetQueryVariables = Exact<{
  userId: Scalars['ID']
  assetConnectionInput: ConnectionInput
  assetFilters: AssetQueryInput
}>

export type UserAssetQueryResult = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    assets: {
      __typename: 'UserAssetsConnection'
      awaitingSync: boolean
      edges: Array<{
        __typename: 'UserAssetsEdge'
        cursor: string
        node: {
          __typename: 'UserAssetType'
          id: string
          contractName: string
          contractType: ContractTypes
          estimatedValueEth?: PseudoBigNumber | null
          symbol: string
          address: string
          details:
            | {
                __typename: 'UserERC20Asset'
                decimals: number
                balance: PseudoBigNumber
                thumbnail?: string | null
              }
            | {
                __typename: 'UserNFTAsset'
                tokenId?: string | null
                url?: string | null
                amount: PseudoBigNumber
                mediaUrls: Array<string>
                name?: string | null
                nftImageUrl?: string | null
              }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        hasPreviousPage: boolean
        hasNextPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  } | null
}

export type EmailVerifiedChangedSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type EmailVerifiedChangedSubscriptionResult = {
  __typename: 'Subscription'
  emailVerifiedChanged?: {
    __typename: 'Viewer'
    id: string
    emailVerified: boolean
  } | null
}

export type CollectiveChangedSubscriptionVariables = Exact<{
  collectiveId: Scalars['ID']
}>

export type CollectiveChangedSubscriptionResult = {
  __typename: 'Subscription'
  collectiveChanged: { __typename: 'Collective'; id: string }
}

export type ProposalChangedSubscriptionVariables = Exact<{
  proposalId: Scalars['ID']
}>

export type ProposalChangedSubscriptionResult = {
  __typename: 'Subscription'
  proposalChanged: { __typename: 'CollectiveProposal'; id: string }
}

export type CategoryAddedSubscriptionVariables = Exact<{
  discussionBoardId: Scalars['ID']
}>

export type CategoryAddedSubscriptionResult = {
  __typename: 'Subscription'
  categoryAdded: {
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }
}

export type CategoryChangedSubscriptionVariables = Exact<{
  categoryId: Scalars['ID']
}>

export type CategoryChangedSubscriptionResult = {
  __typename: 'Subscription'
  categoryChanged: {
    __typename: 'Category'
    id: string
    name: string
    description?: string | null
    order: number
    removable: boolean
    governance: boolean
    archived: boolean
    totalThreads: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    publicUrl: string
    notificationSettings: {
      __typename: 'DiscussionBoardCategoryNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    discussionBoard: { __typename: 'DiscussionBoard'; id: string }
  }
}

export type CommentAddedSubscriptionVariables = Exact<{
  threadId: Scalars['ID']
}>

export type CommentAddedSubscriptionResult = {
  __typename: 'Subscription'
  commentAdded: {
    __typename: 'Comment'
    id: string
    body: string
    replyCount: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    parentThread?: {
      __typename: 'Thread'
      id: string
      replyCount: number
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type CommentChangedSubscriptionVariables = Exact<{
  commentId: Scalars['ID']
}>

export type CommentChangedSubscriptionResult = {
  __typename: 'Subscription'
  commentChanged: {
    __typename: 'Comment'
    id: string
    body: string
    replyCount: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type DiscussionBoardChangedSubscriptionVariables = Exact<{
  discussionBoardId: Scalars['ID']
}>

export type DiscussionBoardChangedSubscriptionResult = {
  __typename: 'Subscription'
  discussionBoardChanged: {
    __typename: 'DiscussionBoard'
    id: string
    categories: Array<{
      __typename: 'Category'
      id: string
      name: string
      description?: string | null
      order: number
      removable: boolean
      governance: boolean
      archived: boolean
      totalThreads: number
      createdAt: Date
      updatedAt: Date
      deletedAt?: Date | null
      publicUrl: string
      notificationSettings: {
        __typename: 'DiscussionBoardCategoryNotificationSetting'
        id: string
        allowNotifications: boolean
      }
      discussionBoard: { __typename: 'DiscussionBoard'; id: string }
    }>
  }
}

export type ReplyAddedSubscriptionVariables = Exact<{
  commentId: Scalars['ID']
}>

export type ReplyAddedSubscriptionResult = {
  __typename: 'Subscription'
  replyAdded: {
    __typename: 'Comment'
    id: string
    body: string
    replyCount: number
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    parentComment?: {
      __typename: 'Comment'
      id: string
      replyCount: number
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type ThreadAddedSubscriptionVariables = Exact<{
  categoryId: Scalars['ID']
}>

export type ThreadAddedSubscriptionResult = {
  __typename: 'Subscription'
  threadAdded: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    category: { __typename: 'Category'; id: string; totalThreads: number }
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
  }
}

export type ThreadChangedSubscriptionVariables = Exact<{
  threadId: Scalars['ID']
}>

export type ThreadChangedSubscriptionResult = {
  __typename: 'Subscription'
  threadChanged: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type ThreadPinnedSubscriptionVariables = Exact<{
  categoryId: Scalars['ID']
}>

export type ThreadPinnedSubscriptionResult = {
  __typename: 'Subscription'
  threadPinned: {
    __typename: 'Thread'
    id: string
    title: string
    body: string
    type: ThreadType
    replyCount: number
    viewsCount: number
    publicUrl: string
    isPinned: boolean
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date | null
    collaboratorsCount: number
    pinnedBy?: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    } | null
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    proposal?: {
      __typename: 'CollectiveProposal'
      id: string
      type: CollectiveProposalType
      title: string
      status: CollectiveProposalStatus
      totalVoters: PseudoBigNumber
      totalVotes: PseudoBigNumber
      votesFor: PseudoBigNumber
      votesAgainst: PseudoBigNumber
      requireVotingSignature: boolean
      votingAnonymity: VotingAnonymity
      votesAbstain: PseudoBigNumber
      isBatch: boolean
      isPartOfBatch: boolean
      publicUrl: string
      myVote?: VoteType | null
      quorumNeeded: number
      isQuorumReached: boolean
      isAuthor: boolean
      isUsingMatchingWallet: boolean
      isVotable: boolean
      isShortForm: boolean
      canVote: boolean
      startTime: Date
      endTime: Date
      createdAt: Date
      execution?: {
        __typename: 'CollectiveProposalExecution'
        txHash: string
        blockNumber: number
        status: CollectiveProposalExecutionStatus
        errorMessage?: string | null
      } | null
      survey?: {
        __typename: 'Survey'
        answeredOn?: Date | null
        totalQuestions: number
        totalAnswers: PseudoBigNumber
        isAnswered: boolean
        questions: Array<{
          __typename: 'Question'
          question: string
          questionType: QuestionType
          options: {
            __typename: 'QuestionOptions'
            required: boolean
            dateOptions?: {
              __typename: 'DateOptions'
              min?: Date | null
              max?: Date | null
              includeTime: boolean
            } | null
          }
          choices?: Array<{
            __typename: 'QuestionChoice'
            stringChoice?: string | null
          }> | null
        }>
        myAnswers?: Array<{
          __typename: 'Answer'
          choices: Array<{
            __typename: 'AnswerChoice'
            dateChoice?: Date | null
            stringChoice?: string | null
          }>
        }> | null
        surveyResults: Array<{
          __typename: 'ChoiceBasedResults'
          labels: Array<string>
          dataset: Array<number>
          datasetPercent: Array<number>
          totalAnswers: number
        }>
        options: {
          __typename: 'SurveyOptions'
          showResultsAfter?: ShowResultsAfter | null
        }
        proposal?: { __typename: 'CollectiveProposal'; id: string } | null
      } | null
      collective: { __typename: 'Collective'; id: string }
      proposedBy: {
        __typename: 'CollectiveMember'
        id: string
        user: {
          __typename: 'CollectiveUser'
          id: string
          address: string
          platformUser?: {
            __typename: 'User'
            id: string
            publicUrl: string
            thumb?: string | null
            shortDisplayName?: string | null
            displayName?: string | null
            blocked: boolean
            userType: UserType
            isBot: boolean
          } | null
        }
      }
      thread?: {
        __typename: 'Thread'
        id: string
        replyCount: number
        title: string
      } | null
    } | null
    notificationSettings: {
      __typename: 'DiscussionBoardThreadNotificationSetting'
      id: string
      allowNotifications: boolean
    }
    attachments: Array<{
      __typename: 'ThreadAttachment'
      id: string
      url: string
      type: string
      name: string
    }>
    category: { __typename: 'Category'; id: string }
  }
}

export type EventMessageAddedSubscriptionVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventMessageAddedSubscriptionResult = {
  __typename: 'Subscription'
  eventMessageAdded: {
    __typename: 'EventMessage'
    id: string
    text: string
    type: EventMessageType
    createdAt?: Date | null
    deleted: boolean
    user: {
      __typename: 'User'
      id: string
      publicUrl: string
      thumb?: string | null
      shortDisplayName?: string | null
      displayName?: string | null
      blocked: boolean
      userType: UserType
      isBot: boolean
    }
    event: { __typename: 'Event'; id: string }
  }
}

export type EventOnlineGuestCountChangedSubscriptionVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventOnlineGuestCountChangedSubscriptionResult = {
  __typename: 'Subscription'
  eventOnlineGuestCountChanged: {
    __typename: 'Event'
    id: string
    onlineGuestCount: number
    onlineGuestIds: Array<string>
  }
}

export type EventSignalReceivedSubscriptionVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventSignalReceivedSubscriptionResult = {
  __typename: 'Subscription'
  eventSignalReceived: {
    __typename: 'EventSignal'
    command: SignalCommand
    data: string
  }
}

export type EventStateChangedSubscriptionVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventStateChangedSubscriptionResult = {
  __typename: 'Subscription'
  eventStateChanged: {
    __typename: 'Event'
    id: string
    state: EventState
    actualStartTime?: Date | null
    joinLobbyInMute: boolean
    recording: boolean
  }
}

export type EventUserPlanUpdatedSubscriptionVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventUserPlanUpdatedSubscriptionResult = {
  __typename: 'Subscription'
  eventUserPlanUpdated: {
    __typename: 'Event'
    id: string
    state: EventState
    actualStartTime?: Date | null
    userPlan?: {
      __typename: 'EventUserPlan'
      currentLayout: BroadcastVideoLayout
      fetchedAt: Date
      nextRefecthAt?: Date | null
      currentSpeakers?: Array<{
        __typename: 'User'
        id: string
        publicUrl: string
        thumb?: string | null
        shortDisplayName?: string | null
        displayName?: string | null
        blocked: boolean
        userType: UserType
        isBot: boolean
      }> | null
      mainChannel: {
        __typename: 'EventVideoChannel'
        provider: VideoProvider
        sessionId: string
        token: string
      }
      lobbyChannel: {
        __typename: 'EventVideoChannel'
        provider: VideoProvider
        sessionId: string
        token: string
      }
      currentChannel: {
        __typename: 'EventVideoChannel'
        provider: VideoProvider
        sessionId: string
        token: string
      }
    } | null
  }
}

export type WithIndex<TObject> = TObject & Record<string, any>
export type ResolversObject<TObject> = WithIndex<TObject>

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping of union types */
export type ResolversUnionTypes = ResolversObject<{
  CollectiveAsset: ERCAsset | ExternalAsset
  CollectiveLogDataType:
    | CollectiveLogDataOnAddedOwner
    | CollectiveLogDataOnAnnouncementChanged
    | CollectiveLogDataOnAnnouncementCreated
    | CollectiveLogDataOnAnnouncementDeleted
    | CollectiveLogDataOnChangedThreshold
    | CollectiveLogDataOnCollectiveMembershipProposed
    | CollectiveLogDataOnCollectiveMembershipRequested
    | CollectiveLogDataOnCollectiveSyncRequested
    | CollectiveLogDataOnCollectiveSynced
    | CollectiveLogDataOnDelegationsGranted
    | CollectiveLogDataOnDelegationsRevoked
    | CollectiveLogDataOnDisableTokenTransfersChanged
    | CollectiveLogDataOnEnableDepositsChanged
    | CollectiveLogDataOnEnableNewMembershipRequestsChanged
    | CollectiveLogDataOnEnableWithdrawsChanged
    | CollectiveLogDataOnExchangeRateChanged
    | CollectiveLogDataOnExternalCollectiveJoined
    | CollectiveLogDataOnFeesChanged
    | CollectiveLogDataOnFundsDeposited
    | CollectiveLogDataOnGateDepositsChanged
    | CollectiveLogDataOnMemberAdded
    | CollectiveLogDataOnMemberDepositedFunds
    | CollectiveLogDataOnMemberRemoved
    | CollectiveLogDataOnMemberWithdrewFunds
    | CollectiveLogDataOnMinEthContributionChanged
    | CollectiveLogDataOnNonDepositEtherReceived
    | CollectiveLogDataOnProposalCanceled
    | CollectiveLogDataOnProposalExecuted
    | CollectiveLogDataOnProposalExecutionReset
    | CollectiveLogDataOnProposalRequireVoteSignatureChanged
    | CollectiveLogDataOnProposalSigned
    | CollectiveLogDataOnProposalsBatched
    | CollectiveLogDataOnQuorumNeededInPercentageChanged
    | CollectiveLogDataOnRemovedOwner
    | CollectiveLogDataOnTransfer
    | CollectiveLogDataOnTransferBatch
    | CollectiveLogDataOnTransferSingle
    | CollectiveLogDataOnVoteCast
    | CollectiveLogDataOnVoteChanged
    | CollectiveLogDataOnVoteInvalidated
    | CollectiveLogDataOnVoteWeightUpdated
    | CollectiveLogDataOnVotingAnonymityChanged
    | CollectiveLogDataOnWalletLinked
    | CollectiveLogDataOnWalletUnlinked
  CollectiveProposalCommand:
    | CollectiveProposalAddMemberCommand
    | CollectiveProposalAddSignatorCommand
    | CollectiveProposalCallRemoteCommand
    | CollectiveProposalChangeDisableTokenTransfersCommand
    | CollectiveProposalChangeEnableDepositsCommand
    | CollectiveProposalChangeEnableNewMembershipRequests
    | CollectiveProposalChangeEnableWithdrawsCommand
    | CollectiveProposalChangeExchangeRateCommand
    | CollectiveProposalChangeGateDepositsCommand
    | CollectiveProposalChangeMinEthContributionCommand
    | CollectiveProposalChangeRequireVotingSignatureCommand
    | CollectiveProposalChangeSafeThresholdCommand
    | CollectiveProposalChangeVotingAnonymityCommand
    | CollectiveProposalLinkWalletCommand
    | CollectiveProposalRemoveMemberCommand
    | CollectiveProposalRemoveSignatorCommand
    | CollectiveProposalSendEtherCommand
    | CollectiveProposalSendEtherDisbursementCommand
    | CollectiveProposalSendEtherDisbursementCommands
    | CollectiveProposalSendTokenCommand
    | CollectiveProposalTransferERC721Command
    | CollectiveProposalTransferERC1155Command
    | CollectiveProposalTransferNFTCommand
    | CollectiveProposalTransferTokensCommand
    | CollectiveProposalUnlinkWalletCommand
    | CollectiveProposalUpdateQuorumCommand
  CollectiveProposalOrigin: CollectiveMembershipRequest
  Engagement: Event
  QuestionResults: ChoiceBasedResults
  SearchResultHit:
    | Collective
    | (Omit<CollectiveProposal, 'commands' | 'origin'> & {
        commands: Array<ResolversTypes['CollectiveProposalCommand']>
        origin?: Maybe<ResolversTypes['CollectiveProposalOrigin']>
      })
    | Event
    | User
  SiteFlag: SiteFlagBoolean | SiteFlagNumber | SiteFlagString
  SubdomainEntity: Collective
  UserAssetDetailsType: UserERC20Asset | UserNFTAsset
}>

/** Mapping of union parent types */
export type ResolversUnionParentTypes = ResolversObject<{
  CollectiveAsset: ERCAsset | ExternalAsset
  CollectiveLogDataType:
    | CollectiveLogDataOnAddedOwner
    | CollectiveLogDataOnAnnouncementChanged
    | CollectiveLogDataOnAnnouncementCreated
    | CollectiveLogDataOnAnnouncementDeleted
    | CollectiveLogDataOnChangedThreshold
    | CollectiveLogDataOnCollectiveMembershipProposed
    | CollectiveLogDataOnCollectiveMembershipRequested
    | CollectiveLogDataOnCollectiveSyncRequested
    | CollectiveLogDataOnCollectiveSynced
    | CollectiveLogDataOnDelegationsGranted
    | CollectiveLogDataOnDelegationsRevoked
    | CollectiveLogDataOnDisableTokenTransfersChanged
    | CollectiveLogDataOnEnableDepositsChanged
    | CollectiveLogDataOnEnableNewMembershipRequestsChanged
    | CollectiveLogDataOnEnableWithdrawsChanged
    | CollectiveLogDataOnExchangeRateChanged
    | CollectiveLogDataOnExternalCollectiveJoined
    | CollectiveLogDataOnFeesChanged
    | CollectiveLogDataOnFundsDeposited
    | CollectiveLogDataOnGateDepositsChanged
    | CollectiveLogDataOnMemberAdded
    | CollectiveLogDataOnMemberDepositedFunds
    | CollectiveLogDataOnMemberRemoved
    | CollectiveLogDataOnMemberWithdrewFunds
    | CollectiveLogDataOnMinEthContributionChanged
    | CollectiveLogDataOnNonDepositEtherReceived
    | CollectiveLogDataOnProposalCanceled
    | CollectiveLogDataOnProposalExecuted
    | CollectiveLogDataOnProposalExecutionReset
    | CollectiveLogDataOnProposalRequireVoteSignatureChanged
    | CollectiveLogDataOnProposalSigned
    | CollectiveLogDataOnProposalsBatched
    | CollectiveLogDataOnQuorumNeededInPercentageChanged
    | CollectiveLogDataOnRemovedOwner
    | CollectiveLogDataOnTransfer
    | CollectiveLogDataOnTransferBatch
    | CollectiveLogDataOnTransferSingle
    | CollectiveLogDataOnVoteCast
    | CollectiveLogDataOnVoteChanged
    | CollectiveLogDataOnVoteInvalidated
    | CollectiveLogDataOnVoteWeightUpdated
    | CollectiveLogDataOnVotingAnonymityChanged
    | CollectiveLogDataOnWalletLinked
    | CollectiveLogDataOnWalletUnlinked
  CollectiveProposalCommand:
    | CollectiveProposalAddMemberCommand
    | CollectiveProposalAddSignatorCommand
    | CollectiveProposalCallRemoteCommand
    | CollectiveProposalChangeDisableTokenTransfersCommand
    | CollectiveProposalChangeEnableDepositsCommand
    | CollectiveProposalChangeEnableNewMembershipRequests
    | CollectiveProposalChangeEnableWithdrawsCommand
    | CollectiveProposalChangeExchangeRateCommand
    | CollectiveProposalChangeGateDepositsCommand
    | CollectiveProposalChangeMinEthContributionCommand
    | CollectiveProposalChangeRequireVotingSignatureCommand
    | CollectiveProposalChangeSafeThresholdCommand
    | CollectiveProposalChangeVotingAnonymityCommand
    | CollectiveProposalLinkWalletCommand
    | CollectiveProposalRemoveMemberCommand
    | CollectiveProposalRemoveSignatorCommand
    | CollectiveProposalSendEtherCommand
    | CollectiveProposalSendEtherDisbursementCommand
    | CollectiveProposalSendEtherDisbursementCommands
    | CollectiveProposalSendTokenCommand
    | CollectiveProposalTransferERC721Command
    | CollectiveProposalTransferERC1155Command
    | CollectiveProposalTransferNFTCommand
    | CollectiveProposalTransferTokensCommand
    | CollectiveProposalUnlinkWalletCommand
    | CollectiveProposalUpdateQuorumCommand
  CollectiveProposalOrigin: CollectiveMembershipRequest
  Engagement: Event
  QuestionResults: ChoiceBasedResults
  SearchResultHit:
    | Collective
    | (Omit<CollectiveProposal, 'commands' | 'origin'> & {
        commands: Array<ResolversParentTypes['CollectiveProposalCommand']>
        origin?: Maybe<ResolversParentTypes['CollectiveProposalOrigin']>
      })
    | Event
    | User
  SiteFlag: SiteFlagBoolean | SiteFlagNumber | SiteFlagString
  SubdomainEntity: Collective
  UserAssetDetailsType: UserERC20Asset | UserNFTAsset
}>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Answer: ResolverTypeWrapper<Answer>
  AnswerChoice: ResolverTypeWrapper<AnswerChoice>
  AnswerChoiceInput: AnswerChoiceInput
  AnswerInput: AnswerInput
  AssetQueryInput: AssetQueryInput
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>
  Blockchain: Blockchain
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  BroadcastRole: BroadcastRole
  BroadcastSignalInput: BroadcastSignalInput
  BroadcastVideoLayout: BroadcastVideoLayout
  BundledCollectiveLog: ResolverTypeWrapper<BundledCollectiveLog>
  CastVoteInput: CastVoteInput
  CastVoteMutationResponse: ResolverTypeWrapper<CastVoteMutationResponse>
  Category: ResolverTypeWrapper<Category>
  CategoryInput: CategoryInput
  ChangePasswordMutationResponse: ResolverTypeWrapper<ChangePasswordMutationResponse>
  ChoiceBasedResults: ResolverTypeWrapper<ChoiceBasedResults>
  CollaboratorConnection: ResolverTypeWrapper<CollaboratorConnection>
  CollaboratorEdge: ResolverTypeWrapper<CollaboratorEdge>
  Collective: ResolverTypeWrapper<Collective>
  CollectiveAnnouncement: ResolverTypeWrapper<CollectiveAnnouncement>
  CollectiveApplication: ResolverTypeWrapper<CollectiveApplication>
  CollectiveApplicationStatus: CollectiveApplicationStatus
  CollectiveAsset: ResolverTypeWrapper<ResolversUnionTypes['CollectiveAsset']>
  CollectiveAssetEdge: ResolverTypeWrapper<
    Omit<CollectiveAssetEdge, 'node'> & {
      node: ResolversTypes['CollectiveAsset']
    }
  >
  CollectiveAssetPreview: ResolverTypeWrapper<CollectiveAssetPreview>
  CollectiveAssetType: CollectiveAssetType
  CollectiveAssetsConnection: ResolverTypeWrapper<CollectiveAssetsConnection>
  CollectiveAssetsQueryInput: CollectiveAssetsQueryInput
  CollectiveConnection: ResolverTypeWrapper<CollectiveConnection>
  CollectiveDemo: ResolverTypeWrapper<CollectiveDemo>
  CollectiveDraft: ResolverTypeWrapper<CollectiveDraft>
  CollectiveDraftConnection: ResolverTypeWrapper<CollectiveDraftConnection>
  CollectiveDraftEdge: ResolverTypeWrapper<CollectiveDraftEdge>
  CollectiveDraftInput: CollectiveDraftInput
  CollectiveEdge: ResolverTypeWrapper<CollectiveEdge>
  CollectiveFaq: ResolverTypeWrapper<CollectiveFaq>
  CollectiveIntent: CollectiveIntent
  CollectiveLog: ResolverTypeWrapper<
    Omit<CollectiveLog, 'data'> & {
      data: ResolversTypes['CollectiveLogDataType']
    }
  >
  CollectiveLogDataOnAddedOwner: ResolverTypeWrapper<CollectiveLogDataOnAddedOwner>
  CollectiveLogDataOnAnnouncementChanged: ResolverTypeWrapper<CollectiveLogDataOnAnnouncementChanged>
  CollectiveLogDataOnAnnouncementCreated: ResolverTypeWrapper<CollectiveLogDataOnAnnouncementCreated>
  CollectiveLogDataOnAnnouncementDeleted: ResolverTypeWrapper<CollectiveLogDataOnAnnouncementDeleted>
  CollectiveLogDataOnChangedThreshold: ResolverTypeWrapper<CollectiveLogDataOnChangedThreshold>
  CollectiveLogDataOnCollectiveMembershipProposed: ResolverTypeWrapper<CollectiveLogDataOnCollectiveMembershipProposed>
  CollectiveLogDataOnCollectiveMembershipRequested: ResolverTypeWrapper<CollectiveLogDataOnCollectiveMembershipRequested>
  CollectiveLogDataOnCollectiveSyncRequested: ResolverTypeWrapper<CollectiveLogDataOnCollectiveSyncRequested>
  CollectiveLogDataOnCollectiveSynced: ResolverTypeWrapper<CollectiveLogDataOnCollectiveSynced>
  CollectiveLogDataOnDelegationsGranted: ResolverTypeWrapper<CollectiveLogDataOnDelegationsGranted>
  CollectiveLogDataOnDelegationsRevoked: ResolverTypeWrapper<CollectiveLogDataOnDelegationsRevoked>
  CollectiveLogDataOnDisableTokenTransfersChanged: ResolverTypeWrapper<CollectiveLogDataOnDisableTokenTransfersChanged>
  CollectiveLogDataOnEnableDepositsChanged: ResolverTypeWrapper<CollectiveLogDataOnEnableDepositsChanged>
  CollectiveLogDataOnEnableNewMembershipRequestsChanged: ResolverTypeWrapper<CollectiveLogDataOnEnableNewMembershipRequestsChanged>
  CollectiveLogDataOnEnableWithdrawsChanged: ResolverTypeWrapper<CollectiveLogDataOnEnableWithdrawsChanged>
  CollectiveLogDataOnExchangeRateChanged: ResolverTypeWrapper<CollectiveLogDataOnExchangeRateChanged>
  CollectiveLogDataOnExternalCollectiveJoined: ResolverTypeWrapper<CollectiveLogDataOnExternalCollectiveJoined>
  CollectiveLogDataOnFeesChanged: ResolverTypeWrapper<CollectiveLogDataOnFeesChanged>
  CollectiveLogDataOnFundsDeposited: ResolverTypeWrapper<CollectiveLogDataOnFundsDeposited>
  CollectiveLogDataOnGateDepositsChanged: ResolverTypeWrapper<CollectiveLogDataOnGateDepositsChanged>
  CollectiveLogDataOnMemberAdded: ResolverTypeWrapper<CollectiveLogDataOnMemberAdded>
  CollectiveLogDataOnMemberDepositedFunds: ResolverTypeWrapper<CollectiveLogDataOnMemberDepositedFunds>
  CollectiveLogDataOnMemberRemoved: ResolverTypeWrapper<CollectiveLogDataOnMemberRemoved>
  CollectiveLogDataOnMemberWithdrewFunds: ResolverTypeWrapper<CollectiveLogDataOnMemberWithdrewFunds>
  CollectiveLogDataOnMinEthContributionChanged: ResolverTypeWrapper<CollectiveLogDataOnMinEthContributionChanged>
  CollectiveLogDataOnNonDepositEtherReceived: ResolverTypeWrapper<CollectiveLogDataOnNonDepositEtherReceived>
  CollectiveLogDataOnProposalCanceled: ResolverTypeWrapper<CollectiveLogDataOnProposalCanceled>
  CollectiveLogDataOnProposalExecuted: ResolverTypeWrapper<CollectiveLogDataOnProposalExecuted>
  CollectiveLogDataOnProposalExecutionReset: ResolverTypeWrapper<CollectiveLogDataOnProposalExecutionReset>
  CollectiveLogDataOnProposalRequireVoteSignatureChanged: ResolverTypeWrapper<CollectiveLogDataOnProposalRequireVoteSignatureChanged>
  CollectiveLogDataOnProposalSigned: ResolverTypeWrapper<CollectiveLogDataOnProposalSigned>
  CollectiveLogDataOnProposalsBatched: ResolverTypeWrapper<CollectiveLogDataOnProposalsBatched>
  CollectiveLogDataOnQuorumNeededInPercentageChanged: ResolverTypeWrapper<CollectiveLogDataOnQuorumNeededInPercentageChanged>
  CollectiveLogDataOnRemovedOwner: ResolverTypeWrapper<CollectiveLogDataOnRemovedOwner>
  CollectiveLogDataOnTransfer: ResolverTypeWrapper<CollectiveLogDataOnTransfer>
  CollectiveLogDataOnTransferBatch: ResolverTypeWrapper<CollectiveLogDataOnTransferBatch>
  CollectiveLogDataOnTransferSingle: ResolverTypeWrapper<CollectiveLogDataOnTransferSingle>
  CollectiveLogDataOnVoteCast: ResolverTypeWrapper<CollectiveLogDataOnVoteCast>
  CollectiveLogDataOnVoteChanged: ResolverTypeWrapper<CollectiveLogDataOnVoteChanged>
  CollectiveLogDataOnVoteInvalidated: ResolverTypeWrapper<CollectiveLogDataOnVoteInvalidated>
  CollectiveLogDataOnVoteWeightUpdated: ResolverTypeWrapper<CollectiveLogDataOnVoteWeightUpdated>
  CollectiveLogDataOnVotingAnonymityChanged: ResolverTypeWrapper<CollectiveLogDataOnVotingAnonymityChanged>
  CollectiveLogDataOnWalletLinked: ResolverTypeWrapper<CollectiveLogDataOnWalletLinked>
  CollectiveLogDataOnWalletUnlinked: ResolverTypeWrapper<CollectiveLogDataOnWalletUnlinked>
  CollectiveLogDataType: ResolverTypeWrapper<
    ResolversUnionTypes['CollectiveLogDataType']
  >
  CollectiveLogEventType: CollectiveLogEventType
  CollectiveLogsConnection: ResolverTypeWrapper<CollectiveLogsConnection>
  CollectiveLogsEdge: ResolverTypeWrapper<CollectiveLogsEdge>
  CollectiveLogsQueryInput: CollectiveLogsQueryInput
  CollectiveMember: ResolverTypeWrapper<CollectiveMember>
  CollectiveMemberConnection: ResolverTypeWrapper<CollectiveMemberConnection>
  CollectiveMemberEdge: ResolverTypeWrapper<CollectiveMemberEdge>
  CollectiveMembershipRequest: ResolverTypeWrapper<CollectiveMembershipRequest>
  CollectiveMembershipRequestStatus: CollectiveMembershipRequestStatus
  CollectiveParameters: ResolverTypeWrapper<CollectiveParameters>
  CollectiveProposal: ResolverTypeWrapper<
    Omit<CollectiveProposal, 'commands' | 'origin'> & {
      commands: Array<ResolversTypes['CollectiveProposalCommand']>
      origin?: Maybe<ResolversTypes['CollectiveProposalOrigin']>
    }
  >
  CollectiveProposalAddMemberCommand: ResolverTypeWrapper<CollectiveProposalAddMemberCommand>
  CollectiveProposalAddSignatorCommand: ResolverTypeWrapper<CollectiveProposalAddSignatorCommand>
  CollectiveProposalCallRemoteCommand: ResolverTypeWrapper<CollectiveProposalCallRemoteCommand>
  CollectiveProposalChangeDisableTokenTransfersCommand: ResolverTypeWrapper<CollectiveProposalChangeDisableTokenTransfersCommand>
  CollectiveProposalChangeEnableDepositsCommand: ResolverTypeWrapper<CollectiveProposalChangeEnableDepositsCommand>
  CollectiveProposalChangeEnableNewMembershipRequests: ResolverTypeWrapper<CollectiveProposalChangeEnableNewMembershipRequests>
  CollectiveProposalChangeEnableWithdrawsCommand: ResolverTypeWrapper<CollectiveProposalChangeEnableWithdrawsCommand>
  CollectiveProposalChangeExchangeRateCommand: ResolverTypeWrapper<CollectiveProposalChangeExchangeRateCommand>
  CollectiveProposalChangeGateDepositsCommand: ResolverTypeWrapper<CollectiveProposalChangeGateDepositsCommand>
  CollectiveProposalChangeMinEthContributionCommand: ResolverTypeWrapper<CollectiveProposalChangeMinEthContributionCommand>
  CollectiveProposalChangeRequireVotingSignatureCommand: ResolverTypeWrapper<CollectiveProposalChangeRequireVotingSignatureCommand>
  CollectiveProposalChangeSafeThresholdCommand: ResolverTypeWrapper<CollectiveProposalChangeSafeThresholdCommand>
  CollectiveProposalChangeVotingAnonymityCommand: ResolverTypeWrapper<CollectiveProposalChangeVotingAnonymityCommand>
  CollectiveProposalCommand: ResolverTypeWrapper<
    ResolversUnionTypes['CollectiveProposalCommand']
  >
  CollectiveProposalConnection: ResolverTypeWrapper<CollectiveProposalConnection>
  CollectiveProposalEdge: ResolverTypeWrapper<CollectiveProposalEdge>
  CollectiveProposalExecution: ResolverTypeWrapper<CollectiveProposalExecution>
  CollectiveProposalExecutionStatus: CollectiveProposalExecutionStatus
  CollectiveProposalLinkWalletCommand: ResolverTypeWrapper<CollectiveProposalLinkWalletCommand>
  CollectiveProposalOrigin: ResolverTypeWrapper<
    ResolversUnionTypes['CollectiveProposalOrigin']
  >
  CollectiveProposalRemoveMemberCommand: ResolverTypeWrapper<CollectiveProposalRemoveMemberCommand>
  CollectiveProposalRemoveSignatorCommand: ResolverTypeWrapper<CollectiveProposalRemoveSignatorCommand>
  CollectiveProposalSendEtherCommand: ResolverTypeWrapper<CollectiveProposalSendEtherCommand>
  CollectiveProposalSendEtherDisbursementCommand: ResolverTypeWrapper<CollectiveProposalSendEtherDisbursementCommand>
  CollectiveProposalSendEtherDisbursementCommands: ResolverTypeWrapper<CollectiveProposalSendEtherDisbursementCommands>
  CollectiveProposalSendTokenCommand: ResolverTypeWrapper<CollectiveProposalSendTokenCommand>
  CollectiveProposalSignature: ResolverTypeWrapper<CollectiveProposalSignature>
  CollectiveProposalStatus: CollectiveProposalStatus
  CollectiveProposalStatusHistory: ResolverTypeWrapper<CollectiveProposalStatusHistory>
  CollectiveProposalTransferERC721Command: ResolverTypeWrapper<CollectiveProposalTransferERC721Command>
  CollectiveProposalTransferERC1155Command: ResolverTypeWrapper<CollectiveProposalTransferERC1155Command>
  CollectiveProposalTransferNFTCommand: ResolverTypeWrapper<CollectiveProposalTransferNFTCommand>
  CollectiveProposalTransferTokensCommand: ResolverTypeWrapper<CollectiveProposalTransferTokensCommand>
  CollectiveProposalType: CollectiveProposalType
  CollectiveProposalUnlinkWalletCommand: ResolverTypeWrapper<CollectiveProposalUnlinkWalletCommand>
  CollectiveProposalUpdateQuorumCommand: ResolverTypeWrapper<CollectiveProposalUpdateQuorumCommand>
  CollectiveProposalVote: ResolverTypeWrapper<CollectiveProposalVote>
  CollectiveProposalVoteConnection: ResolverTypeWrapper<CollectiveProposalVoteConnection>
  CollectiveProposalVoteEdge: ResolverTypeWrapper<CollectiveProposalVoteEdge>
  CollectiveScreen: CollectiveScreen
  CollectiveStatsDatapoint: ResolverTypeWrapper<CollectiveStatsDatapoint>
  CollectiveSubmissionLogInput: CollectiveSubmissionLogInput
  CollectiveSyncStatus: CollectiveSyncStatus
  CollectiveTOS: ResolverTypeWrapper<CollectiveTOS>
  CollectiveToken: ResolverTypeWrapper<CollectiveToken>
  CollectiveTokenType: CollectiveTokenType
  CollectiveUser: ResolverTypeWrapper<CollectiveUser>
  CollectiveWallet: ResolverTypeWrapper<CollectiveWallet>
  CollectiveWalletStatsDatapoint: ResolverTypeWrapper<CollectiveWalletStatsDatapoint>
  CollectiveWalletType: CollectiveWalletType
  CommandDelegation: ResolverTypeWrapper<CommandDelegation>
  CommandDelegationStruct: ResolverTypeWrapper<CommandDelegationStruct>
  Comment: ResolverTypeWrapper<Comment>
  CommentConnection: ResolverTypeWrapper<CommentConnection>
  CommentCreateInput: CommentCreateInput
  CommentEdge: ResolverTypeWrapper<CommentEdge>
  CommentUpdateInput: CommentUpdateInput
  ConnectionInput: ConnectionInput
  ContractAbi: ResolverTypeWrapper<ContractAbi>
  ContractTypes: ContractTypes
  CreateCollectiveInput: CreateCollectiveInput
  CryptoWalletAuth: CryptoWalletAuth
  DJPlayState: DJPlayState
  DJSong: ResolverTypeWrapper<DJSong>
  DJState: ResolverTypeWrapper<DJState>
  Date: ResolverTypeWrapper<Scalars['Date']>
  DateOptions: ResolverTypeWrapper<DateOptions>
  DateOptionsInput: DateOptionsInput
  DelegationResult: ResolverTypeWrapper<DelegationResult>
  DelegationsPayload: DelegationsPayload
  DeviceInfoInput: DeviceInfoInput
  DiscussionBoard: ResolverTypeWrapper<DiscussionBoard>
  DiscussionBoardCategoryNotificationSetting: ResolverTypeWrapper<DiscussionBoardCategoryNotificationSetting>
  DiscussionBoardNotificationSetting: ResolverTypeWrapper<DiscussionBoardNotificationSetting>
  DiscussionBoardThreadNotificationSetting: ResolverTypeWrapper<DiscussionBoardThreadNotificationSetting>
  DynamicFileExportFieldsInput: DynamicFileExportFieldsInput
  ERC20Asset: ResolverTypeWrapper<ERC20Asset>
  ERCAsset: ResolverTypeWrapper<ERCAsset>
  EmailPreferences: ResolverTypeWrapper<EmailPreferences>
  EmailType: EmailType
  Engagement: ResolverTypeWrapper<ResolversUnionTypes['Engagement']>
  EngagementConnection: ResolverTypeWrapper<EngagementConnection>
  EngagementEdge: ResolverTypeWrapper<
    Omit<EngagementEdge, 'node'> & { node: ResolversTypes['Engagement'] }
  >
  EngagementSortingInput: EngagementSortingInput
  EnrichmentEmployment: ResolverTypeWrapper<EnrichmentEmployment>
  EnrichmentNFT: ResolverTypeWrapper<EnrichmentNFT>
  EnrichmentSocialProfile: ResolverTypeWrapper<EnrichmentSocialProfile>
  EnrichmentSuggestions: ResolverTypeWrapper<EnrichmentSuggestions>
  EphemeralCryptoAuth: ResolverTypeWrapper<EphemeralCryptoAuth>
  EthNetwork: EthNetwork
  Event: ResolverTypeWrapper<Event>
  EventAsset: ResolverTypeWrapper<EventAsset>
  EventAssetType: EventAssetType
  EventBorder: ResolverTypeWrapper<EventBorder>
  EventConnection: ResolverTypeWrapper<EventConnection>
  EventCounts: ResolverTypeWrapper<EventCounts>
  EventEdge: ResolverTypeWrapper<EventEdge>
  EventFormat: EventFormat
  EventInput: EventInput
  EventMedia: ResolverTypeWrapper<EventMedia>
  EventMessage: ResolverTypeWrapper<EventMessage>
  EventMessageConnection: ResolverTypeWrapper<EventMessageConnection>
  EventMessageEdge: ResolverTypeWrapper<EventMessageEdge>
  EventMessageType: EventMessageType
  EventPlan: ResolverTypeWrapper<EventPlan>
  EventRegistration: ResolverTypeWrapper<EventRegistration>
  EventRegistrationConnection: ResolverTypeWrapper<EventRegistrationConnection>
  EventRegistrationEdge: ResolverTypeWrapper<EventRegistrationEdge>
  EventRegistrationRSVP: EventRegistrationRSVP
  EventRegistrationRole: EventRegistrationRole
  EventSegment: EventSegment
  EventSeries: ResolverTypeWrapper<EventSeries>
  EventSignal: ResolverTypeWrapper<EventSignal>
  EventState: EventState
  EventUserPlan: ResolverTypeWrapper<EventUserPlan>
  EventVideoChannel: ResolverTypeWrapper<EventVideoChannel>
  EventsInSeriesInput: EventsInSeriesInput
  ExternalAsset: ResolverTypeWrapper<ExternalAsset>
  ExternalAssetType: ExternalAssetType
  FeaturedSpeaker: ResolverTypeWrapper<FeaturedSpeaker>
  FileExport: ResolverTypeWrapper<FileExport>
  FileExportInput: FileExportInput
  FileExportStatus: FileExportStatus
  FileExportType: FileExportType
  FileGet: ResolverTypeWrapper<FileGet>
  FileUpload: ResolverTypeWrapper<FileUpload>
  FilterableEngagementField: FilterableEngagementField
  Float: ResolverTypeWrapper<Scalars['Float']>
  GenericMutationResponse: ResolverTypeWrapper<GenericMutationResponse>
  GeolocationObject: ResolverTypeWrapper<GeolocationObject>
  GeolocationObjectInput: GeolocationObjectInput
  ID: ResolverTypeWrapper<Scalars['ID']>
  IncidentLocation: IncidentLocation
  InputStep: InputStep
  Int: ResolverTypeWrapper<Scalars['Int']>
  JSON: ResolverTypeWrapper<Scalars['JSON']>
  JobFairParticipantCompany: ResolverTypeWrapper<JobFairParticipantCompany>
  Languages: ResolverTypeWrapper<Languages>
  LocalFile: ResolverTypeWrapper<LocalFile>
  LocalFileInput: LocalFileInput
  LoginInput: LoginInput
  LoginMutationResponse: ResolverTypeWrapper<LoginMutationResponse>
  LoginWithAppleIdInput: LoginWithAppleIdInput
  MediaUpload: ResolverTypeWrapper<MediaUpload>
  MediaUploadType: MediaUploadType
  MeetingHint: MeetingHint
  MemberFileExportFieldsInput: MemberFileExportFieldsInput
  MetaTag: ResolverTypeWrapper<MetaTag>
  Mutation: ResolverTypeWrapper<{}>
  MutationResponse:
    | ResolversTypes['ChangePasswordMutationResponse']
    | ResolversTypes['GenericMutationResponse']
    | ResolversTypes['LoginMutationResponse']
    | ResolversTypes['ReportContentMutationResponse']
    | ResolversTypes['ResetPasswordMutationResponse']
    | ResolversTypes['SignupMutationResponse']
  NFTAsset: ResolverTypeWrapper<NFTAsset>
  PageInfo: ResolverTypeWrapper<PageInfo>
  PostAnnouncementInput: PostAnnouncementInput
  PostEventMessageInput: PostEventMessageInput
  PreviouslyRecordedEvent: ResolverTypeWrapper<PreviouslyRecordedEvent>
  ProposalCommandInput: ProposalCommandInput
  ProposalCommandType: ProposalCommandType
  ProposalInput: ProposalInput
  Query: ResolverTypeWrapper<{}>
  Question: ResolverTypeWrapper<Question>
  QuestionChoice: ResolverTypeWrapper<QuestionChoice>
  QuestionChoiceInput: QuestionChoiceInput
  QuestionCreationInput: QuestionCreationInput
  QuestionOptions: ResolverTypeWrapper<QuestionOptions>
  QuestionOptionsInput: QuestionOptionsInput
  QuestionResults: ResolverTypeWrapper<ResolversUnionTypes['QuestionResults']>
  QuestionType: QuestionType
  ReportContentInput: ReportContentInput
  ReportContentMutationResponse: ResolverTypeWrapper<ReportContentMutationResponse>
  ResetPasswordInput: ResetPasswordInput
  ResetPasswordMutationResponse: ResolverTypeWrapper<ResetPasswordMutationResponse>
  SEO: ResolverTypeWrapper<SEO>
  SearchResultHit: ResolverTypeWrapper<ResolversUnionTypes['SearchResultHit']>
  SearchResults: ResolverTypeWrapper<
    Omit<SearchResults, 'hits'> & {
      hits: Array<ResolversTypes['SearchResultHit']>
    }
  >
  SetPreferenceInput: SetPreferenceInput
  ShowResultsAfter: ShowResultsAfter
  SignalCommand: SignalCommand
  SignalInput: SignalInput
  SignupInput: SignupInput
  SignupMutationResponse: ResolverTypeWrapper<SignupMutationResponse>
  SignupWithAppleIdInput: SignupWithAppleIdInput
  SiteFlag: ResolverTypeWrapper<ResolversUnionTypes['SiteFlag']>
  SiteFlagBoolean: ResolverTypeWrapper<SiteFlagBoolean>
  SiteFlagNumber: ResolverTypeWrapper<SiteFlagNumber>
  SiteFlagString: ResolverTypeWrapper<SiteFlagString>
  SiteFlagType: SiteFlagType
  SocialNetwork: SocialNetwork
  SortDirection: SortDirection
  SortableEngagementField: SortableEngagementField
  String: ResolverTypeWrapper<Scalars['String']>
  SubdomainEntity: ResolverTypeWrapper<ResolversUnionTypes['SubdomainEntity']>
  Subscription: ResolverTypeWrapper<{}>
  Survey: ResolverTypeWrapper<
    Omit<Survey, 'surveyResults'> & {
      surveyResults: Array<ResolversTypes['QuestionResults']>
    }
  >
  SurveyCreationInput: SurveyCreationInput
  SurveyInput: SurveyInput
  SurveyOptions: ResolverTypeWrapper<SurveyOptions>
  SurveyOptionsInput: SurveyOptionsInput
  SyncStatus: ResolverTypeWrapper<SyncStatus>
  Tag: ResolverTypeWrapper<Tag>
  Thread: ResolverTypeWrapper<Thread>
  ThreadAttachment: ResolverTypeWrapper<ThreadAttachment>
  ThreadAttachmentInput: ThreadAttachmentInput
  ThreadConnection: ResolverTypeWrapper<ThreadConnection>
  ThreadCreateInput: ThreadCreateInput
  ThreadEdge: ResolverTypeWrapper<ThreadEdge>
  ThreadType: ThreadType
  ThreadUpdateInput: ThreadUpdateInput
  TosAgreementType: TosAgreementType
  UpdateUserInput: UpdateUserInput
  Upload: ResolverTypeWrapper<Scalars['Upload']>
  User: ResolverTypeWrapper<User>
  UserAssetDetailsType: ResolverTypeWrapper<
    ResolversUnionTypes['UserAssetDetailsType']
  >
  UserAssetType: ResolverTypeWrapper<
    Omit<UserAssetType, 'details'> & {
      details: ResolversTypes['UserAssetDetailsType']
    }
  >
  UserAssetsConnection: ResolverTypeWrapper<UserAssetsConnection>
  UserAssetsEdge: ResolverTypeWrapper<UserAssetsEdge>
  UserConnection: ResolverTypeWrapper<UserConnection>
  UserERC20Asset: ResolverTypeWrapper<UserERC20Asset>
  UserEdge: ResolverTypeWrapper<UserEdge>
  UserEnrichments: ResolverTypeWrapper<UserEnrichments>
  UserNFTAsset: ResolverTypeWrapper<UserNFTAsset>
  UserNotificationCategory: UserNotificationCategory
  UserPreference: ResolverTypeWrapper<UserPreference>
  UserPreferenceType: UserPreferenceType
  UserSyncStatus: UserSyncStatus
  UserType: UserType
  VideoProvider: VideoProvider
  Viewer: ResolverTypeWrapper<Viewer>
  VoteType: VoteType
  VotingAnonymity: VotingAnonymity
  WalletType: WalletType
}>

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Answer: Answer
  AnswerChoice: AnswerChoice
  AnswerChoiceInput: AnswerChoiceInput
  AnswerInput: AnswerInput
  AssetQueryInput: AssetQueryInput
  BigInt: Scalars['BigInt']
  Boolean: Scalars['Boolean']
  BroadcastSignalInput: BroadcastSignalInput
  BundledCollectiveLog: BundledCollectiveLog
  CastVoteInput: CastVoteInput
  CastVoteMutationResponse: CastVoteMutationResponse
  Category: Category
  CategoryInput: CategoryInput
  ChangePasswordMutationResponse: ChangePasswordMutationResponse
  ChoiceBasedResults: ChoiceBasedResults
  CollaboratorConnection: CollaboratorConnection
  CollaboratorEdge: CollaboratorEdge
  Collective: Collective
  CollectiveAnnouncement: CollectiveAnnouncement
  CollectiveApplication: CollectiveApplication
  CollectiveAsset: ResolversUnionParentTypes['CollectiveAsset']
  CollectiveAssetEdge: Omit<CollectiveAssetEdge, 'node'> & {
    node: ResolversParentTypes['CollectiveAsset']
  }
  CollectiveAssetPreview: CollectiveAssetPreview
  CollectiveAssetsConnection: CollectiveAssetsConnection
  CollectiveAssetsQueryInput: CollectiveAssetsQueryInput
  CollectiveConnection: CollectiveConnection
  CollectiveDemo: CollectiveDemo
  CollectiveDraft: CollectiveDraft
  CollectiveDraftConnection: CollectiveDraftConnection
  CollectiveDraftEdge: CollectiveDraftEdge
  CollectiveDraftInput: CollectiveDraftInput
  CollectiveEdge: CollectiveEdge
  CollectiveFaq: CollectiveFaq
  CollectiveLog: Omit<CollectiveLog, 'data'> & {
    data: ResolversParentTypes['CollectiveLogDataType']
  }
  CollectiveLogDataOnAddedOwner: CollectiveLogDataOnAddedOwner
  CollectiveLogDataOnAnnouncementChanged: CollectiveLogDataOnAnnouncementChanged
  CollectiveLogDataOnAnnouncementCreated: CollectiveLogDataOnAnnouncementCreated
  CollectiveLogDataOnAnnouncementDeleted: CollectiveLogDataOnAnnouncementDeleted
  CollectiveLogDataOnChangedThreshold: CollectiveLogDataOnChangedThreshold
  CollectiveLogDataOnCollectiveMembershipProposed: CollectiveLogDataOnCollectiveMembershipProposed
  CollectiveLogDataOnCollectiveMembershipRequested: CollectiveLogDataOnCollectiveMembershipRequested
  CollectiveLogDataOnCollectiveSyncRequested: CollectiveLogDataOnCollectiveSyncRequested
  CollectiveLogDataOnCollectiveSynced: CollectiveLogDataOnCollectiveSynced
  CollectiveLogDataOnDelegationsGranted: CollectiveLogDataOnDelegationsGranted
  CollectiveLogDataOnDelegationsRevoked: CollectiveLogDataOnDelegationsRevoked
  CollectiveLogDataOnDisableTokenTransfersChanged: CollectiveLogDataOnDisableTokenTransfersChanged
  CollectiveLogDataOnEnableDepositsChanged: CollectiveLogDataOnEnableDepositsChanged
  CollectiveLogDataOnEnableNewMembershipRequestsChanged: CollectiveLogDataOnEnableNewMembershipRequestsChanged
  CollectiveLogDataOnEnableWithdrawsChanged: CollectiveLogDataOnEnableWithdrawsChanged
  CollectiveLogDataOnExchangeRateChanged: CollectiveLogDataOnExchangeRateChanged
  CollectiveLogDataOnExternalCollectiveJoined: CollectiveLogDataOnExternalCollectiveJoined
  CollectiveLogDataOnFeesChanged: CollectiveLogDataOnFeesChanged
  CollectiveLogDataOnFundsDeposited: CollectiveLogDataOnFundsDeposited
  CollectiveLogDataOnGateDepositsChanged: CollectiveLogDataOnGateDepositsChanged
  CollectiveLogDataOnMemberAdded: CollectiveLogDataOnMemberAdded
  CollectiveLogDataOnMemberDepositedFunds: CollectiveLogDataOnMemberDepositedFunds
  CollectiveLogDataOnMemberRemoved: CollectiveLogDataOnMemberRemoved
  CollectiveLogDataOnMemberWithdrewFunds: CollectiveLogDataOnMemberWithdrewFunds
  CollectiveLogDataOnMinEthContributionChanged: CollectiveLogDataOnMinEthContributionChanged
  CollectiveLogDataOnNonDepositEtherReceived: CollectiveLogDataOnNonDepositEtherReceived
  CollectiveLogDataOnProposalCanceled: CollectiveLogDataOnProposalCanceled
  CollectiveLogDataOnProposalExecuted: CollectiveLogDataOnProposalExecuted
  CollectiveLogDataOnProposalExecutionReset: CollectiveLogDataOnProposalExecutionReset
  CollectiveLogDataOnProposalRequireVoteSignatureChanged: CollectiveLogDataOnProposalRequireVoteSignatureChanged
  CollectiveLogDataOnProposalSigned: CollectiveLogDataOnProposalSigned
  CollectiveLogDataOnProposalsBatched: CollectiveLogDataOnProposalsBatched
  CollectiveLogDataOnQuorumNeededInPercentageChanged: CollectiveLogDataOnQuorumNeededInPercentageChanged
  CollectiveLogDataOnRemovedOwner: CollectiveLogDataOnRemovedOwner
  CollectiveLogDataOnTransfer: CollectiveLogDataOnTransfer
  CollectiveLogDataOnTransferBatch: CollectiveLogDataOnTransferBatch
  CollectiveLogDataOnTransferSingle: CollectiveLogDataOnTransferSingle
  CollectiveLogDataOnVoteCast: CollectiveLogDataOnVoteCast
  CollectiveLogDataOnVoteChanged: CollectiveLogDataOnVoteChanged
  CollectiveLogDataOnVoteInvalidated: CollectiveLogDataOnVoteInvalidated
  CollectiveLogDataOnVoteWeightUpdated: CollectiveLogDataOnVoteWeightUpdated
  CollectiveLogDataOnVotingAnonymityChanged: CollectiveLogDataOnVotingAnonymityChanged
  CollectiveLogDataOnWalletLinked: CollectiveLogDataOnWalletLinked
  CollectiveLogDataOnWalletUnlinked: CollectiveLogDataOnWalletUnlinked
  CollectiveLogDataType: ResolversUnionParentTypes['CollectiveLogDataType']
  CollectiveLogsConnection: CollectiveLogsConnection
  CollectiveLogsEdge: CollectiveLogsEdge
  CollectiveLogsQueryInput: CollectiveLogsQueryInput
  CollectiveMember: CollectiveMember
  CollectiveMemberConnection: CollectiveMemberConnection
  CollectiveMemberEdge: CollectiveMemberEdge
  CollectiveMembershipRequest: CollectiveMembershipRequest
  CollectiveParameters: CollectiveParameters
  CollectiveProposal: Omit<CollectiveProposal, 'commands' | 'origin'> & {
    commands: Array<ResolversParentTypes['CollectiveProposalCommand']>
    origin?: Maybe<ResolversParentTypes['CollectiveProposalOrigin']>
  }
  CollectiveProposalAddMemberCommand: CollectiveProposalAddMemberCommand
  CollectiveProposalAddSignatorCommand: CollectiveProposalAddSignatorCommand
  CollectiveProposalCallRemoteCommand: CollectiveProposalCallRemoteCommand
  CollectiveProposalChangeDisableTokenTransfersCommand: CollectiveProposalChangeDisableTokenTransfersCommand
  CollectiveProposalChangeEnableDepositsCommand: CollectiveProposalChangeEnableDepositsCommand
  CollectiveProposalChangeEnableNewMembershipRequests: CollectiveProposalChangeEnableNewMembershipRequests
  CollectiveProposalChangeEnableWithdrawsCommand: CollectiveProposalChangeEnableWithdrawsCommand
  CollectiveProposalChangeExchangeRateCommand: CollectiveProposalChangeExchangeRateCommand
  CollectiveProposalChangeGateDepositsCommand: CollectiveProposalChangeGateDepositsCommand
  CollectiveProposalChangeMinEthContributionCommand: CollectiveProposalChangeMinEthContributionCommand
  CollectiveProposalChangeRequireVotingSignatureCommand: CollectiveProposalChangeRequireVotingSignatureCommand
  CollectiveProposalChangeSafeThresholdCommand: CollectiveProposalChangeSafeThresholdCommand
  CollectiveProposalChangeVotingAnonymityCommand: CollectiveProposalChangeVotingAnonymityCommand
  CollectiveProposalCommand: ResolversUnionParentTypes['CollectiveProposalCommand']
  CollectiveProposalConnection: CollectiveProposalConnection
  CollectiveProposalEdge: CollectiveProposalEdge
  CollectiveProposalExecution: CollectiveProposalExecution
  CollectiveProposalLinkWalletCommand: CollectiveProposalLinkWalletCommand
  CollectiveProposalOrigin: ResolversUnionParentTypes['CollectiveProposalOrigin']
  CollectiveProposalRemoveMemberCommand: CollectiveProposalRemoveMemberCommand
  CollectiveProposalRemoveSignatorCommand: CollectiveProposalRemoveSignatorCommand
  CollectiveProposalSendEtherCommand: CollectiveProposalSendEtherCommand
  CollectiveProposalSendEtherDisbursementCommand: CollectiveProposalSendEtherDisbursementCommand
  CollectiveProposalSendEtherDisbursementCommands: CollectiveProposalSendEtherDisbursementCommands
  CollectiveProposalSendTokenCommand: CollectiveProposalSendTokenCommand
  CollectiveProposalSignature: CollectiveProposalSignature
  CollectiveProposalStatusHistory: CollectiveProposalStatusHistory
  CollectiveProposalTransferERC721Command: CollectiveProposalTransferERC721Command
  CollectiveProposalTransferERC1155Command: CollectiveProposalTransferERC1155Command
  CollectiveProposalTransferNFTCommand: CollectiveProposalTransferNFTCommand
  CollectiveProposalTransferTokensCommand: CollectiveProposalTransferTokensCommand
  CollectiveProposalUnlinkWalletCommand: CollectiveProposalUnlinkWalletCommand
  CollectiveProposalUpdateQuorumCommand: CollectiveProposalUpdateQuorumCommand
  CollectiveProposalVote: CollectiveProposalVote
  CollectiveProposalVoteConnection: CollectiveProposalVoteConnection
  CollectiveProposalVoteEdge: CollectiveProposalVoteEdge
  CollectiveStatsDatapoint: CollectiveStatsDatapoint
  CollectiveSubmissionLogInput: CollectiveSubmissionLogInput
  CollectiveTOS: CollectiveTOS
  CollectiveToken: CollectiveToken
  CollectiveUser: CollectiveUser
  CollectiveWallet: CollectiveWallet
  CollectiveWalletStatsDatapoint: CollectiveWalletStatsDatapoint
  CommandDelegation: CommandDelegation
  CommandDelegationStruct: CommandDelegationStruct
  Comment: Comment
  CommentConnection: CommentConnection
  CommentCreateInput: CommentCreateInput
  CommentEdge: CommentEdge
  CommentUpdateInput: CommentUpdateInput
  ConnectionInput: ConnectionInput
  ContractAbi: ContractAbi
  CreateCollectiveInput: CreateCollectiveInput
  CryptoWalletAuth: CryptoWalletAuth
  DJSong: DJSong
  DJState: DJState
  Date: Scalars['Date']
  DateOptions: DateOptions
  DateOptionsInput: DateOptionsInput
  DelegationResult: DelegationResult
  DelegationsPayload: DelegationsPayload
  DeviceInfoInput: DeviceInfoInput
  DiscussionBoard: DiscussionBoard
  DiscussionBoardCategoryNotificationSetting: DiscussionBoardCategoryNotificationSetting
  DiscussionBoardNotificationSetting: DiscussionBoardNotificationSetting
  DiscussionBoardThreadNotificationSetting: DiscussionBoardThreadNotificationSetting
  DynamicFileExportFieldsInput: DynamicFileExportFieldsInput
  ERC20Asset: ERC20Asset
  ERCAsset: ERCAsset
  EmailPreferences: EmailPreferences
  Engagement: ResolversUnionParentTypes['Engagement']
  EngagementConnection: EngagementConnection
  EngagementEdge: Omit<EngagementEdge, 'node'> & {
    node: ResolversParentTypes['Engagement']
  }
  EngagementSortingInput: EngagementSortingInput
  EnrichmentEmployment: EnrichmentEmployment
  EnrichmentNFT: EnrichmentNFT
  EnrichmentSocialProfile: EnrichmentSocialProfile
  EnrichmentSuggestions: EnrichmentSuggestions
  EphemeralCryptoAuth: EphemeralCryptoAuth
  Event: Event
  EventAsset: EventAsset
  EventBorder: EventBorder
  EventConnection: EventConnection
  EventCounts: EventCounts
  EventEdge: EventEdge
  EventInput: EventInput
  EventMedia: EventMedia
  EventMessage: EventMessage
  EventMessageConnection: EventMessageConnection
  EventMessageEdge: EventMessageEdge
  EventPlan: EventPlan
  EventRegistration: EventRegistration
  EventRegistrationConnection: EventRegistrationConnection
  EventRegistrationEdge: EventRegistrationEdge
  EventSeries: EventSeries
  EventSignal: EventSignal
  EventUserPlan: EventUserPlan
  EventVideoChannel: EventVideoChannel
  EventsInSeriesInput: EventsInSeriesInput
  ExternalAsset: ExternalAsset
  FeaturedSpeaker: FeaturedSpeaker
  FileExport: FileExport
  FileExportInput: FileExportInput
  FileGet: FileGet
  FileUpload: FileUpload
  Float: Scalars['Float']
  GenericMutationResponse: GenericMutationResponse
  GeolocationObject: GeolocationObject
  GeolocationObjectInput: GeolocationObjectInput
  ID: Scalars['ID']
  Int: Scalars['Int']
  JSON: Scalars['JSON']
  JobFairParticipantCompany: JobFairParticipantCompany
  Languages: Languages
  LocalFile: LocalFile
  LocalFileInput: LocalFileInput
  LoginInput: LoginInput
  LoginMutationResponse: LoginMutationResponse
  LoginWithAppleIdInput: LoginWithAppleIdInput
  MediaUpload: MediaUpload
  MemberFileExportFieldsInput: MemberFileExportFieldsInput
  MetaTag: MetaTag
  Mutation: {}
  MutationResponse:
    | ResolversParentTypes['ChangePasswordMutationResponse']
    | ResolversParentTypes['GenericMutationResponse']
    | ResolversParentTypes['LoginMutationResponse']
    | ResolversParentTypes['ReportContentMutationResponse']
    | ResolversParentTypes['ResetPasswordMutationResponse']
    | ResolversParentTypes['SignupMutationResponse']
  NFTAsset: NFTAsset
  PageInfo: PageInfo
  PostAnnouncementInput: PostAnnouncementInput
  PostEventMessageInput: PostEventMessageInput
  PreviouslyRecordedEvent: PreviouslyRecordedEvent
  ProposalCommandInput: ProposalCommandInput
  ProposalInput: ProposalInput
  Query: {}
  Question: Question
  QuestionChoice: QuestionChoice
  QuestionChoiceInput: QuestionChoiceInput
  QuestionCreationInput: QuestionCreationInput
  QuestionOptions: QuestionOptions
  QuestionOptionsInput: QuestionOptionsInput
  QuestionResults: ResolversUnionParentTypes['QuestionResults']
  ReportContentInput: ReportContentInput
  ReportContentMutationResponse: ReportContentMutationResponse
  ResetPasswordInput: ResetPasswordInput
  ResetPasswordMutationResponse: ResetPasswordMutationResponse
  SEO: SEO
  SearchResultHit: ResolversUnionParentTypes['SearchResultHit']
  SearchResults: Omit<SearchResults, 'hits'> & {
    hits: Array<ResolversParentTypes['SearchResultHit']>
  }
  SetPreferenceInput: SetPreferenceInput
  SignalInput: SignalInput
  SignupInput: SignupInput
  SignupMutationResponse: SignupMutationResponse
  SignupWithAppleIdInput: SignupWithAppleIdInput
  SiteFlag: ResolversUnionParentTypes['SiteFlag']
  SiteFlagBoolean: SiteFlagBoolean
  SiteFlagNumber: SiteFlagNumber
  SiteFlagString: SiteFlagString
  String: Scalars['String']
  SubdomainEntity: ResolversUnionParentTypes['SubdomainEntity']
  Subscription: {}
  Survey: Omit<Survey, 'surveyResults'> & {
    surveyResults: Array<ResolversParentTypes['QuestionResults']>
  }
  SurveyCreationInput: SurveyCreationInput
  SurveyInput: SurveyInput
  SurveyOptions: SurveyOptions
  SurveyOptionsInput: SurveyOptionsInput
  SyncStatus: SyncStatus
  Tag: Tag
  Thread: Thread
  ThreadAttachment: ThreadAttachment
  ThreadAttachmentInput: ThreadAttachmentInput
  ThreadConnection: ThreadConnection
  ThreadCreateInput: ThreadCreateInput
  ThreadEdge: ThreadEdge
  ThreadUpdateInput: ThreadUpdateInput
  UpdateUserInput: UpdateUserInput
  Upload: Scalars['Upload']
  User: User
  UserAssetDetailsType: ResolversUnionParentTypes['UserAssetDetailsType']
  UserAssetType: Omit<UserAssetType, 'details'> & {
    details: ResolversParentTypes['UserAssetDetailsType']
  }
  UserAssetsConnection: UserAssetsConnection
  UserAssetsEdge: UserAssetsEdge
  UserConnection: UserConnection
  UserERC20Asset: UserERC20Asset
  UserEdge: UserEdge
  UserEnrichments: UserEnrichments
  UserNFTAsset: UserNFTAsset
  UserPreference: UserPreference
  Viewer: Viewer
}>

export type AnswerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Answer'] = ResolversParentTypes['Answer']
> = ResolversObject<{
  choices?: Resolver<
    Array<ResolversTypes['AnswerChoice']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type AnswerChoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnswerChoice'] = ResolversParentTypes['AnswerChoice']
> = ResolversObject<{
  dateChoice?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  stringChoice?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export interface BigIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt'
}

export type BundledCollectiveLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BundledCollectiveLog'] = ResolversParentTypes['BundledCollectiveLog']
> = ResolversObject<{
  collectiveUsers?: Resolver<
    Array<ResolversTypes['CollectiveUser']>,
    ParentType,
    ContextType
  >
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  logs?: Resolver<
    Array<ResolversTypes['CollectiveLog']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CastVoteMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CastVoteMutationResponse'] = ResolversParentTypes['CastVoteMutationResponse']
> = ResolversObject<{
  delegationFailures?: Resolver<
    Array<ResolversTypes['DelegationResult']>,
    ParentType,
    ContextType
  >
  votes?: Resolver<
    Array<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']
> = ResolversObject<{
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  discussionBoard?: Resolver<
    ResolversTypes['DiscussionBoard'],
    ParentType,
    ContextType
  >
  governance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  notificationSettings?: Resolver<
    ResolversTypes['DiscussionBoardCategoryNotificationSetting'],
    ParentType,
    ContextType
  >
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  removable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  threads?: Resolver<
    ResolversTypes['ThreadConnection'],
    ParentType,
    ContextType,
    RequireFields<CategorythreadsArgs, 'threadConnection'>
  >
  totalThreads?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ChangePasswordMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangePasswordMutationResponse'] = ResolversParentTypes['ChangePasswordMutationResponse']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ChoiceBasedResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChoiceBasedResults'] = ResolversParentTypes['ChoiceBasedResults']
> = ResolversObject<{
  dataset?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>
  datasetPercent?: Resolver<
    Array<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  labels?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  totalAnswers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollaboratorConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollaboratorConnection'] = ResolversParentTypes['CollaboratorConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollaboratorEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollaboratorEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollaboratorEdge'] = ResolversParentTypes['CollaboratorEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Collective'] = ResolversParentTypes['Collective']
> = ResolversObject<{
  activeProposals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  announcement?: Resolver<
    Maybe<ResolversTypes['CollectiveAnnouncement']>,
    ParentType,
    ContextType
  >
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  assets?: Resolver<
    ResolversTypes['CollectiveAssetsConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectiveassetsArgs, 'assetsConnection' | 'where'>
  >
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  delegatesGrantedByViewer?: Resolver<
    Array<ResolversTypes['CommandDelegation']>,
    ParentType,
    ContextType
  >
  delegatesGrantedToViewer?: Resolver<
    Array<ResolversTypes['CommandDelegation']>,
    ParentType,
    ContextType
  >
  delegationAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  discussionBoard?: Resolver<
    ResolversTypes['DiscussionBoard'],
    ParentType,
    ContextType
  >
  engagements?: Resolver<
    ResolversTypes['EngagementConnection'],
    ParentType,
    ContextType,
    RequireFields<
      CollectiveengagementsArgs,
      'engagementConnection' | 'filters' | 'sort'
    >
  >
  erc20Assets?: Resolver<
    Array<ResolversTypes['ERC20Asset']>,
    ParentType,
    ContextType
  >
  externalAssets?: Resolver<
    Array<ResolversTypes['ExternalAsset']>,
    ParentType,
    ContextType
  >
  governanceType?: Resolver<
    ResolversTypes['CollectiveTokenType'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logs?: Resolver<
    ResolversTypes['CollectiveLogsConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectivelogsArgs, 'requestConnection' | 'where'>
  >
  memberFileExport?: Resolver<
    Maybe<ResolversTypes['FileExport']>,
    ParentType,
    ContextType,
    RequireFields<CollectivememberFileExportArgs, 'fileExportId'>
  >
  members?: Resolver<
    ResolversTypes['CollectiveMemberConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectivemembersArgs, 'memberConnection'>
  >
  membership?: Resolver<
    Maybe<ResolversTypes['CollectiveMember']>,
    ParentType,
    ContextType
  >
  membershipRequest?: Resolver<
    Maybe<ResolversTypes['CollectiveMembershipRequest']>,
    ParentType,
    ContextType
  >
  membershipRequests?: Resolver<
    Array<ResolversTypes['CollectiveMembershipRequest']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  network?: Resolver<ResolversTypes['EthNetwork'], ParentType, ContextType>
  nftAssets?: Resolver<
    Array<ResolversTypes['NFTAsset']>,
    ParentType,
    ContextType
  >
  ogImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  parameters?: Resolver<
    ResolversTypes['CollectiveParameters'],
    ParentType,
    ContextType
  >
  proposals?: Resolver<
    ResolversTypes['CollectiveProposalConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectiveproposalsArgs, 'proposalConnection'>
  >
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeThreshold?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  signators?: Resolver<
    Array<ResolversTypes['CollectiveMember']>,
    ParentType,
    ContextType
  >
  statsDatapoints?: Resolver<
    Array<ResolversTypes['CollectiveStatsDatapoint']>,
    ParentType,
    ContextType,
    Partial<CollectivestatsDatapointsArgs>
  >
  subdomain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  syncStatus?: Resolver<ResolversTypes['SyncStatus'], ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tokens?: Resolver<
    Array<ResolversTypes['CollectiveToken']>,
    ParentType,
    ContextType
  >
  totalMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalMembershipRequests?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  totalProposals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalVotingMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  treasuryBalance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  wallets?: Resolver<
    Array<ResolversTypes['CollectiveWallet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveAnnouncementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveAnnouncement'] = ResolversParentTypes['CollectiveAnnouncement']
> = ResolversObject<{
  bodyHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collective?: Resolver<ResolversTypes['Collective'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  postedBy?: Resolver<
    ResolversTypes['CollectiveMember'],
    ParentType,
    ContextType
  >
  sendPush?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveApplication'] = ResolversParentTypes['CollectiveApplication']
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  payload?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['CollectiveApplicationStatus'],
    ParentType,
    ContextType
  >
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveAsset'] = ResolversParentTypes['CollectiveAsset']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'ERCAsset' | 'ExternalAsset',
    ParentType,
    ContextType
  >
}>

export type CollectiveAssetEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveAssetEdge'] = ResolversParentTypes['CollectiveAssetEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CollectiveAsset'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveAssetPreviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveAssetPreview'] = ResolversParentTypes['CollectiveAssetPreview']
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveAssetsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveAssetsConnection'] = ResolversParentTypes['CollectiveAssetsConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveAssetEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveConnection'] = ResolversParentTypes['CollectiveConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveDemoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveDemo'] = ResolversParentTypes['CollectiveDemo']
> = ResolversObject<{
  collective?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveDraftResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveDraft'] = ResolversParentTypes['CollectiveDraft']
> = ResolversObject<{
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  customerSupport?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  depositFeeInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  disableTokenTransfers?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  enableDeposits?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  enableNewMembershipRequests?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  enableWithdraws?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  exchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  externalTokenAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  gateDeposits?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  intent?: Resolver<ResolversTypes['CollectiveIntent'], ParentType, ContextType>
  jumpableSteps?: Resolver<
    Array<ResolversTypes['InputStep']>,
    ParentType,
    ContextType
  >
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastStep?: Resolver<ResolversTypes['InputStep'], ParentType, ContextType>
  members?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  minEthContribution?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  proposalThreshold?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  quorumNeededInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  requireVotingSignature?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  subdomain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  votingAnonymity?: Resolver<
    Maybe<ResolversTypes['VotingAnonymity']>,
    ParentType,
    ContextType
  >
  withdrawFeeInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveDraftConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveDraftConnection'] = ResolversParentTypes['CollectiveDraftConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveDraftEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveDraftEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveDraftEdge'] = ResolversParentTypes['CollectiveDraftEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CollectiveDraft'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveEdge'] = ResolversParentTypes['CollectiveEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Collective'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveFaqResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveFaq'] = ResolversParentTypes['CollectiveFaq']
> = ResolversObject<{
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLog'] = ResolversParentTypes['CollectiveLog']
> = ResolversObject<{
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  collectiveUser?: Resolver<
    Maybe<ResolversTypes['CollectiveUser']>,
    ParentType,
    ContextType
  >
  data?: Resolver<
    ResolversTypes['CollectiveLogDataType'],
    ParentType,
    ContextType
  >
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  txHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnAddedOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnAddedOwner'] = ResolversParentTypes['CollectiveLogDataOnAddedOwner']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnAnnouncementChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnAnnouncementChanged'] = ResolversParentTypes['CollectiveLogDataOnAnnouncementChanged']
> = ResolversObject<{
  announcement?: Resolver<
    ResolversTypes['CollectiveAnnouncement'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnAnnouncementCreatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnAnnouncementCreated'] = ResolversParentTypes['CollectiveLogDataOnAnnouncementCreated']
> = ResolversObject<{
  announcement?: Resolver<
    ResolversTypes['CollectiveAnnouncement'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnAnnouncementDeletedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnAnnouncementDeleted'] = ResolversParentTypes['CollectiveLogDataOnAnnouncementDeleted']
> = ResolversObject<{
  announcement?: Resolver<
    ResolversTypes['CollectiveAnnouncement'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnChangedThresholdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnChangedThreshold'] = ResolversParentTypes['CollectiveLogDataOnChangedThreshold']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  threshold?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnCollectiveMembershipProposedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnCollectiveMembershipProposed'] = ResolversParentTypes['CollectiveLogDataOnCollectiveMembershipProposed']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  request?: Resolver<
    Maybe<ResolversTypes['CollectiveMembershipRequest']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnCollectiveMembershipRequestedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnCollectiveMembershipRequested'] = ResolversParentTypes['CollectiveLogDataOnCollectiveMembershipRequested']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnCollectiveSyncRequestedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnCollectiveSyncRequested'] = ResolversParentTypes['CollectiveLogDataOnCollectiveSyncRequested']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnCollectiveSyncedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnCollectiveSynced'] = ResolversParentTypes['CollectiveLogDataOnCollectiveSynced']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnDelegationsGrantedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnDelegationsGranted'] = ResolversParentTypes['CollectiveLogDataOnDelegationsGranted']
> = ResolversObject<{
  delegations?: Resolver<
    Array<ResolversTypes['CommandDelegationStruct']>,
    ParentType,
    ContextType
  >
  delegator?: Resolver<
    ResolversTypes['CollectiveUser'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnDelegationsRevokedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnDelegationsRevoked'] = ResolversParentTypes['CollectiveLogDataOnDelegationsRevoked']
> = ResolversObject<{
  delegations?: Resolver<
    Array<ResolversTypes['CommandDelegationStruct']>,
    ParentType,
    ContextType
  >
  delegator?: Resolver<
    ResolversTypes['CollectiveUser'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnDisableTokenTransfersChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnDisableTokenTransfersChanged'] = ResolversParentTypes['CollectiveLogDataOnDisableTokenTransfersChanged']
> = ResolversObject<{
  disableTokenTransfers?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnEnableDepositsChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnEnableDepositsChanged'] = ResolversParentTypes['CollectiveLogDataOnEnableDepositsChanged']
> = ResolversObject<{
  enableDeposits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnEnableNewMembershipRequestsChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnEnableNewMembershipRequestsChanged'] = ResolversParentTypes['CollectiveLogDataOnEnableNewMembershipRequestsChanged']
> = ResolversObject<{
  enableNewMembershipRequests?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnEnableWithdrawsChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnEnableWithdrawsChanged'] = ResolversParentTypes['CollectiveLogDataOnEnableWithdrawsChanged']
> = ResolversObject<{
  enableWithdraws?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnExchangeRateChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnExchangeRateChanged'] = ResolversParentTypes['CollectiveLogDataOnExchangeRateChanged']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  newRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  previousRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnExternalCollectiveJoinedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnExternalCollectiveJoined'] = ResolversParentTypes['CollectiveLogDataOnExternalCollectiveJoined']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnFeesChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnFeesChanged'] = ResolversParentTypes['CollectiveLogDataOnFeesChanged']
> = ResolversObject<{
  depositFee?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnFundsDepositedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnFundsDeposited'] = ResolversParentTypes['CollectiveLogDataOnFundsDeposited']
> = ResolversObject<{
  etherAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnGateDepositsChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnGateDepositsChanged'] = ResolversParentTypes['CollectiveLogDataOnGateDepositsChanged']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  gateDeposits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnMemberAddedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnMemberAdded'] = ResolversParentTypes['CollectiveLogDataOnMemberAdded']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  targetMember?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnMemberDepositedFundsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnMemberDepositedFunds'] = ResolversParentTypes['CollectiveLogDataOnMemberDepositedFunds']
> = ResolversObject<{
  etherAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  tokenAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnMemberRemovedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnMemberRemoved'] = ResolversParentTypes['CollectiveLogDataOnMemberRemoved']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  targetMember?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnMemberWithdrewFundsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnMemberWithdrewFunds'] = ResolversParentTypes['CollectiveLogDataOnMemberWithdrewFunds']
> = ResolversObject<{
  etherAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnMinEthContributionChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnMinEthContributionChanged'] = ResolversParentTypes['CollectiveLogDataOnMinEthContributionChanged']
> = ResolversObject<{
  contributionAmount?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnNonDepositEtherReceivedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnNonDepositEtherReceived'] = ResolversParentTypes['CollectiveLogDataOnNonDepositEtherReceived']
> = ResolversObject<{
  etherAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  sender?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnProposalCanceledResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnProposalCanceled'] = ResolversParentTypes['CollectiveLogDataOnProposalCanceled']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnProposalExecutedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnProposalExecuted'] = ResolversParentTypes['CollectiveLogDataOnProposalExecuted']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnProposalExecutionResetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnProposalExecutionReset'] = ResolversParentTypes['CollectiveLogDataOnProposalExecutionReset']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnProposalRequireVoteSignatureChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnProposalRequireVoteSignatureChanged'] = ResolversParentTypes['CollectiveLogDataOnProposalRequireVoteSignatureChanged']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  requireVotingSignature?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnProposalSignedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnProposalSigned'] = ResolversParentTypes['CollectiveLogDataOnProposalSigned']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnProposalsBatchedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnProposalsBatched'] = ResolversParentTypes['CollectiveLogDataOnProposalsBatched']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnQuorumNeededInPercentageChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnQuorumNeededInPercentageChanged'] = ResolversParentTypes['CollectiveLogDataOnQuorumNeededInPercentageChanged']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  quorumNeededInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnRemovedOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnRemovedOwner'] = ResolversParentTypes['CollectiveLogDataOnRemovedOwner']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnTransferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnTransfer'] = ResolversParentTypes['CollectiveLogDataOnTransfer']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<
    Maybe<ResolversTypes['CollectiveToken']>,
    ParentType,
    ContextType
  >
  tokenId?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnTransferBatchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnTransferBatch'] = ResolversParentTypes['CollectiveLogDataOnTransferBatch']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<
    Maybe<ResolversTypes['CollectiveToken']>,
    ParentType,
    ContextType
  >
  tokenIds?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>
  values?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnTransferSingleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnTransferSingle'] = ResolversParentTypes['CollectiveLogDataOnTransferSingle']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<
    Maybe<ResolversTypes['CollectiveToken']>,
    ParentType,
    ContextType
  >
  tokenId?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnVoteCastResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnVoteCast'] = ResolversParentTypes['CollectiveLogDataOnVoteCast']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  masterVote?: Resolver<
    Maybe<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['CollectiveMember'], ParentType, ContextType>
  vote?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnVoteChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnVoteChanged'] = ResolversParentTypes['CollectiveLogDataOnVoteChanged']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  masterVote?: Resolver<
    Maybe<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  newVote?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType
  >
  previousVote?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['CollectiveMember'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnVoteInvalidatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnVoteInvalidated'] = ResolversParentTypes['CollectiveLogDataOnVoteInvalidated']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  invalidatedVote?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType
  >
  oldWeight?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['CollectiveMember'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnVoteWeightUpdatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnVoteWeightUpdated'] = ResolversParentTypes['CollectiveLogDataOnVoteWeightUpdated']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  newWeight?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  oldWeight?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  updatedVote?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['CollectiveMember'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnVotingAnonymityChangedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnVotingAnonymityChanged'] = ResolversParentTypes['CollectiveLogDataOnVotingAnonymityChanged']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  votingAnonymity?: Resolver<
    ResolversTypes['VotingAnonymity'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnWalletLinkedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnWalletLinked'] = ResolversParentTypes['CollectiveLogDataOnWalletLinked']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  blockchain?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  nickname?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataOnWalletUnlinkedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataOnWalletUnlinked'] = ResolversParentTypes['CollectiveLogDataOnWalletUnlinked']
> = ResolversObject<{
  eventType?: Resolver<
    ResolversTypes['CollectiveLogEventType'],
    ParentType,
    ContextType
  >
  walletId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogDataTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogDataType'] = ResolversParentTypes['CollectiveLogDataType']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    | 'CollectiveLogDataOnAddedOwner'
    | 'CollectiveLogDataOnAnnouncementChanged'
    | 'CollectiveLogDataOnAnnouncementCreated'
    | 'CollectiveLogDataOnAnnouncementDeleted'
    | 'CollectiveLogDataOnChangedThreshold'
    | 'CollectiveLogDataOnCollectiveMembershipProposed'
    | 'CollectiveLogDataOnCollectiveMembershipRequested'
    | 'CollectiveLogDataOnCollectiveSyncRequested'
    | 'CollectiveLogDataOnCollectiveSynced'
    | 'CollectiveLogDataOnDelegationsGranted'
    | 'CollectiveLogDataOnDelegationsRevoked'
    | 'CollectiveLogDataOnDisableTokenTransfersChanged'
    | 'CollectiveLogDataOnEnableDepositsChanged'
    | 'CollectiveLogDataOnEnableNewMembershipRequestsChanged'
    | 'CollectiveLogDataOnEnableWithdrawsChanged'
    | 'CollectiveLogDataOnExchangeRateChanged'
    | 'CollectiveLogDataOnExternalCollectiveJoined'
    | 'CollectiveLogDataOnFeesChanged'
    | 'CollectiveLogDataOnFundsDeposited'
    | 'CollectiveLogDataOnGateDepositsChanged'
    | 'CollectiveLogDataOnMemberAdded'
    | 'CollectiveLogDataOnMemberDepositedFunds'
    | 'CollectiveLogDataOnMemberRemoved'
    | 'CollectiveLogDataOnMemberWithdrewFunds'
    | 'CollectiveLogDataOnMinEthContributionChanged'
    | 'CollectiveLogDataOnNonDepositEtherReceived'
    | 'CollectiveLogDataOnProposalCanceled'
    | 'CollectiveLogDataOnProposalExecuted'
    | 'CollectiveLogDataOnProposalExecutionReset'
    | 'CollectiveLogDataOnProposalRequireVoteSignatureChanged'
    | 'CollectiveLogDataOnProposalSigned'
    | 'CollectiveLogDataOnProposalsBatched'
    | 'CollectiveLogDataOnQuorumNeededInPercentageChanged'
    | 'CollectiveLogDataOnRemovedOwner'
    | 'CollectiveLogDataOnTransfer'
    | 'CollectiveLogDataOnTransferBatch'
    | 'CollectiveLogDataOnTransferSingle'
    | 'CollectiveLogDataOnVoteCast'
    | 'CollectiveLogDataOnVoteChanged'
    | 'CollectiveLogDataOnVoteInvalidated'
    | 'CollectiveLogDataOnVoteWeightUpdated'
    | 'CollectiveLogDataOnVotingAnonymityChanged'
    | 'CollectiveLogDataOnWalletLinked'
    | 'CollectiveLogDataOnWalletUnlinked',
    ParentType,
    ContextType
  >
}>

export type CollectiveLogsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogsConnection'] = ResolversParentTypes['CollectiveLogsConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveLogsEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveLogsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveLogsEdge'] = ResolversParentTypes['CollectiveLogsEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<
    ResolversTypes['BundledCollectiveLog'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveMemberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveMember'] = ResolversParentTypes['CollectiveMember']
> = ResolversObject<{
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  bannedFromEvents?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  collective?: Resolver<ResolversTypes['Collective'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  percentOfTotalTokenSupply?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  privileged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  proposalsProposed?: Resolver<
    ResolversTypes['CollectiveProposalConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectiveMemberproposalsProposedArgs, 'proposalConnection'>
  >
  proposalsVoted?: Resolver<
    Array<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  totalTokens?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['CollectiveUser'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveMemberConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveMemberConnection'] = ResolversParentTypes['CollectiveMemberConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveMemberEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveMemberEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveMemberEdge'] = ResolversParentTypes['CollectiveMemberEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CollectiveMember'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveMembershipRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveMembershipRequest'] = ResolversParentTypes['CollectiveMembershipRequest']
> = ResolversObject<{
  collective?: Resolver<ResolversTypes['Collective'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  membershipProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['CollectiveMembershipRequestStatus'],
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['CollectiveUser'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveParametersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveParameters'] = ResolversParentTypes['CollectiveParameters']
> = ResolversObject<{
  depositFeeInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  disableTokenTransfers?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  enableDeposits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  enableNewMembershipRequests?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  enableWithdraws?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  exchangeRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  gateDeposits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  minEthContribution?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  proposalThreshold?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  quorumNeededInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  requireVotingSignature?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  votingAnonymity?: Resolver<
    ResolversTypes['VotingAnonymity'],
    ParentType,
    ContextType
  >
  votingDelayInMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  votingPeriodInMinutes?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  withdrawFeeInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposal'] = ResolversParentTypes['CollectiveProposal']
> = ResolversObject<{
  batchProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  batchedProposals?: Resolver<
    Array<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  canVote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  collective?: Resolver<ResolversTypes['Collective'], ParentType, ContextType>
  commands?: Resolver<
    Array<ResolversTypes['CollectiveProposalCommand']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  descriptionHtml?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eligibleDelegations?: Resolver<
    Array<ResolversTypes['CollectiveMember']>,
    ParentType,
    ContextType
  >
  endTime?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  estimatedExecutionGas?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  execution?: Resolver<
    Maybe<ResolversTypes['CollectiveProposalExecution']>,
    ParentType,
    ContextType
  >
  hasOnChainCommands?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAuthor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isBatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPartOfBatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isQuorumReached?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isShortForm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isUsingMatchingWallet?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isVotable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  myVote?: Resolver<Maybe<ResolversTypes['VoteType']>, ParentType, ContextType>
  ogImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  origin?: Resolver<
    Maybe<ResolversTypes['CollectiveProposalOrigin']>,
    ParentType,
    ContextType
  >
  proposedBy?: Resolver<
    ResolversTypes['CollectiveMember'],
    ParentType,
    ContextType
  >
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  quorumNeeded?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  requireVotingSignature?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  signatures?: Resolver<
    Array<ResolversTypes['CollectiveProposalSignature']>,
    ParentType,
    ContextType
  >
  startTime?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['CollectiveProposalStatus'],
    ParentType,
    ContextType
  >
  statusHistory?: Resolver<
    Array<ResolversTypes['CollectiveProposalStatusHistory']>,
    ParentType,
    ContextType
  >
  survey?: Resolver<Maybe<ResolversTypes['Survey']>, ParentType, ContextType>
  thread?: Resolver<Maybe<ResolversTypes['Thread']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalVoters?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  totalVotersAbstain?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  totalVotersAgainst?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  totalVotersFor?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  totalVotes?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  type?: Resolver<
    ResolversTypes['CollectiveProposalType'],
    ParentType,
    ContextType
  >
  votersAbstain?: Resolver<
    Array<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  votersAgainst?: Resolver<
    Array<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  votersFor?: Resolver<
    Array<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  votes?: Resolver<
    ResolversTypes['CollectiveProposalVoteConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectiveProposalvotesArgs, 'voteConnection'>
  >
  votesAbstain?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  votesAgainst?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  votesFor?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  votingAnonymity?: Resolver<
    ResolversTypes['VotingAnonymity'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalAddMemberCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalAddMemberCommand'] = ResolversParentTypes['CollectiveProposalAddMemberCommand']
> = ResolversObject<{
  memberAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalAddSignatorCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalAddSignatorCommand'] = ResolversParentTypes['CollectiveProposalAddSignatorCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  signatorAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalCallRemoteCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalCallRemoteCommand'] = ResolversParentTypes['CollectiveProposalCallRemoteCommand']
> = ResolversObject<{
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeDisableTokenTransfersCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeDisableTokenTransfersCommand'] = ResolversParentTypes['CollectiveProposalChangeDisableTokenTransfersCommand']
> = ResolversObject<{
  disableTokenTransfers?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeEnableDepositsCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeEnableDepositsCommand'] = ResolversParentTypes['CollectiveProposalChangeEnableDepositsCommand']
> = ResolversObject<{
  enableDeposits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeEnableNewMembershipRequestsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeEnableNewMembershipRequests'] = ResolversParentTypes['CollectiveProposalChangeEnableNewMembershipRequests']
> = ResolversObject<{
  enableNewMembershipRequests?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeEnableWithdrawsCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeEnableWithdrawsCommand'] = ResolversParentTypes['CollectiveProposalChangeEnableWithdrawsCommand']
> = ResolversObject<{
  enableWithdraws?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeExchangeRateCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeExchangeRateCommand'] = ResolversParentTypes['CollectiveProposalChangeExchangeRateCommand']
> = ResolversObject<{
  exchangeRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeGateDepositsCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeGateDepositsCommand'] = ResolversParentTypes['CollectiveProposalChangeGateDepositsCommand']
> = ResolversObject<{
  gateDeposits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeMinEthContributionCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeMinEthContributionCommand'] = ResolversParentTypes['CollectiveProposalChangeMinEthContributionCommand']
> = ResolversObject<{
  minEthContribution?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeRequireVotingSignatureCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeRequireVotingSignatureCommand'] = ResolversParentTypes['CollectiveProposalChangeRequireVotingSignatureCommand']
> = ResolversObject<{
  requireVotingSignature?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeSafeThresholdCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeSafeThresholdCommand'] = ResolversParentTypes['CollectiveProposalChangeSafeThresholdCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  safeThreshold?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalChangeVotingAnonymityCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalChangeVotingAnonymityCommand'] = ResolversParentTypes['CollectiveProposalChangeVotingAnonymityCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  votingAnonymity?: Resolver<
    ResolversTypes['VotingAnonymity'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalCommand'] = ResolversParentTypes['CollectiveProposalCommand']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    | 'CollectiveProposalAddMemberCommand'
    | 'CollectiveProposalAddSignatorCommand'
    | 'CollectiveProposalCallRemoteCommand'
    | 'CollectiveProposalChangeDisableTokenTransfersCommand'
    | 'CollectiveProposalChangeEnableDepositsCommand'
    | 'CollectiveProposalChangeEnableNewMembershipRequests'
    | 'CollectiveProposalChangeEnableWithdrawsCommand'
    | 'CollectiveProposalChangeExchangeRateCommand'
    | 'CollectiveProposalChangeGateDepositsCommand'
    | 'CollectiveProposalChangeMinEthContributionCommand'
    | 'CollectiveProposalChangeRequireVotingSignatureCommand'
    | 'CollectiveProposalChangeSafeThresholdCommand'
    | 'CollectiveProposalChangeVotingAnonymityCommand'
    | 'CollectiveProposalLinkWalletCommand'
    | 'CollectiveProposalRemoveMemberCommand'
    | 'CollectiveProposalRemoveSignatorCommand'
    | 'CollectiveProposalSendEtherCommand'
    | 'CollectiveProposalSendEtherDisbursementCommand'
    | 'CollectiveProposalSendEtherDisbursementCommands'
    | 'CollectiveProposalSendTokenCommand'
    | 'CollectiveProposalTransferERC721Command'
    | 'CollectiveProposalTransferERC1155Command'
    | 'CollectiveProposalTransferNFTCommand'
    | 'CollectiveProposalTransferTokensCommand'
    | 'CollectiveProposalUnlinkWalletCommand'
    | 'CollectiveProposalUpdateQuorumCommand',
    ParentType,
    ContextType
  >
}>

export type CollectiveProposalConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalConnection'] = ResolversParentTypes['CollectiveProposalConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveProposalEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalEdge'] = ResolversParentTypes['CollectiveProposalEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CollectiveProposal'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalExecutionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalExecution'] = ResolversParentTypes['CollectiveProposalExecution']
> = ResolversObject<{
  blockNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  errorMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CollectiveProposalExecutionStatus'],
    ParentType,
    ContextType
  >
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalLinkWalletCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalLinkWalletCommand'] = ResolversParentTypes['CollectiveProposalLinkWalletCommand']
> = ResolversObject<{
  blockchain?: Resolver<ResolversTypes['Blockchain'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  walletNickname?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalOriginResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalOrigin'] = ResolversParentTypes['CollectiveProposalOrigin']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'CollectiveMembershipRequest',
    ParentType,
    ContextType
  >
}>

export type CollectiveProposalRemoveMemberCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalRemoveMemberCommand'] = ResolversParentTypes['CollectiveProposalRemoveMemberCommand']
> = ResolversObject<{
  memberAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalRemoveSignatorCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalRemoveSignatorCommand'] = ResolversParentTypes['CollectiveProposalRemoveSignatorCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  signatorAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalSendEtherCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalSendEtherCommand'] = ResolversParentTypes['CollectiveProposalSendEtherCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalSendEtherDisbursementCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalSendEtherDisbursementCommand'] = ResolversParentTypes['CollectiveProposalSendEtherDisbursementCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalSendEtherDisbursementCommandsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalSendEtherDisbursementCommands'] = ResolversParentTypes['CollectiveProposalSendEtherDisbursementCommands']
> = ResolversObject<{
  commands?: Resolver<
    Array<ResolversTypes['CollectiveProposalSendEtherDisbursementCommand']>,
    ParentType,
    ContextType
  >
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalSendTokenCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalSendTokenCommand'] = ResolversParentTypes['CollectiveProposalSendTokenCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalSignatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalSignature'] = ResolversParentTypes['CollectiveProposalSignature']
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['CollectiveUser'], ParentType, ContextType>
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalStatusHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalStatusHistory'] = ResolversParentTypes['CollectiveProposalStatusHistory']
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  status?: Resolver<
    ResolversTypes['CollectiveProposalStatus'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalTransferERC721CommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalTransferERC721Command'] = ResolversParentTypes['CollectiveProposalTransferERC721Command']
> = ResolversObject<{
  asset?: Resolver<
    Maybe<ResolversTypes['CollectiveAssetPreview']>,
    ParentType,
    ContextType
  >
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalTransferERC1155CommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalTransferERC1155Command'] = ResolversParentTypes['CollectiveProposalTransferERC1155Command']
> = ResolversObject<{
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  asset?: Resolver<
    Maybe<ResolversTypes['CollectiveAssetPreview']>,
    ParentType,
    ContextType
  >
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalTransferNFTCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalTransferNFTCommand'] = ResolversParentTypes['CollectiveProposalTransferNFTCommand']
> = ResolversObject<{
  asset?: Resolver<
    Maybe<ResolversTypes['CollectiveAssetPreview']>,
    ParentType,
    ContextType
  >
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalTransferTokensCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalTransferTokensCommand'] = ResolversParentTypes['CollectiveProposalTransferTokensCommand']
> = ResolversObject<{
  asset?: Resolver<
    Maybe<ResolversTypes['CollectiveAssetPreview']>,
    ParentType,
    ContextType
  >
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalUnlinkWalletCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalUnlinkWalletCommand'] = ResolversParentTypes['CollectiveProposalUnlinkWalletCommand']
> = ResolversObject<{
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  walletId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalUpdateQuorumCommandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalUpdateQuorumCommand'] = ResolversParentTypes['CollectiveProposalUpdateQuorumCommand']
> = ResolversObject<{
  quorumNeededInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  safeInstructions?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalVoteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalVote'] = ResolversParentTypes['CollectiveProposalVote']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  masterVote?: Resolver<
    Maybe<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  member?: Resolver<ResolversTypes['CollectiveMember'], ParentType, ContextType>
  proposal?: Resolver<
    ResolversTypes['CollectiveProposal'],
    ParentType,
    ContextType
  >
  signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  signatureUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  surveyAnswers?: Resolver<
    Maybe<Array<ResolversTypes['Answer']>>,
    ParentType,
    ContextType
  >
  type?: Resolver<ResolversTypes['VoteType'], ParentType, ContextType>
  weight?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalVoteConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalVoteConnection'] = ResolversParentTypes['CollectiveProposalVoteConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CollectiveProposalVoteEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveProposalVoteEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveProposalVoteEdge'] = ResolversParentTypes['CollectiveProposalVoteEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveStatsDatapointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveStatsDatapoint'] = ResolversParentTypes['CollectiveStatsDatapoint']
> = ResolversObject<{
  collective?: Resolver<ResolversTypes['Collective'], ParentType, ContextType>
  estimatedTotalValueEth?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  estimatedTotalValueUSD?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  time?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  totalMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalProposals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveTOSResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveTOS'] = ResolversParentTypes['CollectiveTOS']
> = ResolversObject<{
  collectiveId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSigned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  tosHtml?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveToken'] = ResolversParentTypes['CollectiveToken']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contractType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isMainToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalSupply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  totalVotingSupply?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  type?: Resolver<
    ResolversTypes['CollectiveTokenType'],
    ParentType,
    ContextType
  >
  voteWeight?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveUser'] = ResolversParentTypes['CollectiveUser']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectives?: Resolver<
    Array<ResolversTypes['Collective']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  platformUser?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType
  >
  proposalsProposed?: Resolver<
    ResolversTypes['CollectiveProposalConnection'],
    ParentType,
    ContextType,
    RequireFields<CollectiveUserproposalsProposedArgs, 'proposalConnection'>
  >
  proposalsVoted?: Resolver<
    Array<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveWalletResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveWallet'] = ResolversParentTypes['CollectiveWallet']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isMainWallet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  statsDatapoints?: Resolver<
    Array<ResolversTypes['CollectiveWalletStatsDatapoint']>,
    ParentType,
    ContextType,
    Partial<CollectiveWalletstatsDatapointsArgs>
  >
  type?: Resolver<ResolversTypes['WalletType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CollectiveWalletStatsDatapointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CollectiveWalletStatsDatapoint'] = ResolversParentTypes['CollectiveWalletStatsDatapoint']
> = ResolversObject<{
  conversionRateUSD?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  estimatedNFTValueEth?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  estimatedNFTValueUSD?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  estimatedTokensValueEth?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  estimatedTokensValueUSD?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  estimatedTotalValueEth?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  estimatedTotalValueUSD?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >
  estimatedValueEth?: Resolver<
    ResolversTypes['BigInt'],
    ParentType,
    ContextType
  >
  estimatedValueUSD?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  time?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  wallet?: Resolver<ResolversTypes['CollectiveWallet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CommandDelegationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommandDelegation'] = ResolversParentTypes['CommandDelegation']
> = ResolversObject<{
  collectiveMember?: Resolver<
    ResolversTypes['CollectiveMember'],
    ParentType,
    ContextType
  >
  command?: Resolver<
    Maybe<ResolversTypes['ProposalCommandType']>,
    ParentType,
    ContextType
  >
  maxValue?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  surveys?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  wildcard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CommandDelegationStructResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommandDelegationStruct'] = ResolversParentTypes['CommandDelegationStruct']
> = ResolversObject<{
  command?: Resolver<
    Maybe<ResolversTypes['ProposalCommandType']>,
    ParentType,
    ContextType
  >
  delegatee?: Resolver<
    ResolversTypes['CollectiveUser'],
    ParentType,
    ContextType
  >
  maxValue?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>
  surveys?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  wildcard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']
> = ResolversObject<{
  attachments?: Resolver<
    Array<ResolversTypes['ThreadAttachment']>,
    ParentType,
    ContextType
  >
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  comments?: Resolver<
    ResolversTypes['CommentConnection'],
    ParentType,
    ContextType,
    RequireFields<CommentcommentsArgs, 'commentConnection'>
  >
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  parentComment?: Resolver<
    Maybe<ResolversTypes['Comment']>,
    ParentType,
    ContextType
  >
  parentThread?: Resolver<
    Maybe<ResolversTypes['Thread']>,
    ParentType,
    ContextType
  >
  replyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ThreadType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CommentConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommentConnection'] = ResolversParentTypes['CommentConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['CommentEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type CommentEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommentEdge'] = ResolversParentTypes['CommentEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Comment'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ContractAbiResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractAbi'] = ResolversParentTypes['ContractAbi']
> = ResolversObject<{
  abi?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  network?: Resolver<ResolversTypes['EthNetwork'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DJSongResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DJSong'] = ResolversParentTypes['DJSong']
> = ResolversObject<{
  artist?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DJStateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DJState'] = ResolversParentTypes['DJState']
> = ResolversObject<{
  nowPlayingSongId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  playState?: Resolver<ResolversTypes['DJPlayState'], ParentType, ContextType>
  volume?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date'
}

export type DateOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateOptions'] = ResolversParentTypes['DateOptions']
> = ResolversObject<{
  includeTime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  max?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  min?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DelegationResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DelegationResult'] = ResolversParentTypes['DelegationResult']
> = ResolversObject<{
  collectiveMember?: Resolver<
    ResolversTypes['CollectiveMember'],
    ParentType,
    ContextType
  >
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DiscussionBoardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscussionBoard'] = ResolversParentTypes['DiscussionBoard']
> = ResolversObject<{
  categories?: Resolver<
    Array<ResolversTypes['Category']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  notificationSettings?: Resolver<
    ResolversTypes['DiscussionBoardNotificationSetting'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DiscussionBoardCategoryNotificationSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscussionBoardCategoryNotificationSetting'] = ResolversParentTypes['DiscussionBoardCategoryNotificationSetting']
> = ResolversObject<{
  allowNotifications?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DiscussionBoardNotificationSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscussionBoardNotificationSetting'] = ResolversParentTypes['DiscussionBoardNotificationSetting']
> = ResolversObject<{
  allowGlobalNotifications?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type DiscussionBoardThreadNotificationSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DiscussionBoardThreadNotificationSetting'] = ResolversParentTypes['DiscussionBoardThreadNotificationSetting']
> = ResolversObject<{
  allowNotifications?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ERC20AssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ERC20Asset'] = ResolversParentTypes['ERC20Asset']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  estimatedValueEth?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  estimatedValueUSD?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastSynced?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  wallet?: Resolver<ResolversTypes['CollectiveWallet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ERCAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ERCAsset'] = ResolversParentTypes['ERCAsset']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  contractName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contractType?: Resolver<
    ResolversTypes['ContractTypes'],
    ParentType,
    ContextType
  >
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  estimatedValueEth?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  estimatedValueUSD?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  externalUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastSynced?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mediaUrls?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tokenId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<
    Maybe<ResolversTypes['CollectiveUser']>,
    ParentType,
    ContextType
  >
  wallet?: Resolver<
    Maybe<ResolversTypes['CollectiveWallet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EmailPreferencesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailPreferences'] = ResolversParentTypes['EmailPreferences']
> = ResolversObject<{
  allowedTypes?: Resolver<
    Array<ResolversTypes['EmailType']>,
    ParentType,
    ContextType
  >
  truncatedEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  unsubscribedFromAll?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EngagementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Engagement'] = ResolversParentTypes['Engagement']
> = ResolversObject<{
  __resolveType: TypeResolveFn<'Event', ParentType, ContextType>
}>

export type EngagementConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementConnection'] = ResolversParentTypes['EngagementConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['EngagementEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EngagementEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EngagementEdge'] = ResolversParentTypes['EngagementEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Engagement'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EnrichmentEmploymentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrichmentEmployment'] = ResolversParentTypes['EnrichmentEmployment']
> = ResolversObject<{
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isPrimary?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EnrichmentNFTResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrichmentNFT'] = ResolversParentTypes['EnrichmentNFT']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  collectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EnrichmentSocialProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrichmentSocialProfile'] = ResolversParentTypes['EnrichmentSocialProfile']
> = ResolversObject<{
  network?: Resolver<
    Maybe<ResolversTypes['SocialNetwork']>,
    ParentType,
    ContextType
  >
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EnrichmentSuggestionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EnrichmentSuggestions'] = ResolversParentTypes['EnrichmentSuggestions']
> = ResolversObject<{
  nfts?: Resolver<
    Maybe<Array<ResolversTypes['EnrichmentNFT']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EphemeralCryptoAuthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EphemeralCryptoAuth'] = ResolversParentTypes['EphemeralCryptoAuth']
> = ResolversObject<{
  authMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']
> = ResolversObject<{
  actualStartTime?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  assets?: Resolver<
    Array<ResolversTypes['EventAsset']>,
    ParentType,
    ContextType
  >
  canEdit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  canJoinNotice?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  canJoinVideo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  capacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  collective?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType
  >
  counts?: Resolver<ResolversTypes['EventCounts'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  discoverable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  format?: Resolver<ResolversTypes['EventFormat'], ParentType, ContextType>
  formatDescription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  formatLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  geolocation?: Resolver<
    Maybe<ResolversTypes['GeolocationObject']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  introductionTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  isCurrentlySpeaking?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  isOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPrivileged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  joinLobbyInMute?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  media?: Resolver<ResolversTypes['EventMedia'], ParentType, ContextType>
  messages?: Resolver<
    Maybe<ResolversTypes['EventMessageConnection']>,
    ParentType,
    ContextType,
    Partial<EventmessagesArgs>
  >
  ogImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  onlineGuestCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  onlineGuestIds?: Resolver<
    Array<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  passive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  plan?: Resolver<Maybe<ResolversTypes['EventPlan']>, ParentType, ContextType>
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recording?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  registrations?: Resolver<
    ResolversTypes['EventRegistrationConnection'],
    ParentType,
    ContextType,
    RequireFields<EventregistrationsArgs, 'registrationConnection'>
  >
  rsvp?: Resolver<
    Maybe<ResolversTypes['EventRegistrationRSVP']>,
    ParentType,
    ContextType
  >
  series?: Resolver<
    Maybe<ResolversTypes['EventSeries']>,
    ParentType,
    ContextType
  >
  shareable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  speakers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  startTime?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventState'], ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  userPlan?: Resolver<
    Maybe<ResolversTypes['EventUserPlan']>,
    ParentType,
    ContextType
  >
  waitlisted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventAsset'] = ResolversParentTypes['EventAsset']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['EventAssetType'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventBorderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventBorder'] = ResolversParentTypes['EventBorder']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lobbyHeightRatio?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  lobbyTopUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  meetingBottomUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  meetingHeightRatio?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  meetingTopUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventConnection'] = ResolversParentTypes['EventConnection']
> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['EventEdge']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventCountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventCounts'] = ResolversParentTypes['EventCounts']
> = ResolversObject<{
  attended?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  attending?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  matched?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maybeAttending?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  notAttending?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  passive?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventEdge'] = ResolversParentTypes['EventEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventMediaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventMedia'] = ResolversParentTypes['EventMedia']
> = ResolversObject<{
  borders?: Resolver<
    Array<ResolversTypes['EventBorder']>,
    ParentType,
    ContextType
  >
  djPlaylist?: Resolver<
    Array<ResolversTypes['DJSong']>,
    ParentType,
    ContextType
  >
  djUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  enableBorders?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  enableDJ?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventMessage'] = ResolversParentTypes['EventMessage']
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EventMessageType'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventMessageConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventMessageConnection'] = ResolversParentTypes['EventMessageConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['EventMessageEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventMessageEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventMessageEdge'] = ResolversParentTypes['EventMessageEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['EventMessage'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventPlan'] = ResolversParentTypes['EventPlan']
> = ResolversObject<{
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  layout?: Resolver<
    ResolversTypes['BroadcastVideoLayout'],
    ParentType,
    ContextType
  >
  mainChannelSessionId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >
  speakers?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventRegistrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventRegistration'] = ResolversParentTypes['EventRegistration']
> = ResolversObject<{
  attended?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  banned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  online?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  passive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  role?: Resolver<
    ResolversTypes['EventRegistrationRole'],
    ParentType,
    ContextType
  >
  rsvp?: Resolver<
    ResolversTypes['EventRegistrationRSVP'],
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  waitlisted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventRegistrationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventRegistrationConnection'] = ResolversParentTypes['EventRegistrationConnection']
> = ResolversObject<{
  edges?: Resolver<
    Array<ResolversTypes['EventRegistrationEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventRegistrationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventRegistrationEdge'] = ResolversParentTypes['EventRegistrationEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['EventRegistration'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventSeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventSeries'] = ResolversParentTypes['EventSeries']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventSignalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventSignal'] = ResolversParentTypes['EventSignal']
> = ResolversObject<{
  command?: Resolver<ResolversTypes['SignalCommand'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  sender?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventUserPlanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventUserPlan'] = ResolversParentTypes['EventUserPlan']
> = ResolversObject<{
  currentChannel?: Resolver<
    ResolversTypes['EventVideoChannel'],
    ParentType,
    ContextType
  >
  currentLayout?: Resolver<
    ResolversTypes['BroadcastVideoLayout'],
    ParentType,
    ContextType
  >
  currentSpeakers?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType
  >
  fetchedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  lobbyChannel?: Resolver<
    ResolversTypes['EventVideoChannel'],
    ParentType,
    ContextType
  >
  mainChannel?: Resolver<
    ResolversTypes['EventVideoChannel'],
    ParentType,
    ContextType
  >
  nextRefecthAt?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type EventVideoChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventVideoChannel'] = ResolversParentTypes['EventVideoChannel']
> = ResolversObject<{
  provider?: Resolver<ResolversTypes['VideoProvider'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ExternalAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExternalAsset'] = ResolversParentTypes['ExternalAsset']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  estimatedValueUSD?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  externalUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  formattedAmount?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastSynced?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['ExternalAssetType'], ParentType, ContextType>
  wallet?: Resolver<
    Maybe<ResolversTypes['CollectiveWallet']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type FeaturedSpeakerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedSpeaker'] = ResolversParentTypes['FeaturedSpeaker']
> = ResolversObject<{
  event?: Resolver<ResolversTypes['Event'], ParentType, ContextType>
  speaker?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type FileExportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FileExport'] = ResolversParentTypes['FileExport']
> = ResolversObject<{
  errorMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  file?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['FileExportStatus'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['FileExportType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type FileGetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FileGet'] = ResolversParentTypes['FileGet']
> = ResolversObject<{
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type FileUploadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FileUpload'] = ResolversParentTypes['FileUpload']
> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type GenericMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenericMutationResponse'] = ResolversParentTypes['GenericMutationResponse']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type GeolocationObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeolocationObject'] = ResolversParentTypes['GeolocationObject']
> = ResolversObject<{
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export interface JSONScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type JobFairParticipantCompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobFairParticipantCompany'] = ResolversParentTypes['JobFairParticipantCompany']
> = ResolversObject<{
  lookingToHire?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type LanguagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Languages'] = ResolversParentTypes['Languages']
> = ResolversObject<{
  en?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type LocalFileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocalFile'] = ResolversParentTypes['LocalFile']
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type LoginMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoginMutationResponse'] = ResolversParentTypes['LoginMutationResponse']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  jwtToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type MediaUploadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MediaUpload'] = ResolversParentTypes['MediaUpload']
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  mediaUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  thumbUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['MediaUploadType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type MetaTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetaTag'] = ResolversParentTypes['MetaTag']
> = ResolversObject<{
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = ResolversObject<{
  agreeToCollectiveTos?: Resolver<
    Maybe<ResolversTypes['CollectiveTOS']>,
    ParentType,
    ContextType,
    RequireFields<MutationagreeToCollectiveTosArgs, 'type'>
  >
  answerCollectiveProposalSurvey?: Resolver<
    ResolversTypes['CollectiveProposalVote'],
    ParentType,
    ContextType,
    RequireFields<MutationanswerCollectiveProposalSurveyArgs, 'survey'>
  >
  archiveCategory?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<MutationarchiveCategoryArgs, 'archived' | 'categoryId'>
  >
  authCrypto?: Resolver<
    Maybe<ResolversTypes['Viewer']>,
    ParentType,
    ContextType,
    RequireFields<MutationauthCryptoArgs, 'address' | 'signature'>
  >
  banAttendee?: Resolver<
    Maybe<ResolversTypes['EventRegistration']>,
    ParentType,
    ContextType,
    RequireFields<MutationbanAttendeeArgs, 'eventId' | 'userId'>
  >
  banMemberFromEvents?: Resolver<
    Maybe<ResolversTypes['CollectiveMember']>,
    ParentType,
    ContextType,
    RequireFields<MutationbanMemberFromEventsArgs, 'collectiveId' | 'userId'>
  >
  batchProposals?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationbatchProposalsArgs, 'proposalIds'>
  >
  cancelProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationcancelProposalArgs, 'proposalId'>
  >
  castVote?: Resolver<
    Maybe<ResolversTypes['CollectiveProposalVote']>,
    ParentType,
    ContextType,
    RequireFields<MutationcastVoteArgs, 'vote'>
  >
  castVoteWithDelegations?: Resolver<
    Maybe<ResolversTypes['CastVoteMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationcastVoteWithDelegationsArgs, 'vote'>
  >
  changePassword?: Resolver<
    Maybe<ResolversTypes['ChangePasswordMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationchangePasswordArgs, 'password'>
  >
  createCategory?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<
      MutationcreateCategoryArgs,
      'categoryDetails' | 'discussionBoardId'
    >
  >
  createCollective?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateCollectiveArgs, 'input'>
  >
  createCollectiveDraft?: Resolver<
    Maybe<ResolversTypes['CollectiveDraft']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateCollectiveDraftArgs, 'input'>
  >
  createComment?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateCommentArgs, 'commentDetails' | 'threadId'>
  >
  createEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateEventArgs, 'event'>
  >
  createMemberFileExport?: Resolver<
    ResolversTypes['FileExport'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateMemberFileExportArgs, 'collectiveId' | 'where'>
  >
  createProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationcreateProposalArgs, 'collectiveId' | 'proposal'>
  >
  createThread?: Resolver<
    ResolversTypes['Thread'],
    ParentType,
    ContextType,
    RequireFields<MutationcreateThreadArgs, 'categoryId' | 'threadDetails'>
  >
  deleteAccount?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType
  >
  deleteAnnouncement?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteAnnouncementArgs, 'id'>
  >
  deleteCategory?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteCategoryArgs, 'categoryId'>
  >
  deleteCollectiveDraft?: Resolver<
    Maybe<ResolversTypes['CollectiveDraft']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteCollectiveDraftArgs, 'collectiveDraftId'>
  >
  deleteEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteEventArgs, 'eventId'>
  >
  deleteThread?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationdeleteThreadArgs, 'threadId'>
  >
  editAnnouncement?: Resolver<
    Maybe<ResolversTypes['CollectiveAnnouncement']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationeditAnnouncementArgs,
      'announcement' | 'announcementId'
    >
  >
  editEventAsset?: Resolver<
    ResolversTypes['EventAsset'],
    ParentType,
    ContextType,
    RequireFields<MutationeditEventAssetArgs, 'assetId' | 'caption' | 'public'>
  >
  endSpeaker?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationendSpeakerArgs, 'eventId' | 'userId'>
  >
  execProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationexecProposalArgs,
      'blockNumber' | 'proposalId' | 'txHash'
    >
  >
  joinExternalCollective?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<MutationjoinExternalCollectiveArgs, 'collectiveId'>
  >
  leaveEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationleaveEventArgs, 'eventId'>
  >
  login?: Resolver<
    Maybe<ResolversTypes['LoginMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationloginArgs, 'login'>
  >
  loginCrypto?: Resolver<
    Maybe<ResolversTypes['LoginMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationloginCryptoArgs, 'login'>
  >
  loginWithAppleId?: Resolver<
    Maybe<ResolversTypes['LoginMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationloginWithAppleIdArgs, 'login'>
  >
  pinThread?: Resolver<
    ResolversTypes['Thread'],
    ParentType,
    ContextType,
    RequireFields<MutationpinThreadArgs, 'pinned' | 'threadId'>
  >
  postAnnouncement?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<MutationpostAnnouncementArgs, 'announcement' | 'collectiveId'>
  >
  postCollectiveSubmissionLog?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationpostCollectiveSubmissionLogArgs, 'log'>
  >
  prioritizeUserForAssetSync?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationprioritizeUserForAssetSyncArgs, 'id'>
  >
  proposeCollectiveMembership?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationproposeCollectiveMembershipArgs, 'requestId'>
  >
  recoverPassword?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationrecoverPasswordArgs, 'email'>
  >
  reportContent?: Resolver<
    Maybe<ResolversTypes['ReportContentMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationreportContentArgs, 'report'>
  >
  requestCollectiveMembership?: Resolver<
    Maybe<ResolversTypes['CollectiveMembershipRequest']>,
    ParentType,
    ContextType,
    RequireFields<MutationrequestCollectiveMembershipArgs, 'collectiveId'>
  >
  resendVerificationEmail?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType
  >
  resetExecution?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<MutationresetExecutionArgs, 'proposalId'>
  >
  resetPassword?: Resolver<
    Maybe<ResolversTypes['ResetPasswordMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationresetPasswordArgs, 'reset'>
  >
  rsvpToEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationrsvpToEventArgs, 'eventId' | 'rsvp'>
  >
  saveEmailPreferences?: Resolver<
    ResolversTypes['EmailPreferences'],
    ParentType,
    ContextType,
    RequireFields<
      MutationsaveEmailPreferencesArgs,
      'allowedTypes' | 'emailId' | 'unsubscribedFromAll'
    >
  >
  setAttendeeRole?: Resolver<
    Maybe<ResolversTypes['EventRegistration']>,
    ParentType,
    ContextType,
    RequireFields<MutationsetAttendeeRoleArgs, 'eventId' | 'role' | 'userId'>
  >
  setEventState?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationsetEventStateArgs, 'eventId' | 'eventState'>
  >
  setPassiveMode?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationsetPassiveModeArgs, 'eventId' | 'passive'>
  >
  setPreference?: Resolver<
    Maybe<ResolversTypes['UserPreference']>,
    ParentType,
    ContextType,
    RequireFields<MutationsetPreferenceArgs, 'preference'>
  >
  setRecordingState?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationsetRecordingStateArgs,
      'eventId' | 'newRecordingState'
    >
  >
  setSpeaker?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationsetSpeakerArgs, 'eventId'>
  >
  signProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationsignProposalArgs,
      'nonce' | 'proposalId' | 'signature'
    >
  >
  signalAttendee?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationsignalAttendeeArgs, 'signal'>
  >
  signalAttendees?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationsignalAttendeesArgs, 'signal'>
  >
  signup?: Resolver<
    Maybe<ResolversTypes['SignupMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationsignupArgs, 'signup'>
  >
  signupWithAppleId?: Resolver<
    Maybe<ResolversTypes['SignupMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationsignupWithAppleIdArgs, 'signup'>
  >
  startEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationstartEventArgs, 'eventId'>
  >
  submitCollectiveApplication?: Resolver<
    Maybe<ResolversTypes['CollectiveApplication']>,
    ParentType,
    ContextType,
    RequireFields<MutationsubmitCollectiveApplicationArgs, 'payload'>
  >
  syncCollective?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<MutationsyncCollectiveArgs, 'collectiveId'>
  >
  unlinkWallet?: Resolver<
    Maybe<ResolversTypes['Viewer']>,
    ParentType,
    ContextType,
    RequireFields<MutationunlinkWalletArgs, 'address'>
  >
  unsubscribeFromAllEmails?: Resolver<
    ResolversTypes['EmailPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationunsubscribeFromAllEmailsArgs, 'emailId'>
  >
  updateCategory?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<MutationupdateCategoryArgs, 'categoryDetails' | 'categoryId'>
  >
  updateCollectiveApplication?: Resolver<
    Maybe<ResolversTypes['CollectiveApplication']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationupdateCollectiveApplicationArgs,
      'applicationId' | 'payload'
    >
  >
  updateCollectiveDraft?: Resolver<
    Maybe<ResolversTypes['CollectiveDraft']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationupdateCollectiveDraftArgs,
      'collectiveDraftId' | 'input'
    >
  >
  updateComment?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<MutationupdateCommentArgs, 'commentDetails' | 'threadId'>
  >
  updateDiscussionBoardCategoryNotificationSetting?: Resolver<
    ResolversTypes['DiscussionBoard'],
    ParentType,
    ContextType,
    RequireFields<
      MutationupdateDiscussionBoardCategoryNotificationSettingArgs,
      'allowNotifications' | 'categoryId'
    >
  >
  updateDiscussionBoardNotificationSetting?: Resolver<
    ResolversTypes['DiscussionBoard'],
    ParentType,
    ContextType,
    RequireFields<
      MutationupdateDiscussionBoardNotificationSettingArgs,
      'allowGlobalNotifications' | 'discussionBoardId'
    >
  >
  updateDiscussionBoardThreadNotificationSetting?: Resolver<
    ResolversTypes['Thread'],
    ParentType,
    ContextType,
    RequireFields<
      MutationupdateDiscussionBoardThreadNotificationSettingArgs,
      'allowNotifications' | 'threadId'
    >
  >
  updateEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateEventArgs, 'event' | 'eventId'>
  >
  updateThread?: Resolver<
    ResolversTypes['Thread'],
    ParentType,
    ContextType,
    RequireFields<MutationupdateThreadArgs, 'threadDetails' | 'threadId'>
  >
  updateUser?: Resolver<
    Maybe<ResolversTypes['Viewer']>,
    ParentType,
    ContextType,
    RequireFields<MutationupdateUserArgs, 'update'>
  >
  upgradeWaitlistedAttendee?: Resolver<
    Maybe<ResolversTypes['EventRegistration']>,
    ParentType,
    ContextType,
    RequireFields<MutationupgradeWaitlistedAttendeeArgs, 'eventId' | 'userId'>
  >
  uploadAvatar?: Resolver<
    Maybe<ResolversTypes['Viewer']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadAvatarArgs, 'file'>
  >
  uploadAvatarURL?: Resolver<
    Maybe<ResolversTypes['Viewer']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadAvatarURLArgs, 'avatarURL'>
  >
  uploadCollectiveApplicationAvatar?: Resolver<
    Maybe<ResolversTypes['CollectiveApplication']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationuploadCollectiveApplicationAvatarArgs,
      'applicationId' | 'file'
    >
  >
  uploadCollectiveAvatar?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadCollectiveAvatarArgs, 'collectiveId' | 'file'>
  >
  uploadCollectiveDraftAvatar?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationuploadCollectiveDraftAvatarArgs, 'file'>
  >
  uploadEventThumb?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    RequireFields<MutationuploadEventThumbArgs, 'eventId' | 'file'>
  >
  verifyEmail?: Resolver<
    Maybe<ResolversTypes['Viewer']>,
    ParentType,
    ContextType,
    RequireFields<MutationverifyEmailArgs, 'emailVerificationKey'>
  >
}>

export type MutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MutationResponse'] = ResolversParentTypes['MutationResponse']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    | 'ChangePasswordMutationResponse'
    | 'GenericMutationResponse'
    | 'LoginMutationResponse'
    | 'ReportContentMutationResponse'
    | 'ResetPasswordMutationResponse'
    | 'SignupMutationResponse',
    ParentType,
    ContextType
  >
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
}>

export type NFTAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NFTAsset'] = ResolversParentTypes['NFTAsset']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  contractType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  estimatedValueEth?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  estimatedValueUSD?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >
  externalUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastSynced?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  mediaUrls?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tokenId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  wallet?: Resolver<ResolversTypes['CollectiveWallet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = ResolversObject<{
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type PreviouslyRecordedEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PreviouslyRecordedEvent'] = ResolversParentTypes['PreviouslyRecordedEvent']
> = ResolversObject<{
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  thumb?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = ResolversObject<{
  authMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  category?: Resolver<
    ResolversTypes['Category'],
    ParentType,
    ContextType,
    RequireFields<QuerycategoryArgs, 'categoryId'>
  >
  collective?: Resolver<
    Maybe<ResolversTypes['Collective']>,
    ParentType,
    ContextType,
    RequireFields<QuerycollectiveArgs, 'collectiveId'>
  >
  collectiveApplication?: Resolver<
    Maybe<ResolversTypes['CollectiveApplication']>,
    ParentType,
    ContextType,
    RequireFields<QuerycollectiveApplicationArgs, 'applicationId'>
  >
  collectiveApplications?: Resolver<
    Maybe<Array<ResolversTypes['CollectiveApplication']>>,
    ParentType,
    ContextType
  >
  collectiveDemo?: Resolver<
    Maybe<ResolversTypes['CollectiveDemo']>,
    ParentType,
    ContextType,
    RequireFields<QuerycollectiveDemoArgs, 'collectiveDemoId'>
  >
  collectiveDraft?: Resolver<
    Maybe<ResolversTypes['CollectiveDraft']>,
    ParentType,
    ContextType,
    RequireFields<QuerycollectiveDraftArgs, 'collectiveDraftId'>
  >
  collectiveFaqs?: Resolver<
    Array<ResolversTypes['CollectiveFaq']>,
    ParentType,
    ContextType,
    Partial<QuerycollectiveFaqsArgs>
  >
  collectiveProposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType,
    RequireFields<QuerycollectiveProposalArgs, 'proposalId'>
  >
  collectiveTos?: Resolver<
    ResolversTypes['CollectiveTOS'],
    ParentType,
    ContextType,
    RequireFields<QuerycollectiveTosArgs, 'collectiveId'>
  >
  comment?: Resolver<
    ResolversTypes['Comment'],
    ParentType,
    ContextType,
    RequireFields<QuerycommentArgs, 'commentId'>
  >
  contractAbi?: Resolver<
    ResolversTypes['ContractAbi'],
    ParentType,
    ContextType,
    RequireFields<QuerycontractAbiArgs, 'address' | 'network'>
  >
  currentCollectiveId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  currentEvent?: Resolver<
    Maybe<ResolversTypes['Event']>,
    ParentType,
    ContextType
  >
  discoverEngagements?: Resolver<
    Maybe<ResolversTypes['EngagementConnection']>,
    ParentType,
    ContextType,
    RequireFields<
      QuerydiscoverEngagementsArgs,
      'engagementConnection' | 'filters' | 'sort'
    >
  >
  discussionBoard?: Resolver<
    ResolversTypes['DiscussionBoard'],
    ParentType,
    ContextType,
    RequireFields<QuerydiscussionBoardArgs, 'discussionBoardId'>
  >
  enrichments?: Resolver<
    Maybe<ResolversTypes['UserEnrichments']>,
    ParentType,
    ContextType
  >
  ephemeralCryptoAuth?: Resolver<
    ResolversTypes['EphemeralCryptoAuth'],
    ParentType,
    ContextType
  >
  event?: Resolver<
    ResolversTypes['Event'],
    ParentType,
    ContextType,
    RequireFields<QueryeventArgs, 'id'>
  >
  eventHeartbeat?: Resolver<
    Maybe<ResolversTypes['GenericMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryeventHeartbeatArgs, 'id'>
  >
  eventNoShows?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType,
    RequireFields<QueryeventNoShowsArgs, 'id'>
  >
  eventSeriesBySlug?: Resolver<
    Maybe<ResolversTypes['EventSeries']>,
    ParentType,
    ContextType,
    RequireFields<QueryeventSeriesBySlugArgs, 'slug'>
  >
  eventSeriesSuggestion?: Resolver<
    Array<ResolversTypes['EventSeries']>,
    ParentType,
    ContextType,
    Partial<QueryeventSeriesSuggestionArgs>
  >
  featuredEvents?: Resolver<
    Array<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    Partial<QueryfeaturedEventsArgs>
  >
  featuredSpeakers?: Resolver<
    Array<ResolversTypes['FeaturedSpeaker']>,
    ParentType,
    ContextType,
    Partial<QueryfeaturedSpeakersArgs>
  >
  flags?: Resolver<
    Array<Maybe<ResolversTypes['SiteFlag']>>,
    ParentType,
    ContextType
  >
  getEmailPreferences?: Resolver<
    ResolversTypes['EmailPreferences'],
    ParentType,
    ContextType,
    RequireFields<QuerygetEmailPreferencesArgs, 'emailId'>
  >
  hasChrome?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasGmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasTestFlight?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  language?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QuerylanguageArgs, 'language'>
  >
  languages?: Resolver<ResolversTypes['Languages'], ParentType, ContextType>
  me?: Resolver<
    ResolversTypes['Viewer'],
    ParentType,
    ContextType,
    Partial<QuerymeArgs>
  >
  previousEvents?: Resolver<
    Array<ResolversTypes['PreviouslyRecordedEvent']>,
    ParentType,
    ContextType,
    Partial<QuerypreviousEventsArgs>
  >
  resolveSubdomain?: Resolver<
    Maybe<ResolversTypes['SubdomainEntity']>,
    ParentType,
    ContextType,
    RequireFields<QueryresolveSubdomainArgs, 'slug'>
  >
  search?: Resolver<
    ResolversTypes['SearchResults'],
    ParentType,
    ContextType,
    RequireFields<QuerysearchArgs, 'query'>
  >
  seo?: Resolver<
    Maybe<ResolversTypes['SEO']>,
    ParentType,
    ContextType,
    RequireFields<QueryseoArgs, 'path'>
  >
  tags?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Tag']>>>,
    ParentType,
    ContextType
  >
  thread?: Resolver<
    ResolversTypes['Thread'],
    ParentType,
    ContextType,
    RequireFields<QuerythreadArgs, 'threadId'>
  >
  threadFileGetUrl?: Resolver<
    ResolversTypes['FileGet'],
    ParentType,
    ContextType,
    RequireFields<QuerythreadFileGetUrlArgs, 'discussionBoardId' | 'key'>
  >
  threadFileUploadUrl?: Resolver<
    ResolversTypes['FileUpload'],
    ParentType,
    ContextType,
    RequireFields<QuerythreadFileUploadUrlArgs, 'discussionBoardId'>
  >
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryuserArgs, 'id'>
  >
}>

export type QuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']
> = ResolversObject<{
  choices?: Resolver<
    Maybe<Array<ResolversTypes['QuestionChoice']>>,
    ParentType,
    ContextType
  >
  options?: Resolver<ResolversTypes['QuestionOptions'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  questionType?: Resolver<
    ResolversTypes['QuestionType'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type QuestionChoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionChoice'] = ResolversParentTypes['QuestionChoice']
> = ResolversObject<{
  stringChoice?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type QuestionOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionOptions'] = ResolversParentTypes['QuestionOptions']
> = ResolversObject<{
  dateOptions?: Resolver<
    Maybe<ResolversTypes['DateOptions']>,
    ParentType,
    ContextType
  >
  required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type QuestionResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionResults'] = ResolversParentTypes['QuestionResults']
> = ResolversObject<{
  __resolveType: TypeResolveFn<'ChoiceBasedResults', ParentType, ContextType>
}>

export type ReportContentMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportContentMutationResponse'] = ResolversParentTypes['ReportContentMutationResponse']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ResetPasswordMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResetPasswordMutationResponse'] = ResolversParentTypes['ResetPasswordMutationResponse']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  jwtToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SEOResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SEO'] = ResolversParentTypes['SEO']
> = ResolversObject<{
  additionalMetaTags?: Resolver<
    Maybe<Array<ResolversTypes['MetaTag']>>,
    ParentType,
    ContextType
  >
  metaDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  metaKeywords?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  path?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SearchResultHitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchResultHit'] = ResolversParentTypes['SearchResultHit']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'Collective' | 'CollectiveProposal' | 'Event' | 'User',
    ParentType,
    ContextType
  >
}>

export type SearchResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchResults'] = ResolversParentTypes['SearchResults']
> = ResolversObject<{
  hits?: Resolver<
    Array<ResolversTypes['SearchResultHit']>,
    ParentType,
    ContextType
  >
  totalHits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SignupMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignupMutationResponse'] = ResolversParentTypes['SignupMutationResponse']
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  jwtToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SiteFlagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteFlag'] = ResolversParentTypes['SiteFlag']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'SiteFlagBoolean' | 'SiteFlagNumber' | 'SiteFlagString',
    ParentType,
    ContextType
  >
}>

export type SiteFlagBooleanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteFlagBoolean'] = ResolversParentTypes['SiteFlagBoolean']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['SiteFlagType'], ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SiteFlagNumberResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteFlagNumber'] = ResolversParentTypes['SiteFlagNumber']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['SiteFlagType'], ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SiteFlagStringResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteFlagString'] = ResolversParentTypes['SiteFlagString']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['SiteFlagType'], ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SubdomainEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubdomainEntity'] = ResolversParentTypes['SubdomainEntity']
> = ResolversObject<{
  __resolveType: TypeResolveFn<'Collective', ParentType, ContextType>
}>

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']
> = ResolversObject<{
  categoryAdded?: SubscriptionResolver<
    ResolversTypes['Category'],
    'categoryAdded',
    ParentType,
    ContextType,
    RequireFields<SubscriptioncategoryAddedArgs, 'discussionBoardId'>
  >
  categoryChanged?: SubscriptionResolver<
    ResolversTypes['Category'],
    'categoryChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptioncategoryChangedArgs, 'categoryId'>
  >
  collectiveChanged?: SubscriptionResolver<
    ResolversTypes['Collective'],
    'collectiveChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptioncollectiveChangedArgs, 'collectiveId'>
  >
  commentAdded?: SubscriptionResolver<
    ResolversTypes['Comment'],
    'commentAdded',
    ParentType,
    ContextType,
    RequireFields<SubscriptioncommentAddedArgs, 'threadId'>
  >
  commentChanged?: SubscriptionResolver<
    ResolversTypes['Comment'],
    'commentChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptioncommentChangedArgs, 'commentId'>
  >
  discussionBoardChanged?: SubscriptionResolver<
    ResolversTypes['DiscussionBoard'],
    'discussionBoardChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptiondiscussionBoardChangedArgs, 'discussionBoardId'>
  >
  emailVerifiedChanged?: SubscriptionResolver<
    Maybe<ResolversTypes['Viewer']>,
    'emailVerifiedChanged',
    ParentType,
    ContextType
  >
  eventMessageAdded?: SubscriptionResolver<
    ResolversTypes['EventMessage'],
    'eventMessageAdded',
    ParentType,
    ContextType,
    RequireFields<SubscriptioneventMessageAddedArgs, 'eventId'>
  >
  eventOnlineGuestCountChanged?: SubscriptionResolver<
    ResolversTypes['Event'],
    'eventOnlineGuestCountChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptioneventOnlineGuestCountChangedArgs, 'eventId'>
  >
  eventSignalReceived?: SubscriptionResolver<
    ResolversTypes['EventSignal'],
    'eventSignalReceived',
    ParentType,
    ContextType,
    RequireFields<SubscriptioneventSignalReceivedArgs, 'eventId'>
  >
  eventStateChanged?: SubscriptionResolver<
    ResolversTypes['Event'],
    'eventStateChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptioneventStateChangedArgs, 'eventId'>
  >
  eventUserPlanUpdated?: SubscriptionResolver<
    ResolversTypes['Event'],
    'eventUserPlanUpdated',
    ParentType,
    ContextType,
    RequireFields<SubscriptioneventUserPlanUpdatedArgs, 'eventId'>
  >
  proposalChanged?: SubscriptionResolver<
    ResolversTypes['CollectiveProposal'],
    'proposalChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptionproposalChangedArgs, 'proposalId'>
  >
  replyAdded?: SubscriptionResolver<
    ResolversTypes['Comment'],
    'replyAdded',
    ParentType,
    ContextType,
    RequireFields<SubscriptionreplyAddedArgs, 'commentId'>
  >
  threadAdded?: SubscriptionResolver<
    ResolversTypes['Thread'],
    'threadAdded',
    ParentType,
    ContextType,
    RequireFields<SubscriptionthreadAddedArgs, 'categoryId'>
  >
  threadChanged?: SubscriptionResolver<
    ResolversTypes['Thread'],
    'threadChanged',
    ParentType,
    ContextType,
    RequireFields<SubscriptionthreadChangedArgs, 'threadId'>
  >
  threadPinned?: SubscriptionResolver<
    ResolversTypes['Thread'],
    'threadPinned',
    ParentType,
    ContextType,
    RequireFields<SubscriptionthreadPinnedArgs, 'categoryId'>
  >
}>

export type SurveyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Survey'] = ResolversParentTypes['Survey']
> = ResolversObject<{
  answeredOn?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  isAnswered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  myAnswers?: Resolver<
    Maybe<Array<ResolversTypes['Answer']>>,
    ParentType,
    ContextType
  >
  options?: Resolver<ResolversTypes['SurveyOptions'], ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  questions?: Resolver<
    Array<ResolversTypes['Question']>,
    ParentType,
    ContextType
  >
  surveyResults?: Resolver<
    Array<ResolversTypes['QuestionResults']>,
    ParentType,
    ContextType
  >
  totalAnswers?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  totalQuestions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SurveyOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SurveyOptions'] = ResolversParentTypes['SurveyOptions']
> = ResolversObject<{
  showResultsAfter?: Resolver<
    Maybe<ResolversTypes['ShowResultsAfter']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type SyncStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SyncStatus'] = ResolversParentTypes['SyncStatus']
> = ResolversObject<{
  lastSyncedAt?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  status?: Resolver<
    ResolversTypes['CollectiveSyncStatus'],
    ParentType,
    ContextType
  >
  syncStartedAt?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type TagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']
> = ResolversObject<{
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ThreadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Thread'] = ResolversParentTypes['Thread']
> = ResolversObject<{
  attachments?: Resolver<
    Array<ResolversTypes['ThreadAttachment']>,
    ParentType,
    ContextType
  >
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType>
  collaborators?: Resolver<
    ResolversTypes['CollaboratorConnection'],
    ParentType,
    ContextType,
    RequireFields<ThreadcollaboratorsArgs, 'collaboratorConnection'>
  >
  collaboratorsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  comments?: Resolver<
    ResolversTypes['CommentConnection'],
    ParentType,
    ContextType,
    RequireFields<ThreadcommentsArgs, 'commentConnection'>
  >
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  deletedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPinned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  notificationSettings?: Resolver<
    ResolversTypes['DiscussionBoardThreadNotificationSetting'],
    ParentType,
    ContextType
  >
  pinnedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  proposal?: Resolver<
    Maybe<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  replyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ThreadType'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  viewsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ThreadAttachmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThreadAttachment'] = ResolversParentTypes['ThreadAttachment']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ThreadConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThreadConnection'] = ResolversParentTypes['ThreadConnection']
> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['ThreadEdge']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ThreadEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ThreadEdge'] = ResolversParentTypes['ThreadEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Thread'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export interface UploadScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload'
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = ResolversObject<{
  assets?: Resolver<
    ResolversTypes['UserAssetsConnection'],
    ParentType,
    ContextType,
    RequireFields<UserassetsArgs, 'assetConnectionInput' | 'assetFilters'>
  >
  blocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  canSendDM?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  collectiveUser?: Resolver<
    Array<ResolversTypes['CollectiveUser']>,
    ParentType,
    ContextType
  >
  collectives?: Resolver<
    ResolversTypes['CollectiveConnection'],
    ParentType,
    ContextType,
    RequireFields<UsercollectivesArgs, 'collectiveConnection'>
  >
  created?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  engagementDates?: Resolver<
    Array<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  engagements?: Resolver<
    ResolversTypes['EngagementConnection'],
    ParentType,
    ContextType,
    RequireFields<
      UserengagementsArgs,
      'engagementConnection' | 'filters' | 'sort'
    >
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isBot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  linkedin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  possiblySpam?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shortDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  syncStatus?: Resolver<
    ResolversTypes['UserSyncStatus'],
    ParentType,
    ContextType
  >
  syncStatusDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserAssetDetailsTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAssetDetailsType'] = ResolversParentTypes['UserAssetDetailsType']
> = ResolversObject<{
  __resolveType: TypeResolveFn<
    'UserERC20Asset' | 'UserNFTAsset',
    ParentType,
    ContextType
  >
}>

export type UserAssetTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAssetType'] = ResolversParentTypes['UserAssetType']
> = ResolversObject<{
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contractName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contractType?: Resolver<
    ResolversTypes['ContractTypes'],
    ParentType,
    ContextType
  >
  details?: Resolver<
    ResolversTypes['UserAssetDetailsType'],
    ParentType,
    ContextType
  >
  estimatedValueEth?: Resolver<
    Maybe<ResolversTypes['BigInt']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserAssetsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAssetsConnection'] = ResolversParentTypes['UserAssetsConnection']
> = ResolversObject<{
  awaitingSync?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  edges?: Resolver<
    Array<ResolversTypes['UserAssetsEdge']>,
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserAssetsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAssetsEdge'] = ResolversParentTypes['UserAssetsEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['UserAssetType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']
> = ResolversObject<{
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserERC20AssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserERC20Asset'] = ResolversParentTypes['UserERC20Asset']
> = ResolversObject<{
  balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge']
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserEnrichmentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEnrichments'] = ResolversParentTypes['UserEnrichments']
> = ResolversObject<{
  awaitingEnrichments?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  suggestions?: Resolver<
    ResolversTypes['EnrichmentSuggestions'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserNFTAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNFTAsset'] = ResolversParentTypes['UserNFTAsset']
> = ResolversObject<{
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  mediaUrls?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nftImageUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  tokenId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type UserPreferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPreference'] = ResolversParentTypes['UserPreference']
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type ViewerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Viewer'] = ResolversParentTypes['Viewer']
> = ResolversObject<{
  appleIdConnected?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  assets?: Resolver<
    ResolversTypes['UserAssetsConnection'],
    ParentType,
    ContextType,
    RequireFields<ViewerassetsArgs, 'assetConnectionInput' | 'assetFilters'>
  >
  blockedUsers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >
  canCreateDiscoverableEvents?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  collectiveDrafts?: Resolver<
    ResolversTypes['CollectiveDraftConnection'],
    ParentType,
    ContextType,
    RequireFields<ViewercollectiveDraftsArgs, 'collectiveConnection'>
  >
  collectiveUser?: Resolver<
    Array<ResolversTypes['CollectiveUser']>,
    ParentType,
    ContextType
  >
  collectives?: Resolver<
    ResolversTypes['CollectiveConnection'],
    ParentType,
    ContextType,
    RequireFields<ViewercollectivesArgs, 'collectiveConnection'>
  >
  collectivesActionablesCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  engagementDates?: Resolver<
    Array<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  engagements?: Resolver<
    ResolversTypes['EngagementConnection'],
    ParentType,
    ContextType,
    RequireFields<
      ViewerengagementsArgs,
      'engagementConnection' | 'filters' | 'sort'
    >
  >
  hasCollectives?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  instagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isBot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jwtToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nonce?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  possiblySpam?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  preferences?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserPreference']>>>,
    ParentType,
    ContextType
  >
  proposalsToExecute?: Resolver<
    Array<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  proposalsToVote?: Resolver<
    Array<ResolversTypes['CollectiveProposal']>,
    ParentType,
    ContextType
  >
  publicUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shortDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  streamChatToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  syncStatus?: Resolver<
    ResolversTypes['UserSyncStatus'],
    ParentType,
    ContextType
  >
  syncStatusDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >
  thumb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userType?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}>

export type Resolvers<ContextType = any> = ResolversObject<{
  Answer?: AnswerResolvers<ContextType>
  AnswerChoice?: AnswerChoiceResolvers<ContextType>
  BigInt?: GraphQLScalarType
  BundledCollectiveLog?: BundledCollectiveLogResolvers<ContextType>
  CastVoteMutationResponse?: CastVoteMutationResponseResolvers<ContextType>
  Category?: CategoryResolvers<ContextType>
  ChangePasswordMutationResponse?: ChangePasswordMutationResponseResolvers<ContextType>
  ChoiceBasedResults?: ChoiceBasedResultsResolvers<ContextType>
  CollaboratorConnection?: CollaboratorConnectionResolvers<ContextType>
  CollaboratorEdge?: CollaboratorEdgeResolvers<ContextType>
  Collective?: CollectiveResolvers<ContextType>
  CollectiveAnnouncement?: CollectiveAnnouncementResolvers<ContextType>
  CollectiveApplication?: CollectiveApplicationResolvers<ContextType>
  CollectiveAsset?: CollectiveAssetResolvers<ContextType>
  CollectiveAssetEdge?: CollectiveAssetEdgeResolvers<ContextType>
  CollectiveAssetPreview?: CollectiveAssetPreviewResolvers<ContextType>
  CollectiveAssetsConnection?: CollectiveAssetsConnectionResolvers<ContextType>
  CollectiveConnection?: CollectiveConnectionResolvers<ContextType>
  CollectiveDemo?: CollectiveDemoResolvers<ContextType>
  CollectiveDraft?: CollectiveDraftResolvers<ContextType>
  CollectiveDraftConnection?: CollectiveDraftConnectionResolvers<ContextType>
  CollectiveDraftEdge?: CollectiveDraftEdgeResolvers<ContextType>
  CollectiveEdge?: CollectiveEdgeResolvers<ContextType>
  CollectiveFaq?: CollectiveFaqResolvers<ContextType>
  CollectiveLog?: CollectiveLogResolvers<ContextType>
  CollectiveLogDataOnAddedOwner?: CollectiveLogDataOnAddedOwnerResolvers<ContextType>
  CollectiveLogDataOnAnnouncementChanged?: CollectiveLogDataOnAnnouncementChangedResolvers<ContextType>
  CollectiveLogDataOnAnnouncementCreated?: CollectiveLogDataOnAnnouncementCreatedResolvers<ContextType>
  CollectiveLogDataOnAnnouncementDeleted?: CollectiveLogDataOnAnnouncementDeletedResolvers<ContextType>
  CollectiveLogDataOnChangedThreshold?: CollectiveLogDataOnChangedThresholdResolvers<ContextType>
  CollectiveLogDataOnCollectiveMembershipProposed?: CollectiveLogDataOnCollectiveMembershipProposedResolvers<ContextType>
  CollectiveLogDataOnCollectiveMembershipRequested?: CollectiveLogDataOnCollectiveMembershipRequestedResolvers<ContextType>
  CollectiveLogDataOnCollectiveSyncRequested?: CollectiveLogDataOnCollectiveSyncRequestedResolvers<ContextType>
  CollectiveLogDataOnCollectiveSynced?: CollectiveLogDataOnCollectiveSyncedResolvers<ContextType>
  CollectiveLogDataOnDelegationsGranted?: CollectiveLogDataOnDelegationsGrantedResolvers<ContextType>
  CollectiveLogDataOnDelegationsRevoked?: CollectiveLogDataOnDelegationsRevokedResolvers<ContextType>
  CollectiveLogDataOnDisableTokenTransfersChanged?: CollectiveLogDataOnDisableTokenTransfersChangedResolvers<ContextType>
  CollectiveLogDataOnEnableDepositsChanged?: CollectiveLogDataOnEnableDepositsChangedResolvers<ContextType>
  CollectiveLogDataOnEnableNewMembershipRequestsChanged?: CollectiveLogDataOnEnableNewMembershipRequestsChangedResolvers<ContextType>
  CollectiveLogDataOnEnableWithdrawsChanged?: CollectiveLogDataOnEnableWithdrawsChangedResolvers<ContextType>
  CollectiveLogDataOnExchangeRateChanged?: CollectiveLogDataOnExchangeRateChangedResolvers<ContextType>
  CollectiveLogDataOnExternalCollectiveJoined?: CollectiveLogDataOnExternalCollectiveJoinedResolvers<ContextType>
  CollectiveLogDataOnFeesChanged?: CollectiveLogDataOnFeesChangedResolvers<ContextType>
  CollectiveLogDataOnFundsDeposited?: CollectiveLogDataOnFundsDepositedResolvers<ContextType>
  CollectiveLogDataOnGateDepositsChanged?: CollectiveLogDataOnGateDepositsChangedResolvers<ContextType>
  CollectiveLogDataOnMemberAdded?: CollectiveLogDataOnMemberAddedResolvers<ContextType>
  CollectiveLogDataOnMemberDepositedFunds?: CollectiveLogDataOnMemberDepositedFundsResolvers<ContextType>
  CollectiveLogDataOnMemberRemoved?: CollectiveLogDataOnMemberRemovedResolvers<ContextType>
  CollectiveLogDataOnMemberWithdrewFunds?: CollectiveLogDataOnMemberWithdrewFundsResolvers<ContextType>
  CollectiveLogDataOnMinEthContributionChanged?: CollectiveLogDataOnMinEthContributionChangedResolvers<ContextType>
  CollectiveLogDataOnNonDepositEtherReceived?: CollectiveLogDataOnNonDepositEtherReceivedResolvers<ContextType>
  CollectiveLogDataOnProposalCanceled?: CollectiveLogDataOnProposalCanceledResolvers<ContextType>
  CollectiveLogDataOnProposalExecuted?: CollectiveLogDataOnProposalExecutedResolvers<ContextType>
  CollectiveLogDataOnProposalExecutionReset?: CollectiveLogDataOnProposalExecutionResetResolvers<ContextType>
  CollectiveLogDataOnProposalRequireVoteSignatureChanged?: CollectiveLogDataOnProposalRequireVoteSignatureChangedResolvers<ContextType>
  CollectiveLogDataOnProposalSigned?: CollectiveLogDataOnProposalSignedResolvers<ContextType>
  CollectiveLogDataOnProposalsBatched?: CollectiveLogDataOnProposalsBatchedResolvers<ContextType>
  CollectiveLogDataOnQuorumNeededInPercentageChanged?: CollectiveLogDataOnQuorumNeededInPercentageChangedResolvers<ContextType>
  CollectiveLogDataOnRemovedOwner?: CollectiveLogDataOnRemovedOwnerResolvers<ContextType>
  CollectiveLogDataOnTransfer?: CollectiveLogDataOnTransferResolvers<ContextType>
  CollectiveLogDataOnTransferBatch?: CollectiveLogDataOnTransferBatchResolvers<ContextType>
  CollectiveLogDataOnTransferSingle?: CollectiveLogDataOnTransferSingleResolvers<ContextType>
  CollectiveLogDataOnVoteCast?: CollectiveLogDataOnVoteCastResolvers<ContextType>
  CollectiveLogDataOnVoteChanged?: CollectiveLogDataOnVoteChangedResolvers<ContextType>
  CollectiveLogDataOnVoteInvalidated?: CollectiveLogDataOnVoteInvalidatedResolvers<ContextType>
  CollectiveLogDataOnVoteWeightUpdated?: CollectiveLogDataOnVoteWeightUpdatedResolvers<ContextType>
  CollectiveLogDataOnVotingAnonymityChanged?: CollectiveLogDataOnVotingAnonymityChangedResolvers<ContextType>
  CollectiveLogDataOnWalletLinked?: CollectiveLogDataOnWalletLinkedResolvers<ContextType>
  CollectiveLogDataOnWalletUnlinked?: CollectiveLogDataOnWalletUnlinkedResolvers<ContextType>
  CollectiveLogDataType?: CollectiveLogDataTypeResolvers<ContextType>
  CollectiveLogsConnection?: CollectiveLogsConnectionResolvers<ContextType>
  CollectiveLogsEdge?: CollectiveLogsEdgeResolvers<ContextType>
  CollectiveMember?: CollectiveMemberResolvers<ContextType>
  CollectiveMemberConnection?: CollectiveMemberConnectionResolvers<ContextType>
  CollectiveMemberEdge?: CollectiveMemberEdgeResolvers<ContextType>
  CollectiveMembershipRequest?: CollectiveMembershipRequestResolvers<ContextType>
  CollectiveParameters?: CollectiveParametersResolvers<ContextType>
  CollectiveProposal?: CollectiveProposalResolvers<ContextType>
  CollectiveProposalAddMemberCommand?: CollectiveProposalAddMemberCommandResolvers<ContextType>
  CollectiveProposalAddSignatorCommand?: CollectiveProposalAddSignatorCommandResolvers<ContextType>
  CollectiveProposalCallRemoteCommand?: CollectiveProposalCallRemoteCommandResolvers<ContextType>
  CollectiveProposalChangeDisableTokenTransfersCommand?: CollectiveProposalChangeDisableTokenTransfersCommandResolvers<ContextType>
  CollectiveProposalChangeEnableDepositsCommand?: CollectiveProposalChangeEnableDepositsCommandResolvers<ContextType>
  CollectiveProposalChangeEnableNewMembershipRequests?: CollectiveProposalChangeEnableNewMembershipRequestsResolvers<ContextType>
  CollectiveProposalChangeEnableWithdrawsCommand?: CollectiveProposalChangeEnableWithdrawsCommandResolvers<ContextType>
  CollectiveProposalChangeExchangeRateCommand?: CollectiveProposalChangeExchangeRateCommandResolvers<ContextType>
  CollectiveProposalChangeGateDepositsCommand?: CollectiveProposalChangeGateDepositsCommandResolvers<ContextType>
  CollectiveProposalChangeMinEthContributionCommand?: CollectiveProposalChangeMinEthContributionCommandResolvers<ContextType>
  CollectiveProposalChangeRequireVotingSignatureCommand?: CollectiveProposalChangeRequireVotingSignatureCommandResolvers<ContextType>
  CollectiveProposalChangeSafeThresholdCommand?: CollectiveProposalChangeSafeThresholdCommandResolvers<ContextType>
  CollectiveProposalChangeVotingAnonymityCommand?: CollectiveProposalChangeVotingAnonymityCommandResolvers<ContextType>
  CollectiveProposalCommand?: CollectiveProposalCommandResolvers<ContextType>
  CollectiveProposalConnection?: CollectiveProposalConnectionResolvers<ContextType>
  CollectiveProposalEdge?: CollectiveProposalEdgeResolvers<ContextType>
  CollectiveProposalExecution?: CollectiveProposalExecutionResolvers<ContextType>
  CollectiveProposalLinkWalletCommand?: CollectiveProposalLinkWalletCommandResolvers<ContextType>
  CollectiveProposalOrigin?: CollectiveProposalOriginResolvers<ContextType>
  CollectiveProposalRemoveMemberCommand?: CollectiveProposalRemoveMemberCommandResolvers<ContextType>
  CollectiveProposalRemoveSignatorCommand?: CollectiveProposalRemoveSignatorCommandResolvers<ContextType>
  CollectiveProposalSendEtherCommand?: CollectiveProposalSendEtherCommandResolvers<ContextType>
  CollectiveProposalSendEtherDisbursementCommand?: CollectiveProposalSendEtherDisbursementCommandResolvers<ContextType>
  CollectiveProposalSendEtherDisbursementCommands?: CollectiveProposalSendEtherDisbursementCommandsResolvers<ContextType>
  CollectiveProposalSendTokenCommand?: CollectiveProposalSendTokenCommandResolvers<ContextType>
  CollectiveProposalSignature?: CollectiveProposalSignatureResolvers<ContextType>
  CollectiveProposalStatusHistory?: CollectiveProposalStatusHistoryResolvers<ContextType>
  CollectiveProposalTransferERC721Command?: CollectiveProposalTransferERC721CommandResolvers<ContextType>
  CollectiveProposalTransferERC1155Command?: CollectiveProposalTransferERC1155CommandResolvers<ContextType>
  CollectiveProposalTransferNFTCommand?: CollectiveProposalTransferNFTCommandResolvers<ContextType>
  CollectiveProposalTransferTokensCommand?: CollectiveProposalTransferTokensCommandResolvers<ContextType>
  CollectiveProposalUnlinkWalletCommand?: CollectiveProposalUnlinkWalletCommandResolvers<ContextType>
  CollectiveProposalUpdateQuorumCommand?: CollectiveProposalUpdateQuorumCommandResolvers<ContextType>
  CollectiveProposalVote?: CollectiveProposalVoteResolvers<ContextType>
  CollectiveProposalVoteConnection?: CollectiveProposalVoteConnectionResolvers<ContextType>
  CollectiveProposalVoteEdge?: CollectiveProposalVoteEdgeResolvers<ContextType>
  CollectiveStatsDatapoint?: CollectiveStatsDatapointResolvers<ContextType>
  CollectiveTOS?: CollectiveTOSResolvers<ContextType>
  CollectiveToken?: CollectiveTokenResolvers<ContextType>
  CollectiveUser?: CollectiveUserResolvers<ContextType>
  CollectiveWallet?: CollectiveWalletResolvers<ContextType>
  CollectiveWalletStatsDatapoint?: CollectiveWalletStatsDatapointResolvers<ContextType>
  CommandDelegation?: CommandDelegationResolvers<ContextType>
  CommandDelegationStruct?: CommandDelegationStructResolvers<ContextType>
  Comment?: CommentResolvers<ContextType>
  CommentConnection?: CommentConnectionResolvers<ContextType>
  CommentEdge?: CommentEdgeResolvers<ContextType>
  ContractAbi?: ContractAbiResolvers<ContextType>
  DJSong?: DJSongResolvers<ContextType>
  DJState?: DJStateResolvers<ContextType>
  Date?: GraphQLScalarType
  DateOptions?: DateOptionsResolvers<ContextType>
  DelegationResult?: DelegationResultResolvers<ContextType>
  DiscussionBoard?: DiscussionBoardResolvers<ContextType>
  DiscussionBoardCategoryNotificationSetting?: DiscussionBoardCategoryNotificationSettingResolvers<ContextType>
  DiscussionBoardNotificationSetting?: DiscussionBoardNotificationSettingResolvers<ContextType>
  DiscussionBoardThreadNotificationSetting?: DiscussionBoardThreadNotificationSettingResolvers<ContextType>
  ERC20Asset?: ERC20AssetResolvers<ContextType>
  ERCAsset?: ERCAssetResolvers<ContextType>
  EmailPreferences?: EmailPreferencesResolvers<ContextType>
  Engagement?: EngagementResolvers<ContextType>
  EngagementConnection?: EngagementConnectionResolvers<ContextType>
  EngagementEdge?: EngagementEdgeResolvers<ContextType>
  EnrichmentEmployment?: EnrichmentEmploymentResolvers<ContextType>
  EnrichmentNFT?: EnrichmentNFTResolvers<ContextType>
  EnrichmentSocialProfile?: EnrichmentSocialProfileResolvers<ContextType>
  EnrichmentSuggestions?: EnrichmentSuggestionsResolvers<ContextType>
  EphemeralCryptoAuth?: EphemeralCryptoAuthResolvers<ContextType>
  Event?: EventResolvers<ContextType>
  EventAsset?: EventAssetResolvers<ContextType>
  EventBorder?: EventBorderResolvers<ContextType>
  EventConnection?: EventConnectionResolvers<ContextType>
  EventCounts?: EventCountsResolvers<ContextType>
  EventEdge?: EventEdgeResolvers<ContextType>
  EventMedia?: EventMediaResolvers<ContextType>
  EventMessage?: EventMessageResolvers<ContextType>
  EventMessageConnection?: EventMessageConnectionResolvers<ContextType>
  EventMessageEdge?: EventMessageEdgeResolvers<ContextType>
  EventPlan?: EventPlanResolvers<ContextType>
  EventRegistration?: EventRegistrationResolvers<ContextType>
  EventRegistrationConnection?: EventRegistrationConnectionResolvers<ContextType>
  EventRegistrationEdge?: EventRegistrationEdgeResolvers<ContextType>
  EventSeries?: EventSeriesResolvers<ContextType>
  EventSignal?: EventSignalResolvers<ContextType>
  EventUserPlan?: EventUserPlanResolvers<ContextType>
  EventVideoChannel?: EventVideoChannelResolvers<ContextType>
  ExternalAsset?: ExternalAssetResolvers<ContextType>
  FeaturedSpeaker?: FeaturedSpeakerResolvers<ContextType>
  FileExport?: FileExportResolvers<ContextType>
  FileGet?: FileGetResolvers<ContextType>
  FileUpload?: FileUploadResolvers<ContextType>
  GenericMutationResponse?: GenericMutationResponseResolvers<ContextType>
  GeolocationObject?: GeolocationObjectResolvers<ContextType>
  JSON?: GraphQLScalarType
  JobFairParticipantCompany?: JobFairParticipantCompanyResolvers<ContextType>
  Languages?: LanguagesResolvers<ContextType>
  LocalFile?: LocalFileResolvers<ContextType>
  LoginMutationResponse?: LoginMutationResponseResolvers<ContextType>
  MediaUpload?: MediaUploadResolvers<ContextType>
  MetaTag?: MetaTagResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  MutationResponse?: MutationResponseResolvers<ContextType>
  NFTAsset?: NFTAssetResolvers<ContextType>
  PageInfo?: PageInfoResolvers<ContextType>
  PreviouslyRecordedEvent?: PreviouslyRecordedEventResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  Question?: QuestionResolvers<ContextType>
  QuestionChoice?: QuestionChoiceResolvers<ContextType>
  QuestionOptions?: QuestionOptionsResolvers<ContextType>
  QuestionResults?: QuestionResultsResolvers<ContextType>
  ReportContentMutationResponse?: ReportContentMutationResponseResolvers<ContextType>
  ResetPasswordMutationResponse?: ResetPasswordMutationResponseResolvers<ContextType>
  SEO?: SEOResolvers<ContextType>
  SearchResultHit?: SearchResultHitResolvers<ContextType>
  SearchResults?: SearchResultsResolvers<ContextType>
  SignupMutationResponse?: SignupMutationResponseResolvers<ContextType>
  SiteFlag?: SiteFlagResolvers<ContextType>
  SiteFlagBoolean?: SiteFlagBooleanResolvers<ContextType>
  SiteFlagNumber?: SiteFlagNumberResolvers<ContextType>
  SiteFlagString?: SiteFlagStringResolvers<ContextType>
  SubdomainEntity?: SubdomainEntityResolvers<ContextType>
  Subscription?: SubscriptionResolvers<ContextType>
  Survey?: SurveyResolvers<ContextType>
  SurveyOptions?: SurveyOptionsResolvers<ContextType>
  SyncStatus?: SyncStatusResolvers<ContextType>
  Tag?: TagResolvers<ContextType>
  Thread?: ThreadResolvers<ContextType>
  ThreadAttachment?: ThreadAttachmentResolvers<ContextType>
  ThreadConnection?: ThreadConnectionResolvers<ContextType>
  ThreadEdge?: ThreadEdgeResolvers<ContextType>
  Upload?: GraphQLScalarType
  User?: UserResolvers<ContextType>
  UserAssetDetailsType?: UserAssetDetailsTypeResolvers<ContextType>
  UserAssetType?: UserAssetTypeResolvers<ContextType>
  UserAssetsConnection?: UserAssetsConnectionResolvers<ContextType>
  UserAssetsEdge?: UserAssetsEdgeResolvers<ContextType>
  UserConnection?: UserConnectionResolvers<ContextType>
  UserERC20Asset?: UserERC20AssetResolvers<ContextType>
  UserEdge?: UserEdgeResolvers<ContextType>
  UserEnrichments?: UserEnrichmentsResolvers<ContextType>
  UserNFTAsset?: UserNFTAssetResolvers<ContextType>
  UserPreference?: UserPreferenceResolvers<ContextType>
  Viewer?: ViewerResolvers<ContextType>
}>

export type ChangePasswordMutationMutationFn = Apollo.MutationFunction<
  ChangePasswordMutationResult,
  ChangePasswordMutationVariables
>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutationResult,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangePasswordMutationResult,
    ChangePasswordMutationVariables
  >(Operations.ChangePasswordMutation, options)
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>
export type ChangePasswordMutationMutationResult =
  Apollo.MutationResult<ChangePasswordMutationResult>
export type ChangePasswordMutationMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutationResult,
  ChangePasswordMutationVariables
>
export type LoginCryptoMutationMutationFn = Apollo.MutationFunction<
  LoginCryptoMutationResult,
  LoginCryptoMutationVariables
>

/**
 * __useLoginCryptoMutation__
 *
 * To run a mutation, you first call `useLoginCryptoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginCryptoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginCryptoMutation, { data, loading, error }] = useLoginCryptoMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useLoginCryptoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginCryptoMutationResult,
    LoginCryptoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LoginCryptoMutationResult,
    LoginCryptoMutationVariables
  >(Operations.LoginCryptoMutation, options)
}
export type LoginCryptoMutationHookResult = ReturnType<
  typeof useLoginCryptoMutation
>
export type LoginCryptoMutationMutationResult =
  Apollo.MutationResult<LoginCryptoMutationResult>
export type LoginCryptoMutationMutationOptions = Apollo.BaseMutationOptions<
  LoginCryptoMutationResult,
  LoginCryptoMutationVariables
>
export type LoginMutationMutationFn = Apollo.MutationFunction<
  LoginMutationResult,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutationResult,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutationResult, LoginMutationVariables>(
    Operations.LoginMutation,
    options
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationMutationResult =
  Apollo.MutationResult<LoginMutationResult>
export type LoginMutationMutationOptions = Apollo.BaseMutationOptions<
  LoginMutationResult,
  LoginMutationVariables
>
export type LoginWithAppleIdMutationMutationFn = Apollo.MutationFunction<
  LoginWithAppleIdMutationResult,
  LoginWithAppleIdMutationVariables
>

/**
 * __useLoginWithAppleIdMutation__
 *
 * To run a mutation, you first call `useLoginWithAppleIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithAppleIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithAppleIdMutation, { data, loading, error }] = useLoginWithAppleIdMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useLoginWithAppleIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithAppleIdMutationResult,
    LoginWithAppleIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LoginWithAppleIdMutationResult,
    LoginWithAppleIdMutationVariables
  >(Operations.LoginWithAppleIdMutation, options)
}
export type LoginWithAppleIdMutationHookResult = ReturnType<
  typeof useLoginWithAppleIdMutation
>
export type LoginWithAppleIdMutationMutationResult =
  Apollo.MutationResult<LoginWithAppleIdMutationResult>
export type LoginWithAppleIdMutationMutationOptions =
  Apollo.BaseMutationOptions<
    LoginWithAppleIdMutationResult,
    LoginWithAppleIdMutationVariables
  >
export type PrioritizeUserForAssetSyncMutationMutationFn =
  Apollo.MutationFunction<
    PrioritizeUserForAssetSyncMutationResult,
    PrioritizeUserForAssetSyncMutationVariables
  >

/**
 * __usePrioritizeUserForAssetSyncMutation__
 *
 * To run a mutation, you first call `usePrioritizeUserForAssetSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrioritizeUserForAssetSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prioritizeUserForAssetSyncMutation, { data, loading, error }] = usePrioritizeUserForAssetSyncMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrioritizeUserForAssetSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PrioritizeUserForAssetSyncMutationResult,
    PrioritizeUserForAssetSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PrioritizeUserForAssetSyncMutationResult,
    PrioritizeUserForAssetSyncMutationVariables
  >(Operations.PrioritizeUserForAssetSyncMutation, options)
}
export type PrioritizeUserForAssetSyncMutationHookResult = ReturnType<
  typeof usePrioritizeUserForAssetSyncMutation
>
export type PrioritizeUserForAssetSyncMutationMutationResult =
  Apollo.MutationResult<PrioritizeUserForAssetSyncMutationResult>
export type PrioritizeUserForAssetSyncMutationMutationOptions =
  Apollo.BaseMutationOptions<
    PrioritizeUserForAssetSyncMutationResult,
    PrioritizeUserForAssetSyncMutationVariables
  >
export type RecoverPasswordMutationMutationFn = Apollo.MutationFunction<
  RecoverPasswordMutationResult,
  RecoverPasswordMutationVariables
>

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRecoverPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecoverPasswordMutationResult,
    RecoverPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RecoverPasswordMutationResult,
    RecoverPasswordMutationVariables
  >(Operations.RecoverPasswordMutation, options)
}
export type RecoverPasswordMutationHookResult = ReturnType<
  typeof useRecoverPasswordMutation
>
export type RecoverPasswordMutationMutationResult =
  Apollo.MutationResult<RecoverPasswordMutationResult>
export type RecoverPasswordMutationMutationOptions = Apollo.BaseMutationOptions<
  RecoverPasswordMutationResult,
  RecoverPasswordMutationVariables
>
export type ReportContentMutationMutationFn = Apollo.MutationFunction<
  ReportContentMutationResult,
  ReportContentMutationVariables
>

/**
 * __useReportContentMutation__
 *
 * To run a mutation, you first call `useReportContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportContentMutation, { data, loading, error }] = useReportContentMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useReportContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportContentMutationResult,
    ReportContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReportContentMutationResult,
    ReportContentMutationVariables
  >(Operations.ReportContentMutation, options)
}
export type ReportContentMutationHookResult = ReturnType<
  typeof useReportContentMutation
>
export type ReportContentMutationMutationResult =
  Apollo.MutationResult<ReportContentMutationResult>
export type ReportContentMutationMutationOptions = Apollo.BaseMutationOptions<
  ReportContentMutationResult,
  ReportContentMutationVariables
>
export type ResendVerificationEmailMutationMutationFn = Apollo.MutationFunction<
  ResendVerificationEmailMutationResult,
  ResendVerificationEmailMutationVariables
>

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendVerificationEmailMutationResult,
    ResendVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResendVerificationEmailMutationResult,
    ResendVerificationEmailMutationVariables
  >(Operations.ResendVerificationEmailMutation, options)
}
export type ResendVerificationEmailMutationHookResult = ReturnType<
  typeof useResendVerificationEmailMutation
>
export type ResendVerificationEmailMutationMutationResult =
  Apollo.MutationResult<ResendVerificationEmailMutationResult>
export type ResendVerificationEmailMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ResendVerificationEmailMutationResult,
    ResendVerificationEmailMutationVariables
  >
export type ResetPasswordMutationMutationFn = Apollo.MutationFunction<
  ResetPasswordMutationResult,
  ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      reset: // value for 'reset'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutationResult,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPasswordMutationResult,
    ResetPasswordMutationVariables
  >(Operations.ResetPasswordMutation, options)
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>
export type ResetPasswordMutationMutationResult =
  Apollo.MutationResult<ResetPasswordMutationResult>
export type ResetPasswordMutationMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutationResult,
  ResetPasswordMutationVariables
>
export type SignupWithAppleIdMutationMutationFn = Apollo.MutationFunction<
  SignupWithAppleIdMutationResult,
  SignupWithAppleIdMutationVariables
>

/**
 * __useSignupWithAppleIdMutation__
 *
 * To run a mutation, you first call `useSignupWithAppleIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithAppleIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithAppleIdMutation, { data, loading, error }] = useSignupWithAppleIdMutation({
 *   variables: {
 *      signup: // value for 'signup'
 *   },
 * });
 */
export function useSignupWithAppleIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupWithAppleIdMutationResult,
    SignupWithAppleIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignupWithAppleIdMutationResult,
    SignupWithAppleIdMutationVariables
  >(Operations.SignupWithAppleIdMutation, options)
}
export type SignupWithAppleIdMutationHookResult = ReturnType<
  typeof useSignupWithAppleIdMutation
>
export type SignupWithAppleIdMutationMutationResult =
  Apollo.MutationResult<SignupWithAppleIdMutationResult>
export type SignupWithAppleIdMutationMutationOptions =
  Apollo.BaseMutationOptions<
    SignupWithAppleIdMutationResult,
    SignupWithAppleIdMutationVariables
  >
export type SetPreferenceMutationMutationFn = Apollo.MutationFunction<
  SetPreferenceMutationResult,
  SetPreferenceMutationVariables
>

/**
 * __useSetPreferenceMutation__
 *
 * To run a mutation, you first call `useSetPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreferenceMutation, { data, loading, error }] = useSetPreferenceMutation({
 *   variables: {
 *      preference: // value for 'preference'
 *   },
 * });
 */
export function useSetPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPreferenceMutationResult,
    SetPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetPreferenceMutationResult,
    SetPreferenceMutationVariables
  >(Operations.SetPreferenceMutation, options)
}
export type SetPreferenceMutationHookResult = ReturnType<
  typeof useSetPreferenceMutation
>
export type SetPreferenceMutationMutationResult =
  Apollo.MutationResult<SetPreferenceMutationResult>
export type SetPreferenceMutationMutationOptions = Apollo.BaseMutationOptions<
  SetPreferenceMutationResult,
  SetPreferenceMutationVariables
>
export type SignupMutationMutationFn = Apollo.MutationFunction<
  SignupMutationResult,
  SignupMutationVariables
>

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      signup: // value for 'signup'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutationResult,
    SignupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignupMutationResult, SignupMutationVariables>(
    Operations.SignupMutation,
    options
  )
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>
export type SignupMutationMutationResult =
  Apollo.MutationResult<SignupMutationResult>
export type SignupMutationMutationOptions = Apollo.BaseMutationOptions<
  SignupMutationResult,
  SignupMutationVariables
>
export type StartEventMutationMutationFn = Apollo.MutationFunction<
  StartEventMutationResult,
  StartEventMutationVariables
>

/**
 * __useStartEventMutation__
 *
 * To run a mutation, you first call `useStartEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEventMutation, { data, loading, error }] = useStartEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useStartEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartEventMutationResult,
    StartEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StartEventMutationResult,
    StartEventMutationVariables
  >(Operations.StartEventMutation, options)
}
export type StartEventMutationHookResult = ReturnType<
  typeof useStartEventMutation
>
export type StartEventMutationMutationResult =
  Apollo.MutationResult<StartEventMutationResult>
export type StartEventMutationMutationOptions = Apollo.BaseMutationOptions<
  StartEventMutationResult,
  StartEventMutationVariables
>
export type UpdateUserMutationMutationFn = Apollo.MutationFunction<
  UpdateUserMutationResult,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutationResult,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserMutationResult,
    UpdateUserMutationVariables
  >(Operations.UpdateUserMutation, options)
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationMutationResult =
  Apollo.MutationResult<UpdateUserMutationResult>
export type UpdateUserMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutationResult,
  UpdateUserMutationVariables
>
export type UploadAvatarMutationMutationFn = Apollo.MutationFunction<
  UploadAvatarMutationResult,
  UploadAvatarMutationVariables
>

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadAvatarMutationResult,
    UploadAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadAvatarMutationResult,
    UploadAvatarMutationVariables
  >(Operations.UploadAvatarMutation, options)
}
export type UploadAvatarMutationHookResult = ReturnType<
  typeof useUploadAvatarMutation
>
export type UploadAvatarMutationMutationResult =
  Apollo.MutationResult<UploadAvatarMutationResult>
export type UploadAvatarMutationMutationOptions = Apollo.BaseMutationOptions<
  UploadAvatarMutationResult,
  UploadAvatarMutationVariables
>
export type UploadAvatarURLMutationMutationFn = Apollo.MutationFunction<
  UploadAvatarURLMutationResult,
  UploadAvatarURLMutationVariables
>

/**
 * __useUploadAvatarURLMutation__
 *
 * To run a mutation, you first call `useUploadAvatarURLMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarURLMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarUrlMutation, { data, loading, error }] = useUploadAvatarURLMutation({
 *   variables: {
 *      avatarURL: // value for 'avatarURL'
 *   },
 * });
 */
export function useUploadAvatarURLMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadAvatarURLMutationResult,
    UploadAvatarURLMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadAvatarURLMutationResult,
    UploadAvatarURLMutationVariables
  >(Operations.UploadAvatarURLMutation, options)
}
export type UploadAvatarURLMutationHookResult = ReturnType<
  typeof useUploadAvatarURLMutation
>
export type UploadAvatarURLMutationMutationResult =
  Apollo.MutationResult<UploadAvatarURLMutationResult>
export type UploadAvatarURLMutationMutationOptions = Apollo.BaseMutationOptions<
  UploadAvatarURLMutationResult,
  UploadAvatarURLMutationVariables
>
export type VerifyEmailMutationMutationFn = Apollo.MutationFunction<
  VerifyEmailMutationResult,
  VerifyEmailMutationVariables
>

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      emailVerificationKey: // value for 'emailVerificationKey'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutationResult,
    VerifyEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VerifyEmailMutationResult,
    VerifyEmailMutationVariables
  >(Operations.VerifyEmailMutation, options)
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>
export type VerifyEmailMutationMutationResult =
  Apollo.MutationResult<VerifyEmailMutationResult>
export type VerifyEmailMutationMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutationResult,
  VerifyEmailMutationVariables
>
export type SubmitCollectiveApplicationMutationMutationFn =
  Apollo.MutationFunction<
    SubmitCollectiveApplicationMutationResult,
    SubmitCollectiveApplicationMutationVariables
  >

/**
 * __useSubmitCollectiveApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitCollectiveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCollectiveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCollectiveApplicationMutation, { data, loading, error }] = useSubmitCollectiveApplicationMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSubmitCollectiveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitCollectiveApplicationMutationResult,
    SubmitCollectiveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitCollectiveApplicationMutationResult,
    SubmitCollectiveApplicationMutationVariables
  >(Operations.SubmitCollectiveApplicationMutation, options)
}
export type SubmitCollectiveApplicationMutationHookResult = ReturnType<
  typeof useSubmitCollectiveApplicationMutation
>
export type SubmitCollectiveApplicationMutationMutationResult =
  Apollo.MutationResult<SubmitCollectiveApplicationMutationResult>
export type SubmitCollectiveApplicationMutationMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitCollectiveApplicationMutationResult,
    SubmitCollectiveApplicationMutationVariables
  >
export type UpdateCollectiveApplicationMutationMutationFn =
  Apollo.MutationFunction<
    UpdateCollectiveApplicationMutationResult,
    UpdateCollectiveApplicationMutationVariables
  >

/**
 * __useUpdateCollectiveApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateCollectiveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectiveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectiveApplicationMutation, { data, loading, error }] = useUpdateCollectiveApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateCollectiveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectiveApplicationMutationResult,
    UpdateCollectiveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectiveApplicationMutationResult,
    UpdateCollectiveApplicationMutationVariables
  >(Operations.UpdateCollectiveApplicationMutation, options)
}
export type UpdateCollectiveApplicationMutationHookResult = ReturnType<
  typeof useUpdateCollectiveApplicationMutation
>
export type UpdateCollectiveApplicationMutationMutationResult =
  Apollo.MutationResult<UpdateCollectiveApplicationMutationResult>
export type UpdateCollectiveApplicationMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectiveApplicationMutationResult,
    UpdateCollectiveApplicationMutationVariables
  >
export type UploadCollectiveApplicationAvatarMutationMutationFn =
  Apollo.MutationFunction<
    UploadCollectiveApplicationAvatarMutationResult,
    UploadCollectiveApplicationAvatarMutationVariables
  >

/**
 * __useUploadCollectiveApplicationAvatarMutation__
 *
 * To run a mutation, you first call `useUploadCollectiveApplicationAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCollectiveApplicationAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCollectiveApplicationAvatarMutation, { data, loading, error }] = useUploadCollectiveApplicationAvatarMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCollectiveApplicationAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCollectiveApplicationAvatarMutationResult,
    UploadCollectiveApplicationAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadCollectiveApplicationAvatarMutationResult,
    UploadCollectiveApplicationAvatarMutationVariables
  >(Operations.UploadCollectiveApplicationAvatarMutation, options)
}
export type UploadCollectiveApplicationAvatarMutationHookResult = ReturnType<
  typeof useUploadCollectiveApplicationAvatarMutation
>
export type UploadCollectiveApplicationAvatarMutationMutationResult =
  Apollo.MutationResult<UploadCollectiveApplicationAvatarMutationResult>
export type UploadCollectiveApplicationAvatarMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UploadCollectiveApplicationAvatarMutationResult,
    UploadCollectiveApplicationAvatarMutationVariables
  >
export type CreateCollectiveDraftMutationMutationFn = Apollo.MutationFunction<
  CreateCollectiveDraftMutationResult,
  CreateCollectiveDraftMutationVariables
>

/**
 * __useCreateCollectiveDraftMutation__
 *
 * To run a mutation, you first call `useCreateCollectiveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectiveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectiveDraftMutation, { data, loading, error }] = useCreateCollectiveDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectiveDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectiveDraftMutationResult,
    CreateCollectiveDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCollectiveDraftMutationResult,
    CreateCollectiveDraftMutationVariables
  >(Operations.CreateCollectiveDraftMutation, options)
}
export type CreateCollectiveDraftMutationHookResult = ReturnType<
  typeof useCreateCollectiveDraftMutation
>
export type CreateCollectiveDraftMutationMutationResult =
  Apollo.MutationResult<CreateCollectiveDraftMutationResult>
export type CreateCollectiveDraftMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCollectiveDraftMutationResult,
    CreateCollectiveDraftMutationVariables
  >
export type deleteCollectiveDraftMutationMutationFn = Apollo.MutationFunction<
  deleteCollectiveDraftMutationResult,
  deleteCollectiveDraftMutationVariables
>

/**
 * __usedeleteCollectiveDraftMutation__
 *
 * To run a mutation, you first call `usedeleteCollectiveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usedeleteCollectiveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectiveDraftMutation, { data, loading, error }] = usedeleteCollectiveDraftMutation({
 *   variables: {
 *      collectiveDraftId: // value for 'collectiveDraftId'
 *   },
 * });
 */
export function usedeleteCollectiveDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    deleteCollectiveDraftMutationResult,
    deleteCollectiveDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    deleteCollectiveDraftMutationResult,
    deleteCollectiveDraftMutationVariables
  >(Operations.deleteCollectiveDraftMutation, options)
}
export type deleteCollectiveDraftMutationHookResult = ReturnType<
  typeof usedeleteCollectiveDraftMutation
>
export type deleteCollectiveDraftMutationMutationResult =
  Apollo.MutationResult<deleteCollectiveDraftMutationResult>
export type deleteCollectiveDraftMutationMutationOptions =
  Apollo.BaseMutationOptions<
    deleteCollectiveDraftMutationResult,
    deleteCollectiveDraftMutationVariables
  >
export type UpdateCollectiveDraftMutationMutationFn = Apollo.MutationFunction<
  UpdateCollectiveDraftMutationResult,
  UpdateCollectiveDraftMutationVariables
>

/**
 * __useUpdateCollectiveDraftMutation__
 *
 * To run a mutation, you first call `useUpdateCollectiveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectiveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectiveDraftMutation, { data, loading, error }] = useUpdateCollectiveDraftMutation({
 *   variables: {
 *      collectiveDraftId: // value for 'collectiveDraftId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectiveDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCollectiveDraftMutationResult,
    UpdateCollectiveDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCollectiveDraftMutationResult,
    UpdateCollectiveDraftMutationVariables
  >(Operations.UpdateCollectiveDraftMutation, options)
}
export type UpdateCollectiveDraftMutationHookResult = ReturnType<
  typeof useUpdateCollectiveDraftMutation
>
export type UpdateCollectiveDraftMutationMutationResult =
  Apollo.MutationResult<UpdateCollectiveDraftMutationResult>
export type UpdateCollectiveDraftMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCollectiveDraftMutationResult,
    UpdateCollectiveDraftMutationVariables
  >
export type PostCollectiveSubmissionLogMutationMutationFn =
  Apollo.MutationFunction<
    PostCollectiveSubmissionLogMutationResult,
    PostCollectiveSubmissionLogMutationVariables
  >

/**
 * __usePostCollectiveSubmissionLogMutation__
 *
 * To run a mutation, you first call `usePostCollectiveSubmissionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCollectiveSubmissionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCollectiveSubmissionLogMutation, { data, loading, error }] = usePostCollectiveSubmissionLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *   },
 * });
 */
export function usePostCollectiveSubmissionLogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostCollectiveSubmissionLogMutationResult,
    PostCollectiveSubmissionLogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PostCollectiveSubmissionLogMutationResult,
    PostCollectiveSubmissionLogMutationVariables
  >(Operations.PostCollectiveSubmissionLogMutation, options)
}
export type PostCollectiveSubmissionLogMutationHookResult = ReturnType<
  typeof usePostCollectiveSubmissionLogMutation
>
export type PostCollectiveSubmissionLogMutationMutationResult =
  Apollo.MutationResult<PostCollectiveSubmissionLogMutationResult>
export type PostCollectiveSubmissionLogMutationMutationOptions =
  Apollo.BaseMutationOptions<
    PostCollectiveSubmissionLogMutationResult,
    PostCollectiveSubmissionLogMutationVariables
  >
export type AgreeToCollectiveTosMutationMutationFn = Apollo.MutationFunction<
  AgreeToCollectiveTosMutationResult,
  AgreeToCollectiveTosMutationVariables
>

/**
 * __useAgreeToCollectiveTosMutation__
 *
 * To run a mutation, you first call `useAgreeToCollectiveTosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeToCollectiveTosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeToCollectiveTosMutation, { data, loading, error }] = useAgreeToCollectiveTosMutation({
 *   variables: {
 *      type: // value for 'type'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgreeToCollectiveTosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgreeToCollectiveTosMutationResult,
    AgreeToCollectiveTosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AgreeToCollectiveTosMutationResult,
    AgreeToCollectiveTosMutationVariables
  >(Operations.AgreeToCollectiveTosMutation, options)
}
export type AgreeToCollectiveTosMutationHookResult = ReturnType<
  typeof useAgreeToCollectiveTosMutation
>
export type AgreeToCollectiveTosMutationMutationResult =
  Apollo.MutationResult<AgreeToCollectiveTosMutationResult>
export type AgreeToCollectiveTosMutationMutationOptions =
  Apollo.BaseMutationOptions<
    AgreeToCollectiveTosMutationResult,
    AgreeToCollectiveTosMutationVariables
  >
export type AnswerCollectiveProposalSurveyMutationMutationFn =
  Apollo.MutationFunction<
    AnswerCollectiveProposalSurveyMutationResult,
    AnswerCollectiveProposalSurveyMutationVariables
  >

/**
 * __useAnswerCollectiveProposalSurveyMutation__
 *
 * To run a mutation, you first call `useAnswerCollectiveProposalSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerCollectiveProposalSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerCollectiveProposalSurveyMutation, { data, loading, error }] = useAnswerCollectiveProposalSurveyMutation({
 *   variables: {
 *      survey: // value for 'survey'
 *   },
 * });
 */
export function useAnswerCollectiveProposalSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerCollectiveProposalSurveyMutationResult,
    AnswerCollectiveProposalSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AnswerCollectiveProposalSurveyMutationResult,
    AnswerCollectiveProposalSurveyMutationVariables
  >(Operations.AnswerCollectiveProposalSurveyMutation, options)
}
export type AnswerCollectiveProposalSurveyMutationHookResult = ReturnType<
  typeof useAnswerCollectiveProposalSurveyMutation
>
export type AnswerCollectiveProposalSurveyMutationMutationResult =
  Apollo.MutationResult<AnswerCollectiveProposalSurveyMutationResult>
export type AnswerCollectiveProposalSurveyMutationMutationOptions =
  Apollo.BaseMutationOptions<
    AnswerCollectiveProposalSurveyMutationResult,
    AnswerCollectiveProposalSurveyMutationVariables
  >
export type AuthCryptoMutationMutationFn = Apollo.MutationFunction<
  AuthCryptoMutationResult,
  AuthCryptoMutationVariables
>

/**
 * __useAuthCryptoMutation__
 *
 * To run a mutation, you first call `useAuthCryptoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthCryptoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authCryptoMutation, { data, loading, error }] = useAuthCryptoMutation({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useAuthCryptoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthCryptoMutationResult,
    AuthCryptoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthCryptoMutationResult,
    AuthCryptoMutationVariables
  >(Operations.AuthCryptoMutation, options)
}
export type AuthCryptoMutationHookResult = ReturnType<
  typeof useAuthCryptoMutation
>
export type AuthCryptoMutationMutationResult =
  Apollo.MutationResult<AuthCryptoMutationResult>
export type AuthCryptoMutationMutationOptions = Apollo.BaseMutationOptions<
  AuthCryptoMutationResult,
  AuthCryptoMutationVariables
>
export type BanMemberFromEventsMutationMutationFn = Apollo.MutationFunction<
  BanMemberFromEventsMutationResult,
  BanMemberFromEventsMutationVariables
>

/**
 * __useBanMemberFromEventsMutation__
 *
 * To run a mutation, you first call `useBanMemberFromEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanMemberFromEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banMemberFromEventsMutation, { data, loading, error }] = useBanMemberFromEventsMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBanMemberFromEventsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BanMemberFromEventsMutationResult,
    BanMemberFromEventsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BanMemberFromEventsMutationResult,
    BanMemberFromEventsMutationVariables
  >(Operations.BanMemberFromEventsMutation, options)
}
export type BanMemberFromEventsMutationHookResult = ReturnType<
  typeof useBanMemberFromEventsMutation
>
export type BanMemberFromEventsMutationMutationResult =
  Apollo.MutationResult<BanMemberFromEventsMutationResult>
export type BanMemberFromEventsMutationMutationOptions =
  Apollo.BaseMutationOptions<
    BanMemberFromEventsMutationResult,
    BanMemberFromEventsMutationVariables
  >
export type BatchProposalsMutationMutationFn = Apollo.MutationFunction<
  BatchProposalsMutationResult,
  BatchProposalsMutationVariables
>

/**
 * __useBatchProposalsMutation__
 *
 * To run a mutation, you first call `useBatchProposalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchProposalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchProposalsMutation, { data, loading, error }] = useBatchProposalsMutation({
 *   variables: {
 *      proposalIds: // value for 'proposalIds'
 *   },
 * });
 */
export function useBatchProposalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchProposalsMutationResult,
    BatchProposalsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchProposalsMutationResult,
    BatchProposalsMutationVariables
  >(Operations.BatchProposalsMutation, options)
}
export type BatchProposalsMutationHookResult = ReturnType<
  typeof useBatchProposalsMutation
>
export type BatchProposalsMutationMutationResult =
  Apollo.MutationResult<BatchProposalsMutationResult>
export type BatchProposalsMutationMutationOptions = Apollo.BaseMutationOptions<
  BatchProposalsMutationResult,
  BatchProposalsMutationVariables
>
export type CancelProposalMutationMutationFn = Apollo.MutationFunction<
  CancelProposalMutationResult,
  CancelProposalMutationVariables
>

/**
 * __useCancelProposalMutation__
 *
 * To run a mutation, you first call `useCancelProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProposalMutation, { data, loading, error }] = useCancelProposalMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useCancelProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelProposalMutationResult,
    CancelProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelProposalMutationResult,
    CancelProposalMutationVariables
  >(Operations.CancelProposalMutation, options)
}
export type CancelProposalMutationHookResult = ReturnType<
  typeof useCancelProposalMutation
>
export type CancelProposalMutationMutationResult =
  Apollo.MutationResult<CancelProposalMutationResult>
export type CancelProposalMutationMutationOptions = Apollo.BaseMutationOptions<
  CancelProposalMutationResult,
  CancelProposalMutationVariables
>
export type CastVoteMutationMutationFn = Apollo.MutationFunction<
  CastVoteMutationResult,
  CastVoteMutationVariables
>

/**
 * __useCastVoteMutation__
 *
 * To run a mutation, you first call `useCastVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCastVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [castVoteMutation, { data, loading, error }] = useCastVoteMutation({
 *   variables: {
 *      vote: // value for 'vote'
 *   },
 * });
 */
export function useCastVoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CastVoteMutationResult,
    CastVoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CastVoteMutationResult, CastVoteMutationVariables>(
    Operations.CastVoteMutation,
    options
  )
}
export type CastVoteMutationHookResult = ReturnType<typeof useCastVoteMutation>
export type CastVoteMutationMutationResult =
  Apollo.MutationResult<CastVoteMutationResult>
export type CastVoteMutationMutationOptions = Apollo.BaseMutationOptions<
  CastVoteMutationResult,
  CastVoteMutationVariables
>
export type CastVoteWithDelegationsMutationMutationFn = Apollo.MutationFunction<
  CastVoteWithDelegationsMutationResult,
  CastVoteWithDelegationsMutationVariables
>

/**
 * __useCastVoteWithDelegationsMutation__
 *
 * To run a mutation, you first call `useCastVoteWithDelegationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCastVoteWithDelegationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [castVoteWithDelegationsMutation, { data, loading, error }] = useCastVoteWithDelegationsMutation({
 *   variables: {
 *      vote: // value for 'vote'
 *   },
 * });
 */
export function useCastVoteWithDelegationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CastVoteWithDelegationsMutationResult,
    CastVoteWithDelegationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CastVoteWithDelegationsMutationResult,
    CastVoteWithDelegationsMutationVariables
  >(Operations.CastVoteWithDelegationsMutation, options)
}
export type CastVoteWithDelegationsMutationHookResult = ReturnType<
  typeof useCastVoteWithDelegationsMutation
>
export type CastVoteWithDelegationsMutationMutationResult =
  Apollo.MutationResult<CastVoteWithDelegationsMutationResult>
export type CastVoteWithDelegationsMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CastVoteWithDelegationsMutationResult,
    CastVoteWithDelegationsMutationVariables
  >
export type CreateCollectiveMutationMutationFn = Apollo.MutationFunction<
  CreateCollectiveMutationResult,
  CreateCollectiveMutationVariables
>

/**
 * __useCreateCollectiveMutation__
 *
 * To run a mutation, you first call `useCreateCollectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectiveMutation, { data, loading, error }] = useCreateCollectiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCollectiveMutationResult,
    CreateCollectiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCollectiveMutationResult,
    CreateCollectiveMutationVariables
  >(Operations.CreateCollectiveMutation, options)
}
export type CreateCollectiveMutationHookResult = ReturnType<
  typeof useCreateCollectiveMutation
>
export type CreateCollectiveMutationMutationResult =
  Apollo.MutationResult<CreateCollectiveMutationResult>
export type CreateCollectiveMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCollectiveMutationResult,
    CreateCollectiveMutationVariables
  >
export type CreateMemberFileExportMutationMutationFn = Apollo.MutationFunction<
  CreateMemberFileExportMutationResult,
  CreateMemberFileExportMutationVariables
>

/**
 * __useCreateMemberFileExportMutation__
 *
 * To run a mutation, you first call `useCreateMemberFileExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberFileExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberFileExportMutation, { data, loading, error }] = useCreateMemberFileExportMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCreateMemberFileExportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberFileExportMutationResult,
    CreateMemberFileExportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMemberFileExportMutationResult,
    CreateMemberFileExportMutationVariables
  >(Operations.CreateMemberFileExportMutation, options)
}
export type CreateMemberFileExportMutationHookResult = ReturnType<
  typeof useCreateMemberFileExportMutation
>
export type CreateMemberFileExportMutationMutationResult =
  Apollo.MutationResult<CreateMemberFileExportMutationResult>
export type CreateMemberFileExportMutationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMemberFileExportMutationResult,
    CreateMemberFileExportMutationVariables
  >
export type CreateProposalMutationMutationFn = Apollo.MutationFunction<
  CreateProposalMutationResult,
  CreateProposalMutationVariables
>

/**
 * __useCreateProposalMutation__
 *
 * To run a mutation, you first call `useCreateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalMutation, { data, loading, error }] = useCreateProposalMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      proposal: // value for 'proposal'
 *   },
 * });
 */
export function useCreateProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProposalMutationResult,
    CreateProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProposalMutationResult,
    CreateProposalMutationVariables
  >(Operations.CreateProposalMutation, options)
}
export type CreateProposalMutationHookResult = ReturnType<
  typeof useCreateProposalMutation
>
export type CreateProposalMutationMutationResult =
  Apollo.MutationResult<CreateProposalMutationResult>
export type CreateProposalMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateProposalMutationResult,
  CreateProposalMutationVariables
>
export type DeleteAnnouncementMutationMutationFn = Apollo.MutationFunction<
  DeleteAnnouncementMutationResult,
  DeleteAnnouncementMutationVariables
>

/**
 * __useDeleteAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementMutation, { data, loading, error }] = useDeleteAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnnouncementMutationResult,
    DeleteAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAnnouncementMutationResult,
    DeleteAnnouncementMutationVariables
  >(Operations.DeleteAnnouncementMutation, options)
}
export type DeleteAnnouncementMutationHookResult = ReturnType<
  typeof useDeleteAnnouncementMutation
>
export type DeleteAnnouncementMutationMutationResult =
  Apollo.MutationResult<DeleteAnnouncementMutationResult>
export type DeleteAnnouncementMutationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAnnouncementMutationResult,
    DeleteAnnouncementMutationVariables
  >
export type EditAnnouncementMutationMutationFn = Apollo.MutationFunction<
  EditAnnouncementMutationResult,
  EditAnnouncementMutationVariables
>

/**
 * __useEditAnnouncementMutation__
 *
 * To run a mutation, you first call `useEditAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnnouncementMutation, { data, loading, error }] = useEditAnnouncementMutation({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *      announcement: // value for 'announcement'
 *   },
 * });
 */
export function useEditAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAnnouncementMutationResult,
    EditAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditAnnouncementMutationResult,
    EditAnnouncementMutationVariables
  >(Operations.EditAnnouncementMutation, options)
}
export type EditAnnouncementMutationHookResult = ReturnType<
  typeof useEditAnnouncementMutation
>
export type EditAnnouncementMutationMutationResult =
  Apollo.MutationResult<EditAnnouncementMutationResult>
export type EditAnnouncementMutationMutationOptions =
  Apollo.BaseMutationOptions<
    EditAnnouncementMutationResult,
    EditAnnouncementMutationVariables
  >
export type ExecProposalMutationMutationFn = Apollo.MutationFunction<
  ExecProposalMutationResult,
  ExecProposalMutationVariables
>

/**
 * __useExecProposalMutation__
 *
 * To run a mutation, you first call `useExecProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [execProposalMutation, { data, loading, error }] = useExecProposalMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      txHash: // value for 'txHash'
 *      blockNumber: // value for 'blockNumber'
 *   },
 * });
 */
export function useExecProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecProposalMutationResult,
    ExecProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExecProposalMutationResult,
    ExecProposalMutationVariables
  >(Operations.ExecProposalMutation, options)
}
export type ExecProposalMutationHookResult = ReturnType<
  typeof useExecProposalMutation
>
export type ExecProposalMutationMutationResult =
  Apollo.MutationResult<ExecProposalMutationResult>
export type ExecProposalMutationMutationOptions = Apollo.BaseMutationOptions<
  ExecProposalMutationResult,
  ExecProposalMutationVariables
>
export type JoinExternalCollectiveMutationMutationFn = Apollo.MutationFunction<
  JoinExternalCollectiveMutationResult,
  JoinExternalCollectiveMutationVariables
>

/**
 * __useJoinExternalCollectiveMutation__
 *
 * To run a mutation, you first call `useJoinExternalCollectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinExternalCollectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinExternalCollectiveMutation, { data, loading, error }] = useJoinExternalCollectiveMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useJoinExternalCollectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinExternalCollectiveMutationResult,
    JoinExternalCollectiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    JoinExternalCollectiveMutationResult,
    JoinExternalCollectiveMutationVariables
  >(Operations.JoinExternalCollectiveMutation, options)
}
export type JoinExternalCollectiveMutationHookResult = ReturnType<
  typeof useJoinExternalCollectiveMutation
>
export type JoinExternalCollectiveMutationMutationResult =
  Apollo.MutationResult<JoinExternalCollectiveMutationResult>
export type JoinExternalCollectiveMutationMutationOptions =
  Apollo.BaseMutationOptions<
    JoinExternalCollectiveMutationResult,
    JoinExternalCollectiveMutationVariables
  >
export type PostAnnouncementMutationMutationFn = Apollo.MutationFunction<
  PostAnnouncementMutationResult,
  PostAnnouncementMutationVariables
>

/**
 * __usePostAnnouncementMutation__
 *
 * To run a mutation, you first call `usePostAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postAnnouncementMutation, { data, loading, error }] = usePostAnnouncementMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      announcement: // value for 'announcement'
 *   },
 * });
 */
export function usePostAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostAnnouncementMutationResult,
    PostAnnouncementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PostAnnouncementMutationResult,
    PostAnnouncementMutationVariables
  >(Operations.PostAnnouncementMutation, options)
}
export type PostAnnouncementMutationHookResult = ReturnType<
  typeof usePostAnnouncementMutation
>
export type PostAnnouncementMutationMutationResult =
  Apollo.MutationResult<PostAnnouncementMutationResult>
export type PostAnnouncementMutationMutationOptions =
  Apollo.BaseMutationOptions<
    PostAnnouncementMutationResult,
    PostAnnouncementMutationVariables
  >
export type ProposeCollectiveMembershipMutationMutationFn =
  Apollo.MutationFunction<
    ProposeCollectiveMembershipMutationResult,
    ProposeCollectiveMembershipMutationVariables
  >

/**
 * __useProposeCollectiveMembershipMutation__
 *
 * To run a mutation, you first call `useProposeCollectiveMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProposeCollectiveMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proposeCollectiveMembershipMutation, { data, loading, error }] = useProposeCollectiveMembershipMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useProposeCollectiveMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProposeCollectiveMembershipMutationResult,
    ProposeCollectiveMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ProposeCollectiveMembershipMutationResult,
    ProposeCollectiveMembershipMutationVariables
  >(Operations.ProposeCollectiveMembershipMutation, options)
}
export type ProposeCollectiveMembershipMutationHookResult = ReturnType<
  typeof useProposeCollectiveMembershipMutation
>
export type ProposeCollectiveMembershipMutationMutationResult =
  Apollo.MutationResult<ProposeCollectiveMembershipMutationResult>
export type ProposeCollectiveMembershipMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ProposeCollectiveMembershipMutationResult,
    ProposeCollectiveMembershipMutationVariables
  >
export type RequestCollectiveMembershipMutationMutationFn =
  Apollo.MutationFunction<
    RequestCollectiveMembershipMutationResult,
    RequestCollectiveMembershipMutationVariables
  >

/**
 * __useRequestCollectiveMembershipMutation__
 *
 * To run a mutation, you first call `useRequestCollectiveMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCollectiveMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCollectiveMembershipMutation, { data, loading, error }] = useRequestCollectiveMembershipMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useRequestCollectiveMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCollectiveMembershipMutationResult,
    RequestCollectiveMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestCollectiveMembershipMutationResult,
    RequestCollectiveMembershipMutationVariables
  >(Operations.RequestCollectiveMembershipMutation, options)
}
export type RequestCollectiveMembershipMutationHookResult = ReturnType<
  typeof useRequestCollectiveMembershipMutation
>
export type RequestCollectiveMembershipMutationMutationResult =
  Apollo.MutationResult<RequestCollectiveMembershipMutationResult>
export type RequestCollectiveMembershipMutationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestCollectiveMembershipMutationResult,
    RequestCollectiveMembershipMutationVariables
  >
export type ResetExecutionMutationMutationFn = Apollo.MutationFunction<
  ResetExecutionMutationResult,
  ResetExecutionMutationVariables
>

/**
 * __useResetExecutionMutation__
 *
 * To run a mutation, you first call `useResetExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetExecutionMutation, { data, loading, error }] = useResetExecutionMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useResetExecutionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetExecutionMutationResult,
    ResetExecutionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetExecutionMutationResult,
    ResetExecutionMutationVariables
  >(Operations.ResetExecutionMutation, options)
}
export type ResetExecutionMutationHookResult = ReturnType<
  typeof useResetExecutionMutation
>
export type ResetExecutionMutationMutationResult =
  Apollo.MutationResult<ResetExecutionMutationResult>
export type ResetExecutionMutationMutationOptions = Apollo.BaseMutationOptions<
  ResetExecutionMutationResult,
  ResetExecutionMutationVariables
>
export type SignProposalMutationMutationFn = Apollo.MutationFunction<
  SignProposalMutationResult,
  SignProposalMutationVariables
>

/**
 * __useSignProposalMutation__
 *
 * To run a mutation, you first call `useSignProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signProposalMutation, { data, loading, error }] = useSignProposalMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      signature: // value for 'signature'
 *      nonce: // value for 'nonce'
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useSignProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignProposalMutationResult,
    SignProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignProposalMutationResult,
    SignProposalMutationVariables
  >(Operations.SignProposalMutation, options)
}
export type SignProposalMutationHookResult = ReturnType<
  typeof useSignProposalMutation
>
export type SignProposalMutationMutationResult =
  Apollo.MutationResult<SignProposalMutationResult>
export type SignProposalMutationMutationOptions = Apollo.BaseMutationOptions<
  SignProposalMutationResult,
  SignProposalMutationVariables
>
export type SyncCollectiveMutationMutationFn = Apollo.MutationFunction<
  SyncCollectiveMutationResult,
  SyncCollectiveMutationVariables
>

/**
 * __useSyncCollectiveMutation__
 *
 * To run a mutation, you first call `useSyncCollectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncCollectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncCollectiveMutation, { data, loading, error }] = useSyncCollectiveMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      proposalConnection: // value for 'proposalConnection'
 *   },
 * });
 */
export function useSyncCollectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncCollectiveMutationResult,
    SyncCollectiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SyncCollectiveMutationResult,
    SyncCollectiveMutationVariables
  >(Operations.SyncCollectiveMutation, options)
}
export type SyncCollectiveMutationHookResult = ReturnType<
  typeof useSyncCollectiveMutation
>
export type SyncCollectiveMutationMutationResult =
  Apollo.MutationResult<SyncCollectiveMutationResult>
export type SyncCollectiveMutationMutationOptions = Apollo.BaseMutationOptions<
  SyncCollectiveMutationResult,
  SyncCollectiveMutationVariables
>
export type UnlinkWalletMutationMutationFn = Apollo.MutationFunction<
  UnlinkWalletMutationResult,
  UnlinkWalletMutationVariables
>

/**
 * __useUnlinkWalletMutation__
 *
 * To run a mutation, you first call `useUnlinkWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkWalletMutation, { data, loading, error }] = useUnlinkWalletMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUnlinkWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkWalletMutationResult,
    UnlinkWalletMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnlinkWalletMutationResult,
    UnlinkWalletMutationVariables
  >(Operations.UnlinkWalletMutation, options)
}
export type UnlinkWalletMutationHookResult = ReturnType<
  typeof useUnlinkWalletMutation
>
export type UnlinkWalletMutationMutationResult =
  Apollo.MutationResult<UnlinkWalletMutationResult>
export type UnlinkWalletMutationMutationOptions = Apollo.BaseMutationOptions<
  UnlinkWalletMutationResult,
  UnlinkWalletMutationVariables
>
export type UploadCollectiveAvatarMutationMutationFn = Apollo.MutationFunction<
  UploadCollectiveAvatarMutationResult,
  UploadCollectiveAvatarMutationVariables
>

/**
 * __useUploadCollectiveAvatarMutation__
 *
 * To run a mutation, you first call `useUploadCollectiveAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCollectiveAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCollectiveAvatarMutation, { data, loading, error }] = useUploadCollectiveAvatarMutation({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCollectiveAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCollectiveAvatarMutationResult,
    UploadCollectiveAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadCollectiveAvatarMutationResult,
    UploadCollectiveAvatarMutationVariables
  >(Operations.UploadCollectiveAvatarMutation, options)
}
export type UploadCollectiveAvatarMutationHookResult = ReturnType<
  typeof useUploadCollectiveAvatarMutation
>
export type UploadCollectiveAvatarMutationMutationResult =
  Apollo.MutationResult<UploadCollectiveAvatarMutationResult>
export type UploadCollectiveAvatarMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UploadCollectiveAvatarMutationResult,
    UploadCollectiveAvatarMutationVariables
  >
export type UploadCollectiveDraftAvatarMutationMutationFn =
  Apollo.MutationFunction<
    UploadCollectiveDraftAvatarMutationResult,
    UploadCollectiveDraftAvatarMutationVariables
  >

/**
 * __useUploadCollectiveDraftAvatarMutation__
 *
 * To run a mutation, you first call `useUploadCollectiveDraftAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCollectiveDraftAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCollectiveDraftAvatarMutation, { data, loading, error }] = useUploadCollectiveDraftAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *      collectiveDraftId: // value for 'collectiveDraftId'
 *   },
 * });
 */
export function useUploadCollectiveDraftAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCollectiveDraftAvatarMutationResult,
    UploadCollectiveDraftAvatarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadCollectiveDraftAvatarMutationResult,
    UploadCollectiveDraftAvatarMutationVariables
  >(Operations.UploadCollectiveDraftAvatarMutation, options)
}
export type UploadCollectiveDraftAvatarMutationHookResult = ReturnType<
  typeof useUploadCollectiveDraftAvatarMutation
>
export type UploadCollectiveDraftAvatarMutationMutationResult =
  Apollo.MutationResult<UploadCollectiveDraftAvatarMutationResult>
export type UploadCollectiveDraftAvatarMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UploadCollectiveDraftAvatarMutationResult,
    UploadCollectiveDraftAvatarMutationVariables
  >
export type CreateCategoryMutationMutationFn = Apollo.MutationFunction<
  CreateCategoryMutationResult,
  CreateCategoryMutationVariables
>

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *      categoryDetails: // value for 'categoryDetails'
 *   },
 * });
 */
export function useCreateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCategoryMutationResult,
    CreateCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCategoryMutationResult,
    CreateCategoryMutationVariables
  >(Operations.CreateCategoryMutation, options)
}
export type CreateCategoryMutationHookResult = ReturnType<
  typeof useCreateCategoryMutation
>
export type CreateCategoryMutationMutationResult =
  Apollo.MutationResult<CreateCategoryMutationResult>
export type CreateCategoryMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateCategoryMutationResult,
  CreateCategoryMutationVariables
>
export type CreateCommentMutationMutationFn = Apollo.MutationFunction<
  CreateCommentMutationResult,
  CreateCommentMutationVariables
>

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      commentDetails: // value for 'commentDetails'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentMutationResult,
    CreateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCommentMutationResult,
    CreateCommentMutationVariables
  >(Operations.CreateCommentMutation, options)
}
export type CreateCommentMutationHookResult = ReturnType<
  typeof useCreateCommentMutation
>
export type CreateCommentMutationMutationResult =
  Apollo.MutationResult<CreateCommentMutationResult>
export type CreateCommentMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutationResult,
  CreateCommentMutationVariables
>
export type CreateThreadMutationMutationFn = Apollo.MutationFunction<
  CreateThreadMutationResult,
  CreateThreadMutationVariables
>

/**
 * __useCreateThreadMutation__
 *
 * To run a mutation, you first call `useCreateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThreadMutation, { data, loading, error }] = useCreateThreadMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      threadDetails: // value for 'threadDetails'
 *   },
 * });
 */
export function useCreateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThreadMutationResult,
    CreateThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateThreadMutationResult,
    CreateThreadMutationVariables
  >(Operations.CreateThreadMutation, options)
}
export type CreateThreadMutationHookResult = ReturnType<
  typeof useCreateThreadMutation
>
export type CreateThreadMutationMutationResult =
  Apollo.MutationResult<CreateThreadMutationResult>
export type CreateThreadMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateThreadMutationResult,
  CreateThreadMutationVariables
>
export type DeleteCategoryMutationMutationFn = Apollo.MutationFunction<
  DeleteCategoryMutationResult,
  DeleteCategoryMutationVariables
>

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCategoryMutationResult,
    DeleteCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCategoryMutationResult,
    DeleteCategoryMutationVariables
  >(Operations.DeleteCategoryMutation, options)
}
export type DeleteCategoryMutationHookResult = ReturnType<
  typeof useDeleteCategoryMutation
>
export type DeleteCategoryMutationMutationResult =
  Apollo.MutationResult<DeleteCategoryMutationResult>
export type DeleteCategoryMutationMutationOptions = Apollo.BaseMutationOptions<
  DeleteCategoryMutationResult,
  DeleteCategoryMutationVariables
>
export type DeleteThreadMutationMutationFn = Apollo.MutationFunction<
  DeleteThreadMutationResult,
  DeleteThreadMutationVariables
>

/**
 * __useDeleteThreadMutation__
 *
 * To run a mutation, you first call `useDeleteThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThreadMutation, { data, loading, error }] = useDeleteThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useDeleteThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteThreadMutationResult,
    DeleteThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteThreadMutationResult,
    DeleteThreadMutationVariables
  >(Operations.DeleteThreadMutation, options)
}
export type DeleteThreadMutationHookResult = ReturnType<
  typeof useDeleteThreadMutation
>
export type DeleteThreadMutationMutationResult =
  Apollo.MutationResult<DeleteThreadMutationResult>
export type DeleteThreadMutationMutationOptions = Apollo.BaseMutationOptions<
  DeleteThreadMutationResult,
  DeleteThreadMutationVariables
>
export type PinThreadMutationMutationFn = Apollo.MutationFunction<
  PinThreadMutationResult,
  PinThreadMutationVariables
>

/**
 * __usePinThreadMutation__
 *
 * To run a mutation, you first call `usePinThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinThreadMutation, { data, loading, error }] = usePinThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      pinned: // value for 'pinned'
 *   },
 * });
 */
export function usePinThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PinThreadMutationResult,
    PinThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PinThreadMutationResult,
    PinThreadMutationVariables
  >(Operations.PinThreadMutation, options)
}
export type PinThreadMutationHookResult = ReturnType<
  typeof usePinThreadMutation
>
export type PinThreadMutationMutationResult =
  Apollo.MutationResult<PinThreadMutationResult>
export type PinThreadMutationMutationOptions = Apollo.BaseMutationOptions<
  PinThreadMutationResult,
  PinThreadMutationVariables
>
export type UpdateCategoryMutationMutationFn = Apollo.MutationFunction<
  UpdateCategoryMutationResult,
  UpdateCategoryMutationVariables
>

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      categoryDetails: // value for 'categoryDetails'
 *   },
 * });
 */
export function useUpdateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCategoryMutationResult,
    UpdateCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCategoryMutationResult,
    UpdateCategoryMutationVariables
  >(Operations.UpdateCategoryMutation, options)
}
export type UpdateCategoryMutationHookResult = ReturnType<
  typeof useUpdateCategoryMutation
>
export type UpdateCategoryMutationMutationResult =
  Apollo.MutationResult<UpdateCategoryMutationResult>
export type UpdateCategoryMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCategoryMutationResult,
  UpdateCategoryMutationVariables
>
export type UpdateCommentMutationMutationFn = Apollo.MutationFunction<
  UpdateCommentMutationResult,
  UpdateCommentMutationVariables
>

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      commentDetails: // value for 'commentDetails'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentMutationResult,
    UpdateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCommentMutationResult,
    UpdateCommentMutationVariables
  >(Operations.UpdateCommentMutation, options)
}
export type UpdateCommentMutationHookResult = ReturnType<
  typeof useUpdateCommentMutation
>
export type UpdateCommentMutationMutationResult =
  Apollo.MutationResult<UpdateCommentMutationResult>
export type UpdateCommentMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutationResult,
  UpdateCommentMutationVariables
>
export type UpdateDiscussionBoardCategoryNotificationSettingMutationMutationFn =
  Apollo.MutationFunction<
    UpdateDiscussionBoardCategoryNotificationSettingMutationResult,
    UpdateDiscussionBoardCategoryNotificationSettingMutationVariables
  >

/**
 * __useUpdateDiscussionBoardCategoryNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionBoardCategoryNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionBoardCategoryNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionBoardCategoryNotificationSettingMutation, { data, loading, error }] = useUpdateDiscussionBoardCategoryNotificationSettingMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      allowNotifications: // value for 'allowNotifications'
 *   },
 * });
 */
export function useUpdateDiscussionBoardCategoryNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscussionBoardCategoryNotificationSettingMutationResult,
    UpdateDiscussionBoardCategoryNotificationSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDiscussionBoardCategoryNotificationSettingMutationResult,
    UpdateDiscussionBoardCategoryNotificationSettingMutationVariables
  >(
    Operations.UpdateDiscussionBoardCategoryNotificationSettingMutation,
    options
  )
}
export type UpdateDiscussionBoardCategoryNotificationSettingMutationHookResult =
  ReturnType<typeof useUpdateDiscussionBoardCategoryNotificationSettingMutation>
export type UpdateDiscussionBoardCategoryNotificationSettingMutationMutationResult =
  Apollo.MutationResult<UpdateDiscussionBoardCategoryNotificationSettingMutationResult>
export type UpdateDiscussionBoardCategoryNotificationSettingMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDiscussionBoardCategoryNotificationSettingMutationResult,
    UpdateDiscussionBoardCategoryNotificationSettingMutationVariables
  >
export type UpdateDiscussionBoardNotificationSettingMutationMutationFn =
  Apollo.MutationFunction<
    UpdateDiscussionBoardNotificationSettingMutationResult,
    UpdateDiscussionBoardNotificationSettingMutationVariables
  >

/**
 * __useUpdateDiscussionBoardNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionBoardNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionBoardNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionBoardNotificationSettingMutation, { data, loading, error }] = useUpdateDiscussionBoardNotificationSettingMutation({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *      allowGlobalNotifications: // value for 'allowGlobalNotifications'
 *   },
 * });
 */
export function useUpdateDiscussionBoardNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscussionBoardNotificationSettingMutationResult,
    UpdateDiscussionBoardNotificationSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDiscussionBoardNotificationSettingMutationResult,
    UpdateDiscussionBoardNotificationSettingMutationVariables
  >(Operations.UpdateDiscussionBoardNotificationSettingMutation, options)
}
export type UpdateDiscussionBoardNotificationSettingMutationHookResult =
  ReturnType<typeof useUpdateDiscussionBoardNotificationSettingMutation>
export type UpdateDiscussionBoardNotificationSettingMutationMutationResult =
  Apollo.MutationResult<UpdateDiscussionBoardNotificationSettingMutationResult>
export type UpdateDiscussionBoardNotificationSettingMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDiscussionBoardNotificationSettingMutationResult,
    UpdateDiscussionBoardNotificationSettingMutationVariables
  >
export type UpdateDiscussionBoardThreadNotificationSettingMutationMutationFn =
  Apollo.MutationFunction<
    UpdateDiscussionBoardThreadNotificationSettingMutationResult,
    UpdateDiscussionBoardThreadNotificationSettingMutationVariables
  >

/**
 * __useUpdateDiscussionBoardThreadNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionBoardThreadNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionBoardThreadNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionBoardThreadNotificationSettingMutation, { data, loading, error }] = useUpdateDiscussionBoardThreadNotificationSettingMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      allowNotifications: // value for 'allowNotifications'
 *   },
 * });
 */
export function useUpdateDiscussionBoardThreadNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscussionBoardThreadNotificationSettingMutationResult,
    UpdateDiscussionBoardThreadNotificationSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDiscussionBoardThreadNotificationSettingMutationResult,
    UpdateDiscussionBoardThreadNotificationSettingMutationVariables
  >(Operations.UpdateDiscussionBoardThreadNotificationSettingMutation, options)
}
export type UpdateDiscussionBoardThreadNotificationSettingMutationHookResult =
  ReturnType<typeof useUpdateDiscussionBoardThreadNotificationSettingMutation>
export type UpdateDiscussionBoardThreadNotificationSettingMutationMutationResult =
  Apollo.MutationResult<UpdateDiscussionBoardThreadNotificationSettingMutationResult>
export type UpdateDiscussionBoardThreadNotificationSettingMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDiscussionBoardThreadNotificationSettingMutationResult,
    UpdateDiscussionBoardThreadNotificationSettingMutationVariables
  >
export type UpdateThreadMutationMutationFn = Apollo.MutationFunction<
  UpdateThreadMutationResult,
  UpdateThreadMutationVariables
>

/**
 * __useUpdateThreadMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMutation, { data, loading, error }] = useUpdateThreadMutation({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      threadDetails: // value for 'threadDetails'
 *   },
 * });
 */
export function useUpdateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadMutationResult,
    UpdateThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateThreadMutationResult,
    UpdateThreadMutationVariables
  >(Operations.UpdateThreadMutation, options)
}
export type UpdateThreadMutationHookResult = ReturnType<
  typeof useUpdateThreadMutation
>
export type UpdateThreadMutationMutationResult =
  Apollo.MutationResult<UpdateThreadMutationResult>
export type UpdateThreadMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadMutationResult,
  UpdateThreadMutationVariables
>
export type ArchiveCategoryMutationMutationFn = Apollo.MutationFunction<
  ArchiveCategoryMutationResult,
  ArchiveCategoryMutationVariables
>

/**
 * __useArchiveCategoryMutation__
 *
 * To run a mutation, you first call `useArchiveCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCategoryMutation, { data, loading, error }] = useArchiveCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveCategoryMutationResult,
    ArchiveCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveCategoryMutationResult,
    ArchiveCategoryMutationVariables
  >(Operations.ArchiveCategoryMutation, options)
}
export type ArchiveCategoryMutationHookResult = ReturnType<
  typeof useArchiveCategoryMutation
>
export type ArchiveCategoryMutationMutationResult =
  Apollo.MutationResult<ArchiveCategoryMutationResult>
export type ArchiveCategoryMutationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveCategoryMutationResult,
  ArchiveCategoryMutationVariables
>
export type BanAttendeeMutationMutationFn = Apollo.MutationFunction<
  BanAttendeeMutationResult,
  BanAttendeeMutationVariables
>

/**
 * __useBanAttendeeMutation__
 *
 * To run a mutation, you first call `useBanAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banAttendeeMutation, { data, loading, error }] = useBanAttendeeMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBanAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BanAttendeeMutationResult,
    BanAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BanAttendeeMutationResult,
    BanAttendeeMutationVariables
  >(Operations.BanAttendeeMutation, options)
}
export type BanAttendeeMutationHookResult = ReturnType<
  typeof useBanAttendeeMutation
>
export type BanAttendeeMutationMutationResult =
  Apollo.MutationResult<BanAttendeeMutationResult>
export type BanAttendeeMutationMutationOptions = Apollo.BaseMutationOptions<
  BanAttendeeMutationResult,
  BanAttendeeMutationVariables
>
export type CreateEventMutationMutationFn = Apollo.MutationFunction<
  CreateEventMutationResult,
  CreateEventMutationVariables
>

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutationResult,
    CreateEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateEventMutationResult,
    CreateEventMutationVariables
  >(Operations.CreateEventMutation, options)
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>
export type CreateEventMutationMutationResult =
  Apollo.MutationResult<CreateEventMutationResult>
export type CreateEventMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutationResult,
  CreateEventMutationVariables
>
export type DeleteEventMutationMutationFn = Apollo.MutationFunction<
  DeleteEventMutationResult,
  DeleteEventMutationVariables
>

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventMutationResult,
    DeleteEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteEventMutationResult,
    DeleteEventMutationVariables
  >(Operations.DeleteEventMutation, options)
}
export type DeleteEventMutationHookResult = ReturnType<
  typeof useDeleteEventMutation
>
export type DeleteEventMutationMutationResult =
  Apollo.MutationResult<DeleteEventMutationResult>
export type DeleteEventMutationMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutationResult,
  DeleteEventMutationVariables
>
export type EditEventAssetMutationMutationFn = Apollo.MutationFunction<
  EditEventAssetMutationResult,
  EditEventAssetMutationVariables
>

/**
 * __useEditEventAssetMutation__
 *
 * To run a mutation, you first call `useEditEventAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventAssetMutation, { data, loading, error }] = useEditEventAssetMutation({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      caption: // value for 'caption'
 *      public: // value for 'public'
 *   },
 * });
 */
export function useEditEventAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditEventAssetMutationResult,
    EditEventAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EditEventAssetMutationResult,
    EditEventAssetMutationVariables
  >(Operations.EditEventAssetMutation, options)
}
export type EditEventAssetMutationHookResult = ReturnType<
  typeof useEditEventAssetMutation
>
export type EditEventAssetMutationMutationResult =
  Apollo.MutationResult<EditEventAssetMutationResult>
export type EditEventAssetMutationMutationOptions = Apollo.BaseMutationOptions<
  EditEventAssetMutationResult,
  EditEventAssetMutationVariables
>
export type EndSpeakerMutationMutationFn = Apollo.MutationFunction<
  EndSpeakerMutationResult,
  EndSpeakerMutationVariables
>

/**
 * __useEndSpeakerMutation__
 *
 * To run a mutation, you first call `useEndSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSpeakerMutation, { data, loading, error }] = useEndSpeakerMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEndSpeakerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndSpeakerMutationResult,
    EndSpeakerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EndSpeakerMutationResult,
    EndSpeakerMutationVariables
  >(Operations.EndSpeakerMutation, options)
}
export type EndSpeakerMutationHookResult = ReturnType<
  typeof useEndSpeakerMutation
>
export type EndSpeakerMutationMutationResult =
  Apollo.MutationResult<EndSpeakerMutationResult>
export type EndSpeakerMutationMutationOptions = Apollo.BaseMutationOptions<
  EndSpeakerMutationResult,
  EndSpeakerMutationVariables
>
export type LeaveEventMutationMutationFn = Apollo.MutationFunction<
  LeaveEventMutationResult,
  LeaveEventMutationVariables
>

/**
 * __useLeaveEventMutation__
 *
 * To run a mutation, you first call `useLeaveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveEventMutation, { data, loading, error }] = useLeaveEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useLeaveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveEventMutationResult,
    LeaveEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LeaveEventMutationResult,
    LeaveEventMutationVariables
  >(Operations.LeaveEventMutation, options)
}
export type LeaveEventMutationHookResult = ReturnType<
  typeof useLeaveEventMutation
>
export type LeaveEventMutationMutationResult =
  Apollo.MutationResult<LeaveEventMutationResult>
export type LeaveEventMutationMutationOptions = Apollo.BaseMutationOptions<
  LeaveEventMutationResult,
  LeaveEventMutationVariables
>
export type RSVPToEventMutationMutationFn = Apollo.MutationFunction<
  RSVPToEventMutationResult,
  RSVPToEventMutationVariables
>

/**
 * __useRSVPToEventMutation__
 *
 * To run a mutation, you first call `useRSVPToEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRSVPToEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rsvpToEventMutation, { data, loading, error }] = useRSVPToEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      rsvp: // value for 'rsvp'
 *   },
 * });
 */
export function useRSVPToEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RSVPToEventMutationResult,
    RSVPToEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RSVPToEventMutationResult,
    RSVPToEventMutationVariables
  >(Operations.RSVPToEventMutation, options)
}
export type RSVPToEventMutationHookResult = ReturnType<
  typeof useRSVPToEventMutation
>
export type RSVPToEventMutationMutationResult =
  Apollo.MutationResult<RSVPToEventMutationResult>
export type RSVPToEventMutationMutationOptions = Apollo.BaseMutationOptions<
  RSVPToEventMutationResult,
  RSVPToEventMutationVariables
>
export type SetAttendeeRoleMutationMutationFn = Apollo.MutationFunction<
  SetAttendeeRoleMutationResult,
  SetAttendeeRoleMutationVariables
>

/**
 * __useSetAttendeeRoleMutation__
 *
 * To run a mutation, you first call `useSetAttendeeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAttendeeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAttendeeRoleMutation, { data, loading, error }] = useSetAttendeeRoleMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSetAttendeeRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAttendeeRoleMutationResult,
    SetAttendeeRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetAttendeeRoleMutationResult,
    SetAttendeeRoleMutationVariables
  >(Operations.SetAttendeeRoleMutation, options)
}
export type SetAttendeeRoleMutationHookResult = ReturnType<
  typeof useSetAttendeeRoleMutation
>
export type SetAttendeeRoleMutationMutationResult =
  Apollo.MutationResult<SetAttendeeRoleMutationResult>
export type SetAttendeeRoleMutationMutationOptions = Apollo.BaseMutationOptions<
  SetAttendeeRoleMutationResult,
  SetAttendeeRoleMutationVariables
>
export type SetEventStateMutationMutationFn = Apollo.MutationFunction<
  SetEventStateMutationResult,
  SetEventStateMutationVariables
>

/**
 * __useSetEventStateMutation__
 *
 * To run a mutation, you first call `useSetEventStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEventStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEventStateMutation, { data, loading, error }] = useSetEventStateMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      eventState: // value for 'eventState'
 *   },
 * });
 */
export function useSetEventStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEventStateMutationResult,
    SetEventStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetEventStateMutationResult,
    SetEventStateMutationVariables
  >(Operations.SetEventStateMutation, options)
}
export type SetEventStateMutationHookResult = ReturnType<
  typeof useSetEventStateMutation
>
export type SetEventStateMutationMutationResult =
  Apollo.MutationResult<SetEventStateMutationResult>
export type SetEventStateMutationMutationOptions = Apollo.BaseMutationOptions<
  SetEventStateMutationResult,
  SetEventStateMutationVariables
>
export type SetPassiveModeMutationMutationFn = Apollo.MutationFunction<
  SetPassiveModeMutationResult,
  SetPassiveModeMutationVariables
>

/**
 * __useSetPassiveModeMutation__
 *
 * To run a mutation, you first call `useSetPassiveModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPassiveModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPassiveModeMutation, { data, loading, error }] = useSetPassiveModeMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      passive: // value for 'passive'
 *   },
 * });
 */
export function useSetPassiveModeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPassiveModeMutationResult,
    SetPassiveModeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetPassiveModeMutationResult,
    SetPassiveModeMutationVariables
  >(Operations.SetPassiveModeMutation, options)
}
export type SetPassiveModeMutationHookResult = ReturnType<
  typeof useSetPassiveModeMutation
>
export type SetPassiveModeMutationMutationResult =
  Apollo.MutationResult<SetPassiveModeMutationResult>
export type SetPassiveModeMutationMutationOptions = Apollo.BaseMutationOptions<
  SetPassiveModeMutationResult,
  SetPassiveModeMutationVariables
>
export type SetRecordingStateMutationMutationFn = Apollo.MutationFunction<
  SetRecordingStateMutationResult,
  SetRecordingStateMutationVariables
>

/**
 * __useSetRecordingStateMutation__
 *
 * To run a mutation, you first call `useSetRecordingStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRecordingStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRecordingStateMutation, { data, loading, error }] = useSetRecordingStateMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      newRecordingState: // value for 'newRecordingState'
 *   },
 * });
 */
export function useSetRecordingStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetRecordingStateMutationResult,
    SetRecordingStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetRecordingStateMutationResult,
    SetRecordingStateMutationVariables
  >(Operations.SetRecordingStateMutation, options)
}
export type SetRecordingStateMutationHookResult = ReturnType<
  typeof useSetRecordingStateMutation
>
export type SetRecordingStateMutationMutationResult =
  Apollo.MutationResult<SetRecordingStateMutationResult>
export type SetRecordingStateMutationMutationOptions =
  Apollo.BaseMutationOptions<
    SetRecordingStateMutationResult,
    SetRecordingStateMutationVariables
  >
export type SetSpeakerMutationMutationFn = Apollo.MutationFunction<
  SetSpeakerMutationResult,
  SetSpeakerMutationVariables
>

/**
 * __useSetSpeakerMutation__
 *
 * To run a mutation, you first call `useSetSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSpeakerMutation, { data, loading, error }] = useSetSpeakerMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useSetSpeakerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSpeakerMutationResult,
    SetSpeakerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetSpeakerMutationResult,
    SetSpeakerMutationVariables
  >(Operations.SetSpeakerMutation, options)
}
export type SetSpeakerMutationHookResult = ReturnType<
  typeof useSetSpeakerMutation
>
export type SetSpeakerMutationMutationResult =
  Apollo.MutationResult<SetSpeakerMutationResult>
export type SetSpeakerMutationMutationOptions = Apollo.BaseMutationOptions<
  SetSpeakerMutationResult,
  SetSpeakerMutationVariables
>
export type SignalAttendeeMutationMutationFn = Apollo.MutationFunction<
  SignalAttendeeMutationResult,
  SignalAttendeeMutationVariables
>

/**
 * __useSignalAttendeeMutation__
 *
 * To run a mutation, you first call `useSignalAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignalAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signalAttendeeMutation, { data, loading, error }] = useSignalAttendeeMutation({
 *   variables: {
 *      signal: // value for 'signal'
 *   },
 * });
 */
export function useSignalAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignalAttendeeMutationResult,
    SignalAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignalAttendeeMutationResult,
    SignalAttendeeMutationVariables
  >(Operations.SignalAttendeeMutation, options)
}
export type SignalAttendeeMutationHookResult = ReturnType<
  typeof useSignalAttendeeMutation
>
export type SignalAttendeeMutationMutationResult =
  Apollo.MutationResult<SignalAttendeeMutationResult>
export type SignalAttendeeMutationMutationOptions = Apollo.BaseMutationOptions<
  SignalAttendeeMutationResult,
  SignalAttendeeMutationVariables
>
export type SignalAttendeesMutationMutationFn = Apollo.MutationFunction<
  SignalAttendeesMutationResult,
  SignalAttendeesMutationVariables
>

/**
 * __useSignalAttendeesMutation__
 *
 * To run a mutation, you first call `useSignalAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignalAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signalAttendeesMutation, { data, loading, error }] = useSignalAttendeesMutation({
 *   variables: {
 *      signal: // value for 'signal'
 *   },
 * });
 */
export function useSignalAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignalAttendeesMutationResult,
    SignalAttendeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignalAttendeesMutationResult,
    SignalAttendeesMutationVariables
  >(Operations.SignalAttendeesMutation, options)
}
export type SignalAttendeesMutationHookResult = ReturnType<
  typeof useSignalAttendeesMutation
>
export type SignalAttendeesMutationMutationResult =
  Apollo.MutationResult<SignalAttendeesMutationResult>
export type SignalAttendeesMutationMutationOptions = Apollo.BaseMutationOptions<
  SignalAttendeesMutationResult,
  SignalAttendeesMutationVariables
>
export type UpdateEventMutationMutationFn = Apollo.MutationFunction<
  UpdateEventMutationResult,
  UpdateEventMutationVariables
>

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventMutationResult,
    UpdateEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateEventMutationResult,
    UpdateEventMutationVariables
  >(Operations.UpdateEventMutation, options)
}
export type UpdateEventMutationHookResult = ReturnType<
  typeof useUpdateEventMutation
>
export type UpdateEventMutationMutationResult =
  Apollo.MutationResult<UpdateEventMutationResult>
export type UpdateEventMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutationResult,
  UpdateEventMutationVariables
>
export type UpgradeWaitlistedAttendeeMutationMutationFn =
  Apollo.MutationFunction<
    UpgradeWaitlistedAttendeeMutationResult,
    UpgradeWaitlistedAttendeeMutationVariables
  >

/**
 * __useUpgradeWaitlistedAttendeeMutation__
 *
 * To run a mutation, you first call `useUpgradeWaitlistedAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeWaitlistedAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeWaitlistedAttendeeMutation, { data, loading, error }] = useUpgradeWaitlistedAttendeeMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpgradeWaitlistedAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeWaitlistedAttendeeMutationResult,
    UpgradeWaitlistedAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpgradeWaitlistedAttendeeMutationResult,
    UpgradeWaitlistedAttendeeMutationVariables
  >(Operations.UpgradeWaitlistedAttendeeMutation, options)
}
export type UpgradeWaitlistedAttendeeMutationHookResult = ReturnType<
  typeof useUpgradeWaitlistedAttendeeMutation
>
export type UpgradeWaitlistedAttendeeMutationMutationResult =
  Apollo.MutationResult<UpgradeWaitlistedAttendeeMutationResult>
export type UpgradeWaitlistedAttendeeMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpgradeWaitlistedAttendeeMutationResult,
    UpgradeWaitlistedAttendeeMutationVariables
  >
export type UploadEventThumbMutationMutationFn = Apollo.MutationFunction<
  UploadEventThumbMutationResult,
  UploadEventThumbMutationVariables
>

/**
 * __useUploadEventThumbMutation__
 *
 * To run a mutation, you first call `useUploadEventThumbMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadEventThumbMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadEventThumbMutation, { data, loading, error }] = useUploadEventThumbMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadEventThumbMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadEventThumbMutationResult,
    UploadEventThumbMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadEventThumbMutationResult,
    UploadEventThumbMutationVariables
  >(Operations.UploadEventThumbMutation, options)
}
export type UploadEventThumbMutationHookResult = ReturnType<
  typeof useUploadEventThumbMutation
>
export type UploadEventThumbMutationMutationResult =
  Apollo.MutationResult<UploadEventThumbMutationResult>
export type UploadEventThumbMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UploadEventThumbMutationResult,
    UploadEventThumbMutationVariables
  >
export type DeleteAccountMutationMutationFn = Apollo.MutationFunction<
  DeleteAccountMutationResult,
  DeleteAccountMutationVariables
>

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutationResult,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteAccountMutationResult,
    DeleteAccountMutationVariables
  >(Operations.DeleteAccountMutation, options)
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>
export type DeleteAccountMutationMutationResult =
  Apollo.MutationResult<DeleteAccountMutationResult>
export type DeleteAccountMutationMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutationResult,
  DeleteAccountMutationVariables
>

/**
 * __useBaseUserQuery__
 *
 * To run a query within a React component, call `useBaseUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBaseUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    BaseUserQueryResult,
    BaseUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BaseUserQueryResult, BaseUserQueryVariables>(
    Operations.BaseUserQuery,
    options
  )
}
export function useBaseUserQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseUserQueryResult,
    BaseUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BaseUserQueryResult, BaseUserQueryVariables>(
    Operations.BaseUserQuery,
    options
  )
}
export type BaseUserQueryHookResult = ReturnType<typeof useBaseUserQuery>
export type BaseUserQueryLazyQueryHookResult = ReturnType<
  typeof useBaseUserQueryLazyQuery
>
export type BaseUserQueryQueryResult = Apollo.QueryResult<
  BaseUserQueryResult,
  BaseUserQueryVariables
>

/**
 * __useBlockedUsersQuery__
 *
 * To run a query within a React component, call `useBlockedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockedUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BlockedUsersQueryResult,
    BlockedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BlockedUsersQueryResult, BlockedUsersQueryVariables>(
    Operations.BlockedUsersQuery,
    options
  )
}
export function useBlockedUsersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlockedUsersQueryResult,
    BlockedUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BlockedUsersQueryResult,
    BlockedUsersQueryVariables
  >(Operations.BlockedUsersQuery, options)
}
export type BlockedUsersQueryHookResult = ReturnType<
  typeof useBlockedUsersQuery
>
export type BlockedUsersQueryLazyQueryHookResult = ReturnType<
  typeof useBlockedUsersQueryLazyQuery
>
export type BlockedUsersQueryQueryResult = Apollo.QueryResult<
  BlockedUsersQueryResult,
  BlockedUsersQueryVariables
>

/**
 * __useCurrentCollectiveIdQuery__
 *
 * To run a query within a React component, call `useCurrentCollectiveIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCollectiveIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCollectiveIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCollectiveIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCollectiveIdQueryResult,
    CurrentCollectiveIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentCollectiveIdQueryResult,
    CurrentCollectiveIdQueryVariables
  >(Operations.CurrentCollectiveIdQuery, options)
}
export function useCurrentCollectiveIdQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCollectiveIdQueryResult,
    CurrentCollectiveIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentCollectiveIdQueryResult,
    CurrentCollectiveIdQueryVariables
  >(Operations.CurrentCollectiveIdQuery, options)
}
export type CurrentCollectiveIdQueryHookResult = ReturnType<
  typeof useCurrentCollectiveIdQuery
>
export type CurrentCollectiveIdQueryLazyQueryHookResult = ReturnType<
  typeof useCurrentCollectiveIdQueryLazyQuery
>
export type CurrentCollectiveIdQueryQueryResult = Apollo.QueryResult<
  CurrentCollectiveIdQueryResult,
  CurrentCollectiveIdQueryVariables
>

/**
 * __useDiscoverEngagementsQuery__
 *
 * To run a query within a React component, call `useDiscoverEngagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoverEngagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoverEngagementsQuery({
 *   variables: {
 *      engagementConnection: // value for 'engagementConnection'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      seriesId: // value for 'seriesId'
 *      seriesSlug: // value for 'seriesSlug'
 *      ignoreEventId: // value for 'ignoreEventId'
 *   },
 * });
 */
export function useDiscoverEngagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscoverEngagementsQueryResult,
    DiscoverEngagementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DiscoverEngagementsQueryResult,
    DiscoverEngagementsQueryVariables
  >(Operations.DiscoverEngagementsQuery, options)
}
export function useDiscoverEngagementsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscoverEngagementsQueryResult,
    DiscoverEngagementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DiscoverEngagementsQueryResult,
    DiscoverEngagementsQueryVariables
  >(Operations.DiscoverEngagementsQuery, options)
}
export type DiscoverEngagementsQueryHookResult = ReturnType<
  typeof useDiscoverEngagementsQuery
>
export type DiscoverEngagementsQueryLazyQueryHookResult = ReturnType<
  typeof useDiscoverEngagementsQueryLazyQuery
>
export type DiscoverEngagementsQueryQueryResult = Apollo.QueryResult<
  DiscoverEngagementsQueryResult,
  DiscoverEngagementsQueryVariables
>

/**
 * __useEnrichmentsQuery__
 *
 * To run a query within a React component, call `useEnrichmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrichmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrichmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnrichmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EnrichmentsQueryResult,
    EnrichmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EnrichmentsQueryResult, EnrichmentsQueryVariables>(
    Operations.EnrichmentsQuery,
    options
  )
}
export function useEnrichmentsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnrichmentsQueryResult,
    EnrichmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EnrichmentsQueryResult, EnrichmentsQueryVariables>(
    Operations.EnrichmentsQuery,
    options
  )
}
export type EnrichmentsQueryHookResult = ReturnType<typeof useEnrichmentsQuery>
export type EnrichmentsQueryLazyQueryHookResult = ReturnType<
  typeof useEnrichmentsQueryLazyQuery
>
export type EnrichmentsQueryQueryResult = Apollo.QueryResult<
  EnrichmentsQueryResult,
  EnrichmentsQueryVariables
>

/**
 * __useEventUserPlanQuery__
 *
 * To run a query within a React component, call `useEventUserPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventUserPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventUserPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventUserPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventUserPlanQueryResult,
    EventUserPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventUserPlanQueryResult, EventUserPlanQueryVariables>(
    Operations.EventUserPlanQuery,
    options
  )
}
export function useEventUserPlanQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventUserPlanQueryResult,
    EventUserPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventUserPlanQueryResult,
    EventUserPlanQueryVariables
  >(Operations.EventUserPlanQuery, options)
}
export type EventUserPlanQueryHookResult = ReturnType<
  typeof useEventUserPlanQuery
>
export type EventUserPlanQueryLazyQueryHookResult = ReturnType<
  typeof useEventUserPlanQueryLazyQuery
>
export type EventUserPlanQueryQueryResult = Apollo.QueryResult<
  EventUserPlanQueryResult,
  EventUserPlanQueryVariables
>

/**
 * __useGetEmailPreferencesQuery__
 *
 * To run a query within a React component, call `useGetEmailPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailPreferencesQuery({
 *   variables: {
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useGetEmailPreferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEmailPreferencesQueryResult,
    GetEmailPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEmailPreferencesQueryResult,
    GetEmailPreferencesQueryVariables
  >(Operations.GetEmailPreferencesQuery, options)
}
export function useGetEmailPreferencesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailPreferencesQueryResult,
    GetEmailPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEmailPreferencesQueryResult,
    GetEmailPreferencesQueryVariables
  >(Operations.GetEmailPreferencesQuery, options)
}
export type GetEmailPreferencesQueryHookResult = ReturnType<
  typeof useGetEmailPreferencesQuery
>
export type GetEmailPreferencesQueryLazyQueryHookResult = ReturnType<
  typeof useGetEmailPreferencesQueryLazyQuery
>
export type GetEmailPreferencesQueryQueryResult = Apollo.QueryResult<
  GetEmailPreferencesQueryResult,
  GetEmailPreferencesQueryVariables
>

/**
 * __useHasChromeQuery__
 *
 * To run a query within a React component, call `useHasChromeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasChromeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasChromeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasChromeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasChromeQueryResult,
    HasChromeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HasChromeQueryResult, HasChromeQueryVariables>(
    Operations.HasChromeQuery,
    options
  )
}
export function useHasChromeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasChromeQueryResult,
    HasChromeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HasChromeQueryResult, HasChromeQueryVariables>(
    Operations.HasChromeQuery,
    options
  )
}
export type HasChromeQueryHookResult = ReturnType<typeof useHasChromeQuery>
export type HasChromeQueryLazyQueryHookResult = ReturnType<
  typeof useHasChromeQueryLazyQuery
>
export type HasChromeQueryQueryResult = Apollo.QueryResult<
  HasChromeQueryResult,
  HasChromeQueryVariables
>

/**
 * __useHasGmailQuery__
 *
 * To run a query within a React component, call `useHasGmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasGmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasGmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasGmailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasGmailQueryResult,
    HasGmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HasGmailQueryResult, HasGmailQueryVariables>(
    Operations.HasGmailQuery,
    options
  )
}
export function useHasGmailQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasGmailQueryResult,
    HasGmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HasGmailQueryResult, HasGmailQueryVariables>(
    Operations.HasGmailQuery,
    options
  )
}
export type HasGmailQueryHookResult = ReturnType<typeof useHasGmailQuery>
export type HasGmailQueryLazyQueryHookResult = ReturnType<
  typeof useHasGmailQueryLazyQuery
>
export type HasGmailQueryQueryResult = Apollo.QueryResult<
  HasGmailQueryResult,
  HasGmailQueryVariables
>

/**
 * __useIsConnectedQuery__
 *
 * To run a query within a React component, call `useIsConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsConnectedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsConnectedQueryResult,
    IsConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsConnectedQueryResult, IsConnectedQueryVariables>(
    Operations.IsConnectedQuery,
    options
  )
}
export function useIsConnectedQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsConnectedQueryResult,
    IsConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsConnectedQueryResult, IsConnectedQueryVariables>(
    Operations.IsConnectedQuery,
    options
  )
}
export type IsConnectedQueryHookResult = ReturnType<typeof useIsConnectedQuery>
export type IsConnectedQueryLazyQueryHookResult = ReturnType<
  typeof useIsConnectedQueryLazyQuery
>
export type IsConnectedQueryQueryResult = Apollo.QueryResult<
  IsConnectedQueryResult,
  IsConnectedQueryVariables
>

/**
 * __useLanguageQuery__
 *
 * To run a query within a React component, call `useLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useLanguageQuery(
  baseOptions: Apollo.QueryHookOptions<
    LanguageQueryResult,
    LanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LanguageQueryResult, LanguageQueryVariables>(
    Operations.LanguageQuery,
    options
  )
}
export function useLanguageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LanguageQueryResult,
    LanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LanguageQueryResult, LanguageQueryVariables>(
    Operations.LanguageQuery,
    options
  )
}
export type LanguageQueryHookResult = ReturnType<typeof useLanguageQuery>
export type LanguageQueryLazyQueryHookResult = ReturnType<
  typeof useLanguageQueryLazyQuery
>
export type LanguageQueryQueryResult = Apollo.QueryResult<
  LanguageQueryResult,
  LanguageQueryVariables
>

/**
 * __useNonceQuery__
 *
 * To run a query within a React component, call `useNonceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonceQuery({
 *   variables: {
 *   },
 * });
 */
export function useNonceQuery(
  baseOptions?: Apollo.QueryHookOptions<NonceQueryResult, NonceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NonceQueryResult, NonceQueryVariables>(
    Operations.NonceQuery,
    options
  )
}
export function useNonceQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonceQueryResult,
    NonceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NonceQueryResult, NonceQueryVariables>(
    Operations.NonceQuery,
    options
  )
}
export type NonceQueryHookResult = ReturnType<typeof useNonceQuery>
export type NonceQueryLazyQueryHookResult = ReturnType<
  typeof useNonceQueryLazyQuery
>
export type NonceQueryQueryResult = Apollo.QueryResult<
  NonceQueryResult,
  NonceQueryVariables
>

/**
 * __useResolveSubdomainQuery__
 *
 * To run a query within a React component, call `useResolveSubdomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveSubdomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveSubdomainQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useResolveSubdomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResolveSubdomainQueryResult,
    ResolveSubdomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ResolveSubdomainQueryResult,
    ResolveSubdomainQueryVariables
  >(Operations.ResolveSubdomainQuery, options)
}
export function useResolveSubdomainQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResolveSubdomainQueryResult,
    ResolveSubdomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ResolveSubdomainQueryResult,
    ResolveSubdomainQueryVariables
  >(Operations.ResolveSubdomainQuery, options)
}
export type ResolveSubdomainQueryHookResult = ReturnType<
  typeof useResolveSubdomainQuery
>
export type ResolveSubdomainQueryLazyQueryHookResult = ReturnType<
  typeof useResolveSubdomainQueryLazyQuery
>
export type ResolveSubdomainQueryQueryResult = Apollo.QueryResult<
  ResolveSubdomainQueryResult,
  ResolveSubdomainQueryVariables
>

/**
 * __useSEOQuery__
 *
 * To run a query within a React component, call `useSEOQuery` and pass it any options that fit your needs.
 * When your component renders, `useSEOQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSEOQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSEOQuery(
  baseOptions: Apollo.QueryHookOptions<SEOQueryResult, SEOQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SEOQueryResult, SEOQueryVariables>(
    Operations.SEOQuery,
    options
  )
}
export function useSEOQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SEOQueryResult, SEOQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SEOQueryResult, SEOQueryVariables>(
    Operations.SEOQuery,
    options
  )
}
export type SEOQueryHookResult = ReturnType<typeof useSEOQuery>
export type SEOQueryLazyQueryHookResult = ReturnType<
  typeof useSEOQueryLazyQuery
>
export type SEOQueryQueryResult = Apollo.QueryResult<
  SEOQueryResult,
  SEOQueryVariables
>

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQueryResult, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchQueryResult, SearchQueryVariables>(
    Operations.SearchQuery,
    options
  )
}
export function useSearchQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchQueryResult,
    SearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchQueryResult, SearchQueryVariables>(
    Operations.SearchQuery,
    options
  )
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>
export type SearchQueryLazyQueryHookResult = ReturnType<
  typeof useSearchQueryLazyQuery
>
export type SearchQueryQueryResult = Apollo.QueryResult<
  SearchQueryResult,
  SearchQueryVariables
>

/**
 * __useSiteFlagsQuery__
 *
 * To run a query within a React component, call `useSiteFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SiteFlagsQueryResult,
    SiteFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SiteFlagsQueryResult, SiteFlagsQueryVariables>(
    Operations.SiteFlagsQuery,
    options
  )
}
export function useSiteFlagsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteFlagsQueryResult,
    SiteFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SiteFlagsQueryResult, SiteFlagsQueryVariables>(
    Operations.SiteFlagsQuery,
    options
  )
}
export type SiteFlagsQueryHookResult = ReturnType<typeof useSiteFlagsQuery>
export type SiteFlagsQueryLazyQueryHookResult = ReturnType<
  typeof useSiteFlagsQueryLazyQuery
>
export type SiteFlagsQueryQueryResult = Apollo.QueryResult<
  SiteFlagsQueryResult,
  SiteFlagsQueryVariables
>

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<TagsQueryResult, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TagsQueryResult, TagsQueryVariables>(
    Operations.TagsQuery,
    options
  )
}
export function useTagsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsQueryResult, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TagsQueryResult, TagsQueryVariables>(
    Operations.TagsQuery,
    options
  )
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>
export type TagsQueryLazyQueryHookResult = ReturnType<
  typeof useTagsQueryLazyQuery
>
export type TagsQueryQueryResult = Apollo.QueryResult<
  TagsQueryResult,
  TagsQueryVariables
>

/**
 * __useTokenQuery__
 *
 * To run a query within a React component, call `useTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<TokenQueryResult, TokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TokenQueryResult, TokenQueryVariables>(
    Operations.TokenQuery,
    options
  )
}
export function useTokenQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TokenQueryResult,
    TokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TokenQueryResult, TokenQueryVariables>(
    Operations.TokenQuery,
    options
  )
}
export type TokenQueryHookResult = ReturnType<typeof useTokenQuery>
export type TokenQueryLazyQueryHookResult = ReturnType<
  typeof useTokenQueryLazyQuery
>
export type TokenQueryQueryResult = Apollo.QueryResult<
  TokenQueryResult,
  TokenQueryVariables
>

/**
 * __useUserEngagementDatesQuery__
 *
 * To run a query within a React component, call `useUserEngagementDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEngagementDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEngagementDatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEngagementDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserEngagementDatesQueryResult,
    UserEngagementDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserEngagementDatesQueryResult,
    UserEngagementDatesQueryVariables
  >(Operations.UserEngagementDatesQuery, options)
}
export function useUserEngagementDatesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEngagementDatesQueryResult,
    UserEngagementDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserEngagementDatesQueryResult,
    UserEngagementDatesQueryVariables
  >(Operations.UserEngagementDatesQuery, options)
}
export type UserEngagementDatesQueryHookResult = ReturnType<
  typeof useUserEngagementDatesQuery
>
export type UserEngagementDatesQueryLazyQueryHookResult = ReturnType<
  typeof useUserEngagementDatesQueryLazyQuery
>
export type UserEngagementDatesQueryQueryResult = Apollo.QueryResult<
  UserEngagementDatesQueryResult,
  UserEngagementDatesQueryVariables
>

/**
 * __useUserEngagementsQuery__
 *
 * To run a query within a React component, call `useUserEngagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEngagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEngagementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      engagementConnection: // value for 'engagementConnection'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *      dateFilter: // value for 'dateFilter'
 *   },
 * });
 */
export function useUserEngagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserEngagementsQueryResult,
    UserEngagementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserEngagementsQueryResult,
    UserEngagementsQueryVariables
  >(Operations.UserEngagementsQuery, options)
}
export function useUserEngagementsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEngagementsQueryResult,
    UserEngagementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserEngagementsQueryResult,
    UserEngagementsQueryVariables
  >(Operations.UserEngagementsQuery, options)
}
export type UserEngagementsQueryHookResult = ReturnType<
  typeof useUserEngagementsQuery
>
export type UserEngagementsQueryLazyQueryHookResult = ReturnType<
  typeof useUserEngagementsQueryLazyQuery
>
export type UserEngagementsQueryQueryResult = Apollo.QueryResult<
  UserEngagementsQueryResult,
  UserEngagementsQueryVariables
>

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQueryResult, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserQueryResult, UserQueryVariables>(
    Operations.UserQuery,
    options
  )
}
export function useUserQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQueryResult, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserQueryResult, UserQueryVariables>(
    Operations.UserQuery,
    options
  )
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserQueryLazyQueryHookResult = ReturnType<
  typeof useUserQueryLazyQuery
>
export type UserQueryQueryResult = Apollo.QueryResult<
  UserQueryResult,
  UserQueryVariables
>

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *      deviceInfo: // value for 'deviceInfo'
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQueryResult, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ViewerQueryResult, ViewerQueryVariables>(
    Operations.ViewerQuery,
    options
  )
}
export function useViewerQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewerQueryResult,
    ViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ViewerQueryResult, ViewerQueryVariables>(
    Operations.ViewerQuery,
    options
  )
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>
export type ViewerQueryLazyQueryHookResult = ReturnType<
  typeof useViewerQueryLazyQuery
>
export type ViewerQueryQueryResult = Apollo.QueryResult<
  ViewerQueryResult,
  ViewerQueryVariables
>

/**
 * __useEphemeralCryptoAuthQuery__
 *
 * To run a query within a React component, call `useEphemeralCryptoAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useEphemeralCryptoAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEphemeralCryptoAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useEphemeralCryptoAuthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EphemeralCryptoAuthQueryResult,
    EphemeralCryptoAuthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EphemeralCryptoAuthQueryResult,
    EphemeralCryptoAuthQueryVariables
  >(Operations.EphemeralCryptoAuthQuery, options)
}
export function useEphemeralCryptoAuthQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EphemeralCryptoAuthQueryResult,
    EphemeralCryptoAuthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EphemeralCryptoAuthQueryResult,
    EphemeralCryptoAuthQueryVariables
  >(Operations.EphemeralCryptoAuthQuery, options)
}
export type EphemeralCryptoAuthQueryHookResult = ReturnType<
  typeof useEphemeralCryptoAuthQuery
>
export type EphemeralCryptoAuthQueryLazyQueryHookResult = ReturnType<
  typeof useEphemeralCryptoAuthQueryLazyQuery
>
export type EphemeralCryptoAuthQueryQueryResult = Apollo.QueryResult<
  EphemeralCryptoAuthQueryResult,
  EphemeralCryptoAuthQueryVariables
>

/**
 * __useCollectiveApplicationQuery__
 *
 * To run a query within a React component, call `useCollectiveApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCollectiveApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveApplicationQueryResult,
    CollectiveApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveApplicationQueryResult,
    CollectiveApplicationQueryVariables
  >(Operations.CollectiveApplicationQuery, options)
}
export function useCollectiveApplicationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveApplicationQueryResult,
    CollectiveApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveApplicationQueryResult,
    CollectiveApplicationQueryVariables
  >(Operations.CollectiveApplicationQuery, options)
}
export type CollectiveApplicationQueryHookResult = ReturnType<
  typeof useCollectiveApplicationQuery
>
export type CollectiveApplicationQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveApplicationQueryLazyQuery
>
export type CollectiveApplicationQueryQueryResult = Apollo.QueryResult<
  CollectiveApplicationQueryResult,
  CollectiveApplicationQueryVariables
>

/**
 * __useCollectiveApplicationsQuery__
 *
 * To run a query within a React component, call `useCollectiveApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollectiveApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollectiveApplicationsQueryResult,
    CollectiveApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveApplicationsQueryResult,
    CollectiveApplicationsQueryVariables
  >(Operations.CollectiveApplicationsQuery, options)
}
export function useCollectiveApplicationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveApplicationsQueryResult,
    CollectiveApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveApplicationsQueryResult,
    CollectiveApplicationsQueryVariables
  >(Operations.CollectiveApplicationsQuery, options)
}
export type CollectiveApplicationsQueryHookResult = ReturnType<
  typeof useCollectiveApplicationsQuery
>
export type CollectiveApplicationsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveApplicationsQueryLazyQuery
>
export type CollectiveApplicationsQueryQueryResult = Apollo.QueryResult<
  CollectiveApplicationsQueryResult,
  CollectiveApplicationsQueryVariables
>

/**
 * __useCollectiveDraftQuery__
 *
 * To run a query within a React component, call `useCollectiveDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveDraftQuery({
 *   variables: {
 *      collectiveDraftId: // value for 'collectiveDraftId'
 *   },
 * });
 */
export function useCollectiveDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveDraftQueryResult,
    CollectiveDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveDraftQueryResult,
    CollectiveDraftQueryVariables
  >(Operations.CollectiveDraftQuery, options)
}
export function useCollectiveDraftQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveDraftQueryResult,
    CollectiveDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveDraftQueryResult,
    CollectiveDraftQueryVariables
  >(Operations.CollectiveDraftQuery, options)
}
export type CollectiveDraftQueryHookResult = ReturnType<
  typeof useCollectiveDraftQuery
>
export type CollectiveDraftQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveDraftQueryLazyQuery
>
export type CollectiveDraftQueryQueryResult = Apollo.QueryResult<
  CollectiveDraftQueryResult,
  CollectiveDraftQueryVariables
>

/**
 * __useViewerCollectiveDraftsQuery__
 *
 * To run a query within a React component, call `useViewerCollectiveDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerCollectiveDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerCollectiveDraftsQuery({
 *   variables: {
 *      collectiveConnection: // value for 'collectiveConnection'
 *   },
 * });
 */
export function useViewerCollectiveDraftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewerCollectiveDraftsQueryResult,
    ViewerCollectiveDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ViewerCollectiveDraftsQueryResult,
    ViewerCollectiveDraftsQueryVariables
  >(Operations.ViewerCollectiveDraftsQuery, options)
}
export function useViewerCollectiveDraftsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewerCollectiveDraftsQueryResult,
    ViewerCollectiveDraftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ViewerCollectiveDraftsQueryResult,
    ViewerCollectiveDraftsQueryVariables
  >(Operations.ViewerCollectiveDraftsQuery, options)
}
export type ViewerCollectiveDraftsQueryHookResult = ReturnType<
  typeof useViewerCollectiveDraftsQuery
>
export type ViewerCollectiveDraftsQueryLazyQueryHookResult = ReturnType<
  typeof useViewerCollectiveDraftsQueryLazyQuery
>
export type ViewerCollectiveDraftsQueryQueryResult = Apollo.QueryResult<
  ViewerCollectiveDraftsQueryResult,
  ViewerCollectiveDraftsQueryVariables
>

/**
 * __useAuthMessageQuery__
 *
 * To run a query within a React component, call `useAuthMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthMessageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthMessageQueryResult,
    AuthMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthMessageQueryResult, AuthMessageQueryVariables>(
    Operations.AuthMessageQuery,
    options
  )
}
export function useAuthMessageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthMessageQueryResult,
    AuthMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthMessageQueryResult, AuthMessageQueryVariables>(
    Operations.AuthMessageQuery,
    options
  )
}
export type AuthMessageQueryHookResult = ReturnType<typeof useAuthMessageQuery>
export type AuthMessageQueryLazyQueryHookResult = ReturnType<
  typeof useAuthMessageQueryLazyQuery
>
export type AuthMessageQueryQueryResult = Apollo.QueryResult<
  AuthMessageQueryResult,
  AuthMessageQueryVariables
>

/**
 * __useBaseCollectiveQuery__
 *
 * To run a query within a React component, call `useBaseCollectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseCollectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseCollectiveQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useBaseCollectiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    BaseCollectiveQueryResult,
    BaseCollectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BaseCollectiveQueryResult,
    BaseCollectiveQueryVariables
  >(Operations.BaseCollectiveQuery, options)
}
export function useBaseCollectiveQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseCollectiveQueryResult,
    BaseCollectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BaseCollectiveQueryResult,
    BaseCollectiveQueryVariables
  >(Operations.BaseCollectiveQuery, options)
}
export type BaseCollectiveQueryHookResult = ReturnType<
  typeof useBaseCollectiveQuery
>
export type BaseCollectiveQueryLazyQueryHookResult = ReturnType<
  typeof useBaseCollectiveQueryLazyQuery
>
export type BaseCollectiveQueryQueryResult = Apollo.QueryResult<
  BaseCollectiveQueryResult,
  BaseCollectiveQueryVariables
>

/**
 * __useCollectiveAssetsQuery__
 *
 * To run a query within a React component, call `useCollectiveAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveAssetsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      assetsConnection: // value for 'assetsConnection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCollectiveAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveAssetsQueryResult,
    CollectiveAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveAssetsQueryResult,
    CollectiveAssetsQueryVariables
  >(Operations.CollectiveAssetsQuery, options)
}
export function useCollectiveAssetsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveAssetsQueryResult,
    CollectiveAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveAssetsQueryResult,
    CollectiveAssetsQueryVariables
  >(Operations.CollectiveAssetsQuery, options)
}
export type CollectiveAssetsQueryHookResult = ReturnType<
  typeof useCollectiveAssetsQuery
>
export type CollectiveAssetsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveAssetsQueryLazyQuery
>
export type CollectiveAssetsQueryQueryResult = Apollo.QueryResult<
  CollectiveAssetsQueryResult,
  CollectiveAssetsQueryVariables
>

/**
 * __useCollectiveDemoQuery__
 *
 * To run a query within a React component, call `useCollectiveDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveDemoQuery({
 *   variables: {
 *      collectiveDemoId: // value for 'collectiveDemoId'
 *   },
 * });
 */
export function useCollectiveDemoQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveDemoQueryResult,
    CollectiveDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveDemoQueryResult,
    CollectiveDemoQueryVariables
  >(Operations.CollectiveDemoQuery, options)
}
export function useCollectiveDemoQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveDemoQueryResult,
    CollectiveDemoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveDemoQueryResult,
    CollectiveDemoQueryVariables
  >(Operations.CollectiveDemoQuery, options)
}
export type CollectiveDemoQueryHookResult = ReturnType<
  typeof useCollectiveDemoQuery
>
export type CollectiveDemoQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveDemoQueryLazyQuery
>
export type CollectiveDemoQueryQueryResult = Apollo.QueryResult<
  CollectiveDemoQueryResult,
  CollectiveDemoQueryVariables
>

/**
 * __useCollectiveEngagementsQuery__
 *
 * To run a query within a React component, call `useCollectiveEngagementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveEngagementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveEngagementsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      engagementConnection: // value for 'engagementConnection'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCollectiveEngagementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveEngagementsQueryResult,
    CollectiveEngagementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveEngagementsQueryResult,
    CollectiveEngagementsQueryVariables
  >(Operations.CollectiveEngagementsQuery, options)
}
export function useCollectiveEngagementsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveEngagementsQueryResult,
    CollectiveEngagementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveEngagementsQueryResult,
    CollectiveEngagementsQueryVariables
  >(Operations.CollectiveEngagementsQuery, options)
}
export type CollectiveEngagementsQueryHookResult = ReturnType<
  typeof useCollectiveEngagementsQuery
>
export type CollectiveEngagementsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveEngagementsQueryLazyQuery
>
export type CollectiveEngagementsQueryQueryResult = Apollo.QueryResult<
  CollectiveEngagementsQueryResult,
  CollectiveEngagementsQueryVariables
>

/**
 * __useCollectiveFaqsQuery__
 *
 * To run a query within a React component, call `useCollectiveFaqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveFaqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveFaqsQuery({
 *   variables: {
 *      screen: // value for 'screen'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCollectiveFaqsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollectiveFaqsQueryResult,
    CollectiveFaqsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveFaqsQueryResult,
    CollectiveFaqsQueryVariables
  >(Operations.CollectiveFaqsQuery, options)
}
export function useCollectiveFaqsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveFaqsQueryResult,
    CollectiveFaqsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveFaqsQueryResult,
    CollectiveFaqsQueryVariables
  >(Operations.CollectiveFaqsQuery, options)
}
export type CollectiveFaqsQueryHookResult = ReturnType<
  typeof useCollectiveFaqsQuery
>
export type CollectiveFaqsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveFaqsQueryLazyQuery
>
export type CollectiveFaqsQueryQueryResult = Apollo.QueryResult<
  CollectiveFaqsQueryResult,
  CollectiveFaqsQueryVariables
>

/**
 * __useListCollectiveLogsQuery__
 *
 * To run a query within a React component, call `useListCollectiveLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollectiveLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollectiveLogsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      requestConnection: // value for 'requestConnection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCollectiveLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListCollectiveLogsQueryResult,
    ListCollectiveLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListCollectiveLogsQueryResult,
    ListCollectiveLogsQueryVariables
  >(Operations.ListCollectiveLogsQuery, options)
}
export function useListCollectiveLogsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCollectiveLogsQueryResult,
    ListCollectiveLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListCollectiveLogsQueryResult,
    ListCollectiveLogsQueryVariables
  >(Operations.ListCollectiveLogsQuery, options)
}
export type ListCollectiveLogsQueryHookResult = ReturnType<
  typeof useListCollectiveLogsQuery
>
export type ListCollectiveLogsQueryLazyQueryHookResult = ReturnType<
  typeof useListCollectiveLogsQueryLazyQuery
>
export type ListCollectiveLogsQueryQueryResult = Apollo.QueryResult<
  ListCollectiveLogsQueryResult,
  ListCollectiveLogsQueryVariables
>

/**
 * __useCollectiveMemberFileExportQuery__
 *
 * To run a query within a React component, call `useCollectiveMemberFileExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveMemberFileExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveMemberFileExportQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      fileExportId: // value for 'fileExportId'
 *   },
 * });
 */
export function useCollectiveMemberFileExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveMemberFileExportQueryResult,
    CollectiveMemberFileExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveMemberFileExportQueryResult,
    CollectiveMemberFileExportQueryVariables
  >(Operations.CollectiveMemberFileExportQuery, options)
}
export function useCollectiveMemberFileExportQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveMemberFileExportQueryResult,
    CollectiveMemberFileExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveMemberFileExportQueryResult,
    CollectiveMemberFileExportQueryVariables
  >(Operations.CollectiveMemberFileExportQuery, options)
}
export type CollectiveMemberFileExportQueryHookResult = ReturnType<
  typeof useCollectiveMemberFileExportQuery
>
export type CollectiveMemberFileExportQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveMemberFileExportQueryLazyQuery
>
export type CollectiveMemberFileExportQueryQueryResult = Apollo.QueryResult<
  CollectiveMemberFileExportQueryResult,
  CollectiveMemberFileExportQueryVariables
>

/**
 * __useCollectiveMembersQuery__
 *
 * To run a query within a React component, call `useCollectiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveMembersQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      memberConnection: // value for 'memberConnection'
 *   },
 * });
 */
export function useCollectiveMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveMembersQueryResult,
    CollectiveMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveMembersQueryResult,
    CollectiveMembersQueryVariables
  >(Operations.CollectiveMembersQuery, options)
}
export function useCollectiveMembersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveMembersQueryResult,
    CollectiveMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveMembersQueryResult,
    CollectiveMembersQueryVariables
  >(Operations.CollectiveMembersQuery, options)
}
export type CollectiveMembersQueryHookResult = ReturnType<
  typeof useCollectiveMembersQuery
>
export type CollectiveMembersQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveMembersQueryLazyQuery
>
export type CollectiveMembersQueryQueryResult = Apollo.QueryResult<
  CollectiveMembersQueryResult,
  CollectiveMembersQueryVariables
>

/**
 * __useCollectiveMembershipRequestsQuery__
 *
 * To run a query within a React component, call `useCollectiveMembershipRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveMembershipRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveMembershipRequestsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useCollectiveMembershipRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveMembershipRequestsQueryResult,
    CollectiveMembershipRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveMembershipRequestsQueryResult,
    CollectiveMembershipRequestsQueryVariables
  >(Operations.CollectiveMembershipRequestsQuery, options)
}
export function useCollectiveMembershipRequestsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveMembershipRequestsQueryResult,
    CollectiveMembershipRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveMembershipRequestsQueryResult,
    CollectiveMembershipRequestsQueryVariables
  >(Operations.CollectiveMembershipRequestsQuery, options)
}
export type CollectiveMembershipRequestsQueryHookResult = ReturnType<
  typeof useCollectiveMembershipRequestsQuery
>
export type CollectiveMembershipRequestsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveMembershipRequestsQueryLazyQuery
>
export type CollectiveMembershipRequestsQueryQueryResult = Apollo.QueryResult<
  CollectiveMembershipRequestsQueryResult,
  CollectiveMembershipRequestsQueryVariables
>

/**
 * __useCollectiveProposalDelegationsQuery__
 *
 * To run a query within a React component, call `useCollectiveProposalDelegationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveProposalDelegationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveProposalDelegationsQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCollectiveProposalDelegationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveProposalDelegationsQueryResult,
    CollectiveProposalDelegationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveProposalDelegationsQueryResult,
    CollectiveProposalDelegationsQueryVariables
  >(Operations.CollectiveProposalDelegationsQuery, options)
}
export function useCollectiveProposalDelegationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveProposalDelegationsQueryResult,
    CollectiveProposalDelegationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveProposalDelegationsQueryResult,
    CollectiveProposalDelegationsQueryVariables
  >(Operations.CollectiveProposalDelegationsQuery, options)
}
export type CollectiveProposalDelegationsQueryHookResult = ReturnType<
  typeof useCollectiveProposalDelegationsQuery
>
export type CollectiveProposalDelegationsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveProposalDelegationsQueryLazyQuery
>
export type CollectiveProposalDelegationsQueryQueryResult = Apollo.QueryResult<
  CollectiveProposalDelegationsQueryResult,
  CollectiveProposalDelegationsQueryVariables
>

/**
 * __useCollectiveProposalOgImageQuery__
 *
 * To run a query within a React component, call `useCollectiveProposalOgImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveProposalOgImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveProposalOgImageQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCollectiveProposalOgImageQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveProposalOgImageQueryResult,
    CollectiveProposalOgImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveProposalOgImageQueryResult,
    CollectiveProposalOgImageQueryVariables
  >(Operations.CollectiveProposalOgImageQuery, options)
}
export function useCollectiveProposalOgImageQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveProposalOgImageQueryResult,
    CollectiveProposalOgImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveProposalOgImageQueryResult,
    CollectiveProposalOgImageQueryVariables
  >(Operations.CollectiveProposalOgImageQuery, options)
}
export type CollectiveProposalOgImageQueryHookResult = ReturnType<
  typeof useCollectiveProposalOgImageQuery
>
export type CollectiveProposalOgImageQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveProposalOgImageQueryLazyQuery
>
export type CollectiveProposalOgImageQueryQueryResult = Apollo.QueryResult<
  CollectiveProposalOgImageQueryResult,
  CollectiveProposalOgImageQueryVariables
>

/**
 * __useCollectiveProposalQuery__
 *
 * To run a query within a React component, call `useCollectiveProposalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveProposalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveProposalQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCollectiveProposalQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveProposalQueryResult,
    CollectiveProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveProposalQueryResult,
    CollectiveProposalQueryVariables
  >(Operations.CollectiveProposalQuery, options)
}
export function useCollectiveProposalQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveProposalQueryResult,
    CollectiveProposalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveProposalQueryResult,
    CollectiveProposalQueryVariables
  >(Operations.CollectiveProposalQuery, options)
}
export type CollectiveProposalQueryHookResult = ReturnType<
  typeof useCollectiveProposalQuery
>
export type CollectiveProposalQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveProposalQueryLazyQuery
>
export type CollectiveProposalQueryQueryResult = Apollo.QueryResult<
  CollectiveProposalQueryResult,
  CollectiveProposalQueryVariables
>

/**
 * __useCollectiveVotesQuery__
 *
 * To run a query within a React component, call `useCollectiveVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveVotesQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *      voteConnection: // value for 'voteConnection'
 *   },
 * });
 */
export function useCollectiveVotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveVotesQueryResult,
    CollectiveVotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveVotesQueryResult,
    CollectiveVotesQueryVariables
  >(Operations.CollectiveVotesQuery, options)
}
export function useCollectiveVotesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveVotesQueryResult,
    CollectiveVotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveVotesQueryResult,
    CollectiveVotesQueryVariables
  >(Operations.CollectiveVotesQuery, options)
}
export type CollectiveVotesQueryHookResult = ReturnType<
  typeof useCollectiveVotesQuery
>
export type CollectiveVotesQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveVotesQueryLazyQuery
>
export type CollectiveVotesQueryQueryResult = Apollo.QueryResult<
  CollectiveVotesQueryResult,
  CollectiveVotesQueryVariables
>

/**
 * __useCollectiveProposalsQuery__
 *
 * To run a query within a React component, call `useCollectiveProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveProposalsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      proposalConnection: // value for 'proposalConnection'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useCollectiveProposalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveProposalsQueryResult,
    CollectiveProposalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveProposalsQueryResult,
    CollectiveProposalsQueryVariables
  >(Operations.CollectiveProposalsQuery, options)
}
export function useCollectiveProposalsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveProposalsQueryResult,
    CollectiveProposalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveProposalsQueryResult,
    CollectiveProposalsQueryVariables
  >(Operations.CollectiveProposalsQuery, options)
}
export type CollectiveProposalsQueryHookResult = ReturnType<
  typeof useCollectiveProposalsQuery
>
export type CollectiveProposalsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveProposalsQueryLazyQuery
>
export type CollectiveProposalsQueryQueryResult = Apollo.QueryResult<
  CollectiveProposalsQueryResult,
  CollectiveProposalsQueryVariables
>

/**
 * __useCollectiveQuery__
 *
 * To run a query within a React component, call `useCollectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      proposalConnection: // value for 'proposalConnection'
 *   },
 * });
 */
export function useCollectiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveQueryResult,
    CollectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CollectiveQueryResult, CollectiveQueryVariables>(
    Operations.CollectiveQuery,
    options
  )
}
export function useCollectiveQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveQueryResult,
    CollectiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CollectiveQueryResult, CollectiveQueryVariables>(
    Operations.CollectiveQuery,
    options
  )
}
export type CollectiveQueryHookResult = ReturnType<typeof useCollectiveQuery>
export type CollectiveQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveQueryLazyQuery
>
export type CollectiveQueryQueryResult = Apollo.QueryResult<
  CollectiveQueryResult,
  CollectiveQueryVariables
>

/**
 * __useCollectiveSignatorsQuery__
 *
 * To run a query within a React component, call `useCollectiveSignatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveSignatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveSignatorsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useCollectiveSignatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveSignatorsQueryResult,
    CollectiveSignatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveSignatorsQueryResult,
    CollectiveSignatorsQueryVariables
  >(Operations.CollectiveSignatorsQuery, options)
}
export function useCollectiveSignatorsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveSignatorsQueryResult,
    CollectiveSignatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveSignatorsQueryResult,
    CollectiveSignatorsQueryVariables
  >(Operations.CollectiveSignatorsQuery, options)
}
export type CollectiveSignatorsQueryHookResult = ReturnType<
  typeof useCollectiveSignatorsQuery
>
export type CollectiveSignatorsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveSignatorsQueryLazyQuery
>
export type CollectiveSignatorsQueryQueryResult = Apollo.QueryResult<
  CollectiveSignatorsQueryResult,
  CollectiveSignatorsQueryVariables
>

/**
 * __useCollectiveStatsQuery__
 *
 * To run a query within a React component, call `useCollectiveStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveStatsQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCollectiveStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveStatsQueryResult,
    CollectiveStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectiveStatsQueryResult,
    CollectiveStatsQueryVariables
  >(Operations.CollectiveStatsQuery, options)
}
export function useCollectiveStatsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveStatsQueryResult,
    CollectiveStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveStatsQueryResult,
    CollectiveStatsQueryVariables
  >(Operations.CollectiveStatsQuery, options)
}
export type CollectiveStatsQueryHookResult = ReturnType<
  typeof useCollectiveStatsQuery
>
export type CollectiveStatsQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveStatsQueryLazyQuery
>
export type CollectiveStatsQueryQueryResult = Apollo.QueryResult<
  CollectiveStatsQueryResult,
  CollectiveStatsQueryVariables
>

/**
 * __useCollectiveTosQuery__
 *
 * To run a query within a React component, call `useCollectiveTosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveTosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveTosQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useCollectiveTosQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectiveTosQueryResult,
    CollectiveTosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CollectiveTosQueryResult, CollectiveTosQueryVariables>(
    Operations.CollectiveTosQuery,
    options
  )
}
export function useCollectiveTosQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectiveTosQueryResult,
    CollectiveTosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectiveTosQueryResult,
    CollectiveTosQueryVariables
  >(Operations.CollectiveTosQuery, options)
}
export type CollectiveTosQueryHookResult = ReturnType<
  typeof useCollectiveTosQuery
>
export type CollectiveTosQueryLazyQueryHookResult = ReturnType<
  typeof useCollectiveTosQueryLazyQuery
>
export type CollectiveTosQueryQueryResult = Apollo.QueryResult<
  CollectiveTosQueryResult,
  CollectiveTosQueryVariables
>

/**
 * __useCollectivesDashboardQuery__
 *
 * To run a query within a React component, call `useCollectivesDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectivesDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectivesDashboardQuery({
 *   variables: {
 *      collectiveConnection: // value for 'collectiveConnection'
 *   },
 * });
 */
export function useCollectivesDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectivesDashboardQueryResult,
    CollectivesDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectivesDashboardQueryResult,
    CollectivesDashboardQueryVariables
  >(Operations.CollectivesDashboardQuery, options)
}
export function useCollectivesDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectivesDashboardQueryResult,
    CollectivesDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectivesDashboardQueryResult,
    CollectivesDashboardQueryVariables
  >(Operations.CollectivesDashboardQuery, options)
}
export type CollectivesDashboardQueryHookResult = ReturnType<
  typeof useCollectivesDashboardQuery
>
export type CollectivesDashboardQueryLazyQueryHookResult = ReturnType<
  typeof useCollectivesDashboardQueryLazyQuery
>
export type CollectivesDashboardQueryQueryResult = Apollo.QueryResult<
  CollectivesDashboardQueryResult,
  CollectivesDashboardQueryVariables
>

/**
 * __useCollectivesQuery__
 *
 * To run a query within a React component, call `useCollectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectivesQuery({
 *   variables: {
 *      collectiveConnection: // value for 'collectiveConnection'
 *   },
 * });
 */
export function useCollectivesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollectivesQueryResult,
    CollectivesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CollectivesQueryResult, CollectivesQueryVariables>(
    Operations.CollectivesQuery,
    options
  )
}
export function useCollectivesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectivesQueryResult,
    CollectivesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CollectivesQueryResult, CollectivesQueryVariables>(
    Operations.CollectivesQuery,
    options
  )
}
export type CollectivesQueryHookResult = ReturnType<typeof useCollectivesQuery>
export type CollectivesQueryLazyQueryHookResult = ReturnType<
  typeof useCollectivesQueryLazyQuery
>
export type CollectivesQueryQueryResult = Apollo.QueryResult<
  CollectivesQueryResult,
  CollectivesQueryVariables
>

/**
 * __useContractAbiQuery__
 *
 * To run a query within a React component, call `useContractAbiQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractAbiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractAbiQuery({
 *   variables: {
 *      address: // value for 'address'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useContractAbiQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractAbiQueryResult,
    ContractAbiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractAbiQueryResult, ContractAbiQueryVariables>(
    Operations.ContractAbiQuery,
    options
  )
}
export function useContractAbiQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractAbiQueryResult,
    ContractAbiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractAbiQueryResult, ContractAbiQueryVariables>(
    Operations.ContractAbiQuery,
    options
  )
}
export type ContractAbiQueryHookResult = ReturnType<typeof useContractAbiQuery>
export type ContractAbiQueryLazyQueryHookResult = ReturnType<
  typeof useContractAbiQueryLazyQuery
>
export type ContractAbiQueryQueryResult = Apollo.QueryResult<
  ContractAbiQueryResult,
  ContractAbiQueryVariables
>

/**
 * __useDelegatesGrantedByViewerQuery__
 *
 * To run a query within a React component, call `useDelegatesGrantedByViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelegatesGrantedByViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelegatesGrantedByViewerQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useDelegatesGrantedByViewerQuery(
  baseOptions: Apollo.QueryHookOptions<
    DelegatesGrantedByViewerQueryResult,
    DelegatesGrantedByViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DelegatesGrantedByViewerQueryResult,
    DelegatesGrantedByViewerQueryVariables
  >(Operations.DelegatesGrantedByViewerQuery, options)
}
export function useDelegatesGrantedByViewerQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DelegatesGrantedByViewerQueryResult,
    DelegatesGrantedByViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DelegatesGrantedByViewerQueryResult,
    DelegatesGrantedByViewerQueryVariables
  >(Operations.DelegatesGrantedByViewerQuery, options)
}
export type DelegatesGrantedByViewerQueryHookResult = ReturnType<
  typeof useDelegatesGrantedByViewerQuery
>
export type DelegatesGrantedByViewerQueryLazyQueryHookResult = ReturnType<
  typeof useDelegatesGrantedByViewerQueryLazyQuery
>
export type DelegatesGrantedByViewerQueryQueryResult = Apollo.QueryResult<
  DelegatesGrantedByViewerQueryResult,
  DelegatesGrantedByViewerQueryVariables
>

/**
 * __useDelegatesGrantedToViewerQuery__
 *
 * To run a query within a React component, call `useDelegatesGrantedToViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelegatesGrantedToViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelegatesGrantedToViewerQuery({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useDelegatesGrantedToViewerQuery(
  baseOptions: Apollo.QueryHookOptions<
    DelegatesGrantedToViewerQueryResult,
    DelegatesGrantedToViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DelegatesGrantedToViewerQueryResult,
    DelegatesGrantedToViewerQueryVariables
  >(Operations.DelegatesGrantedToViewerQuery, options)
}
export function useDelegatesGrantedToViewerQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DelegatesGrantedToViewerQueryResult,
    DelegatesGrantedToViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DelegatesGrantedToViewerQueryResult,
    DelegatesGrantedToViewerQueryVariables
  >(Operations.DelegatesGrantedToViewerQuery, options)
}
export type DelegatesGrantedToViewerQueryHookResult = ReturnType<
  typeof useDelegatesGrantedToViewerQuery
>
export type DelegatesGrantedToViewerQueryLazyQueryHookResult = ReturnType<
  typeof useDelegatesGrantedToViewerQueryLazyQuery
>
export type DelegatesGrantedToViewerQueryQueryResult = Apollo.QueryResult<
  DelegatesGrantedToViewerQueryResult,
  DelegatesGrantedToViewerQueryVariables
>

/**
 * __useEstimatedExecutionGasQuery__
 *
 * To run a query within a React component, call `useEstimatedExecutionGasQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatedExecutionGasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatedExecutionGasQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useEstimatedExecutionGasQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimatedExecutionGasQueryResult,
    EstimatedExecutionGasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EstimatedExecutionGasQueryResult,
    EstimatedExecutionGasQueryVariables
  >(Operations.EstimatedExecutionGasQuery, options)
}
export function useEstimatedExecutionGasQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatedExecutionGasQueryResult,
    EstimatedExecutionGasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EstimatedExecutionGasQueryResult,
    EstimatedExecutionGasQueryVariables
  >(Operations.EstimatedExecutionGasQuery, options)
}
export type EstimatedExecutionGasQueryHookResult = ReturnType<
  typeof useEstimatedExecutionGasQuery
>
export type EstimatedExecutionGasQueryLazyQueryHookResult = ReturnType<
  typeof useEstimatedExecutionGasQueryLazyQuery
>
export type EstimatedExecutionGasQueryQueryResult = Apollo.QueryResult<
  EstimatedExecutionGasQueryResult,
  EstimatedExecutionGasQueryVariables
>

/**
 * __useCategoryThreadsQuery__
 *
 * To run a query within a React component, call `useCategoryThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryThreadsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      threadConnection: // value for 'threadConnection'
 *      collaboratorConnection: // value for 'collaboratorConnection'
 *   },
 * });
 */
export function useCategoryThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoryThreadsQueryResult,
    CategoryThreadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CategoryThreadsQueryResult,
    CategoryThreadsQueryVariables
  >(Operations.CategoryThreadsQuery, options)
}
export function useCategoryThreadsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoryThreadsQueryResult,
    CategoryThreadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CategoryThreadsQueryResult,
    CategoryThreadsQueryVariables
  >(Operations.CategoryThreadsQuery, options)
}
export type CategoryThreadsQueryHookResult = ReturnType<
  typeof useCategoryThreadsQuery
>
export type CategoryThreadsQueryLazyQueryHookResult = ReturnType<
  typeof useCategoryThreadsQueryLazyQuery
>
export type CategoryThreadsQueryQueryResult = Apollo.QueryResult<
  CategoryThreadsQueryResult,
  CategoryThreadsQueryVariables
>

/**
 * __useCommentQuery__
 *
 * To run a query within a React component, call `useCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useCommentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommentQueryResult,
    CommentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CommentQueryResult, CommentQueryVariables>(
    Operations.CommentQuery,
    options
  )
}
export function useCommentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommentQueryResult,
    CommentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CommentQueryResult, CommentQueryVariables>(
    Operations.CommentQuery,
    options
  )
}
export type CommentQueryHookResult = ReturnType<typeof useCommentQuery>
export type CommentQueryLazyQueryHookResult = ReturnType<
  typeof useCommentQueryLazyQuery
>
export type CommentQueryQueryResult = Apollo.QueryResult<
  CommentQueryResult,
  CommentQueryVariables
>

/**
 * __useCommentRepliesQuery__
 *
 * To run a query within a React component, call `useCommentRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentRepliesQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      commentConnection: // value for 'commentConnection'
 *   },
 * });
 */
export function useCommentRepliesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommentRepliesQueryResult,
    CommentRepliesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CommentRepliesQueryResult,
    CommentRepliesQueryVariables
  >(Operations.CommentRepliesQuery, options)
}
export function useCommentRepliesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommentRepliesQueryResult,
    CommentRepliesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CommentRepliesQueryResult,
    CommentRepliesQueryVariables
  >(Operations.CommentRepliesQuery, options)
}
export type CommentRepliesQueryHookResult = ReturnType<
  typeof useCommentRepliesQuery
>
export type CommentRepliesQueryLazyQueryHookResult = ReturnType<
  typeof useCommentRepliesQueryLazyQuery
>
export type CommentRepliesQueryQueryResult = Apollo.QueryResult<
  CommentRepliesQueryResult,
  CommentRepliesQueryVariables
>

/**
 * __useDiscussionBoardQuery__
 *
 * To run a query within a React component, call `useDiscussionBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionBoardQuery({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *   },
 * });
 */
export function useDiscussionBoardQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscussionBoardQueryResult,
    DiscussionBoardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DiscussionBoardQueryResult,
    DiscussionBoardQueryVariables
  >(Operations.DiscussionBoardQuery, options)
}
export function useDiscussionBoardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscussionBoardQueryResult,
    DiscussionBoardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DiscussionBoardQueryResult,
    DiscussionBoardQueryVariables
  >(Operations.DiscussionBoardQuery, options)
}
export type DiscussionBoardQueryHookResult = ReturnType<
  typeof useDiscussionBoardQuery
>
export type DiscussionBoardQueryLazyQueryHookResult = ReturnType<
  typeof useDiscussionBoardQueryLazyQuery
>
export type DiscussionBoardQueryQueryResult = Apollo.QueryResult<
  DiscussionBoardQueryResult,
  DiscussionBoardQueryVariables
>

/**
 * __useThreadCollaboratorsQuery__
 *
 * To run a query within a React component, call `useThreadCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadCollaboratorsQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      collaboratorConnection: // value for 'collaboratorConnection'
 *   },
 * });
 */
export function useThreadCollaboratorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThreadCollaboratorsQueryResult,
    ThreadCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ThreadCollaboratorsQueryResult,
    ThreadCollaboratorsQueryVariables
  >(Operations.ThreadCollaboratorsQuery, options)
}
export function useThreadCollaboratorsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadCollaboratorsQueryResult,
    ThreadCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ThreadCollaboratorsQueryResult,
    ThreadCollaboratorsQueryVariables
  >(Operations.ThreadCollaboratorsQuery, options)
}
export type ThreadCollaboratorsQueryHookResult = ReturnType<
  typeof useThreadCollaboratorsQuery
>
export type ThreadCollaboratorsQueryLazyQueryHookResult = ReturnType<
  typeof useThreadCollaboratorsQueryLazyQuery
>
export type ThreadCollaboratorsQueryQueryResult = Apollo.QueryResult<
  ThreadCollaboratorsQueryResult,
  ThreadCollaboratorsQueryVariables
>

/**
 * __useThreadCommentsQuery__
 *
 * To run a query within a React component, call `useThreadCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadCommentsQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      commentConnection: // value for 'commentConnection'
 *      repliesConnection: // value for 'repliesConnection'
 *      collaboratorConnection: // value for 'collaboratorConnection'
 *   },
 * });
 */
export function useThreadCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThreadCommentsQueryResult,
    ThreadCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ThreadCommentsQueryResult,
    ThreadCommentsQueryVariables
  >(Operations.ThreadCommentsQuery, options)
}
export function useThreadCommentsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadCommentsQueryResult,
    ThreadCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ThreadCommentsQueryResult,
    ThreadCommentsQueryVariables
  >(Operations.ThreadCommentsQuery, options)
}
export type ThreadCommentsQueryHookResult = ReturnType<
  typeof useThreadCommentsQuery
>
export type ThreadCommentsQueryLazyQueryHookResult = ReturnType<
  typeof useThreadCommentsQueryLazyQuery
>
export type ThreadCommentsQueryQueryResult = Apollo.QueryResult<
  ThreadCommentsQueryResult,
  ThreadCommentsQueryVariables
>

/**
 * __useThreadFileGetURLQuery__
 *
 * To run a query within a React component, call `useThreadFileGetURLQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadFileGetURLQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadFileGetURLQuery({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useThreadFileGetURLQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThreadFileGetURLQueryResult,
    ThreadFileGetURLQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ThreadFileGetURLQueryResult,
    ThreadFileGetURLQueryVariables
  >(Operations.ThreadFileGetURLQuery, options)
}
export function useThreadFileGetURLQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadFileGetURLQueryResult,
    ThreadFileGetURLQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ThreadFileGetURLQueryResult,
    ThreadFileGetURLQueryVariables
  >(Operations.ThreadFileGetURLQuery, options)
}
export type ThreadFileGetURLQueryHookResult = ReturnType<
  typeof useThreadFileGetURLQuery
>
export type ThreadFileGetURLQueryLazyQueryHookResult = ReturnType<
  typeof useThreadFileGetURLQueryLazyQuery
>
export type ThreadFileGetURLQueryQueryResult = Apollo.QueryResult<
  ThreadFileGetURLQueryResult,
  ThreadFileGetURLQueryVariables
>

/**
 * __useThreadFileUploadURLQuery__
 *
 * To run a query within a React component, call `useThreadFileUploadURLQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadFileUploadURLQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadFileUploadURLQuery({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *      fileName: // value for 'fileName'
 *      fileExtension: // value for 'fileExtension'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useThreadFileUploadURLQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThreadFileUploadURLQueryResult,
    ThreadFileUploadURLQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ThreadFileUploadURLQueryResult,
    ThreadFileUploadURLQueryVariables
  >(Operations.ThreadFileUploadURLQuery, options)
}
export function useThreadFileUploadURLQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadFileUploadURLQueryResult,
    ThreadFileUploadURLQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ThreadFileUploadURLQueryResult,
    ThreadFileUploadURLQueryVariables
  >(Operations.ThreadFileUploadURLQuery, options)
}
export type ThreadFileUploadURLQueryHookResult = ReturnType<
  typeof useThreadFileUploadURLQuery
>
export type ThreadFileUploadURLQueryLazyQueryHookResult = ReturnType<
  typeof useThreadFileUploadURLQueryLazyQuery
>
export type ThreadFileUploadURLQueryQueryResult = Apollo.QueryResult<
  ThreadFileUploadURLQueryResult,
  ThreadFileUploadURLQueryVariables
>

/**
 * __useThreadQuery__
 *
 * To run a query within a React component, call `useThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadQuery({
 *   variables: {
 *      threadId: // value for 'threadId'
 *      collaboratorConnection: // value for 'collaboratorConnection'
 *   },
 * });
 */
export function useThreadQuery(
  baseOptions: Apollo.QueryHookOptions<ThreadQueryResult, ThreadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ThreadQueryResult, ThreadQueryVariables>(
    Operations.ThreadQuery,
    options
  )
}
export function useThreadQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadQueryResult,
    ThreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ThreadQueryResult, ThreadQueryVariables>(
    Operations.ThreadQuery,
    options
  )
}
export type ThreadQueryHookResult = ReturnType<typeof useThreadQuery>
export type ThreadQueryLazyQueryHookResult = ReturnType<
  typeof useThreadQueryLazyQuery
>
export type ThreadQueryQueryResult = Apollo.QueryResult<
  ThreadQueryResult,
  ThreadQueryVariables
>

/**
 * __useCurrentEventQuery__
 *
 * To run a query within a React component, call `useCurrentEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentEventQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentEventQueryResult,
    CurrentEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentEventQueryResult, CurrentEventQueryVariables>(
    Operations.CurrentEventQuery,
    options
  )
}
export function useCurrentEventQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentEventQueryResult,
    CurrentEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentEventQueryResult,
    CurrentEventQueryVariables
  >(Operations.CurrentEventQuery, options)
}
export type CurrentEventQueryHookResult = ReturnType<
  typeof useCurrentEventQuery
>
export type CurrentEventQueryLazyQueryHookResult = ReturnType<
  typeof useCurrentEventQueryLazyQuery
>
export type CurrentEventQueryQueryResult = Apollo.QueryResult<
  CurrentEventQueryResult,
  CurrentEventQueryVariables
>

/**
 * __useEventHeartbeatQuery__
 *
 * To run a query within a React component, call `useEventHeartbeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventHeartbeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventHeartbeatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventHeartbeatQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventHeartbeatQueryResult,
    EventHeartbeatQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventHeartbeatQueryResult,
    EventHeartbeatQueryVariables
  >(Operations.EventHeartbeatQuery, options)
}
export function useEventHeartbeatQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventHeartbeatQueryResult,
    EventHeartbeatQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventHeartbeatQueryResult,
    EventHeartbeatQueryVariables
  >(Operations.EventHeartbeatQuery, options)
}
export type EventHeartbeatQueryHookResult = ReturnType<
  typeof useEventHeartbeatQuery
>
export type EventHeartbeatQueryLazyQueryHookResult = ReturnType<
  typeof useEventHeartbeatQueryLazyQuery
>
export type EventHeartbeatQueryQueryResult = Apollo.QueryResult<
  EventHeartbeatQueryResult,
  EventHeartbeatQueryVariables
>

/**
 * __useEventHostQuery__
 *
 * To run a query within a React component, call `useEventHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventHostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventHostQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventHostQueryResult,
    EventHostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventHostQueryResult, EventHostQueryVariables>(
    Operations.EventHostQuery,
    options
  )
}
export function useEventHostQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventHostQueryResult,
    EventHostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventHostQueryResult, EventHostQueryVariables>(
    Operations.EventHostQuery,
    options
  )
}
export type EventHostQueryHookResult = ReturnType<typeof useEventHostQuery>
export type EventHostQueryLazyQueryHookResult = ReturnType<
  typeof useEventHostQueryLazyQuery
>
export type EventHostQueryQueryResult = Apollo.QueryResult<
  EventHostQueryResult,
  EventHostQueryVariables
>

/**
 * __useEventMessagesQuery__
 *
 * To run a query within a React component, call `useEventMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      messageConnection: // value for 'messageConnection'
 *   },
 * });
 */
export function useEventMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventMessagesQueryResult,
    EventMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventMessagesQueryResult, EventMessagesQueryVariables>(
    Operations.EventMessagesQuery,
    options
  )
}
export function useEventMessagesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventMessagesQueryResult,
    EventMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventMessagesQueryResult,
    EventMessagesQueryVariables
  >(Operations.EventMessagesQuery, options)
}
export type EventMessagesQueryHookResult = ReturnType<
  typeof useEventMessagesQuery
>
export type EventMessagesQueryLazyQueryHookResult = ReturnType<
  typeof useEventMessagesQueryLazyQuery
>
export type EventMessagesQueryQueryResult = Apollo.QueryResult<
  EventMessagesQueryResult,
  EventMessagesQueryVariables
>

/**
 * __useEventNoShowsQuery__
 *
 * To run a query within a React component, call `useEventNoShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventNoShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventNoShowsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventNoShowsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventNoShowsQueryResult,
    EventNoShowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventNoShowsQueryResult, EventNoShowsQueryVariables>(
    Operations.EventNoShowsQuery,
    options
  )
}
export function useEventNoShowsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventNoShowsQueryResult,
    EventNoShowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventNoShowsQueryResult,
    EventNoShowsQueryVariables
  >(Operations.EventNoShowsQuery, options)
}
export type EventNoShowsQueryHookResult = ReturnType<
  typeof useEventNoShowsQuery
>
export type EventNoShowsQueryLazyQueryHookResult = ReturnType<
  typeof useEventNoShowsQueryLazyQuery
>
export type EventNoShowsQueryQueryResult = Apollo.QueryResult<
  EventNoShowsQueryResult,
  EventNoShowsQueryVariables
>

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQueryResult, EventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventQueryResult, EventQueryVariables>(
    Operations.EventQuery,
    options
  )
}
export function useEventQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventQueryResult,
    EventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventQueryResult, EventQueryVariables>(
    Operations.EventQuery,
    options
  )
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>
export type EventQueryLazyQueryHookResult = ReturnType<
  typeof useEventQueryLazyQuery
>
export type EventQueryQueryResult = Apollo.QueryResult<
  EventQueryResult,
  EventQueryVariables
>

/**
 * __useEventRegistrationsQuery__
 *
 * To run a query within a React component, call `useEventRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventRegistrationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      registrationConnection: // value for 'registrationConnection'
 *   },
 * });
 */
export function useEventRegistrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventRegistrationsQueryResult,
    EventRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventRegistrationsQueryResult,
    EventRegistrationsQueryVariables
  >(Operations.EventRegistrationsQuery, options)
}
export function useEventRegistrationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventRegistrationsQueryResult,
    EventRegistrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventRegistrationsQueryResult,
    EventRegistrationsQueryVariables
  >(Operations.EventRegistrationsQuery, options)
}
export type EventRegistrationsQueryHookResult = ReturnType<
  typeof useEventRegistrationsQuery
>
export type EventRegistrationsQueryLazyQueryHookResult = ReturnType<
  typeof useEventRegistrationsQueryLazyQuery
>
export type EventRegistrationsQueryQueryResult = Apollo.QueryResult<
  EventRegistrationsQueryResult,
  EventRegistrationsQueryVariables
>

/**
 * __useEventSeriesBySlugQuery__
 *
 * To run a query within a React component, call `useEventSeriesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSeriesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSeriesBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventSeriesBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventSeriesBySlugQueryResult,
    EventSeriesBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventSeriesBySlugQueryResult,
    EventSeriesBySlugQueryVariables
  >(Operations.EventSeriesBySlugQuery, options)
}
export function useEventSeriesBySlugQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventSeriesBySlugQueryResult,
    EventSeriesBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventSeriesBySlugQueryResult,
    EventSeriesBySlugQueryVariables
  >(Operations.EventSeriesBySlugQuery, options)
}
export type EventSeriesBySlugQueryHookResult = ReturnType<
  typeof useEventSeriesBySlugQuery
>
export type EventSeriesBySlugQueryLazyQueryHookResult = ReturnType<
  typeof useEventSeriesBySlugQueryLazyQuery
>
export type EventSeriesBySlugQueryQueryResult = Apollo.QueryResult<
  EventSeriesBySlugQueryResult,
  EventSeriesBySlugQueryVariables
>

/**
 * __useEventSeriesSuggestionQuery__
 *
 * To run a query within a React component, call `useEventSeriesSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSeriesSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSeriesSuggestionQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useEventSeriesSuggestionQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventSeriesSuggestionQueryResult,
    EventSeriesSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EventSeriesSuggestionQueryResult,
    EventSeriesSuggestionQueryVariables
  >(Operations.EventSeriesSuggestionQuery, options)
}
export function useEventSeriesSuggestionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventSeriesSuggestionQueryResult,
    EventSeriesSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventSeriesSuggestionQueryResult,
    EventSeriesSuggestionQueryVariables
  >(Operations.EventSeriesSuggestionQuery, options)
}
export type EventSeriesSuggestionQueryHookResult = ReturnType<
  typeof useEventSeriesSuggestionQuery
>
export type EventSeriesSuggestionQueryLazyQueryHookResult = ReturnType<
  typeof useEventSeriesSuggestionQueryLazyQuery
>
export type EventSeriesSuggestionQueryQueryResult = Apollo.QueryResult<
  EventSeriesSuggestionQueryResult,
  EventSeriesSuggestionQueryVariables
>

/**
 * __useFeaturedEventsQuery__
 *
 * To run a query within a React component, call `useFeaturedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedEventsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFeaturedEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeaturedEventsQueryResult,
    FeaturedEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FeaturedEventsQueryResult,
    FeaturedEventsQueryVariables
  >(Operations.FeaturedEventsQuery, options)
}
export function useFeaturedEventsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeaturedEventsQueryResult,
    FeaturedEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FeaturedEventsQueryResult,
    FeaturedEventsQueryVariables
  >(Operations.FeaturedEventsQuery, options)
}
export type FeaturedEventsQueryHookResult = ReturnType<
  typeof useFeaturedEventsQuery
>
export type FeaturedEventsQueryLazyQueryHookResult = ReturnType<
  typeof useFeaturedEventsQueryLazyQuery
>
export type FeaturedEventsQueryQueryResult = Apollo.QueryResult<
  FeaturedEventsQueryResult,
  FeaturedEventsQueryVariables
>

/**
 * __useFeaturedSpeakersQuery__
 *
 * To run a query within a React component, call `useFeaturedSpeakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedSpeakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedSpeakersQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFeaturedSpeakersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeaturedSpeakersQueryResult,
    FeaturedSpeakersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FeaturedSpeakersQueryResult,
    FeaturedSpeakersQueryVariables
  >(Operations.FeaturedSpeakersQuery, options)
}
export function useFeaturedSpeakersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeaturedSpeakersQueryResult,
    FeaturedSpeakersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FeaturedSpeakersQueryResult,
    FeaturedSpeakersQueryVariables
  >(Operations.FeaturedSpeakersQuery, options)
}
export type FeaturedSpeakersQueryHookResult = ReturnType<
  typeof useFeaturedSpeakersQuery
>
export type FeaturedSpeakersQueryLazyQueryHookResult = ReturnType<
  typeof useFeaturedSpeakersQueryLazyQuery
>
export type FeaturedSpeakersQueryQueryResult = Apollo.QueryResult<
  FeaturedSpeakersQueryResult,
  FeaturedSpeakersQueryVariables
>

/**
 * __usePreviousEventsQuery__
 *
 * To run a query within a React component, call `usePreviousEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviousEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviousEventsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function usePreviousEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PreviousEventsQueryResult,
    PreviousEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PreviousEventsQueryResult,
    PreviousEventsQueryVariables
  >(Operations.PreviousEventsQuery, options)
}
export function usePreviousEventsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreviousEventsQueryResult,
    PreviousEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PreviousEventsQueryResult,
    PreviousEventsQueryVariables
  >(Operations.PreviousEventsQuery, options)
}
export type PreviousEventsQueryHookResult = ReturnType<
  typeof usePreviousEventsQuery
>
export type PreviousEventsQueryLazyQueryHookResult = ReturnType<
  typeof usePreviousEventsQueryLazyQuery
>
export type PreviousEventsQueryQueryResult = Apollo.QueryResult<
  PreviousEventsQueryResult,
  PreviousEventsQueryVariables
>

/**
 * __useUserAssetQuery__
 *
 * To run a query within a React component, call `useUserAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAssetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      assetConnectionInput: // value for 'assetConnectionInput'
 *      assetFilters: // value for 'assetFilters'
 *   },
 * });
 */
export function useUserAssetQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAssetQueryResult,
    UserAssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserAssetQueryResult, UserAssetQueryVariables>(
    Operations.UserAssetQuery,
    options
  )
}
export function useUserAssetQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAssetQueryResult,
    UserAssetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserAssetQueryResult, UserAssetQueryVariables>(
    Operations.UserAssetQuery,
    options
  )
}
export type UserAssetQueryHookResult = ReturnType<typeof useUserAssetQuery>
export type UserAssetQueryLazyQueryHookResult = ReturnType<
  typeof useUserAssetQueryLazyQuery
>
export type UserAssetQueryQueryResult = Apollo.QueryResult<
  UserAssetQueryResult,
  UserAssetQueryVariables
>

/**
 * __useEmailVerifiedChangedSubscription__
 *
 * To run a query within a React component, call `useEmailVerifiedChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifiedChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifiedChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEmailVerifiedChangedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    EmailVerifiedChangedSubscriptionResult,
    EmailVerifiedChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    EmailVerifiedChangedSubscriptionResult,
    EmailVerifiedChangedSubscriptionVariables
  >(Operations.EmailVerifiedChangedSubscription, options)
}
export type EmailVerifiedChangedSubscriptionHookResult = ReturnType<
  typeof useEmailVerifiedChangedSubscription
>
export type EmailVerifiedChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<EmailVerifiedChangedSubscriptionResult>

/**
 * __useCollectiveChangedSubscription__
 *
 * To run a query within a React component, call `useCollectiveChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCollectiveChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectiveChangedSubscription({
 *   variables: {
 *      collectiveId: // value for 'collectiveId'
 *   },
 * });
 */
export function useCollectiveChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CollectiveChangedSubscriptionResult,
    CollectiveChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CollectiveChangedSubscriptionResult,
    CollectiveChangedSubscriptionVariables
  >(Operations.CollectiveChangedSubscription, options)
}
export type CollectiveChangedSubscriptionHookResult = ReturnType<
  typeof useCollectiveChangedSubscription
>
export type CollectiveChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<CollectiveChangedSubscriptionResult>

/**
 * __useProposalChangedSubscription__
 *
 * To run a query within a React component, call `useProposalChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProposalChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalChangedSubscription({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useProposalChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ProposalChangedSubscriptionResult,
    ProposalChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ProposalChangedSubscriptionResult,
    ProposalChangedSubscriptionVariables
  >(Operations.ProposalChangedSubscription, options)
}
export type ProposalChangedSubscriptionHookResult = ReturnType<
  typeof useProposalChangedSubscription
>
export type ProposalChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ProposalChangedSubscriptionResult>

/**
 * __useCategoryAddedSubscription__
 *
 * To run a query within a React component, call `useCategoryAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCategoryAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryAddedSubscription({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *   },
 * });
 */
export function useCategoryAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CategoryAddedSubscriptionResult,
    CategoryAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CategoryAddedSubscriptionResult,
    CategoryAddedSubscriptionVariables
  >(Operations.CategoryAddedSubscription, options)
}
export type CategoryAddedSubscriptionHookResult = ReturnType<
  typeof useCategoryAddedSubscription
>
export type CategoryAddedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<CategoryAddedSubscriptionResult>

/**
 * __useCategoryChangedSubscription__
 *
 * To run a query within a React component, call `useCategoryChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCategoryChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryChangedSubscription({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCategoryChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CategoryChangedSubscriptionResult,
    CategoryChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CategoryChangedSubscriptionResult,
    CategoryChangedSubscriptionVariables
  >(Operations.CategoryChangedSubscription, options)
}
export type CategoryChangedSubscriptionHookResult = ReturnType<
  typeof useCategoryChangedSubscription
>
export type CategoryChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<CategoryChangedSubscriptionResult>

/**
 * __useCommentAddedSubscription__
 *
 * To run a query within a React component, call `useCommentAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentAddedSubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useCommentAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentAddedSubscriptionResult,
    CommentAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CommentAddedSubscriptionResult,
    CommentAddedSubscriptionVariables
  >(Operations.CommentAddedSubscription, options)
}
export type CommentAddedSubscriptionHookResult = ReturnType<
  typeof useCommentAddedSubscription
>
export type CommentAddedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<CommentAddedSubscriptionResult>

/**
 * __useCommentChangedSubscription__
 *
 * To run a query within a React component, call `useCommentChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentChangedSubscription({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useCommentChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommentChangedSubscriptionResult,
    CommentChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CommentChangedSubscriptionResult,
    CommentChangedSubscriptionVariables
  >(Operations.CommentChangedSubscription, options)
}
export type CommentChangedSubscriptionHookResult = ReturnType<
  typeof useCommentChangedSubscription
>
export type CommentChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<CommentChangedSubscriptionResult>

/**
 * __useDiscussionBoardChangedSubscription__
 *
 * To run a query within a React component, call `useDiscussionBoardChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionBoardChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionBoardChangedSubscription({
 *   variables: {
 *      discussionBoardId: // value for 'discussionBoardId'
 *   },
 * });
 */
export function useDiscussionBoardChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DiscussionBoardChangedSubscriptionResult,
    DiscussionBoardChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    DiscussionBoardChangedSubscriptionResult,
    DiscussionBoardChangedSubscriptionVariables
  >(Operations.DiscussionBoardChangedSubscription, options)
}
export type DiscussionBoardChangedSubscriptionHookResult = ReturnType<
  typeof useDiscussionBoardChangedSubscription
>
export type DiscussionBoardChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<DiscussionBoardChangedSubscriptionResult>

/**
 * __useReplyAddedSubscription__
 *
 * To run a query within a React component, call `useReplyAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReplyAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReplyAddedSubscription({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useReplyAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ReplyAddedSubscriptionResult,
    ReplyAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ReplyAddedSubscriptionResult,
    ReplyAddedSubscriptionVariables
  >(Operations.ReplyAddedSubscription, options)
}
export type ReplyAddedSubscriptionHookResult = ReturnType<
  typeof useReplyAddedSubscription
>
export type ReplyAddedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ReplyAddedSubscriptionResult>

/**
 * __useThreadAddedSubscription__
 *
 * To run a query within a React component, call `useThreadAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useThreadAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadAddedSubscription({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useThreadAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ThreadAddedSubscriptionResult,
    ThreadAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ThreadAddedSubscriptionResult,
    ThreadAddedSubscriptionVariables
  >(Operations.ThreadAddedSubscription, options)
}
export type ThreadAddedSubscriptionHookResult = ReturnType<
  typeof useThreadAddedSubscription
>
export type ThreadAddedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ThreadAddedSubscriptionResult>

/**
 * __useThreadChangedSubscription__
 *
 * To run a query within a React component, call `useThreadChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useThreadChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadChangedSubscription({
 *   variables: {
 *      threadId: // value for 'threadId'
 *   },
 * });
 */
export function useThreadChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ThreadChangedSubscriptionResult,
    ThreadChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ThreadChangedSubscriptionResult,
    ThreadChangedSubscriptionVariables
  >(Operations.ThreadChangedSubscription, options)
}
export type ThreadChangedSubscriptionHookResult = ReturnType<
  typeof useThreadChangedSubscription
>
export type ThreadChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ThreadChangedSubscriptionResult>

/**
 * __useThreadPinnedSubscription__
 *
 * To run a query within a React component, call `useThreadPinnedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useThreadPinnedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadPinnedSubscription({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useThreadPinnedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ThreadPinnedSubscriptionResult,
    ThreadPinnedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ThreadPinnedSubscriptionResult,
    ThreadPinnedSubscriptionVariables
  >(Operations.ThreadPinnedSubscription, options)
}
export type ThreadPinnedSubscriptionHookResult = ReturnType<
  typeof useThreadPinnedSubscription
>
export type ThreadPinnedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<ThreadPinnedSubscriptionResult>

/**
 * __useEventMessageAddedSubscription__
 *
 * To run a query within a React component, call `useEventMessageAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventMessageAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMessageAddedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventMessageAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventMessageAddedSubscriptionResult,
    EventMessageAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    EventMessageAddedSubscriptionResult,
    EventMessageAddedSubscriptionVariables
  >(Operations.EventMessageAddedSubscription, options)
}
export type EventMessageAddedSubscriptionHookResult = ReturnType<
  typeof useEventMessageAddedSubscription
>
export type EventMessageAddedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<EventMessageAddedSubscriptionResult>

/**
 * __useEventOnlineGuestCountChangedSubscription__
 *
 * To run a query within a React component, call `useEventOnlineGuestCountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventOnlineGuestCountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventOnlineGuestCountChangedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventOnlineGuestCountChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventOnlineGuestCountChangedSubscriptionResult,
    EventOnlineGuestCountChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    EventOnlineGuestCountChangedSubscriptionResult,
    EventOnlineGuestCountChangedSubscriptionVariables
  >(Operations.EventOnlineGuestCountChangedSubscription, options)
}
export type EventOnlineGuestCountChangedSubscriptionHookResult = ReturnType<
  typeof useEventOnlineGuestCountChangedSubscription
>
export type EventOnlineGuestCountChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<EventOnlineGuestCountChangedSubscriptionResult>

/**
 * __useEventSignalReceivedSubscription__
 *
 * To run a query within a React component, call `useEventSignalReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventSignalReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSignalReceivedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventSignalReceivedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventSignalReceivedSubscriptionResult,
    EventSignalReceivedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    EventSignalReceivedSubscriptionResult,
    EventSignalReceivedSubscriptionVariables
  >(Operations.EventSignalReceivedSubscription, options)
}
export type EventSignalReceivedSubscriptionHookResult = ReturnType<
  typeof useEventSignalReceivedSubscription
>
export type EventSignalReceivedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<EventSignalReceivedSubscriptionResult>

/**
 * __useEventStateChangedSubscription__
 *
 * To run a query within a React component, call `useEventStateChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventStateChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventStateChangedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventStateChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventStateChangedSubscriptionResult,
    EventStateChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    EventStateChangedSubscriptionResult,
    EventStateChangedSubscriptionVariables
  >(Operations.EventStateChangedSubscription, options)
}
export type EventStateChangedSubscriptionHookResult = ReturnType<
  typeof useEventStateChangedSubscription
>
export type EventStateChangedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<EventStateChangedSubscriptionResult>

/**
 * __useEventUserPlanUpdatedSubscription__
 *
 * To run a query within a React component, call `useEventUserPlanUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventUserPlanUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventUserPlanUpdatedSubscription({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventUserPlanUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventUserPlanUpdatedSubscriptionResult,
    EventUserPlanUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    EventUserPlanUpdatedSubscriptionResult,
    EventUserPlanUpdatedSubscriptionVariables
  >(Operations.EventUserPlanUpdatedSubscription, options)
}
export type EventUserPlanUpdatedSubscriptionHookResult = ReturnType<
  typeof useEventUserPlanUpdatedSubscription
>
export type EventUserPlanUpdatedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<EventUserPlanUpdatedSubscriptionResult>
